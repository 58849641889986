// import React, { useState, useEffect, useRef } from "react";
// import { CHANGE_PASSWORD, PROFILE } from "../../constant";
// import { GET, PUT } from "../../constant/apiMethods";
// import { connect } from "react-redux";
// import Labels from "../../services/apiLabels";
// import config from "../../config";
// import profileImage from "../../assets/images/no_user.png";
// export const API_ENDPOINT = (label) => {
//   return `${config.baseUrl}${label}`;
// };
// function Profile({
//   getprofile,
//   updateprofile,
//   data,
//   profileData,
//   ChangePassword,
// }) {
//   const initialData = {
//     first_name:"",
//     last_name:"",
//     company_name:"",
//     mobile_number:"",
//     address:"",
//     city:"",
//     county:1,
//     country:1,
//     postcode:"",
//     type:1,
//     client_type:1
//   }
//   const [formData, setFormData] = useState(initialData);
//   const [imageUrl, setImageUrl] = useState(null);
//   const [file, setFile] = useState(null);
//   const [changePasswordData, setChangePasswordData] = useState({});
//   const [validation, setValidation] = useState("");
//   let [image, setImage] = useState({});
//   const ref = useRef();
//   let id = localStorage.getItem("WFAC_localstorage_auth");
//   id = JSON.parse(id);
//   id = id.id;
//   const updateData = (e) => {
//     setFormData({
//       ...formData,
//       [e.target.name]: e.target.value,
//     });
//   };
//   const changePassword = (e) => {
//     setChangePasswordData({
//       ...changePasswordData,
//       [e.target.name]: e.target.value,
//     });
//   };
//   const updatehandler = (e) => {
//     e.preventDefault();
//     if(formData.first_name && formData.last_name){
//       // && formData.company_name && formData.mobile_number && formData.address && formData.city && formData.county && formData.country && formData.postcode && formData.garagecode && formData.client_type && formData.type
//       let form_data = new FormData();
//       if(file){
//         form_data.append("image", image);
//       }
//       form_data.append("first_name", formData.first_name)
//       form_data.append("last_name", formData.last_name)
//       form_data.append("company_name", formData.company_name)
//       form_data.append("mobile_number", formData.mobile_number)
//       form_data.append("address", formData.address)
//       form_data.append("city", formData.city)
//       form_data.append("county", formData.county)
//       form_data.append("country", formData.country)
//       form_data.append("postcode", formData.postcode)
//       form_data.append("garagecode", formData.garagecode)
//       form_data.append("client_type", formData.client_type)
//       form_data.append("type", formData.type)
//       updateprofile(id, form_data);
//       getprofile(id);
//     }
//     else{
//       let d={}
//       if(! formData.first_name){
//         // console.log("password not ehir")
//         d["first_name"]=""
        
//       }
//       if(!formData.last_name){
//         d["last_name"]=""
       
//       }
//       setFormData({
//         ...formData,
//         ...d,
//       });
//     }
//   };
//   const handleChangePassword = (e) => {
//     e.preventDefault();
//     if(changePasswordData.password && changePasswordData.newpassword && changePasswordData.confirmpassword){
//       const { confirmpassword, newpassword } = changePasswordData;
//       if (newpassword !== confirmpassword) {
//         // setValidation("Password must match");
//       } else {
//         const postData = {
//           old_password: changePasswordData.password,
//           new_password: changePasswordData.newpassword,
//         };
//         ChangePassword(id,postData);
//       }
//     }
//     else{
//       let d={}
//       if(! changePasswordData.password){
//         // console.log("password not ehir")
//         d["password"]=""
        
//       }
//       if(!changePasswordData.newpassword){
//         d["newpassword"]=""
       
//       }
//       if(!changePasswordData.confirmpassword){
//         d["confirmpassword"]=""
       
//       }
//       setChangePasswordData({
//         ...changePasswordData,
//         ...d
//       });
      
   
    
//     }
//   };
//   useEffect(() => {
//     if (data) {
//       image = data?.user?.image;
//       formData.first_name = data?.user?.first_name;
//       formData.last_name = data?.user?.last_name;
//       formData.username = data?.user?.username;
//       formData.company_name = data?.user?.company_name;
//       formData.email = data?.user?.email;
//       formData.mobile_number = data?.user?.mobile_number;
//       formData.address = data?.user?.address;
//       formData.city = data?.user?.city;
//       formData.county = data?.user?.county ? data?.user?.county : "";
//       formData.country = data?.user?.country ? data?.user?.country : "";
//       formData.postcode = data?.user?.postcode;
//       formData.garagecode = data?.user?.garagecode;
//       formData.type = data?.user?.type ? data?.user?.type : "" ;
//       formData.client_type = data?.user?.client_type;
//       setFormData({ ...formData });
//     }
//   }, [data]);
//   useEffect(() => {
//     if(Object.keys(data).length==0){
//       getprofile(id);
//     }
//       }, []);
//   async function file_chosen(e) {
//     setFile(ref.current.files[0]);
//     setImage(e.target.files[0]);
//   }
//   const handleRef = (e) => {
//     e.preventDefault();
//     ref.current.click();
//   };
//   useEffect(() => {
//     if (file) {
//       setImageUrl(URL.createObjectURL(file));
//     }
//   }, [file]);
//   return (
//     <>
//       <div className="py-6 px-5 bg-white bg-opacity-75">
//         <div className="mx-auto max-w-7xl">
//           <h1 className="text-2xl font-semibold text-gray-900">Profile</h1>
//         </div>
//         <div className=" mt-3">
//           <form onSubmit={(e) => updatehandler(e)}>
//             <div className="row box8">
//               <div className="col-sm-12 d-flex justify-center align-items-center gap-3 pb-4">
//                 <div
//                   style={{
//                     display: "flex",
//                     flexDirection: "row",
//                     gap: 8,
//                     alignItems: "center",
//                   }}
//                 >
//                   <button onClick={handleRef}>
//                     <img
                      
//                       src={data?.user?.image === null || data?.user?.image ==""? profileImage : file === null ? data?.user?.image : imageUrl }
//                       alt=""
//                       className="selectImage"
//                     />
//                   </button>
//                 </div>
//                 <input
//                   onChange={(e) => file_chosen(e)}
//                   ref={ref}
//                   type="file"
//                   name="image"
//                   style={{ display: "none" }}
//                 />
//               </div>
//               <div className="col-sm-6 form-group">
//                 <label htmlFor="name-f">First Name</label>
//                 <input
//                   type="text"
//                   className="form-control"
//                   name="first_name"
//                   id="name-f"
//                   placeholder="Enter your first name."
//                   onChange={updateData}
//                   value={formData?.first_name}
//                 />
//                 {formData?.first_name==""?<label htmlFor="name-f" className="text-danger">First Name is Required</label>:null}
//               </div>
//               <div className="col-sm-6 form-group">
//                 <label htmlFor="name-l">Last name</label>
//                 <input
//                   type="text"
//                   className="form-control"
//                   name="last_name"
//                   id="name-l"
//                   placeholder="Enter your last name."
//                   onChange={updateData}
//                   value={formData?.last_name}
//                 />
//                 {formData?.last_name==""?<label htmlFor="name-f" className="text-danger">Last Name is Required</label>:null}
//               </div>
//               <div className="col-sm-6 form-group">
//                 <label htmlFor="name-l">User name</label>
//                 <input
//                   disabled
//                   type="text"
//                   className="form-control"
//                   placeholder="username"
//                   value={formData?.username}
//                 />
//               </div>
//               <div className="col-sm-6 form-group">
//                 <label htmlFor="email">Company Name</label>
//                 <input
//                   type="text"
//                   className="form-control"
//                   name="company_name"
//                   id="company_name"
//                   placeholder="Enter your company name"
//                   onChange={updateData}
//                   value={formData?.company_name}
//                 />
//               </div>
//               <div className="col-sm-6 form-group">
//                 <label htmlFor="email">Email</label>
//                 <input
//                   disabled
//                   type="email"
//                   className="form-control"
//                   placeholder="Enter your email."
//                   value={formData?.email}
//                 />
//               </div>
//               <div className="col-sm-6 form-group">
//                 <label htmlFor="email">Phone No</label>
//                 <input
//                   type="phone"
//                   className="form-control"
//                   name="mobile_number"
//                   id="mobile_number"
//                   placeholder="Enter your phone no."
//                   onChange={updateData}
//                   value={formData?.mobile_number}
//                 />
//               </div>
//               <div className="col-sm-6 form-group">
//                 <label htmlFor="address-1">Address</label>
//                 <input
//                   type="address"
//                   className="form-control"
//                   name="address"
//                   id="address"
//                   placeholder="Locality/House/Street no."
//                   onChange={updateData}
//                   value={formData?.address}
//                 />
//               </div>
//               <div className="col-sm-6 form-group">
//                 <label htmlFor="State">City</label>
//                 <input
//                   type="address"
//                   className="form-control"
//                   name="city"
//                   id="city"
//                   placeholder="Enter your city name."
//                   onChange={updateData}
//                   value={formData?.city}
//                 />
//               </div>
//               <div className="col-sm-6 form-group">
//                 <label htmlFor="Country">County</label>
//                 <select
//                   className="form-control p-1 "
//                   onChange={updateData}
//                   name="county" 
//                   value={formData?.county}
//                 >
//                   {data?.county?.map((items) => {
//                     return (
//                       <option key={items?.id} value={items.id}>
//                         {items?.name}
//                       </option>
//                     );
//                   })}
//                 </select>
//               </div>
//               <div className="col-sm-6 form-group">
//                 <label htmlFor="Country">Country</label>
//                 <select
//                   className="form-control p-1 "
//                   onChange={updateData}
//                   name="country"
//                   value={formData.country}
//                 >
//                   {data?.country?.map((items) => {
//                     return (
//                       <option key={items?.id} value={items.id}>
//                         {items?.name}
//                       </option>
//                     );
//                   })}
//                 </select>
//               </div>
//               <div className="col-sm-6 form-group">
//                 <label htmlFor="State">Post Code</label>
//                 <input
//                   type="text"
//                   className="form-control"
//                   name="postcode"
//                   id="postcode"
//                   placeholder="Enter your post code."
//                   onChange={updateData}
//                   value={formData?.postcode}
//                 />
//               </div>
//               <div className="col-sm-6 form-group">
//                 <label htmlFor="State">Garage Code</label>
//                 <input
//                   type="text"
//                   className="form-control"
//                   name="garagecode"
//                   id="garagecode"
//                   placeholder="Enter your garage code."
//                   onChange={updateData}
//                   value={formData?.garagecode}
//                 />
//               </div>
//               <div className="col-sm-6 form-group">
//                 <label htmlFor="Country">Bussiness Type</label>
//                 <select
//                   className="form-control p-1 "
//                   onChange={updateData}
//                   name="type"
//                   value={formData?.type}
//                 >
//                   <option value={1}>Franchise Dealer</option>
//                   <option value={2}>Independent Dealer</option>
//                   <option value={3}>Lease</option>
//                   <option value={4}>Trade</option>
//                   <option value={5}>Other</option>
//                 </select>
//               </div>

//               <div className="col-sm-6 form-group">
//                 <label htmlFor="Country">Next Geare Capital Client</label>
//                 <select
//                   className="form-control p-1 "
//                   onChange={updateData}
//                   name="client_type"
//                   value={formData?.client_type}
//                 >
//                   <option value={1}>Yes</option>
//                   <option value={2}>No</option>
//                 </select>
//               </div>
//               <div className="col-sm-12 form-group mb-0">
//                 <button
//                   className=" w-25 btn_contacta float-right border-radius-2"
//                   type="submit"
//                 >
//                   Update
//                 </button>
//               </div>
//             </div>
//           </form>
//         </div>
//       </div>
//       <div className="py-6 px-5 bg-white bg-opacity-75 my-5">
//         <div className="mx-auto max-w-7xl">
//           <h1 className="text-2xl font-semibold text-gray-900">
//             Change Password
//           </h1>
//           <form action="" onSubmit={handleChangePassword}>
//             <div className="row box8">
//               <div className="col-sm-12">
//                 <div className="col-sm-6 form-group">
//                   <label htmlFor="password">Old Password</label>
//                   <input
//                     type="password"
//                     className="form-control"
//                     name="password"
//                     id="password"
//                     placeholder="Enter your old password."
//                     value={changePasswordData.password}
//                     onChange={changePassword}
//                   />
//                 {changePasswordData && changePasswordData.password==""?<label htmlFor="password" className="text-danger">Old Password is Required</label>:null}
//                 </div>
                
//               </div>
//               <div className="col-sm-12">
//                 <div className="col-sm-6 form-group sm:col-sm-12">
//                   <label htmlFor="newpassword">New Password</label>
//                   <input
//                     type="password"
//                     className="form-control"
//                     name="newpassword"
//                     id="newpassword"
//                     placeholder="Enter your new password."
//                     value={changePasswordData.newpassword}
//                     onChange={changePassword}
//                   />
//                   {changePasswordData && changePasswordData.newpassword==""?
                  
//                   <label htmlFor="newpassword" className="text-danger">New Password is Required</label>:null
                  
//                   }
//                   {console.log("change",changePasswordData)}
//                 </div>
//               </div>
//               <div className="col-sm-12">
//                 <div className="col-sm-6 form-group">
//                   <label htmlFor="password">Confirm Password</label>
//                   <input
//                     type="password"
//                     className="form-control"
//                     name="confirmpassword"
//                     id="confirmpassword"
//                     placeholder="Confirm your password."
//                     value={changePasswordData.confirmpassword}
//                     onChange={changePassword}
//                   />
//                   {changePasswordData && changePasswordData.confirmpassword==""?
//                   <label htmlFor="confirmpassword" className="text-danger">Confirm Password is Required</label>:
//                   changePasswordData.newpassword && changePasswordData.confirmpassword && changePasswordData.confirmpassword!=changePasswordData.newpassword?<label htmlFor="confirmpassword" className="text-danger">Password doesn't match</label>:null}
//                 </div>
//               </div>
             
//               <div className="col-sm-12 form-group mb-0">
//                 <button
//                   className=" w-25 btn_contacta float-right border-radius-2"
//                   type="submit"
//                 >
//                   Change Password
//                 </button>
//               </div>
//             </div>
//           </form>
//         </div>
//       </div>
//     </>
//   );
// }

// const mapStateToProps = (state) => ({
//   user_id: state.auth.id,
//   data: state.profile.data,
//   profileData: state.profile,
// });

// const mapDispatchToProps = (dispatch) => ({
//   // get product list
//   getprofile: (id) =>
//     dispatch({
//       type: PROFILE,
//       payload: {
//         type: PROFILE,
//         label: `${Labels.getupdateprofile}${id}`,
//         method: GET,
//         hideOverlayLoader: true,
//       },
//     }),
//   updateprofile: (id, postData) =>
//     dispatch({
//       type: PROFILE,
//       payload: {
//         type: PROFILE,
//         label: `${Labels.getupdateprofile}${id}/`,
//         method: PUT,
//         hideOverlayLoader: true,
//         data: postData,
//         contentType: true,
//       },
//     }),
//   ChangePassword: (id,postData) =>
//     dispatch({
//       type: CHANGE_PASSWORD,
//       payload: {
//         type: CHANGE_PASSWORD,
//         label: `${Labels.changePassword}${id}/`,
//         method: PUT,
//         hideOverlayLoader: true,
//         data: postData,
//       },
//     }),
// });
// export default connect(mapStateToProps, mapDispatchToProps)(Profile);




import React, { useState, useEffect, useRef } from "react";
import { CHANGE_PASSWORD, PROFILE } from "../../constant";
import { GET, POST, PATCH, PUT } from "../../constant/apiMethods";
import { connect } from "react-redux";
import Labels from "../../services/apiLabels";
import config from "../../config";
import profileImage from "../../assets/images/no_user.png";

export const API_ENDPOINT = (label) => {
  return `${config.baseUrl}${label}`;
};

function Profile({
  getprofile,
  updateprofile,
  data,
  profileData,
  ChangePassword,
}) {
  const [formData, setFormData] = useState({});
  const [imageUrl, setImageUrl] = useState(null);
  const [file, setFile] = useState(null);
  const [changePasswordData, setChangePasswordData] = useState({});
  const [validation, setValidation] = useState("");
  let [image, setImage] = useState("");
  let [county, setCounty] = useState(1);
  let [country, setCountry] = useState(1);
  const ref = useRef();
  let id = localStorage.getItem("WFAC_localstorage_auth");
  id = JSON.parse(id);
  id = id.id;

  const updateData = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const changePassword = (e) => {
    setChangePasswordData({
      ...changePasswordData,
      [e.target.name]: e.target.value,
    });
  };

  const updatehandler = (e) => {
    console.log(formData.image);
    e.preventDefault();
    const postData = {
      image: image,
      first_name: formData.first_name,
      last_name: formData.last_name,
      username: formData.username,
      company_name: formData.company_name,
      email: formData.email,
      mobile_number: formData.mobile_number,
      address: formData.address,
      city: formData.city,
      county: county,
      country: country,
      postcode: formData.postcode,
      garagecode: formData.garagecode,
      type: formData.type,
      client_type: formData.client_type,
    };
    updateprofile(id, postData);
    getprofile(id);
  };

  const handleChangePassword = (e) => {
    e.preventDefault();
    const { confirmpassword, newpassword } = changePasswordData;
    if (newpassword !== confirmpassword) {
      setValidation("Password must match");
    } else {
      const postData = {
        old_password: changePasswordData.password,
        new_password: changePasswordData.newpassword,
        id: id,
      };
      ChangePassword(postData);
    }
  };

  useEffect(() => {
    if (data) {
      image = data?.user?.image;
      formData.first_name = data?.user?.first_name;
      formData.last_name = data?.user?.last_name;
      formData.username = data?.user?.username;
      formData.company_name = data?.user?.company_name;
      formData.email = data?.user?.email;
      formData.mobile_number = data?.user?.mobile_number;
      formData.address = data?.user?.address;
      formData.city = data?.user?.city;
      county = data?.user?.county;
      country = data?.user?.country;
      formData.postcode = data?.user?.postcode;
      formData.garagecode = data?.user?.garagecode;
      formData.type = data?.user?.type;
      formData.client_type = data?.user?.client_type;
      setFormData({ ...formData });
    }
  }, [data]);

  useEffect(() => {
    getprofile(id);
  }, []);

  const readURL = (file) => {
    return new Promise((res, rej) => {
      const reader = new FileReader();
      reader.onload = (e) => res(e.target.result);
      reader.onerror = (e) => rej(e);
      reader.readAsDataURL(file);
    });
  };

  async function file_chosen(e) {
    setFile(ref.current.files[0]);
    let file = e.target.files[0];
    const url = await readURL(file);
    setImage(url);
  }

  const handleRef = (e) => {
    e.preventDefault();
    ref.current.click();
  };

  useEffect(() => {
    if (file) {
      setImageUrl(URL.createObjectURL(file));
    }
  }, [file]);

  return (
    <>
      <style jsx="true">{`
        .table-div-background {
          display: flex;
          flex-direction: column;
          height: 68vh;
        }
      `}</style>

      <div className="table-div-background">
        <div className="my-10 overflow-y-auto overflow-x-hidden container-fluid">
          <div className="mt-3">
            <form onSubmit={(e) => updatehandler(e)}>
              <div className="row box8">
                <div className="col-sm-12 d-flex justify-center align-items-center gap-3 pb-4">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: 8,
                      alignItems: "center",
                    }}
                  >
                    <button onClick={handleRef}>
                      <img
                        src={file === null ? profileImage : imageUrl}
                        alt=""
                        className="selectImage"
                      />
                    </button>
                    <p>
                      {file === null ? "No file chosen" : "1 file selected"}
                    </p>
                  </div>
                  <input
                    onChange={(e) => file_chosen(e)}
                    ref={ref}
                    type="file"
                    value={formData?.image}
                    style={{ display: "none" }}
                  />
                </div>
                <div className="col-sm-6 form-group">
                  <label htmlFor="name-f">First Name</label>
                  <input
                    type="text"
                    className="form-control"
                    name="first_name"
                    id="name-f"
                    placeholder="Enter your first name"
                    onChange={updateData}
                    required
                    value={formData?.first_name}
                  />
                </div>
                <div className="col-sm-6 form-group">
                  <label htmlFor="name-l">Last name</label>
                  <input
                    type="text"
                    className="form-control"
                    name="last_name"
                    id="name-l"
                    placeholder="Enter your last name"
                    onChange={updateData}
                    required
                    value={formData?.last_name}
                  />
                </div>
                <div className="col-sm-6 form-group">
                  <label htmlFor="name-l">User name</label>
                  <input
                    type="text"
                    className="form-control"
                    name="username"
                    id="username"
                    placeholder="username"
                    onChange={updateData}
                    required
                    value={formData?.username}
                  />
                </div>
                <div className="col-sm-6 form-group">
                  <label htmlFor="email">Company Name</label>
                  <input
                    type="text"
                    className="form-control"
                    name="company_name"
                    id="company_name"
                    placeholder="Enter your company name"
                    onChange={updateData}
                    required
                    value={formData?.company_name}
                  />
                </div>
                <div className="col-sm-6 form-group">
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    id="email"
                    placeholder="Enter your email."
                    onChange={updateData}
                    required
                    value={formData?.email}
                  />
                </div>
                <div className="col-sm-6 form-group">
                  <label htmlFor="email">Phone No</label>
                  <input
                    type="phone"
                    className="form-control"
                    name="mobile_number"
                    id="mobile_number"
                    placeholder="Enter your phone no."
                    onChange={updateData}
                    required
                    value={formData?.mobile_number}
                  />
                </div>
                <div className="col-sm-6 form-group">
                  <label htmlFor="address-1">Address</label>
                  <input
                    type="address"
                    className="form-control"
                    name="address"
                    id="address"
                    placeholder="Locality/House/Street no."
                    required
                    onChange={updateData}
                    value={formData?.address}
                  />
                </div>
                <div className="col-sm-6 form-group">
                  <label htmlFor="State">City</label>
                  <input
                    type="address"
                    className="form-control"
                    name="city"
                    id="city"
                    placeholder="Enter your city name."
                    required
                    onChange={updateData}
                    value={formData?.city}
                  />
                </div>
                <div className="col-sm-6 form-group">
                  <label htmlFor="Country">County</label>
                  <select
                    className="form-control p-1 "
                    onChange={(e) => setCounty(e.target.value)}
                    name="county"
                    value={formData?.county}
                  >
                    {data?.county?.map((items) => {
                      return (
                        <option key={items?.id} value={items.id}>
                          {items?.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="col-sm-6 form-group">
                  <label htmlFor="Country">Country</label>
                  <select
                    className="form-control p-1 "
                    onChange={(e) => setCountry(e.target.value)}
                    name="country"
                    value={formData?.country}
                  >
                    {data?.country?.map((items) => {
                      return (
                        <option key={items?.id} value={items.id}>
                          {items?.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="col-sm-6 form-group">
                  <label htmlFor="State">Post Code</label>
                  <input
                    type="text"
                    className="form-control"
                    name="postcode"
                    id="postcode"
                    placeholder="Enter your post code."
                    required
                    onChange={updateData}
                    value={formData?.postcode}
                  />
                </div>
                <div className="col-sm-6 form-group">
                  <label htmlFor="State">Garage Code</label>
                  <input
                    type="text"
                    className="form-control"
                    name="garagecode"
                    id="garagecode"
                    placeholder="Enter your garage code."
                    required
                    onChange={updateData}
                    value={formData?.garagecode}
                  />
                </div>
                <div className="col-sm-6 form-group">
                  <label htmlFor="Country">Bussiness Type</label>
                  <select
                    className="form-control p-1 "
                    onChange={updateData}
                    name="type"
                    value={formData?.type}
                  >
                    <option value="1">Franchise Dealer</option>
                    <option value="2">Independent Dealer</option>
                    <option value="3">Lease</option>
                    <option value="4">Trade</option>\
                    <option value="5">Other</option>
                  </select>
                </div>

                <div className="col-sm-6 form-group">
                  <label htmlFor="Country">Next Geare Capital Client</label>
                  <select
                    className="form-control p-1 "
                    onChange={updateData}
                    name="client_type"
                    value={formData?.client_type}
                  >
                    <option value="1">Yes</option>
                    <option value="2">No</option>
                  </select>
                </div>
                <div className="col-sm-12 d-flex justify-content-center form-group mb-0">
                  <button
                    className="w-auto px-3 btn_contacta border-radius-2"
                    type="submit"
                  >
                    Update
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div className="py-6 px-5 bg-white bg-opacity-75 my-5">
            <div className="mx-auto max-w-7xl">
              <h1 className="text-2xl font-semibold text-gray-900">
                Change Password
              </h1>
              <form action="" onSubmit={handleChangePassword}>
                <div className="row box8">
                  <div className="col-sm-12">
                    <div className="col-sm-6 form-group">
                      <label htmlFor="password">Old Password</label>
                      <input
                        type="password"
                        className="form-control"
                        name="password"
                        id="password"
                        placeholder="Enter your old password."
                        value={changePasswordData.password}
                        onChange={changePassword}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="col-sm-6 form-group sm:col-sm-12">
                      <label htmlFor="newpassword">New Password</label>
                      <input
                        type="password"
                        className="form-control"
                        name="newpassword"
                        id="newpassword"
                        placeholder="Enter your new password."
                        value={changePasswordData.newpassword}
                        onChange={changePassword}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="col-sm-6 form-group">
                      <label htmlFor="password">Confirm Password</label>
                      <input
                        type="password"
                        className="form-control"
                        name="confirmpassword"
                        id="confirmpassword"
                        placeholder="Confirm your password."
                        value={changePasswordData.confirmpassword}
                        onChange={changePassword}
                        required
                      />
                    </div>
                  </div>
                  <div
                    className="col-sm-6"
                    style={{ paddingLeft: "30px", color: "red" }}
                  >
                    {validation && <p>{validation}</p>}
                  </div>
                  <div className="col-sm-12 form-group mb-0 d-flex justify-content-center">
                    <button
                      className="w-auto px-3 btn_contacta border-radius-2"
                      type="submit"
                    >
                      Change Password
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  user_id: state.auth.id,
  data: state.profile.data,
  profileData: state.profile,
});

const mapDispatchToProps = (dispatch) => ({
  // get product list
  getprofile: (id) =>
    dispatch({
      type: PROFILE,
      payload: {
        type: PROFILE,
        label: `${Labels.getupdateprofile}${id}`,
        method: GET,
        hideOverlayLoader: true,
      },
    }),
  updateprofile: (id, postData) =>
    dispatch({
      type: PROFILE,
      payload: {
        type: PROFILE,
        label: `${Labels.getupdateprofile}${id}/`,
        method: PUT,
        hideOverlayLoader: true,
        data: postData,
      },
    }),
  ChangePassword: (postData) =>
    dispatch({
      type: CHANGE_PASSWORD,
      payload: {
        type: CHANGE_PASSWORD,
        label: `${Labels.changePassword}`,
        method: PUT,
        hideOverlayLoader: true,
        data: postData,
      },
    }),
});
export default connect(mapStateToProps, mapDispatchToProps)(Profile);