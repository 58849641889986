import React from "react";

const ArbitrationTable = ({ data }) => {
  return (
    <>
      <style jsx="true">{`
        .main-div-class {
          overflow-x: hidden;
          overflow-y: hidden;
        }

        .table-class {
          width: 100%;
        }

        .header-class {
          text-align: center;
          font-size: 0.9vw !important;
        }

        .body-class {
          font-size: 0.9vw !important;
        }

        @media only screen and (max-width: 1000px) {
          .table-wrapper {
            overflow-x: auto !important;
            margin-right: 30px !important;
          }
          .header-class {
            text-align: vertical;
            font-size: 1.5vw !important;
          }

          .body-class {
            font-size: 1.5vw !important;
          }
        }
      `}</style>

      <div className="flex flex-col">
        <div className="main-div-class">
          <div className="align-middle inline-block w-100">
            <div className="border border-gray-200 rounded-lg table-wrapper">
              <table className="table-class divide-y-2 divide-gray-200">
                <thead className="active-color text-white">
                  <tr>
                    <th
                      scope="col"
                      className="px-1 py-2.5 text-left text-base tracking-wider header-class"
                    >
                      Reg
                    </th>
                    <th
                      scope="col"
                      className="px-1 py-2.5 text-left text-base tracking-wider header-class"
                    >
                      Arrival Date
                    </th>
                    <th
                      scope="col"
                      className="px-1 py-2.5 text-left text-base tracking-wider header-class"
                    >
                      Issue
                    </th>
                    <th
                      scope="col"
                      className="px-1 py-2.5 text-left text-base tracking-wider header-class"
                    >
                      Image
                    </th>
                    <th
                      scope="col"
                      className="px-1 py-2.5 text-left text-base tracking-wider header-class"
                    >
                      Videos
                    </th>
                    <th
                      scope="col"
                      className="px-1 py-2.5 text-left text-base tracking-wider header-class"
                    >
                      Document
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {data.map((item, index) => (
                    <tr key={index}>
                      <td className="body-class px-1 py-2 whitespace-nowrap w-15">
                        {item.registration}
                      </td>
                      <td className="body-class px-1 py-2 whitespace-nowrap w-15">
                        {item.date_arrival}
                      </td>
                      <td className="body-class px-1 py-2 whitespace-nowrap w-15">
                        {item.description}
                      </td>
                      <td className="body-class px-1 py-2 whitespace-nowrap w-15">
                        {item.supporting_image && (
                          <img
                            src={item.supporting_image}
                            alt="Supporting"
                            style={{ width: 50, height: 50 }}
                          />
                        )}
                      </td>
                      <td className="body-class px-1 py-2 whitespace-nowrap w-15">
                        {item.supporting_video && (
                          <video controls style={{ width: 50, height: 50 }}>
                            <source
                              src={item.supporting_video}
                              type="video/mp4"
                            />
                          </video>
                        )}
                      </td>
                      <td className="body-class px-1 py-2 whitespace-nowrap w-15">
                        {item.supporting_document && (
                          <a
                            href={item.supporting_document}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Supporting Document
                          </a>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ArbitrationTable;