import React, {
  useEffect,
  useState,
  useLayoutEffect,
  useCallback,
} from "react";
import axios from "axios";
import { CAR_REPORT_DETAIL, CAR_REPORT } from "../../constant";
import { POST, GET } from "../../constant/apiMethods";
import { connect } from "react-redux";
import Labels from "../../services/apiLabels";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import "./style.css";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css"; // This only needs to be imported once in your app
import ImageView from "./imgeviw";
import ImageGallery from "react-image-gallery";
import { FaPlus, FaMinus } from "react-icons/fa";
import { GrFormSubtract } from "react-icons/gr";
import { ShimmerThumbnail } from "react-shimmer-effects";
import ImgsViewer from "react-images-viewer";
import Car_img from "../../assets/images/hassan2.jpg";
import Car_img2 from "../../assets/images/car_damage.png";
import { useHistory } from "react-router-dom";
import { FaSearch } from "react-icons/fa";
import TextField from "@mui/material/TextField";
import PuffLoader from "react-spinners/ClipLoader";
import { BiImage } from "react-icons/bi";
import ImageViewer from "react-simple-image-viewer";

import { ProgressBar } from "react-bootstrap";
import ProgressNew from "./progres.js";
import PropTypes from "prop-types";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import Progress from "../../components/Progress";

import { useContext } from "react";
import FeedbackContext from "../../context/cmscontext";
import {Report} from "../../pages/ReportDOC/pdf"

export const CarReport = ({
  car_report_detail_data_new,
  winerListloading,
  car_report_detail_call,
  car_report_call,
  car_report_data,
  car_report_detail_data,
  loading,
}) => {
  const location = useLocation();
  const { feedback, isLoading } = useContext(FeedbackContext);
  var car_report_page = feedback.car_report_page;
  const [divshow, setdivshow] = useState("false");
  var actualInnerWidth = document.body.clientWidth;
  var actualInnerHight = document.documentElement.clientHeight;
  console.log(car_report_detail_data_new)
  // const [screenwidth,setwidth]=useState(actualInnerWidth);
  // document.body.style.background = "#F2F4F5"
  const [inpvalue, setInpvalue] = useState();

  const [postcode, setpostcode] = useState();
  const [desp, setdesp] = useState();
  const [left, setleft] = useState();
  const [top, settop] = useState();
  const [report, setReport] = useState(false)
  const [condition, setCondition] = useState(null);
  // function updateSize() {
  //   setSize([window.innerWidth, window.innerHeight]);
  // }
  // window.addEventListener('resize', updateSize);
  // useLayoutEffect(() => {
  //   actualInnerWidth = document.body.clientWidth;
  //
  //   // function updateSize() {
  //   //   setSize([window.innerWidth, window.innerHeight]);
  //   // }
  //   // window.addEventListener('resize', updateSize);
  //   // updateSize();
  //   // return () => window.removeEventListener('resize', updateSize);
  // },);
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const imagewiewopen = () => {};

  const [images, setimages] = useState([
    "//placekitten.com/1500/500",
    "//placekitten.com/4000/3000",
    "//placekitten.com/800/1200",
    "//placekitten.com/1500/1500",
  ]);

  const [apipics, setapipics] = useState([]);

  // console.log"images galery",images,"apipics",apipics)

  const imge_wiew = (data) => {
    // setimages(data)
    setimages(React.Children.toArray(data.map((item) => item.image)));
    // setapipics(data.image)
    // console.log"imge_wiewimge_wiew",data)
    // console.logapipics,"imge_wiewimge_wiew",data.image)
    setIsViewerOpen(true);
  };

  useEffect(() => {
    if (!winerListloading) {
      handler();
    }
  }, [winerListloading]);

  // console.log"useEffectuseEffectuseEffect===============",location.state.product_code )

  useEffect(() => {
    // window.location.reload();
    // refreshPage()
    // console.log"useEffectuseEffectuseEffect",location.state.product_code )
    car_report_call({
      // id:5
      id: location.state.product_code,
    });
    car_report_detail_call({
      // id:5
      id: location.state.product_code,
    });
  }, []);

  function refreshPage() {
    window.location.reload();
  }

  // console.log"show data api",car_report_data)

  const [showdiv, setshowdiv] = useState({
    showMe: true,
  });

  const opendiv = (index) => {
    setshowdiv({
      showdiv: showdiv === index ? null : index,
    });
  };

  // console.log"chk state",showdiv)

  const closediv = () => {
    setshowdiv(false);
  };

  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  // console.logactualInnerWidth,"inp value =======widthwidth=====",actualInnerHight)

  // actualInnerWidth = document.body.clientWidth;

  // document.body.style.zoom = "70%";
  console.log(car_report_data)
  const history = useHistory();

  var main = document.getElementById("main");
  var car_div = document.getElementById("car_div");
  var point = document.getElementById("point");
  var point2 = document.getElementById("point2");
  var line = document.getElementById("overlay_pointer");

  var line_botm = document.getElementById("line_botm");
  var btotm_div = document.getElementById("btotm_div");

  const handler = () => {
    var canvas = document.getElementById("viewport"),
      context = canvas.getContext("2d");
    var base_image = new Image();
    base_image.src =
      "https://backend.sellanywheel.com/static/staticimages/car_certification_v2-e9ae77bea60843256dbd759106c2911e.jpg";
    base_image.onload = function () {
      context.drawImage(base_image, 0, 0);
    };
    main = document.getElementById("main");
    car_div = document.getElementById("car_div");
    point = document.getElementById("point");
    point2 = document.getElementById("point2");
    line = document.getElementById("overlay_pointer");

    line_botm = document.getElementById("line_botm");
    btotm_div = document.getElementById("btotm_div");
  };
  // window.addEventListener('load', handler);

  const [carpic_, setcarpic_] = useState();

  const [cardivshow, setcardivshow] = useState(false);

  // console.log"car pic....",carpic_)

  const dam1 = (data) => {
    car_div.style.display = "block";

    setcardivshow(true);
    setcarpic_(data.item.photo_url);

    // console.log"chk our condtio",data.item)
    if (width <= 900) {
      // console.log"chk condtion if small screen",actualInnerWidth)

      settop(parseInt(data.item.top, 10));
      // console.log"chk our condtio")
      // line_botm.style.height="440px"
      line_botm.style.display = "block";
      line.style.display = "none";
      const line_h2 = parseInt(data.item.left, 10) + 45;

      const line_height = parseInt(data.item.top, 10) + 15;
      const height_remove = parseInt(line_botm.style.height, 10) - line_height;

      line_botm.style.height = `${height_remove}px`;

      line_botm.style.left = `${line_h2}px`;
      const botm_div_left = parseInt(data.item.left, 10) - 50;
      btotm_div.style.left = `${botm_div_left}px`;
    } else {
      // console.log"chk condtion if large screen",actualInnerWidth)
      setleft(parseInt(data.item.left, 10));
      setcardivshow(true);
      setcarpic_(data.item.photo_url);
      line.style.width = `${actualInnerWidth - 450}px`;
      line.style.display = "block";
      line_botm.style.display = "none";
      // car_div.style.display="block"
      const bbb = parseInt(data.item.top, 10) + 65;
      car_div.style.top = `${bbb}px`;
      // // console.log"inp value ======r",line.style.width,"rrrrrrrrrrrrrrrrr======",point.style.left)
      const remove = parseInt(data.item.left, 10) + 5;
      const asas = actualInnerWidth - 310 - remove;

      // line.style.width=`${asas}px`

      // console.log"inp value ==2222222====r",line.style.width,"rrrrrrrrrrrrrrrrr======",asas)
    }
  };

  const dam2 = () => {
    line_botm.style.height = "440px";

    const line_h2 = parseInt(point2.style.left, 10) + 45;

    const line_height = parseInt(point2.style.top, 10) + 15;
    const height_remove = parseInt(line_botm.style.height, 10) - line_height;

    line_botm.style.height = `${height_remove}px`;

    line_botm.style.left = `${line_h2}px`;

    // console.log"chk botom line",line_height,"ssss",line_botm.style.height,"ssss",height_remove)

    //   line.style.width="1130px"
    //   line.style.display="block"
    //   const bbb=parseInt(point2.style.top, 10)+65
    //   car_div.style.top=`${bbb}px`

    // const remove=parseInt(point2.style.left, 10)+5
    //   const ababab=parseInt(line.style.width, 10)-remove

    //   line.style.width=`${ababab}px`

    //   // console.log"inp value ==2222222====r",line.style.width,"rrrrrrrrrrrrrrrrr======",ababab)
  };

  // console.log"api data in redux",car_report_detail_data)

  const dam3 = () => {
    // line.style.top="300px"
    car_div.style.top = "200px";
    // main.style.zoom = "50%";
  };
  const legend = () => {
    var items = [];
    var html = "";
    return React.Children.toArray(
      car_report_data.map((item) => {
        if (!items.includes(item.symbol)) {
          items.push(item.symbol);
          return (
            <p className="p_clor">
              <span className="pint_name">{item.symbol}:&nbsp;</span>
              {item.title}
            </p>
          );
        }
      })
    );
  };
  const closeviw = () => {
    setIsViewerOpen(false);
  };

  const [first, setfirst] = useState({
    photoIndex: 0,
    isOpen: false,
  });
  const { photoIndex, isOpen } = first;

  // loading
  if (winerListloading) {
    return (
      <>
        <div className="container">
          <ShimmerThumbnail height={250} rounded />
          <ShimmerThumbnail height={250} rounded />
        </div>
      </>
    );
  }

  const now = 60;
  return (
    <>
      <div className="main_section p-5">
       

        <h3>{car_report_page.report_text}</h3>
        <div className="carsure-structure-outer pos-rel clearfix mt20">
          <div className="carsure-structure">
            <img
              alt="Car_certification_v2"
              id="image_map_print"
              src={car_report_page.car_certification_image}
            />
            <div className="map maphilighted taggd  ">
              <canvas
                id="viewport"
                style={{
                  width: "553px",
                  height: "406px",
                  position: "absolute",
                  left: "0px",
                  top: "0px",
                  padding: "0px",
                  border: "0px",
                }}
                height="406"
                width="553"
              ></canvas>
              <div
                className="taggd-wrapper"
                style={{ height: " 406px", width: "553px" }}
              >
                <img
                  alt="Car_certification_v2"
                  className="map maphilighted taggd"
                  id="image_map_tag"
                  src="https://wsa4.pakwheels.com/assets/car_certification_v2-e9ae77bea60843256dbd759106c2911e.jpg"
                  style={{ display: "none" }}
                />
                {React.Children.toArray(
                  car_report_data.map((item) => {
                    return (
                      <>
                        <p
                          id="point"
                          className="car_up_text"
                          style={{ top: item.top, left: item.left }}
                          onClick={() => dam1({ item })}
                        >
                          {item.symbol}
                        </p>
                      </>
                    );
                  })
                )}
              </div>
            </div>

            <div
              id="car_div"
              className="carsure-structure-info none-print common"
              style={{ top: "150px", display: "none" }}
            >
              <div style={{ cursor: "pointer" }}>
                <img
                  src={carpic_}
                  id="overlay_image"
                  className="lslide"
                  width="100%"
                  style={{ display: "inline" }}
                  onClick={() => imge_wiew([{ image: carpic_ }])}
                ></img>
              </div>
              <span
                id="overlay_pointer"
                className="pointer"
                style={{
                  width: `${actualInnerWidth - 310 - (left + 5)}px`,
                  top: "96px",
                  display: "none",
                }}
              ></span>
            </div>
          </div>
        </div>

        <div
          id="line_botm"
          className="line_botm"
          style={{
            width: "0px",
            display: "none",
            left: "100px",
            height: `${actualInnerHight - 210 - (top + 5)}px`,
          }}
        ></div>

        <div
          className="damage_show mt-5"
          id="btotm_div"
          style={{ left: "100px" }}
        >
          <div className="pic_show">
            <img
              src={carpic_}
              id="overlay_image"
              className="lslide"
              width="100%"
              style={{ display: "inline" }}
            ></img>
          </div>
        </div>
      </div>

      <div className="container lastcontain">
        <div className="row">
          <h3 className="ml-3">{car_report_page.car_legend_text}</h3>
          <div className="title_list">{legend()}</div>
        </div>
      </div>

      <br></br>
      <br></br>

      <div className="container cicile_con">
        <div className="cicale_wrap ">
          <div style={{ width: 200, height: 200 }}>
            <CircularProgressbar
              value={car_report_detail_data_new.total_inspect}
              text={`${car_report_detail_data_new.total_inspect}/100`}
            />
          </div>
        </div>
      </div>
      <br></br>

      <div className="container progrm_contain ">
        <div className="bar_wrap row">
          {React.Children.toArray(
            car_report_detail_data.map((item) => {
              return (
                <>
                  <div className="col-6">
                    <div className="nam_wrap">
                      <p>{item.title}</p>
                      <span> {`${item.inspect_count}%`}</span>
                    </div>
                    <ProgressBar
                      now={item.inspect_count}
                      style={{ height: "0.6rem" }}
                    />
                  </div>
                </>
              );
            })
          )}
        </div>
      </div>

      <br></br>
      <br></br>
      <br></br>
      <br></br>

      <div className="container lastcontain p-5">
        {isViewerOpen ? (
          <Lightbox
            mainSrc={images[photoIndex]}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            onCloseRequest={() => setIsViewerOpen(false)}
            onMovePrevRequest={() =>
              setfirst({
                photoIndex: (photoIndex + images.length - 1) % images.length,
              })
            }
            onMoveNextRequest={() =>
              setfirst({
                photoIndex: (photoIndex + 1) % images.length,
              })
            }
          />
        ) : null}

        {false ? (
          <>
            <ShimmerThumbnail height={250} rounded />
            <ShimmerThumbnail height={250} rounded />
          </>
        ) : (
          React.Children.toArray(
            car_report_detail_data.map((item, key) => {
              return (
                <>
                  <div className="main_wrap_fist my-2 row">
                    <div className="icon-heading col-6 ">
                      {/* item.image_icon */}
                      {item.photo_url ? (
                        <img className="imgicon" src={item.photo_url}></img>
                      ) : (
                        <img className="imgicon" src={Car_img2}></img>
                      )}

                      <span className="heading ml-2 "> {item.title}</span>
                    </div>
                    <div className="ppp col-6 ">
                      <span className="textp">{`${item.inspect_count}%`}</span>
                      {showdiv.showdiv === key ? (
                        <span className="mx-2" onClick={closediv}>
                          <FaMinus />
                        </span>
                      ) : (
                        <span
                          className="mx-2"
                          onClick={() => {
                            opendiv(key);
                          }}
                        >
                          <FaPlus />
                        </span>
                      )}
                    </div>
                  </div>
                  {showdiv.showdiv === key && (
                    <div className="damge_data">
                      <div className="row row_f ">
                        {React.Children.toArray(
                          item.inspection_title.map((item) => {
                            return (
                              <>
                                {item.selected_inspection_question.length >
                                0 ? (
                                  <>
                                    <div className="col-lg-6 col-md-6 col-sm-12 border ">
                                      <div className="row">
                                        <div className="col-6 px-3 py-3 hedd ">
                                          <h4 className="">{item.question}</h4>

                                          {item.selected_inspection_question[0]
                                            .images_.length == 0 ? null : (
                                            <span
                                              className="report-issue-point"
                                              onClick={() => {
                                                imge_wiew(
                                                  item
                                                    .selected_inspection_question[0]
                                                    .images_
                                                );
                                              }}
                                            >
                                              <BiImage size={22} /> View Image
                                            </span>
                                          )}
                                        </div>
                                        <div className="col-6 p-4 non_">
                                          <h3 className="text-center">
                                            {" "}
                                            {
                                              item
                                                .selected_inspection_question[0]
                                                .choice_select_
                                            }
                                          </h3>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                ) : null}
                              </>
                            );
                          })
                        )}

                        {/* <div className="col-lg-6 col-md-6 col-sm-12">
          <div className="row">
    
          <div className="col-6 px-3 py-3 hedd ">
          <h4 className="">Radiateer</h4>
            
            
        
            <span className="report-issue-point  " onClick={imge_wiew} ><BiImage size={22}/> View Image
                    </span>
    
    
        
          </div>
          <div className="col-6 p-4 non_">
            <h3 className="text-center"> Non-slected</h3>
          </div>
    
          </div>
     
        </div> */}
                      </div>
                    </div>
                  )}
                </>
              );
            })
          )
        )}
      </div>
       <div className="flex justify-center">
          {/* {report ? 
          <Report condition={condition} 
          car_report_detail_data_new={car_report_detail_data_new} car_report_data={car_report_data} car_report_detail_data={car_report_detail_data} /> : */}
      <Link to={`/pdf/${location.state.product_code}/report`}  target="_blank" rel="noopener noreferrer" className="link">
        <button className="button_heder mb-5">Generate PDF</button>
      </Link>
        </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  car_report_data: state.carreport.car_report_data,
  car_report_detail_data: state.carreport.car_report_detail_data.data,
  car_report_detail_data_new: state.carreport.car_report_detail_data,
  loading: state.carreport.loading,
  winerListloading: state.carreport.winerListloading,
  addresform: state.shiping.addresform,
});
const mapDispatchToProps = (dispatch) => ({
  // car report
  car_report_call: (data) =>
    dispatch({
      type: CAR_REPORT,
      payload: {
        type: CAR_REPORT,
        method: POST,
        label: Labels.car_report,
        hideOverlayLoader: true,
        data,
      },
    }),
  // car report detail
  car_report_detail_call: (data) =>
    dispatch({
      type: CAR_REPORT_DETAIL,
      payload: {
        type: CAR_REPORT_DETAIL,
        method: POST,
        label: Labels.car_report_detail,
        hideOverlayLoader: true,
        data,
      },
    }),

  //
});

export default connect(mapStateToProps, mapDispatchToProps)(CarReport);
