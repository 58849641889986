import React from "react";
import { GET, DELETE } from "../constant/apiMethods";
import { MdDeleteForever } from "react-icons/md";
import Labels from "../services/apiLabels";
import { AiOutlineEdit } from "react-icons/ai";
import carimg from "../assets/images.jpg";
import { AiFillCamera } from "react-icons/ai";
import config from "../config";
import { ImHammer2 } from "react-icons/im";

export const API_ENDPOINT = (label) => {
  return `${config.baseUrl}${label}`;
};

const Table = ({
  myVehiclesList,
  getAllCars,
  setisEdit,
  setSelectedTabIdx,
  biddingAuction,
  columns,
}) => {
  const Explore = [
    {
      item: "141C9644",
      rarity: "VOLKSWAGEN Passat",
      editions: "28/11/2022 01:06 PM",
      farmon1: "€6,000",
      farmon2: "Reserve not met",
      utility: "View",
    },
    {
      item: "141C9644",
      rarity: "VOLKSWAGEN Passat",
      editions: "28/11/2022 01:06 PM",
      farmon1: "€6,000",
      farmon2: "Reserve not met",
      utility: "View",
    },
    {
      item: "141C9644",
      rarity: "VOLKSWAGEN Passat",
      editions: "28/11/2022 01:06 PM",
      farmon1: "€6,000",
      farmon2: "Reserve not met",
      utility: "View",
    },
    {
      item: "141C9644",
      rarity: "VOLKSWAGEN Passat",
      editions: "28/11/2022 01:06 PM",
      farmon1: "€6,000",
      farmon2: "Reserve not met",
      utility: "View",
    },
  ];

  const edit = (id) => {
    setisEdit(id);
    setSelectedTabIdx(1);
  };
  const Delete = (id) => {
    const payload = {
      label: `${Labels.delvechiel}${id}/`,
      method: DELETE,

      hideOverlayLoader: true,
    };

    fetchData(payload);
  };

  function fetchData(payload) {
    const { label, data, method, header } = payload;

    const parameters = {
      method,
      body: data,
    };
    fetch(API_ENDPOINT(label), parameters)
      .then((response) => {
        getAllCars();
        console.log("response-----------", response);
      })
      .then((json) => {
        return json;
      });
  }

  function formatDate(dt) {
    let datt = new Date(dt);
    console.log(datt);
    return datt.toLocaleDateString() + " " + datt.toLocaleTimeString();
  }

  function getWidth(idx) {
    console.log("Index", idx);
    if (idx === 0) {
      return "w-14";
    } else if (idx === 1) {
      return "w-30";
    } else if (idx === 2) {
      return "w-14";
    } else if (idx === 3) {
      return "w-14";
    } else if (idx === 4) {
      return "w-14";
    } else {
      return "w-14";
    }
  }

  return (
    <>
      <style jsx="true">{`
        .main-div-class {
          overflow-x: hidden;
          overflow-y: hidden;
        }

        .table-class {
          width: 100%;
        }

        .header-class {
          text-align: center;
          font-size: 0.9vw !important;
        }

        .body-class {
          font-size: 0.9vw !important;
        }

        @media only screen and (max-width: 1000px) {
          .table-wrapper {
            overflow-x: auto !important;
            margin-right: 30px !important;
          }
          .header-class {
            text-align: vertical;
            font-size: 1.5vw !important;
          }

          .body-class {
            font-size: 1.5vw !important;
          }
        }
      `}</style>

      <div className="flex flex-col">
        <div className="main-div-class">
          <div className="align-middle inline-block w-100">
            <div className="border border-gray-200 rounded-lg table-wrapper">
              <table className="table-class divide-y-2 divide-gray-200">
                <thead className="active-color text-white">
                  <tr>
                    {columns?.map((items) => {
                      return (
                        <th
                          scope="col"
                          className="px-1 py-2.5 text-left text-base  tracking-wider header-class"
                        >
                          {items}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200 ">
                  {biddingAuction &&
                    (biddingAuction?.length >= 1 ? (
                      biddingAuction?.map((item, index) => (
                        <>
                          <tr key={index}>
                            {Object.keys(item).map((item2, index2) => (
                              <>
                                <td
                                  className={`body-class px-1 py-3.5 whitespace-nowrap ${getWidth(
                                    index2
                                  )}`}
                                >
                                  {index2 === 2
                                    ? formatDate(item[item2])
                                    : item[item2]}
                                </td>
                              </>
                            ))}
                          </tr>
                        </>
                      ))
                    ) : (
                      <div className="d-flex justify-content-center align-items-center">
                        No Data Found
                      </div>
                    ))}

                  {myVehiclesList &&
                    (myVehiclesList?.length >= 1 ? (
                      myVehiclesList
                        ?.map((item, index) => (
                          <>
                            <tr key={index}>
                              <td className="body-class px-1 py-2 whitespace-nowrap w-20">
                                {item.name}
                              </td>
                              <td className="body-class px-1 py-2 whitespace-nowrap w-30">
                                {`${item.brand_name}-${item.model_name}-${item.fuel_type}`}
                              </td>
                              <td className="body-class px-1 py-2 whitespace-nowrap w-15">
                                {item.price ? item.price : "-"}
                              </td>
                              <td className="body-class px-1 py-2 whitespace-nowrap w-15">
                                <img
                                  style={{ width: 50, height: 50 }}
                                  src={
                                    item.image_method
                                      ? item.image_method
                                      : carimg
                                  }
                                  alt=""
                                ></img>
                              </td>
                              <td className="body-class px-2 py-2 whitespace-nowrap w-20 d-flex">
                                <div
                                  className="d-flex"
                                  style={{ cursor: "pointer" }}
                                >
                                  {!item.allow_delete && (
                                    <MdDeleteForever
                                      onClick={() => {
                                        Delete(item.id);
                                      }}
                                      size={20}
                                      className="mr-2"
                                    />
                                  )}
                                  {!item.hideEdit && (
                                    <AiOutlineEdit
                                      onClick={() => {
                                        edit(item.id);
                                      }}
                                      className="mr-2"
                                      size={20}
                                    />
                                  )}
                                  {!item.hideCamera && (
                                    <AiFillCamera
                                      onClick={() => {
                                        window.open(
                                          `https://inspection.sellanywheel.com/dealerinspection?id=${item.inspect_id}`
                                        );
                                      }}
                                      size={20}
                                    />
                                  )}
                                  <ImHammer2
                                    // onClick={() => {
                                    //   if (item.auction.length > 0) {
                                    //     setauctionData(item.auction[0]);
                                    //     setproduct_id(item.id);
                                    //     setShowModal(true);
                                    //     setRadioVisble(false);
                                    //     setRadio("auction");
                                    //   } else {
                                    //     setproduct_id(item.id);
                                    //     setShowModal(true);
                                    //     if (item.buy_now) {
                                    //       setRadioVisble(false);
                                    //       setRadio("buynow");
                                    //     }
                                    //   }
                                    //   setBuynowData({
                                    //     buy_now_price: item.buy_now_price,
                                    //     buy_now: item.buy_now,
                                    //     id: item.id,
                                    //   });
                                    // }}
                                    className="ml-2"
                                    size={20}
                                  />
                                </div>
                              </td>
                            </tr>
                          </>
                        ))
                        .reverse()
                    ) : (
                      <div className="d-flex justify-content-center align-items-center">
                        No Data Found
                      </div>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Table;