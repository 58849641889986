/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "./styles.css";
// importing redux related things
import { connect } from "react-redux";
// import Labels from "../../services/apiLabels";
import { TOASTER_HIDE, toaster_s, toaster_e, toaster_w } from "../../constant";
import { GrFormClose } from "react-icons/gr";
import { AiOutlineCheckCircle, AiOutlineWarning } from "react-icons/ai";
import { BiErrorCircle } from "react-icons/bi";

const Toaster = (props) => {
  const { toaster, hideToaster } = props;
  const { show, type, heading, description, autoHide } = toaster;
  
  useEffect(() => {
    if (autoHide) {
      setTimeout(() => {
        hideToaster();
      }, 10000);
    }
  }, [autoHide]);
  //
  useEffect(() => {
    if (type === toaster_s) {
      setColor("#42f22b");
    } else if (type === toaster_e) {
      setColor("#dd0808");
    } else if (type === toaster_w) {
      setColor("#e0bd0f");
    } else {
      setColor("");
    }
  }, [type]);
  const backgroundColor = "#f2f2f2";
  const [color, setColor] = useState("");
  // const descArr = ["description1"];
  return (
    <div
      style={{ backgroundColor }}
      className={show ? "toaster-container" : "toaster-hide"}
    >
      <div style={{ backgroundColor: color }} className="statusBar" />
      <span className=" ml-1 d-flex justify-content-start align-items-center ">
        {type === toaster_s ? (
          <AiOutlineCheckCircle color={color} size="1.5rem" />
        ) : type === toaster_e ? (
          <BiErrorCircle color={color} size="1.5rem" />
        ) : type === toaster_w ? (
          <AiOutlineWarning color={color} size="1.5rem" />
        ) : null}
      </span>
      <div style={{ backgroundColor }} className="toaster-content-container">
        <span className="toaster-heading">{heading ? heading : type}</span>

        {React.Children.toArray(description.map((item,i) => {
          return <p key={i} className="toaster-description"> • {item}</p>;
        }))}
        {/* <p className="toaster-description">{description}</p> */}
      </div>
      <span onClick={() => hideToaster()} className="pt-1 pr-2">
        <GrFormClose size="1.2rem" />
      </span>
    </div>
  );
};
const mapStateToProps = (state) => ({
  toaster: state.toaster,
});
const mapDispatchToProps = (dispatch) => ({
  // login action
  hideToaster: (data) =>
    dispatch({
      type: TOASTER_HIDE,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Toaster);
