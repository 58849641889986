import React, { useState, useEffect } from "react";
import "../App.css";
import Table from "./Table";
import { connect } from "react-redux";
import { GET, DELETE } from "../constant/apiMethods";
import { GET_BIDDING_AUCTION } from "../constant";
import Labels from "../services/apiLabels";

const Tabs = ({ getBiddingAuction, biddingAuction }) => {
  const [selectedTabIdx, setSelectedTabIdx] = useState(0);
  const tabs = [
    {
      name: "Active Auction",
      count: biddingAuction?.active_auction_count,
      current: true,
    },
    {
      name: "Upcoming Auction",
      count: biddingAuction?.upcoming_auction_count,
      current: false,
    },
    {
      name: "Lost Auction",
      count: biddingAuction?.lost_auction_count,
      current: false,
    },
    {
      name: "Won Auction",
      count: biddingAuction?.won_auction_count,
      current: false,
    },
  ];

  let id = localStorage.getItem("WFAC_localstorage_auth");
  id = JSON.parse(id);
  id = id.id;

  useEffect(() => {
    getBiddingAuction(id);
  }, []);

  console.log("biddingAuction\n", biddingAuction);

  return (
    <>
      <style jsx="true">{`
        .table-div-background {
          display: flex;
          flex-direction: column;
          height: 68vh;
        }

        .nav-tabs {
          width: 100%;
          height: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          border: none;
        }

        .tabs-class {
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 0.9vw;
          font-weight: bold;
          background: none;
          border: none;
          cursor: pointer;
          transition: all 0.2s ease;
        }

        .active-tab-class {
          font-size: 1vw;
          color: #178970 !important;
          background: none;
          border-bottom: 3px solid #178970;
          transition: all 0.2s ease;
        }

        @media only screen and (max-width: 1000px) {
          .table-div-background {
            margin-right: 2rem !important;
          }

          .tabs-class {
            font-size: 1.3vw;
          }

          .active-tab-class {
            font-size: 1.3vw;
          }
        }

        @media only screen and (max-width: 760px) {
          .table-div-background {
            margin-right: none !important;
          }
        }
      `}</style>

      <div className="table-div-background">
        <nav className="nav-tabs" aria-label="Tabs">
          {tabs.map((tab, index) => (
            <div className="w-100 h-100 d-flex justify-content-center align-items-center">
              <button
                key={index}
                className={`${
                  index === selectedTabIdx ? "active-tab-class" : ""
                } tabs-class`}
                onClick={() => setSelectedTabIdx(index)}
              >
                {`${tab.name} (${tab.count ? tab.count : 0})`}
              </button>
            </div>
          ))}
        </nav>
        {selectedTabIdx === 0 && (
          <div className="mt-10 overflow-auto">
            <Table
              biddingAuction={biddingAuction?.active_auction}
              columns={[
                "Reg",
                "Vehicle",
                "Bid On",
                "Time Left",
                "Offer",
                "Current Bid",
                "Action",
              ]}
            />
          </div>
        )}
        {selectedTabIdx === 1 && (
          <div className="mt-10 overflow-auto">
            <Table
              biddingAuction={biddingAuction?.upcoming_auction}
              columns={[
                "Reg",
                "Vehicle",
                "Start In",
                "Amount",
                "Status",
                "Action",
              ]}
            />
          </div>
        )}
        {selectedTabIdx === 2 && (
          <div className="mt-10 overflow-auto ">
            <Table
              biddingAuction={biddingAuction?.lost_auction}
              columns={[
                "Reg",
                "Vehicle",
                "Bid On",
                "Offer",
                "Winning Bid",
                "Action",
              ]}
            />
          </div>
        )}
        {selectedTabIdx === 3 && (
          <div className="mt-10 overflow-auto">
            <Table
              biddingAuction={biddingAuction?.won_auction}
              columns={[
                "Reg",
                "Vehicle",
                "Bought On",
                "Price",
                "Status",
                "Action",
              ]}
            />
          </div>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  biddingAuction: state.profile.auction,
});

const mapDispatchToProps = (dispatch) => ({
  // get product list
  getBiddingAuction: (id) =>
    dispatch({
      type: GET_BIDDING_AUCTION,
      payload: {
        type: GET_BIDDING_AUCTION,
        label: `${Labels.get_bidding_auction}${id}`,
        method: GET,
        hideOverlayLoader: true,
      },
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Tabs);
