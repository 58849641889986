// import React from 'react'
// import Tab from "../tabs"
// import Watch from "../Auction Won/Watchlist";

// function Arbitration() {
//   return (
//     <>
//                 <div className="py-6 px-5 bg-white bg-opacity-75">
//                   <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
//                   <h1 className="text-2xl font-semibold text-gray-900">Arbitration</h1>
//                            </div>
//                   <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
//                     <div className="py-4">
//                       <div className="h-96 ">
//                       No Data Found
//                       </div>
//                     </div>
//                   </div>
//                 </div>

//     </>
//   )
// }

// export default Arbitration


import React, { useEffect, useState } from "react";
import "./style.css";
import axios from "axios";
import { DatePicker } from "antd";
import { get } from "../../services/localStorage";
import { API_ENDPOINT } from "../../services/api";
import ArbitrationTable from "../ArbitrationTable";

function Arbitration() {
  const [existingData, setExistingData] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [formData, setFormData] = useState({
    registration: "",
    date_arrival: "",
    description: "",
    supporting_image: null,
    supporting_video: null,
    supporting_document: null,
  });
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleDateChange = (date) => {
    setFormData({
      ...formData,
      date_arrival: date,
    });
  };
  const token = get({ key: "auth" });

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Perform validation here before submitting
    const validationErrors = {};
    if (!formData.registration) {
      validationErrors.registration = "Vehicle Registration is required";
    }
    if (!formData.date_arrival) {
      validationErrors.date_arrival = "Date & Time of Arrival is required";
    }
    if (!formData.description) {
      validationErrors.description = "Description is required";
    }
    const dateArrival = new Date(formData.date_arrival)
      .toISOString()
      .split("T")[0];
    setErrors(validationErrors);
    if (Object.keys(validationErrors).length === 0) {
      const token = get({ key: "auth" });
      const formDataToSend = new FormData();
      formDataToSend.append("registration", formData.registration);
      formDataToSend.append("date_arrival", dateArrival);
      formDataToSend.append("user_id", token.id);
      formDataToSend.append("description", formData.description);
      if (formData.supporting_image)
        formDataToSend.append("supporting_image", formData.supporting_image);
      if (formData.supporting_video)
        formDataToSend.append("supporting_video", formData.supporting_video);
      if (formData.supporting_document)
        formDataToSend.append(
          "supporting_document",
          formData.supporting_document
        );
      const parameters = {
        method: "POST",
        headers: {
          Authorization: `Token ${token.token}`,
        },
        body: formDataToSend,
      };
      return await fetch(API_ENDPOINT("api/v1/arbitrations/"), parameters)
        .then((response) => {
          getExisting();
          setShowForm(!showForm);
          return response.json();
        })
        .then((json) => {
          return json;
        });
    }
  };

  const handleFileUpload = (e, type) => {
    const file = e.target.files[0];
    if (file) {
      setFormData({
        ...formData,
        [`supporting_${type}`]: file,
      });
    }
  };

  async function getExisting() {
    try {
      const response = await axios.get(API_ENDPOINT("api/v1/arbitrations/"), {
        headers: {
          Authorization: `Token ${token.token}`,
        },
      });
      console.log(response.data);
      setExistingData(response.data.data);
    } catch (error) {}
  }

  // const columns = [
  //   "Reg",
  //   "Arrival Date",
  //   "Issue",
  //   "Image",
  //   "Videos",
  //   "Document",
  // ];

  useEffect(() => {
    getExisting();
  }, []);

  return (
    <>
      <style jsx="true">{`
        .table-div-background {
          display: flex;
          flex-direction: column;
          height: 68vh;
        }
      `}</style>

      <div className="table-div-background">
        <h6 className="formSubTitle" id="arbitary_tag1">
          We're sorry you've had a bad experience. Please complete the form
          below and a member of the team will be in touch shortly.
        </h6>
        <div className="d-flex justify-content-end">
          <button
            className="btn primButton"
            onClick={() => setShowForm(!showForm)}
          >
            {showForm ? "Close Form" : "Create"}
          </button>
        </div>
        {showForm && (
          <div className="my-10 overflow-y-auto container-fluid">
            <div className="userForm" id="arbit_form">
              <form
                className="pb-0"
                id="arbitration_info"
                autoComplete="off"
                onSubmit={handleSubmit}
                noValidate
              >
                <div className="row">
                  <div className="col-md-5 col-10 form-group">
                    <div className={`group ${errors.registration && "errcls"}`}>
                      <label className="site-label">
                        Vehicle Registration *
                      </label>
                      <input
                        type="text"
                        name="registration"
                        id="registration"
                        className="form-control"
                        value={formData.registration}
                        onChange={handleChange}
                      />
                      {errors.registration && (
                        <span className="spanErrorTag">
                          {errors.registration}
                        </span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-5 col-10 form-group">
                    <div className="group pswdWrpr">
                      <label className="site-label">
                        Date & Time of Arrival *
                      </label>
                      <DatePicker
                        onChange={handleDateChange}
                        value={formData.date_arrival}
                        className="form-control parsley-success"
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-5 col-10 form-group">
                    <div className={`group ${errors.description && "errcls"}`}>
                      <label className="site-label">Describe the Issue *</label>
                      <input
                        type="text"
                        className="form-control"
                        name="description"
                        id="description"
                        value={formData.description}
                        onChange={handleChange}
                      />
                      {errors.description && (
                        <span className="spanErrorTag">
                          {errors.description}
                        </span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-5 col-10 form-group">
                    <div className="group upldBtnWrpr">
                      <label className="site-label">Supporting Images</label>
                      <input
                        type="file"
                        className="flInpt"
                        accept="image/jpeg, image/png"
                        onChange={(e) => handleFileUpload(e, "image")}
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-5 col-10 form-group">
                    <div className="group upldBtnWrpr">
                      <label className="site-label">Supporting Videos</label>
                      <input
                        type="file"
                        className="flInpt"
                        accept="video/*"
                        onChange={(e) => handleFileUpload(e, "video")}
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-5 col-10 form-group">
                    <div className="group upldBtnWrpr">
                      <label className="site-label">Supporting Documents</label>
                      <input
                        type="file"
                        className="flInpt"
                        accept=".pdf"
                        onChange={(e) => handleFileUpload(e, "document")}
                      />
                    </div>
                  </div>
                </div>

                <div className="arbitBtnGrp d-flex justify-content-center">
                  <button
                    type="submit"
                    className="w-auto px-3 btn_contacta border-radius-2"
                    id="arbitSubmitButton"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
        {!showForm && (
          <div className="my-10 overflow-y-auto container-fluid">
            <ArbitrationTable data={existingData} />
          </div>
        )}
      </div>
    </>
  );
}

export default Arbitration;