








import React, { useEffect, useState } from "react";
import Form from "../../components/Form";
//
import { connect } from "react-redux";
import Labels from "../../services/apiLabels";
import {
  OFFICE_TIMING,
  APPOINTMENT_CREATE,
  SELL_MY_CAR_STEPS,
  APPOINTMENT_UPDATE,
} from "../../constant";
import { GET, POST } from "../../constant/apiMethods";
import Dropdown from "../../components/Form/Dropdown";
import {
  getTimeSolts,
  formateTimeSlot,
  formateNumber,
} from "../../utils/formating";
import { scrollTop } from "../../utils/window";
import { getNext7Days } from "../../utils/functions";
import { useDispatch } from "react-redux";
//
function SellMyCarstep4(props) {
  const {
    evaluation,
    postAppointmentCreate,
    getOfficeTiming,
    officetiming,
    loading_officetiming,
    loading_appointment_create,
    //
    office,
    time_selected,
    setoffice,
    settime_selected_slot,
    settime_selected,
    settime_selected_reset,
    //
    formanimationClassname,
    name,
    appointmentId,
    updateIndex,
  } = props;
  const dispatch = useDispatch();
  const typeConstant = APPOINTMENT_CREATE;
  // const typeConstant =
  //   name === "update" ? APPOINTMENT_UPDATE : APPOINTMENT_CREATE;
  // getting office data from api
  useEffect(function () {
    if (officetiming.length < 1) {
      getOfficeTiming();
    }
  }, []);
  const [first_attempt, set_attempt] = useState(true);
  // var first_attempt=true;
  const isTodaySelected = () => {
    let date = new Date();
    const dateSelectedArr = time_selected.date.split("-");
    const dateSelected = +dateSelectedArr[dateSelectedArr.length - 1];
    let istoday = dateSelected === date.getDate();
    return istoday;
  };
  const renderTimeSlots = () => {
    //
    //
    const { day, start, end } = office.timming[time_selected.day_index] || {};
    const slots = getTimeSolts(start, end, isTodaySelected());
    if (slots.length > 0)
      return slots.map((item, index) => {
        const isSelected = time_selected.slot == item;
     
        return (
          <button
            style={{
              padding: "0.5rem",
              margin: "0.5rem",
              borderRadius: "0.5rem",
              background: isSelected ? "var(--primary)" : "var(--white)",
              color: isSelected ? "var(--white)" : "var(--secondary)",
              border: `1px solid ${
                isSelected ? "var(--primary)" : "var(--borderColor)"
              }`,
            }}
            key={index}
            onClick={() => settime_selected_slot(item)}
          >
            {formateTimeSlot(item)}
          </button>
        );
      });
    return (
      <div
        className="w-100"
        style={{ textAlign: "center", fontWeight: "bold" }}
      >
        No Time Slots for Today
      </div>
    );
  };
  //
  const postAppointmentCreateHandler = () => {
    const data = {
      evaluation: evaluation.id,
      office: office.id,
      time_selected: time_selected.slot,
      date_selected: time_selected.date,
      date_applied: time_selected.date,
      updateIndex
    };
    
    if (
      (data.evaluation || appointmentId) &&
      data.office &&
      data.time_selected &&
      time_selected.date
    ) {
      
      // postAppointmentCreate(data);
      dispatch({
        type: typeConstant,
        payload: {
          type: typeConstant,
          method: POST,
          label:
            name === "update"
              ? Labels.appointment_update + appointmentId
              : Labels.appointment_create,
          hideOverlayLoader: true,
          data,
        },
      });
      scrollTop();
    } else {
      
    }
  };
  //
  const renderDayButtons = () => {
    let date = new Date();
    let days = getNext7Days();
    
    let item_ = office.timming[days[0].id];
    
    if (first_attempt) {
      settime_selected({
        date: days[0].dateNum,
        id: days[0].id,
        day_formated: `${item_.day}, ${days[0].date}`,
        ...item_,
      });
      set_attempt(false);
    }

    return days.map((e, index) => {
      let item = office.timming[e.id];
      
      if (item) {
        //
        // // console.log"time Selected current evvv ",time_selected , ' index ' ,e)
        // if (!time_selected.day) {
        //   // // console.log
        //   //   "timeselecteddddddddd if day is empty then \n day =>",
        //   //   !time_selected.day,
        //   //   "\n timeselected day===>",
        //   //   time_selected.day,
        //   //   e.dateNum,
        //   //   " \n day_index=>",
        //   //   item.day_index,
        //   //   " \n item=>",
        //   //   item
        //   // );
        //   settime_selected({
        //     date: e.dateNum,
        //     id: item.day_index,
        //     day_formated: `${item.day}, ${e.date} `,
        //     ...item,
        //   });
        // } else {
        //   // // console.log
        //   //   "timeselecteddddddddd if day is empty then \n day =elseeeeeeeeeeeeeeee =>",
        //   //   !time_selected.day,
        //   //   "\n timeselected day===>",
        //   //   time_selected.day,
        //   //   e.dateNum,
        //   //   " \n day_index=>",
        //   //   item.day_index,
        //   //   " \n item=>",
        //   //   item
        //   // );
        // }
        //
        return (
          <button
            key={index}
            onClick={() => {
              settime_selected({
                ...item,
                date: e.dateNum,
                day_formated: `${item.day}, ${e.date}`,
              });
              settime_selected_slot("");
            }}
            className="text-capitalize"
            style={{
              background:
                time_selected.day_index === item.day_index
                  ? "var(--white)"
                  : "",
              padding: "0.7rem 0",
              textAlign: "left",
              padding: "0.5rem",
            }}
          >
            {item.day}, {e.date}
          </button>
        );
      }
      return;
    });
  };
  //
  const setofficeHandler = (item) => {
    let date = new Date();
    let today = new Date().getDay() || 0;
    let timming = item.timming;
    let timming1;
    // if(Object.keys(timming).includes(today-1)){
    //    timming1 = timming[Object.keys(timming)[today-1]];
    // }
    // else{
    //   timming1 = timming[Object.keys(timming)[0]];
    // }

    setoffice(item);
    
    settime_selected_reset();
    // first_attempt=true;
    // settime_selected({
    //   date: `${date.getFullYear()}-${formateNumber(
    //     date.getMonth() + 1
    //   )}-${formateNumber(date.getDate())}`,
    //   id: timming1.day_index,
    //   day_formated: "",
    //   ...timming1,
    // });
  };
  //
  
  
  return (
    <div className="w-100 h-auto d-flex justify-content-center">
      <Form
        width="fluid"
        // onSubmit={()=>{}}
        onSubmit={postAppointmentCreateHandler}
        buttonText="Get your appointment"
        className={formanimationClassname}
        loading={loading_appointment_create}
        loadingText="Getting your appointment ..."
      >
        {/* officetiming */}
        <Dropdown
          label="Office"
          onSelect={setofficeHandler}
          selectedValue={office}
          loading={loading_officetiming}
          disabled={loading_officetiming}
          data={officetiming}
          titleKey="address"
        />
        {Object.values(office.timming).length > 0 && (
          <>
            <div className={`dropdown_wrapper ${formanimationClassname}`}>
              <div>Appointment Time:</div>
              <div
                style={{
                  padding: "3px 11px",
                  background: "#fff",
                  border: "1px solid var(--borderColor)",
                  minHeight: 30,
                }}
                className="d-flex align-items-center text-capitalize"
              >
                {time_selected.day_formated ||
                  "Please slecet the day from the table below"}
                {time_selected.slot && ` - ${time_selected.slot}`}
              </div>
            </div>

            <div className={`dropdown_wrapper ${formanimationClassname}`}>
              <div
                style={{
                  height: 300,
                  background: "var(--white)",
                  border: "1px solid var(--borderColor)",
                }}
                className="d-flex "
              >
                <div
                  style={{
                    background: "var(--info)",
                    display: "flex",
                    flex: 1,
                    flexDirection: "column",
                  }}
                >
                  {renderDayButtons()}
                </div>
                <div
                  style={{ flex: 3, padding: "3px 11px", overflowY: "scroll" }}
                >
                  {office.address && renderTimeSlots(time_selected.day_index)}
                </div>
              </div>
            </div>
          </>
        )}
      </Form>
    </div>
  );
}
////////
const mapStateToProps = (state) => ({
  evaluation: state.sellMyCar.evaluation_create,
  //
  loading_officetiming: state.sellMyCar.loading_officetiming,
  officetiming: state.sellMyCar.officetiming,
  //   step 4
  office: state.sellMyCar.step4.office,
  time_selected: state.sellMyCar.step4.time_selected,
  //
  loading_appointment_create: state.sellMyCar.loading_appointment_create,
});
const mapDispatchToProps = (dispatch) => ({
  setoffice: (data) =>
    dispatch({
      type: SELL_MY_CAR_STEPS,
      payload: {
        type: "office",
        data,
      },
    }),

  settime_selected_slot: (data) =>
    dispatch({
      type: SELL_MY_CAR_STEPS,
      payload: {
        type: "time_selected_slot",
        data,
      },
    }),
  settime_selected: (data) =>
    dispatch({
      type: SELL_MY_CAR_STEPS,
      payload: {
        type: "time_selected",
        data,
      },
    }),
  settime_selected_reset: (data) =>
    dispatch({
      type: SELL_MY_CAR_STEPS,
      payload: {
        type: "time_selected_reset",
        data,
      },
    }),
  // post OFFICE_TIMING
  getOfficeTiming: (data) => {
    return dispatch({
      type: OFFICE_TIMING,
      payload: {
        type: OFFICE_TIMING,
        method: GET,
        label: `${Labels.officetiming}`,
        hideOverlayLoader: true,
      },
    });
  },
  // post APPOINTMENT_CREATE
  postAppointmentCreate: (data) => {
    return dispatch({
      type: APPOINTMENT_CREATE,
      payload: {
        type: APPOINTMENT_CREATE,
        method: POST,
        label: `${Labels.appointment_create}`,
        hideOverlayLoader: true,
        data,
      },
    });
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(SellMyCarstep4);
















// import React, { useEffect, useState } from "react";
// import Form from "../../components/Form";
// //
// import { connect } from "react-redux";
// import Labels from "../../services/apiLabels";
// import {
//   OFFICE_TIMING,
//   APPOINTMENT_CREATE,
//   SELL_MY_CAR_STEPS,
//   EVALUATE_IMAGE,
// } from "../../constant";
// import { FaPlus } from "react-icons/fa";
// import { GET, POST } from "../../constant/apiMethods";
// import Dropdown from "../../components/Form/Dropdown";
// import { getTimeSolts, formateTimeSlot } from "../../utils/formating";
// import { scrollTop } from "../../utils/window";
// import { getNext7Days } from "../../utils/functions";
// import { API_ENDPOINT } from "../../services/api";
// import { MdCancel } from "react-icons/md";
// //
// function SellMyCarstep4(props) {
//   const {
//     create_image,
//     setcreate_image,
//     postcreatimage_fail,
//     postcreatimage_success,
//     postcreatimage_request,
//     evaluation,
//     createImageLoading,
//   } = props;
//   let imageInputRef = React.createRef();
//   //
//   const postcreatimage = async (data) => {
//     const parameters = {
//       method: POST,
//       body: data,
//     };
//     return await fetch(API_ENDPOINT(Labels.creatimage), parameters)
//       .then((response) => {
//         return response.json();
//       })
//       .then((json) => {
//         return json;
//       });
//   };
//   //
//   const setfilesArrHandler = (e) => {
//     const filesObj = imageInputRef.current.files || {};
//     const arr = Object.values(filesObj);
//     setcreate_image([...create_image, ...arr]);
//   };
//   const removeImage = (index) => {
//     const arr = [...create_image];
//     if (index > -1) {
//       arr.splice(index, 1);
//       setcreate_image(arr);
//     }
//   };
//   //
//   const postcreatimageHandler = async () => {
//     // e.preventDefault();
//     if (create_image.length > 0) {
//       const bodyFormData = new FormData();
//       for (var i = 0; i < create_image.length; i++) {
//         bodyFormData.append("image", create_image[i]);
//       }
//       bodyFormData.append("evaluate_image", evaluation.id);
//       //
//       try {
//         postcreatimage_request();
//         const response = await postcreatimage(bodyFormData);
//         postcreatimage_success(response);
//         
//       } catch (error) {
//         
//       }
//       //
//       //;
//     } else {
//       
//     }
//   };

//   return (
//     <div className="w-100 h-auto d-flex justify-content-center">
//       <Form
//         width="fluid"
//         title="Add Car Images"
//         onSubmit={postcreatimageHandler}
//         buttonText="Submit Images"
//         // className={formanimationClassname}
//         loading={createImageLoading}
//         loadingText="Submitting Images ..."
//       >
//         <div className="dropdown_wrapper d-flex align-items-center flex-wrap">
//           <label className="imgpiker" htmlFor="img">
//             <FaPlus color="var(--primary)" size={45} />
//           </label>

//           <input
//             disabled={createImageLoading}
//             type="file"
//             id="img"
//             name="img"
//             accept="image/*"
//             className="d-none"
//             ref={imageInputRef}
//             onChange={setfilesArrHandler}
//             // onChange={(e) => // console.log"file", e.target.files)}
//             multiple
//           />
//           {create_image.map((item, index) => {
//             return (
//               <div className="imgpiker">
//                 <img
//                   width="100%"
//                   height="100%"
//                   src={URL.createObjectURL(item)}
//                   alt={`car image ${index} `}
//                 />
//                 <span
//                   onClick={() => removeImage(index)}
//                   style={{ position: "absolute", top: 0, right: 0 }}
//                 >
//                   <MdCancel size={20} color="var(--secondary)" />
//                 </span>
//               </div>
//             );
//           })}
//         </div>
//       </Form>
//     </div>
//   );
// }
// ////////
// const mapStateToProps = (state) => ({
//   create_image: state.sellMyCar.create_image,
//   evaluation: state.sellMyCar.evaluation_create,
//   createImageLoading: state.sellMyCar.create_image_loading,
// });
// const mapDispatchToProps = (dispatch) => ({
//   setcreate_image: (data) =>
//     dispatch({
//       type: SELL_MY_CAR_STEPS,
//       payload: {
//         type: "create_image",
//         data,
//       },
//     }),
//   postcreatimage_request: (data) =>
//     dispatch({
//       type: `${EVALUATE_IMAGE}_REQUEST`,
//       payload: data,
//     }),
//   postcreatimage_success: (data) =>
//     dispatch({
//       type: `${EVALUATE_IMAGE}_SUCCESS`,
//       payload: data,
//     }),
//   postcreatimage_fail: (data) =>
//     dispatch({
//       type: `${EVALUATE_IMAGE}_FAIL`,
//       payload: data,
//     }),
// });
// export default connect(mapStateToProps, mapDispatchToProps)(SellMyCarstep4);
