import React from "react";
import "./Won.css";
import Dropdown from "../Dropdown/index";
import Watch from "./Watchlist";
import ActiveBuy from "./Active Buy";
import { useContext } from "react";
import FeedbackContext from "../../context/cmscontext";
import {useLocation} from "react-router-dom"
function AuctionWon() {
     const { feedback, isLoading } = useContext(FeedbackContext);
  var auction_page = feedback.auction_page;
  const location = useLocation()
  return (
    <div className="wonbody">

     {location.pathname === "/auction" && <Dropdown placeholder={auction_page.Active_Buying_text}>
        <ActiveBuy />
      </Dropdown>}
      <Dropdown placeholder={auction_page.Watchlist_text}>
        <Watch />
      </Dropdown>
    </div>
  );
}

export default AuctionWon;
