import React from "react";
import "./style.css";
import ScaleLoader from "react-spinners/ScaleLoader";
import {useLocation} from "react-router-dom"
//
export default function (props) {
  const {
    loading,
    loadingText,
    title,
    onSubmit,
    buttonText,
    children,
    width,
    className,
    hideButton,
    hideButtonOnPrint,
    buttonName,
    buttonClassName,
    childClassName,
    onBack,
  } = props;
  const location = useLocation()
  const page = location.pathname
  console.log(page)
  const getCarPriceHandler = () => {
    
    onSubmit();
  };
  console.log(page)
  return (
   
   <div className={` ${width === "fluid" ? "form_main_wrapper_width_fluid " : "" } ${className || ""}`} style={{padding:0}}>
      {/* <div className="form_header">
        {title && <span className="form_header_text">{title}</span>}
      </div> */}
      <div className={childClassName}>{children}</div>
  {page === "/dashbord/myvehicles" || page === "/dashbord" ? null :  <div className="dropdown_wrapper d-flex align-items-center my-6">
        {onBack && (
          <button 
            style={{
              padding: "0.5rem",
              fontSize: "1rem",
              fontWeight: "bold",
              textTransform: "uppercase",
              letterSpacing: "0.1rem",
            }}
            onClick={onBack}
          >
            Back
          </button>
        )}
        {hideButton ? null : (
          <button
            className={`${
              hideButtonOnPrint && "hideButtonOnPrint"
            } ${buttonClassName}`}
            onClick={!loading ? getCarPriceHandler : () => {}}
            name={buttonName || "CTAButton"}
          >
            {loading ? loadingText || "Loading..." : buttonText || "Submit"}
          </button>
        )}
      </div>}
    </div>
  );
}
