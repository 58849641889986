// import React, { useEffect } from "react";
// import "antd/dist/antd.css";
// import { Select } from "antd";
// import { BsCheck2 } from "react-icons/bs";
// //
// export default function Dropdown(props) {
//   const {
//     label,
//     data,
//     onSelect,
//     titleKey,
//     idKey,
//     disabled,
//     loading,
//     selectedValue,
//   } = props;

//   const { Option } = Select;
//   const children = [];
//   const titleKey_ = titleKey || "title";
//   const idKey_ = idKey || "id";

//   if (data)
//     for (let i = 0; i < data.length; i++) {
//       const item = data[i];
//       children.push(
//         <Option key={item[titleKey_]} value={i}>
//           <div className="d-flex flex-row align-items-center">
//             <span style={{ flex: 1 }}>{item[titleKey_]}</span>
//             {/* {item[idKey_] === selectedValue[idKey_] ? (
//               <BsCheck2 color="var(--primary)" size={20} />
//             ) : null} */}
//           </div>
//         </Option>
//       );
//     }

//   function onSelectHandler(index) {
//     onSelect(data[index]);
//   }
//   // checks if dropdown list contain only one element then set is as selected value
//   useEffect(() => {
//     if (data?.length === 1 && !selectedValue[idKey_]) onSelectHandler(0);
//   }, [data]);
//   //
//   return (
//     <div className="dropdown_wrapper1">
//       <div style={{fontWeight:"bold", marginBottom:5}}>{label}:</div>
//       <Select
//         loading={loading}
//         disabled={disabled}
//         showSearch={true}
//         // allowClear
//         style={{ width: "100%" }}
//         placeholder={`${loading ? "Loading" : "Select"} ${label} `}
//         onChange={onSelectHandler}
//         defaultValue={selectedValue[titleKey_]}
//         value={selectedValue[titleKey_]}
//         filterOption={(input, option) =>{
//           return (option.key ?? '').toLowerCase().includes(input.toLowerCase())
//           }
//         }
//       >
//         {children}
//       </Select>
//     </div>
//   );
// }



import React, { useEffect, useState } from "react";
import "antd/dist/antd.css";
import { Select } from "antd";
import { BsCheck2 } from "react-icons/bs";

export default function Dropdown(props) {
  const {
    label,
    data,
    onSelect,
    titleKey,
    idKey,
    disabled,
    loading,
    selectedValue,
    restrictFuelType // New prop to restrict fuel type inputs
  } = props;

  const { Option } = Select;
  const children = [];
  const titleKey_ = titleKey || "title";
  const idKey_ = idKey || "id";

  const fuelTypeOptions = ["Petrol", "Diesel", "Hybrid", "EV"];
  const [filteredData, setFilteredData] = useState(data);

  if (data) {
    const displayData = restrictFuelType ? fuelTypeOptions.map(type => ({ [titleKey_]: type, [idKey_]: type })) : data;
    for (let i = 0; i < displayData.length; i++) {
      const item = displayData[i];
      children.push(
        <Option key={item[idKey_]} value={item[titleKey_]}>
          <div className="d-flex flex-row align-items-center">
            <span style={{ flex: 1 }}>{item[titleKey_]}</span>
            {selectedValue && item[idKey_] === selectedValue[idKey_] ? (
              <BsCheck2 color="var(--primary)" size={20} />
            ) : null}
          </div>
        </Option>
      );
    }
  }

  function onSelectHandler(value) {
    if (restrictFuelType && !fuelTypeOptions.includes(value)) {
      return; // Invalid fuel type input
    }
    const selectedItem = data.find(item => item[titleKey_] === value) || { [titleKey_]: value, [idKey_]: value };
    onSelect(selectedItem);
  }

  useEffect(() => {
    if (data?.length === 1 && !selectedValue?.[idKey_]) onSelectHandler(data[0][titleKey_]);
  }, [data]);

  const handleSearch = (value) => {
    if (restrictFuelType) {
      setFilteredData(fuelTypeOptions.filter(option => option.toLowerCase().includes(value.toLowerCase())));
    }
  };

  return (
    <div className="dropdown_wrapper1">
      <div style={{ fontWeight: "bold", marginBottom: 5 }}>{label}:</div>
      <Select
        loading={loading}
        disabled={disabled}
        showSearch={true}
        style={{ width: "100%" }}
        placeholder={`${loading ? "Loading" : "Select"} ${label}`}
        onChange={onSelectHandler}
        onSearch={handleSearch}
        defaultValue={selectedValue ? selectedValue[titleKey_] : undefined}
        value={selectedValue ? selectedValue[titleKey_] : undefined}
        filterOption={false} // disable built-in filter to use custom filter
        onInputKeyDown={e => {
          if (e.key === 'Enter' && !data.find(item => item[titleKey_] === e.target.value)) {
            onSelectHandler(e.target.value);
          }
        }}
      >
        {children}
      </Select>
    </div>
  );
}