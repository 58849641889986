import React from "react";
import Footer from "../../components/Footer";
import { scrollTop } from "../../utils/window";

import { useContext } from "react";
import FeedbackContext from "../../context/cmscontext";
function PrivacyPolicy() {
    const { feedback, isLoading } = useContext(FeedbackContext);
var privacy_plicy_page = feedback.privacy_plicy_page;
  return (
    <>
      {scrollTop()}
      <div className="showInterest_headingCont ">
        <div className="showInterest_heading">
          {privacy_plicy_page.main_heading}
        </div>
      </div>

      <div className="container p-5">
        <p>{privacy_plicy_page.prha1}</p>

        <h3>{privacy_plicy_page.What_data1}</h3>
        <h3>{privacy_plicy_page.What_data2}</h3>
        <h3>{privacy_plicy_page.Information_provided}</h3>

        <div
          dangerouslySetInnerHTML={{ __html: privacy_plicy_page.html_code }}
        />

        <h3>{privacy_plicy_page.Location_information}</h3>
        <p>{privacy_plicy_page.prha2}</p>
      </div>
    </>
  );
}

export default PrivacyPolicy;
