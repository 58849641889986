import { sagaRoot } from "./root";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  PLACE_BID,
  PRODUCT_DETAIL,
  PRODUCT_LIST,
  GET_PRODUCT_BIDDING_GRAPH,
  PRODUCT_LIST_BUY,
  PRODUCT_DETAIL_BUY,
  CREATE_SUBSCRIPTION,
  CREATE_CONTACT_US,
  FORGET_PASSWORD,
  SALE_PRODUCT_LIST,
  APPROXY_BID_NEW,
  RESET_PASSWORD,
  SHIPING_COST,
  ADDRESS_FORM,
  ADD_CAR_FEATURES,
  SHIPING_FORM,
  WINERS_CAR_LIST,
  WINERS_CAR_USER_STATUS,
  VEHICLE_LIST,
  ADD_VEHICLE,
  PROFILE,
  CHANGE_PASSWORD,
  ADD_VEHICLE_AUCTION,
  GET_BIDDING_AUCTION
} from "../constant";
// get all product list
export function* ProductListSaga(payload) {
  // const { history, query } = payload.payload.params;
  const response = yield call(sagaRoot, payload);
  // if (response.message.type === "success") {
  //   history.push({ search: query });
  // }
  
}
// get all product detail

export function* ProductDetailSaga(payload) {
  const response = yield call(sagaRoot, payload);
  
}
export function* ProductDetailSagaBuy(payload) {
  const response = yield call(sagaRoot, payload);
  
}
export function* ProductListSagaBuy(payload) {
  const response = yield call(sagaRoot, payload);
  
}
export function* CreateSubscriptionSaga(payload) {
  const response = yield call(sagaRoot, payload);
  
}
export function* CreateContactUsSaga(payload) {
  const response = yield call(sagaRoot, payload);
  
}
export function* SaleProductListSaga(payload) {
  const response = yield call(sagaRoot, payload);
  
}
export function* ForgetPasswordSaga(payload) {
  const response = yield call(sagaRoot, payload);
  
}
export function* ApproxyBidSaga(payload) {
  const response = yield call(sagaRoot, payload);
  
}
export function* ResSetPasswordNewSaga(payload) {
  const response = yield call(sagaRoot, payload);
  
}
export function* ShipingCastSaga(payload) {
  const response = yield call(sagaRoot, payload);
  
}


export function* ShipingCastFormSaga(payload) {
  const response = yield call(sagaRoot, payload);
  
}
export function* CarWinerListSaga(payload) {
  const response = yield call(sagaRoot, payload);
  

  }
export function* myVehiclesListSaga(payload) {
  const response = yield call(sagaRoot, payload);
  
}
export function* myVehicleAddSaga(payload) {
  const response = yield call(sagaRoot, payload);
  
}
export function* CarWinerList_StatusSaga(payload) {
  const response = yield call(sagaRoot, payload);
  
}




export function* AddressFormSaga(payload) {
  const response = yield call(sagaRoot, payload);
  
}
export function* CarFeaturesAddSaga(payload) {
  const response = yield call(sagaRoot, payload);
  
}










export function* PlaceBidSaga(payload) {
  const response = yield call(sagaRoot, payload);
  
}
export function* GetProductBiddingGraphSaga(payload) {
  const response = yield call(sagaRoot, payload);
  
}
export function* getUpdateProfile(payload) {
  const response = yield call(sagaRoot, payload);
  
}
export function* changePassword(payload) {
  const response = yield call(sagaRoot, payload);
  
}
export function* getBiddingAuction(payload) {
  const response = yield call(sagaRoot, payload);
  
}

export function* addCarAuction(payload) {
  const response = yield call(sagaRoot, payload);
  
}
export default function* productWatcher() {
  yield takeLatest(PRODUCT_LIST, ProductListSaga);
  yield takeLatest(PRODUCT_LIST_BUY, ProductListSagaBuy);
  yield takeLatest(PRODUCT_DETAIL, ProductDetailSaga);
  yield takeLatest(PRODUCT_DETAIL_BUY, ProductDetailSagaBuy);
  yield takeLatest(PLACE_BID, PlaceBidSaga);
  yield takeLatest(GET_PRODUCT_BIDDING_GRAPH, GetProductBiddingGraphSaga);
  yield takeLatest( CREATE_SUBSCRIPTION, CreateSubscriptionSaga);
  yield takeLatest( CREATE_CONTACT_US, CreateContactUsSaga);
  yield takeLatest( SALE_PRODUCT_LIST, SaleProductListSaga);
  yield takeLatest( FORGET_PASSWORD, ForgetPasswordSaga);
  yield takeLatest( APPROXY_BID_NEW, ApproxyBidSaga);
  yield takeLatest( RESET_PASSWORD, ResSetPasswordNewSaga);
  yield takeLatest( SHIPING_COST, ShipingCastSaga);

  yield takeLatest( ADDRESS_FORM, AddressFormSaga);
  yield takeLatest( ADD_CAR_FEATURES, CarFeaturesAddSaga);

  yield takeLatest( SHIPING_FORM, ShipingCastFormSaga);
  yield takeLatest( WINERS_CAR_LIST, CarWinerListSaga);
  yield takeLatest( VEHICLE_LIST, myVehiclesListSaga);
  yield takeLatest( ADD_VEHICLE, myVehicleAddSaga);
  yield takeLatest( WINERS_CAR_USER_STATUS, CarWinerList_StatusSaga);
  yield takeLatest( PROFILE, getUpdateProfile);
  yield takeLatest( PROFILE, getUpdateProfile);
  yield takeLatest( CHANGE_PASSWORD, changePassword);
  yield takeLatest( GET_BIDDING_AUCTION, getBiddingAuction);
  yield takeLatest( ADD_VEHICLE_AUCTION, addCarAuction);
}
