/* eslint-disable no-unused-vars */
import React, { Fragment } from "react";
import { AiTwotoneEye, AiOutlineClockCircle } from "react-icons/ai";
import { BsDot } from "react-icons/bs";
import { GoLocation } from "react-icons/go";
import { Card } from "react-bootstrap";
import "./produ.css";
import ProductMain from "../Product View/ProductMain";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams,
} from "react-router-dom";
import ListCard from "./ListCard";
import ListCardShimmer from "./ListCardShimmer";
import product from "../../reducers/product";

function Products(props) {
  const { data, loading,isBuyNow } = props;
  if (loading) {
    return (
      <Fragment>
        <ListCardShimmer />
        <ListCardShimmer />
      </Fragment>
    );
  } else {
    return (
      <div className="p-1 w-100">
        {data?.data?.length > 0 ? (
          React.Children.toArray(
            data?.data?.map((Products, index) => (
              <ListCard key={Products?.id} index={index} Products={Products} isBuyNow={isBuyNow}/>
            ))
          )
        ) : (
          <center>
            <h1>No Cars Found</h1>
          </center>
        )}
      </div>
    );
  }
}

export default Products;