import React, { useEffect ,useState} from "react";
import "./style.css";
import { AiFillDelete } from "react-icons/ai";
import { SHOW_INTEREST, MY_INTERSTE, REMOVE_INTEREST } from "../../constant";
//
import { ShimmerThumbnail ,ShimmerTitle } from "react-shimmer-effects";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Loader } from "semantic-ui-react";
import { BsCalendarDate } from "react-icons/bs";
import { BiGasPump, BiCalendarCheck } from "react-icons/bi";
import ReactCircleModal from "react-circle-modal";
import { connect, useSelector } from "react-redux";
import Labels from "../../services/apiLabels";
import { POST, GET, DELETE } from "../../constant/apiMethods";
import { useHistory } from "react-router-dom";
import { formateDate } from "../../utils/functions";
import { scrollTop } from "../../utils/window";
import { FaTrashAlt } from "react-icons/fa";
import { useContext } from "react";
import FeedbackContext from "../../context/cmscontext";
function MyInterest(props) {
  const { getvendorinterest, userId, data, removecardata, loading,api_call } = props;

  const { feedback, isLoading } = useContext(FeedbackContext);
var my_interest = feedback.my_interest;
const [useryes, setuseryes] = useState(true)
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseYes = () => {
    removecardata(useryes);
    setOpen(false);
  };







  useEffect(() => {
 
    scrollTop()
 
  }, [])
  const history = useHistory();
  
  useEffect(() => {
    if (data.length === 0 || api_call==false ) {
      getvendorinterest(userId);
    
    } else {
   
    }
  }, []);
  const carDtail = (carid) => {
    
    history.push({ pathname: "/cardetail", state: { carid } });
  };

  const removeCar = (id) => {
    handleClickOpen()

    setuseryes(id)

   
  };

  return (
    <>
      <div className="mainDiv1 p-5">
        <div className="showInterest_headingCont">
          <div className="showInterest_heading">
            {my_interest.interested_text}
          </div>
          <div className="showInterest_description">
            {my_interest.interested_desc}
          </div>
        </div>
      </div>

      <div className="mdiv2 mt-5">
        {loading ? (
          <div className="shimer-div ">
            <div className="cardDiv">
              <ShimmerThumbnail height={50} width={200} rounded />
              <ShimmerThumbnail height={20} width={200} rounded />
              <ShimmerThumbnail height={20} width={200} rounded />
              <div className="row">
                <div className="col-6">
                  <span>
                    <ShimmerThumbnail height={30} width={200} rounded />
                  </span>
                </div>
                <div className="col-6">
                  <span>
                    <ShimmerThumbnail height={30} width={100} rounded />
                  </span>
                </div>
              </div>
            </div>

            <div className="cardDiv shimer">
              <ShimmerThumbnail height={50} width={200} rounded />
              <ShimmerThumbnail height={20} width={200} rounded />
              <ShimmerThumbnail height={20} width={200} rounded />
              <div className="row">
                <div className="col-6">
                  <span>
                    <ShimmerThumbnail height={30} width={200} rounded />
                  </span>
                </div>
                <div className="col-6">
                  <span>
                    <ShimmerThumbnail height={30} width={100} rounded />
                  </span>
                </div>
              </div>
            </div>
          </div>
        ) : data.length === 0 ? (
          <>
            <div>
              <h2 className="text-center">{my_interest.no_interest}</h2>
            </div>
          </>
        ) : (
          <div className="Cardiv_wrap">
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Delete Your intereste ?"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {my_interest.alert_dialog_description}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} variant="outlined">
                  {my_interest.no}
                </Button>
                <Button
                  onClick={handleCloseYes}
                  autoFocus
                  variant="outlined"
                  color="error"
                >
                  {my_interest.yes}
                </Button>
              </DialogActions>
            </Dialog>
            {React.Children.toArray(
              data.map((item, key) => {
                return (
                  <div className="cardDiv">
                    <h2>{item.brand_name}</h2>
                    <span className="car-model">
                      {item.model_name}-{item.variant_name}
                    </span>
                    <small className="mt-2 date w-100">
                      {" "}
                      <span>
                        {" "}
                        {my_interest.submit_date}: &nbsp;
                        {formateDate(item.date_selected)}
                      </span>
                    </small>

                    <div className="fotDiv mt-2 row">
                      <div
                        className="fot1 col-sm-12 col-md-6 col-lg-6 fota"
                        style={{ flex: 3 }}
                      >
                        <div className="fueltype  ">
                          {" "}
                          <BiGasPump size={17} />
                          <span style={{ padding: "0 0.2rem" }}>
                            {item.fuel_type}
                          </span>
                        </div>
                        <div className="fueltype ml-3">
                          {" "}
                          <BiCalendarCheck size={17} />
                          <span
                            className="fuel_"
                            style={{ padding: "0 0.2rem" }}
                          >
                            {item.year_name}
                          </span>
                        </div>
                      </div>
                      <div
                        className=" fotDiv_last col-sm-12  col-md-6 col-lg-6 "
                        style={{ flex: 1 }}
                      >
                        <button
                          name="CTAButton"
                          className="btntnext"
                          onClick={() => carDtail(item.id)}
                        >
                          {my_interest.preview_text}
                        </button>
                        <button
                          className="ml-3"
                          onClick={() => removeCar(item.id)}
                          // name="TransparentButton"
                        >
                          {false ? (
                            <Loader active inline />
                          ) : (
                            <FaTrashAlt size={20} color="black" />
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })
            )}
          </div>
        )}
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  userId: state.auth.id,
  data: state.myInterest.data,
  loading: state.myInterest.loading,
  api_call: state.myInterest.api_call,
});
const mapDispatchToProps = (dispatch) => ({
  getvendorinterest: (id) =>
    dispatch({
      type: MY_INTERSTE,
      payload: {
        type: MY_INTERSTE,
        label: `${Labels.vendorinterest}?user=${id}`,
        method: GET,
        hideOverlayLoader: true,
      },
    }),
  removecardata: (id) =>
    dispatch({
      type: REMOVE_INTEREST,
      payload: {
        type: REMOVE_INTEREST,
        label: `${Labels.vendorinterest}?id=${id}`,
        method: DELETE,
        hideOverlayLoader: true,
      },
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyInterest);
