import {
  VEHICLE_LIST,ADD_VEHICLE_AUCTION
} from "../constant/index";

const INITIAL_STATE = {
  loading: false,
  data: [],
  updateData: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case `${VEHICLE_LIST}_REQUEST`:
      return { ...state, loading: true };
    case `${VEHICLE_LIST}_SUCCESS`:
      
      return { ...state, data: action.payload, loading: false };

    case `${VEHICLE_LIST}_FAIL`:
      return { ...state, loading: false };
    case `${VEHICLE_LIST}_ERROR`:
      return { ...state, loading: false };

      

      case `${ADD_VEHICLE_AUCTION}_REQUEST`:
        return { ...state, loading: true };
      case `${ADD_VEHICLE_AUCTION}_SUCCESS`:
        console.log("data updated susscefully",state)
        return { ...state, updateData: action.payload, loading: false };
      case `${ADD_VEHICLE_AUCTION}_FAIL`:
        return { ...state, loading: false };
      case `${ADD_VEHICLE_AUCTION}_ERROR`:
        return { ...state, loading: false };
  
  }

  return state;
};
