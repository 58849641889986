import React, { useEffect, useState } from "react";
import axios from "axios";
import { SALE_PRODUCT_LIST, SHIPING_FORM } from "../../constant";
import { POST } from "../../constant/apiMethods";
import { connect } from "react-redux";
import Labels from "../../services/apiLabels";
import { useLocation } from "react-router-dom";
import "./style.css";
import { useHistory } from "react-router-dom";
import { FaSearch } from "react-icons/fa";
import config from "../../config";
import PuffLoader from "react-spinners/ClipLoader";
import { useContext } from "react";
import Layout from "./layout"
import FeedbackContext from "../../context/cmscontext";
export const Dashboard = ({ sumit_form, addresform }) => {

  const { feedback, isLoading } = useContext(FeedbackContext);
  var shiping_form_page = feedback.shiping_form_page;
  let [loading, setLoading] = useState("false");
  const [divshow, setdivshow] = useState("false");
  const [inpvalue, setInpvalue] = useState("");
  const [postcode, setpostcode] = useState();
  const [desp, setdesp] = useState();
  const [citeslist, setciteslist] = useState([]);
  const [numOfFleets, setNumOfFleets] = useState("1");
  const [fleetType, setFleetType] = useState("");
  const [fleetsList, setFleetsList] = useState([]);

  document.body.style.background = "#F2F4F5";

  console.log("Fleets ============ ", numOfFleets);
  // console.log"inp value ============",addresform)
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    // console.log"inp value ===222222222=========",addresform)
    if (addresform) {
      history.push("/winer");
      // console.log"inp value ======4444444444444444======",addresform)
    }

    axios
      .get(`${config.baseUrl}api/v1/get_fleets/`)
      .then(function (response) {
        if (response.data.data.length > 0) {
          setFleetsList(response.data.data);
        }
      })
      .catch(function (error) {});
  }, [addresform]);

  const addres_set = (data) => {
    setInpvalue(data);
    setdivshow("false");
  };

  // console.log"citexs lisstttt",citeslist)

  const citylisthandler = (e) => {
    setInpvalue(e.target.value);
    if (e.target.value.length > 1) {
      // setInpvalue(e.target.value)
      get_all_cites_list(e.target.value);
      setLoading("true");
    }

    if (e.target.value == "") {
      setdivshow("false");
    }
  };

  const apikey = "85oOFVlBH0GWLXWE0FSGgA35569";

  function get_all_cites_list(a) {
    axios
      .get(
        `https://api-finder.eircode.ie/Latest/finderautocomplete?key=_3c14c473-8c8f-4607-aba9-06d4f1e11a2c&address=${a}&language=en&geographicAddress=true`
      )
      .then(function (response) {
        if (response.data.options.length > 0) {
          setciteslist(response.data.options);
        }
        setLoading("false");
        setdivshow("true");
      })
      .catch(function (error) {});
  }
  const User_Id = JSON.parse(localStorage.getItem("WFAC_localstorage_auth"));

  const submit_form_data = () => {
    sumit_form({
      postcode: postcode,
      inpvalue: inpvalue,
      desp: desp,
      no_of_cars: numOfFleets,
      shipment_type: fleetType,
      product: location.state.product_id,
      user: User_Id.id,
    });
  };

  return (
    <>

      <Layout/>
    </>
  );
};

const mapStateToProps = (state) => ({
  cityes_List_Data: state.cardeailupdate,
  addresform: state.shiping.addresform,
});

const mapDispatchToProps = (dispatch) => ({
  // get product list
  sumit_form: (data) =>
    dispatch({
      type: SHIPING_FORM,
      payload: {
        type: SHIPING_FORM,
        method: POST,
        label: Labels.shiping_form,
        hideOverlayLoader: true,
        data,
      },
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
