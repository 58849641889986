import React, { useEffect } from "react";
import "./Greating.css";
import { connect } from "react-redux";
import { appContact_number, appName } from "../../app.info";
import { BiCopy } from "react-icons/bi";
import { copyTextToClipboard } from "../../utils/window";
import {
  formateTimeSlot,
  capitalizeText,
  formateKey,
} from "../../utils/formating";
import Form from "../../components/Form/index";
import { get, set } from "../../services/localStorage";
import { SELL_MY_CAR_STEPS } from "../../constant";
//
import { useHistory } from "react-router-dom";

function Greatings(props) {
  const {
    evaluation,
    user,
    office,
    time_selected,
    step1,
    step2,
    step4,
    currentStep,
    evaluation_id_,
    reset,
  } = props;
  const formanimationClassname = "animate__animated animate__zoomIn";
  // updating tracking number local storage
  async function updateTrackingLocalStorage() {
    const trackingNumber = evaluation.tracking_number;
    const localTrackingArr = (await get({ key: "tracking_number" })) || [];

    if (!localTrackingArr.includes(trackingNumber)) {
      const updatedTrackingArr = [trackingNumber, ...localTrackingArr];
      set({ key: "tracking_number", data: updatedTrackingArr });

    }
  }
  useEffect(function () {
    updateTrackingLocalStorage();
  }, []);
  //
  
  const history = useHistory();
  //
  const s2lcItems = [
    {
      text: "We Will carefully inspect your car and will give you a final offer.",
    },
    {
      text: "Decide wheather to accept the offer, there's no obligation to sell.",
    },
    { text: "If you accept, we take care of all the paperwork." },
    { text: "We'll transfewr your money safely and securely - instantly!" },
    { text: "Kaching!" },
  ];
  //
  const List = (props) => {
    const { title, value } = props;
    return (
      <div
        className="dropdown_wrapper d-flex w-100"
        style={{ borderBottom: "0.2rem solid var(--infoPlus)" }}
      >
        <span
          className="text-capitalize elevation_s1_lc_cont_1_t_1"
          style={{ margin: "0.2rem 0.5rem", fontWeight: "bold", flex: 1 }}
        >
          {formateKey(title)} :
        </span>
        <span
          className="text-capitalize text-wrap elevation_s1_lc_cont_1_t_1"
          style={{ margin: "0.2rem 0.5rem", flex: 1 }}
        >
          {value}
        </span>
      </div>
    );
  };
  //


// console.log"last step ==========>>>>>",evaluation_id_)


  const CarDetails = ({ title }) => {
    // return s1c2Items.map((item, index) => (
    //   <span
    //     key={index}
    //     className="d-flex align-items-center mt-3 elevation_s1_lc_cont_1_t_1"
    //   >
    //     <HiCheck
    //       color="var(--secondary)"
    //       size={20}
    //       style={{ background: `var(--primary)` }}
    //       className="rounded-circle mr-2 p-1"
    //     />
    //     {item.text}
    //   </span>
    // ));
    return (
      <>
        <div className="overflow-hidden" style={{ padding: "0.5rem 1rem" }}>
          {title && <div className="evaluation_s2_heading">{title}</div>}
          <div className="w-100 d-flex justify-content-center align-items-center flex-column">
            <List title="brand" value={step1["brand"].current.brand_name} />
            <List title="model" value={step1["model"].current.display} />
            <List
              title="fuel type"
              value={step1["fuel_type"].current.display}
            />
            <List title="year" value={step1["year"].current.display} />
            <List title="mileage" value={step2["mileage"].current.title} />
            {/*  */}
            <List
              title="previous_owners"
              value={step2["previous_owners"].current.title}
            />
            <List
              title="services_history"
              value={step2["services_history"].current.title}
            />
          </div>
        </div>
      </>
    );
  };
  //
  let UserDetails = ({ title }) => {
    return (
      <Form className="mt-4" title="User Details" width="fluid" hideButton>
        <div
          className="dropdown_wrapper overflow-hidden"
          style={{ padding: "0.5rem 1rem" }}
        >
          {title && <div className="evaluation_s2_heading">{title}</div>}
          <div className="w-100 d-flex justify-content-center align-items-center flex-column">
            <List title="Name" value={user.name} />
            <List title="Email" value={user.email} />
            <List title="Post Code" value={user.postcode} />
            <List title="Mobile Number" value={user.mobile_number} />
          </div>
        </div>
      </Form>
    );
  };
  //
  const WhatNextList = () => {
    return (
      <Form className="mt-4" title="What's next?" width="fluid" hideButton>
        <div className="dropdown_wrapper">
          <ol className="evaluation_s2_orderlist">
            {s2lcItems.map((item, index) => {
              return <li>{item.text}</li>;
            })}
          </ol>
        </div>
      </Form>
    );
  };
  //
  return (
    <div className="w-100 p-2 d-flex  align-items-center flex-column greating_main_cont">
      <div className="greating_section">
        <span className="greating_cont_heading">
          Thank You {evaluation.Website_user} !
        </span>
        {/*  */}
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ padding: "0.5rem" }}
        >
          <span className="greating_cont_desc" style={{ padding: "0.5rem" }}>
            Please check your email address we have sent you an email with the
            URL. You are advised to open that URL in mobile device and provide
            the pictures or information of your vehicle it will add more value
            to your call and help us find a best price to flip your car.
            {/* We sent a confirmation to your email with your booking details. We
            are happy to welcome you on {capitalizeText(time_selected.day)},
            {formateTimeSlot(time_selected.slot)} in {office.address}. */}
          </span>
        </div>
        <div>
          <button
            className="hide_in_print"
            onClick={()=>{history.push("/carsteps")}} 
            style={{
              background: "var(--info)",
              color: "var(--primary)",
              fontWeight: "bold",
              padding: "1rem",
              borderRadius: "0.5rem",
            }}
          >
            Back to Home
          </button>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-xl-6 col-lg-6 col-12">
            <Form
              title="Booking Details"
              width="fluid"
              onSubmit={() => window.print()}
              buttonText="Print Confirmation"
              buttonName="TransparentButton"
              hideButtonOnPrint
            >
              <div
                className="token_cont"
                style={{ background: "var(--white)" }}
              >
                <span className="token_cont_item token_text">
                  {evaluation.tracking_number}
                </span>
                <button
                  className="token_cont_item token_button"
                  onClick={() =>
                    copyTextToClipboard(evaluation.tracking_number, () =>
                      console.log()
                    )
                  }
                >
                  <BiCopy
                    className="token_cont_item token_text"
                    style={{ color: "var(--white)" }}
                  />
                </button>
              </div>
              <List title="Adress" value={step4["office"].address} />
              <List
                title="Time"
                value={`${capitalizeText(
                  step4.time_selected.day
                )} - ${formateTimeSlot(step4.time_selected.slot)} `}
              />
              <List title="Contact " value={appContact_number} />
              <List
                title="Tracking Number "
                value={evaluation.tracking_number}
              />
            </Form>
            <div className="d-none d-lg-block d-xl-block">
              <UserDetails />
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-12">
            <div className="d-block d-lg-none d-xl-none mt-4" />
            <Form title="Car Details" width="fluid" hideButton>
              <CarDetails />
            </Form>
            <div className="d-none d-lg-block d-xl-block">
              <WhatNextList />
            </div>
            <div className="d-block d-lg-none d-xl-none ">
              <UserDetails />
              <WhatNextList />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => ({
  evaluation: state.sellMyCar.evaluation_create,
  user: state.sellMyCar.user,
  office: state.sellMyCar.step4.office,
  time_selected: state.sellMyCar.step4.time_selected,
  step1: state.sellMyCar.step1,
  step2: state.sellMyCar.step2,
  step4: state.sellMyCar.step4,
  currentStep: state.sellMyCar.currentStep,
  evaluation_id_: state.sellMyCar.appointment_create.evaluation,
});
const mapDispatchToProps = (dispatch) => ({
  reset: (data) =>
    dispatch({
      type: SELL_MY_CAR_STEPS,
      payload: {
        type: "reset",
        data,
      },
    }),
});
export default connect(mapStateToProps, mapDispatchToProps)(Greatings);
