// import React, {  useEffect } from "react";
// // import {  useHistory } from "react-router-dom";
// import Dropdown from "../Form/Dropdown";
// import Form from "../Form";
// //
// import { connect } from "react-redux";
// import Labels from "../../services/apiLabels";
// import { POST, GET } from "../../constant/apiMethods";
// import { BRANDS } from "../../constant/index";
// import { API_ENDPOINT } from "../../services/api";
// //
// const getdata = async (label) => {
  
//   const parameters = {
//     GET,
//     headers: {
//       "Content-Type": "application/json",
//     },
//   };
//   return await fetch(API_ENDPOINT(label), parameters)
//     .then((response) => {
//       return response.json();
//     })
//     .then((json) => {
      
//       return json;
//     });
// };
// let reduxType_ = "SELL_MY_CAR_STEPS";

// // console.log("reduxType_reduxType_---------------",reduxType_)
// //
// function CarDetailForm(props) {
//   const {
//     formWidth,
//     title,
//     fetchingBrands,
//     getbrands,
//     brands,
//     onSubmit,
//     buttonText,
//     //
//     setbrand,
//     setmodel,
//     setfuelType,
//     setyear,
//     setvariant,
//     //
//     reduxType,
//     reducer,
//     loading,
//     state,
//     carData,
//     setcarData,
//     isEdit,
//     carStepsData
//   } = props;

//   const brand = props[reducer]?.step1?.brand?.current;
//   const model = props[reducer]?.step1?.model;
//   const fuel_type = props[reducer]?.step1?.fuel_type;
//   const year = props[reducer]?.step1.year;
//   const variant = props[reducer]?.step1.variant;
//   useEffect(() => {
//     reduxType_ = reduxType ? reduxType : 'SELL_MY_CAR_STEPS';
//     if (brands.length < 1) getbrands();
//   }, []);
//   //



//   useEffect(() => {

//     if (!isEdit) {
//       setbrand({ ...brand, isSelected: false, current: {} });
//       setmodel({ ...defaultState, fetching: false });
//       setfuelType({ ...fuel_type, isSelected: false, current: {} });
//       setyear({ ...year, isSelected: false, current: {} });
  
//     }
    

//    if (isEdit && carStepsData?.brand_name) {
//     setBrandsInfo(carStepsData.brand_name,)
//     // modelsOnSelectHandler(carStepsData.model_name)
//     // fuelTypeOnSelectHandler(carStepsData.fuel_type)
//     // yearsOnSelectHandler(carStepsData.year) 

//    }
  
//   }, [carStepsData]);
//   //

//   const onSubmitHandler = () => {
    
//     onSubmit();
//   };
//   // values states
//   // defaule state variable
//   const defaultState = {
//     fetching: false,
//     data: [],
//     isSelected: false,
//     current: {},
//   };

//   const setBrandsInfo= async (item)=>{
//     if (item?.id) {
//       carData.brands=item.id
//       setcarData({...carData})
//       setbrand({ ...brand, isSelected: true, current: item });
//       setmodel({ ...defaultState, fetching: true });
//     const datam = await getdata(`${Labels.car_models}${item.id}/`);
//     if (datam.length > 0) {
//       console.log("data m iii ",datam)
//       setmodel({
//         data:datam,
//         fetching: false,
//         isSelected: true,
//         current: carStepsData?.model_name,
//       });
      
//       // setfuelType({ ...defaultState, fetching: true });
//       const dataf = await getdata(`${Labels.car_fuel_types}${carStepsData?.model_name?.value}/`);
//       if (dataf.length > 0) {
//         // setmodel({ ...model, isSelected: true, current: carStepsData?.model_name });
//         setfuelType({
//           data:dataf,
//           fetching: false,
//           isSelected: true,
//           current:carStepsData?.fuel_type,
//         });
//         const dataft = await getdata(`${Labels.car_years}${carStepsData?.fuel_type?.value}/`);
//       if (dataft.length > 0) {
     
//         setyear({
//           data:dataft,
//           fetching: false,
//           isSelected: true,
//           current: carStepsData?.year??{},
//         });
//       } else {
//       }
//       } else {
        
//         // setfuelType({ ...defaultState, fetching: false });
//       }
//     } else {
//       // setmodel({ ...defaultState, fetching: false });
//     }
//     }
//   }
//   // call a function when brand is selected
//   const brandOnSelectHandler = async (item = {}) => {
//     // console.log("bra nds info -->> ")
//     if (item.id) {
//       carData.brands=item.id
//       setcarData({...carData})
//       setbrand({ ...brand, isSelected: true, current: item });
//       setmodel({ ...defaultState, fetching: true });
//       const data = await getdata(`${Labels.car_models}${item.id}/`);
//       // console.log("brandOnSelectHandler============>data",data)
//       if (data.length > 0) {
//         setmodel({
//           data,
//           fetching: false,
//           isSelected: false,
//           current: {},
//         });
//       } else {
//         setmodel({ ...defaultState, fetching: false });
//       }
      
//     }
//   };
//   // call a function when model is selected
//   const modelsOnSelectHandler = async (item) => {
//     console.log("modelsOnSelectHandler=======22222=====>item",item)
//     if (item?.value) {
//       carData.model_name=item.value
//       setcarData({...carData})
//       setmodel({ ...model, isSelected: true, current: item });
//       setfuelType({ ...defaultState, fetching: true });
//       const data = await getdata(`${Labels.car_fuel_types}${item.value}/`);
//       if (data.length > 0) {
        
//         setfuelType({
//           data,
//           fetching: false,
//           isSelected: false,
//           current:{},
//         });
//       } else {
        
//         setfuelType({ ...defaultState, fetching: false });
//       }
      
//     }
//   };
//   // call a function when model is selected
//   const fuelTypeOnSelectHandler = async (item) => {
//     // console.log"fuelTypeOnSelectHandler=======33333=====>item",item)
//     if (item.value) {
//       carData.fuel_type=item.value
//       setcarData({...carData})
//       setfuelType({ ...fuel_type, isSelected: true, current: item });
//       setyear({ ...defaultState, fetching: true });
//       const data = await getdata(`${Labels.car_years}${item.value}/`);
//       if (data.length > 0) {
     
//         setyear({
//           data,
//           fetching: false,
//           isSelected: carStepsData?.year?true:false,
//           current: carStepsData?.year??{},
//         });
//       } else {
        
//         setyear({ ...defaultState, fetching: false });
//       }
      
//     }
//   };
//   // call a function when year is selected
//   const yearsOnSelectHandler = async (item) => {
//     console.log("yearsOnSelectHandler=======44444444444=====>item",item)
//     if (item.value) {
//       carData.year=item.value
//       setcarData({...carData})
//       setyear({ ...year, isSelected: true, current: item });
//       setvariant({ ...defaultState, fetching: true });
//       const data = await getdata(`${Labels.car_variants}${item.value}/`);
//       if (data.length > 0) {
//         setvariant({
//           data,
//           fetching: false,
//           isSelected: false,
//           current: carStepsData?.year??{},
//         });
//       } else {
//         setvariant({ ...defaultState, fetching: false });
    
//       }
//     }
//   };
//   //  // call a function when variant is selected
//   const variantsOnSelectHandler = async (item) => {
//     // console.log"variantsOnSelectHandler=======555555=====>item",item)
//     // console.log"c",item)
//     if (item.value) {
//       setvariant({ ...variant, isSelected: true, current: item });
//     }
//   };
//   // console.log("reduxType_reduxType_---------------",reduxType_)
//   return (
//     <Form
//       width={formWidth}
//       loading={loading}
//       loadingText="Submitting Interest ..."
//       title={title}
//       onSubmit={onSubmitHandler}
//       buttonText={buttonText}
//     >
//       {/* brands */}
//       <div className="col-sm-6 form-group">
//       <Dropdown
//         label="Brand"
//         onSelect={brandOnSelectHandler}
//         titleKey="brand_name"
//         selectedValue={brand}
//         loading={fetchingBrands}
//         disabled={fetchingBrands || loading}
//         data={brands}
//       />
//       </div>
    
//       {/* modal */}
//       <div className="col-sm-6 form-group">
//       <Dropdown
//         label="Model"
//         onSelect={modelsOnSelectHandler}
//         titleKey="display"
//         idKey="value"
//         selectedValue={model?.current}
//         loading={model?.fetching}
//         disabled={model?.fetching || !brand?.id || loading}
//         data={model?.data}
//       />
//       </div>
     
//       {/* fuel type */}
//       <div className="col-sm-6 form-group">
//       <Dropdown
//         label="Fuel Type"
//         onSelect={fuelTypeOnSelectHandler}
//         titleKey="display"
//         idKey="value"
//         selectedValue={fuel_type?.current}
//         loading={fuel_type?.fetching}
//         disabled={fuel_type?.fetching || !model?.isSelected || loading}
//         data={fuel_type?.data}
//       />
//       </div>
    
//       {/* year */}
//       <div className="col-sm-6 form-group">
//       <Dropdown
//         label="Year"
//         onSelect={yearsOnSelectHandler}
//         titleKey="display"
//         idKey="value"
//         selectedValue={year?.current}
//         loading={year?.fetching}
//         disabled={year?.fetching || !fuel_type?.isSelected || loading}
//         data={year?.data}
//       />
//       </div>
  
//       {/* variant */}
//       {/* <Dropdown
      
//         label="Variant"
//         onSelect={variantsOnSelectHandler}
//         titleKey="display"
//         idKey="value"
//         selectedValue={variant?.current}
//         loading={variant?.fetching}
//         disabled={variant?.fetching || !year?.isSelected || loading}
//         data={variant?.data}
//       /> */}
//     </Form>
//   );
// }
// const mapStateToProps = (state) => ({
//   fetchingBrands: state.sellMyCar.fetchingBrands,
//   brands: state.sellMyCar.brands,
//   state: state,
//   //
//   showInterest: state.showInterest,
//   sellMyCar: state.sellMyCar,
// });
// const mapDispatchToProps = (dispatch) => ({
//   // get product list
//   getbrands: (data) => {
//     return dispatch({
//       type: BRANDS,
//       payload: {
//         type: BRANDS,
//         method: POST,
//         label: `${Labels.brands}`,
//         hideOverlayLoader: true,
//       },
//     });
//   },
  
//   setbrand: (data) =>
//     dispatch({
//       type: reduxType_,
//       payload: {
//         type: "brand",
//         data,
//       },
//     }),
//   setmodel: (data) =>
//     dispatch({
//       type: reduxType_,
//       payload: {
//         type: "model",
//         data,
//       },
//     }),
//   setfuelType: (data) =>
//     dispatch({
//       type: reduxType_,
//       payload: {
//         type: "fuel_type",
//         data,
//       },
//     }),
//   setyear: (data) =>
//     dispatch({
//       type: reduxType_,
//       payload: {
//         type: "year",
//         data,
//       },
//     }),
//   setvariant: (data) =>
//     dispatch({
//       type: reduxType_,
//       payload: {
//         type: "variant",
//         data,
//       },
//     }),
// });
// export default connect(mapStateToProps, mapDispatchToProps)(CarDetailForm);




import React, { useEffect } from "react";
// import {  useHistory } from "react-router-dom";
import Dropdown from "../Form/Dropdown";
import Form from "../Form";
//
import { connect } from "react-redux";
import Labels from "../../services/apiLabels";
import { POST, GET } from "../../constant/apiMethods";
import { BRANDS } from "../../constant/index";
import { API_ENDPOINT } from "../../services/api";
//
const getdata = async (label) => {
  const parameters = {
    GET,
    headers: {
      "Content-Type": "application/json",
    },
  };
  return await fetch(API_ENDPOINT(label), parameters)
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      return json;
    });
};
let reduxType_ = "SELL_MY_CAR_STEPS";

// console.log("reduxType_reduxType_---------------",reduxType_)
//
function CarDetailForm(props) {
  const {
    formWidth,
    title,
    fetchingBrands,
    getbrands,
    brands,
    onSubmit,
    buttonText,
    //
    setbrand,
    setmodel,
    setfuelType,
    setyear,
    setvariant,
    //
    reduxType,
    reducer,
    loading,
    state,
    carData,
    setcarData,
    isEdit,
    carStepsData,
  } = props;

  const brand = props[reducer]?.step1?.brand?.current;
  const model = props[reducer]?.step1?.model;
  const fuel_type = props[reducer]?.step1?.fuel_type;
  const year = props[reducer]?.step1.year;
  const variant = props[reducer]?.step1.variant;
  useEffect(() => {
    reduxType_ = reduxType ? reduxType : "SELL_MY_CAR_STEPS";
    if (brands.length < 1) getbrands();
  }, []);
  //

  useEffect(() => {
    if (carStepsData && carStepsData.brands) {
      setBrandsInfo(carStepsData.brands);
      modelsOnSelectHandler(carStepsData.model_name);
      fuelTypeOnSelectHandler(carStepsData.fuel_type);
      yearsOnSelectHandler(carStepsData.year);
    }
  }, [carStepsData]);
  //

  const onSubmitHandler = () => {
    onSubmit();
  };
  const resetForm = () => {
    setcarData(defaultState);
    setbrand(defaultState);
    setmodel(defaultState);
    setfuelType(defaultState);
    setyear(defaultState);
    setvariant(defaultState);
  };
  const defaultState = {
    fetching: false,
    data: [],
    isSelected: false,
    current: {},
  };

  const setBrandsInfo = async (item) => {
    if (item?.id) {
      carData.brands = item.id;
      setcarData({ ...carData });
      setbrand({ ...brand, isSelected: true, current: item });
      setmodel({ ...defaultState, fetching: true });
      const datam = await getdata(`${Labels.car_models}${item.id}/`);
      if (datam.length > 0) {
        console.log("data m iii ", datam);
        setmodel({
          data: datam,
          fetching: false,
          isSelected: true,
          current: carStepsData?.model_name,
        });

        // setfuelType({ ...defaultState, fetching: true });
        const dataf = await getdata(
          `${Labels.car_fuel_types}${carStepsData?.model_name?.value}/`
        );
        if (dataf.length > 0) {
          // setmodel({ ...model, isSelected: true, current: carStepsData?.model_name });
          setfuelType({
            data: dataf,
            fetching: false,
            isSelected: true,
            current: carStepsData?.fuel_type,
          });
          const dataft = await getdata(
            `${Labels.car_years}${carStepsData?.fuel_type?.value}/`
          );
          if (dataft.length > 0) {
            setyear({
              data: dataft,
              fetching: false,
              isSelected: true,
              current: carStepsData?.year ?? {},
            });
          } else {
          }
        } else {
          // setfuelType({ ...defaultState, fetching: false });
        }
      } else {
        // setmodel({ ...defaultState, fetching: false });
      }
    }
  };
  // call a function when brand is selected
  const brandOnSelectHandler = async (item = {}) => {
    // console.log("bra nds info -->> ")
    if (item.id) {
      carData.brands = item.id;
      setcarData({ ...carData });
      setbrand({ ...brand, isSelected: true, current: item });
      setmodel({ ...defaultState, fetching: true });
      const data = await getdata(`${Labels.car_models}${item.id}/`);
      // console.log("brandOnSelectHandler============>data",data)
      if (data.length > 0) {
        setmodel({
          data,
          fetching: false,
          isSelected: false,
          current: {},
        });
      } else {
        setmodel({ ...defaultState, fetching: false });
      }
    }
  };
  // call a function when model is selected
  const modelsOnSelectHandler = async (item) => {
    console.log("modelsOnSelectHandler=======22222=====>item", item);
    if (item?.value) {
      carData.model_name = item.value;
      setcarData({ ...carData });
      setmodel({ ...model, isSelected: true, current: item });
      setfuelType({ ...defaultState, fetching: true });
      const data = await getdata(`${Labels.car_fuel_types}${item.value}/`);
      if (data.length > 0) {
        setfuelType({
          data,
          fetching: false,
          isSelected: false,
          current: {},
        });
      } else {
        setfuelType({ ...defaultState, fetching: false });
      }
    }
  };
  const fuelTypeOnSelectHandler = async (item) => {
    if (item.value) {
      carData.fuel_type = item.value;
      setcarData({ ...carData });
      setfuelType({ ...fuel_type, isSelected: true, current: item });
      // setyear({ ...defaultState, fetching: true });
      const data = await getdata(`${Labels.car_years}${item.value}/`);
      if (data.length > 0) {
        setyear({
          data,
          fetching: false,
          isSelected: carStepsData?.year ? true : false,
          current: carStepsData?.year ?? {},
        });
      } else {
        setyear({ ...defaultState, fetching: false });
      }
    } else {
      setfuelType({ ...fuel_type, isSelected: true, current: item });
    }
  };
  
  const yearsOnSelectHandler = async (item) => {
    if (item.value) {
      carData.year = item.value;
      setcarData({ ...carData });
      setyear({ ...year, isSelected: true, current: item });
      setvariant({ ...defaultState, fetching: true });
      const data = await getdata(`${Labels.car_variants}${item.value}/`);
      if (data.length > 0) {
        setvariant({
          data,
          fetching: false,
          isSelected: false,
          current: carStepsData?.year ?? {},
        });
      } else {
        setvariant({ ...defaultState, fetching: false });
      }
    } else {
      setyear({ ...year, isSelected: true, current: item });
    }
  };
  //  // call a function when variant is selected
  const variantsOnSelectHandler = async (item) => {
    // console.log"variantsOnSelectHandler=======555555=====>item",item)
    // console.log"c",item)
    if (item.value) {
      setvariant({ ...variant, isSelected: true, current: item });
    }
  };
  // console.log("reduxType_reduxType_---------------",reduxType_)
  return (
    <Form
      width={formWidth}
      loading={loading}
      loadingText="Submitting Interest ..."
      title={title}
      onSubmit={onSubmitHandler}
      buttonText={buttonText}
    >
      {/* brands */}
      <div className="col-sm-6 form-group">
        <Dropdown
          label="Brand"
          onSelect={brandOnSelectHandler}
          titleKey="brand_name"
          selectedValue={brand}
          loading={fetchingBrands}
          disabled={fetchingBrands || loading}
          data={brands}
        />
      </div>
      {/* modal */}
      <div className="col-sm-6 form-group">
        <Dropdown
          label="Model"
          onSelect={modelsOnSelectHandler}
          titleKey="display"
          idKey="value"
          selectedValue={model?.current}
          loading={model?.fetching}
          disabled={model?.fetching || !brand?.id || loading}
          data={model?.data}
        />
      </div>
      {/* fuel type */}
      <div className="col-sm-6 form-group">
      <Dropdown
        label="Fuel Type"
        onSelect={fuelTypeOnSelectHandler}
        titleKey="display"
        idKey="value"
        selectedValue={fuel_type?.current}
        loading={fuel_type?.fetching}
        disabled={fuel_type?.fetching || !model?.isSelected || loading}
        data={fuel_type?.data}
        restrictFuelType // Add this prop
      />
      </div>
      <div className="col-sm-6 form-group">
      <Dropdown
        label="Year"
        onSelect={yearsOnSelectHandler}
        titleKey="display"
        idKey="value"
        selectedValue={year?.current}
        loading={year?.fetching}
        disabled={year?.fetching || !fuel_type?.isSelected || loading}
        data={year?.data}
      />
      </div>
      {/* variant */}
      {/* <Dropdown
      
        label="Variant"
        onSelect={variantsOnSelectHandler}
        titleKey="display"
        idKey="value"
        selectedValue={variant?.current}
        loading={variant?.fetching}
        disabled={variant?.fetching || !year?.isSelected || loading}
        data={variant?.data}
      /> */}
    </Form>
  );
}
const mapStateToProps = (state) => ({
  fetchingBrands: state.sellMyCar.fetchingBrands,
  brands: state.sellMyCar.brands,
  state: state,
  //
  showInterest: state.showInterest,
  sellMyCar: state.sellMyCar,
});
const mapDispatchToProps = (dispatch) => ({
  // get product list
  getbrands: (data) => {
    return dispatch({
      type: BRANDS,
      payload: {
        type: BRANDS,
        method: POST,
        label: `${Labels.brands}`,
        hideOverlayLoader: true,
      },
    });
  },

  setbrand: (data) =>
    dispatch({
      type: reduxType_,
      payload: {
        type: "brand",
        data,
      },
    }),
  setmodel: (data) =>
    dispatch({
      type: reduxType_,
      payload: {
        type: "model",
        data,
      },
    }),
  setfuelType: (data) =>
    dispatch({
      type: reduxType_,
      payload: {
        type: "fuel_type",
        data,
      },
    }),
  setyear: (data) =>
    dispatch({
      type: reduxType_,
      payload: {
        type: "year",
        data,
      },
    }),
  setvariant: (data) =>
    dispatch({
      type: reduxType_,
      payload: {
        type: "variant",
        data,
      },
    }),
});
export default connect(mapStateToProps, mapDispatchToProps)(CarDetailForm);