import {React,useState} from "react";
import { connect } from "react-redux";
import { MdCall, MdTextsms, MdAccessTime } from "react-icons/md";
import { Link } from "react-router-dom";
import Labels from "../../services/apiLabels";
import { POST, GET } from "../../constant/apiMethods";
import { SHOW_INTEREST, CREATE_SUBSCRIPTION } from "../../constant";
import "./style.css";
export const Footer = ({create_subscription}) => {


const [email, setemail] = useState("")

// console.log"email inp",email)
const subscribe_me=()=>{
    create_subscription({
        email:email
    })
}


  return (
    <>
      <div className="container p-5">
        <h2>Any question ?</h2>
        <p>You'll find the answers of your questions in our FAQs section.</p>
        <div className="row">
          <div className="col-lg-3 col-md-3 col-sm-12 d-flex ">
            <MdCall size={22} /> &nbsp; &nbsp;123 456 789
          </div>
          <div className="col-lg-3 col-md-3 col-sm-12 my-2">
           
          </div>
          <div className="col-lg-3 col-md-3 col-sm-12 d-flex">
            <MdAccessTime size={22} />
            &nbsp;&nbsp; 11:30am - 07:30pm
          </div>
        </div>

        <hr></hr>

        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 list_wrap">
            <ul className="foter_list">
              <Link to="/contactus" className="link">
                <li>Contact </li>
              </Link>

              <Link to="/privacypolicy" className="link">
                <li>Privacy Policy </li>
              </Link>

              <Link to="/faqs" className="link">
                <li>FAQs </li>
              </Link>

              <Link to="/requridocuments" className="link">
                <li>Required documents </li>
              </Link>

              <Link to="/terms" className="link">
                <li>Terms and conditions </li>
              </Link>

              <Link to="/aboutus" className="link">
                <li>About us </li>
              </Link>
            </ul>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <h3>What do you want to do?</h3>
            <p>Choose from our range of services.</p>

            <div className="row bton_wrap">
              <div className="col-4 buy_">
                <Link to="/buynow" className="link">
                  <button className="button_heder">Buy Now</button>
                </Link>
              </div>
              <div className="col-4 buy_ ">
                <Link to="/auction" className="link">
                  <button className="button_heder">Auction </button>
                </Link>
              </div>
              <div className="col-4 buy_">
                <Link to="/showInterest" className="link">
                  <button className="button_heder">Show Interest </button>
                </Link>
              </div>
            </div>

<div className="row p-4 buy_">

    <h3>Subscribe</h3>
    <p> Subscribe our newsletter to stay update every moment</p>
    <div className="col-8 buy_l">
    <div className="inp_wrap ">
                  <input 

onChange={(e)=>{setemail(e.target.value)}}

                  className="inp_contact"
                  placeholder="abc@gmail.com "
                  ></input>
                  </div>
      

    </div>
    <div className="col-4">
    <button className="button_heder" onClick={subscribe_me}>Subscribe</button>
    </div>
     
</div>



          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
    create_subscription: (data) =>
    dispatch({
      type: CREATE_SUBSCRIPTION,
      payload: {
        type: CREATE_SUBSCRIPTION,

        label: Labels.createSubscription,
        method: POST,
        data,
        hideOverlayLoader: true,
      },
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
