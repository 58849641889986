
import {React} from "react";
// import { HiCheck } from "react-icons/hi";
import { connect } from "react-redux";
import "./style.css";
import { FaFacebookSquare ,FaInstagram,FaLinkedin,FaYoutube} from "react-icons/fa";
export const FoterMini = ({}) => {




  return (
    <>
  <div className="w-full bg-charcoal text-white">
        <div className="w-75 md:w-grid-md lg:w-grid-lg xl:w-grid-xl mx-auto flex flex-col md:flex-row py-4 md:py-6 px-4 md:px-0   ">
            <div className="w-full md:w-1-2 text-sm">2022 Copyright © All Rights Reserved</div>
            <div className="w-full md:w-1-2 flex md:justify-end  azaaza">
                <div className="mr-2"><a href="https://www.facebook.com/carfirstpk/" target="_blank"
                        rel="noopener noreferrer">  <FaFacebookSquare
                        color="white"
                        size={25}
                        
                        
                      /> </a> </div>
                <div className="mr-2"><a href="https://www.youtube.com/channel/UCMTFXYdUJO4p3KDDnrGHPDw?sub_confirmation=1"
                        target="_blank" rel="noopener noreferrer">   <FaYoutube
                        color="white"
                        size={25}
                        
                        
                      /></a>
                </div>
                <div className="mr-2"><a href="https://pk.linkedin.com/company/carfirst" target="_blank"
                        rel="noopener noreferrer">   <FaLinkedin
                        color="white"
                        size={25}
                        
                        
                      /> </a></div>
                <div className=""><a href="https://www.instagram.com/carfirstpk/" target="_blank"
                        rel="noopener noreferrer">  <FaInstagram
                        color="white"
                        size={25}
                        
                        
                      />
                    </a></div>
            </div>
        </div>
    </div>
    </>
  );
};


const mapStateToProps = (state) => ({
  // home_page: state.datafile.home_page,
});
export default connect(mapStateToProps, null)(FoterMini);