import React, {useEffect}from "react";
import { AiTwotoneEye } from "react-icons/ai";


import { Card } from "react-bootstrap";
import { ACTIVE_BUYING_LIST } from "../../constant";
import { POST } from "../../constant/apiMethods";
import { connect } from "react-redux";
import Labels from "../../services/apiLabels";
import { ImHammer2 } from "react-icons/im";
import { FaEuroSign } from "react-icons/fa";
import './watch.css';
import {

  ShimmerTitle,
 } from "react-shimmer-effects";
function ActiveBuy({wishList,active_buyingList_ac,user_id,active_buying,loadingGetWishList}) {

useEffect(() => {
 
  if(active_buying.length===0){
    // console.log"active_buying",active_buying)
    active_buyingList_ac({
      id_token:user_id
    })
  }
  
}, [])




  const Data = [
    
    {
      title: "Rolls-Royce Phantom",
      imgsrc:
        "https://cdn.bmwblog.com/wp-content/uploads/2020/09/2021-rolls-royce-ghost-30.jpg",
      detail:
        "Rolls-Royce has always excelled at producing luxury vehicles and one of those fancy set of wheels is the Rolls-Royce Phantom.",
        door: "4dr",
        engine: "1300",
        bid: " Current Bid",
        bidprice: "$ 17000",
        time: "5:46",
        km: "36000",
        wheel: "AWD",
        start:'$ 430'
    },
    {
      imgsrc:
        "https://cdn.bmwblog.com/wp-content/uploads/2020/09/2021-rolls-royce-ghost-30.jpg",
      title: " Hummer 2021 Hybrid",
      detail: "Rolls-Royce has always excelled at producing luxury vehicles",
      door: "4dr",
      engine: "1300",
      bid: " Current Bid",
      bidprice: "$ 17000",
      time: "5:46",
      km: "36000",
      wheel: "AWD",
      start:'$ 430'
    },
    {
      imgsrc:
        "https://th.bing.com/th/id/OIP.6T0X8JDGKvfl9BThAeXeaAHaE6?pid=ImgDet&w=2048&h=1358&rs=1",
      title: "Audii A8 2021",
      detail: "Rolls-Royce has always excelled at producing luxury vehicles",
      door: "4dr",
      engine: "1300",
      bid: " Current Bid",
      bidprice: "$ 17000",
      time: "5:46",
      km: "36000",
      wheel: "AWD",
      start:'$ 430'
    },
  ];
  return (
<>
    {
      loadingGetWishList?
      <>
 <ShimmerTitle className="w-100" line={1} gap={10} variant="primary" />
 <ShimmerTitle className="w-100" line={1} gap={10} variant="primary" />
 <ShimmerTitle className="w-100" line={1} gap={10} variant="primary" />

    </>
    :(
      active_buying.length === 0?

      <>
      <h5>No Data Found</h5>
      </>
:

<div>
      
{React.Children.toArray(active_buying.map((Products) => (
  <div className="watchbody1">
    <Card>
      <div className="watcdisplaylayout">
        {/* -------------image plus icons----------- */}
        <div className="col-sm-4.col-md-4.col-lg-4.col-xl-4 watcfirst">
          <img src={Products.main_image} />
          <div className="watciconcontainer">
              <div className="watciconi">
                <AiTwotoneEye />
              </div>
          </div>
        </div>

        {/* --------------product detail----------- */}
        <div className="col-sm-4.col-md-4.col-lg-4.col-xl-4 watcsecond">

            {/* -----detail --------- */}
          <div className="watcseconddetail">
            <span className="watctexttitle"> {Products.product_name}</span>
            {/* <div className='watcdetailcontainer'>
            <span className="watctext"> {Products.door}<BsDot/></span>
            <span className="watctext"> {Products.wheel}<BsDot/></span>
            <span className="watctext"> {Products.engine}<BsDot/></span>
            </div> */}

            <span className="watctext"> <FaEuroSign/>  {Products.your_bid} </span>
            <span className="watctext"> <ImHammer2/> {Products.status}</span>
            </div>
            {/* --------detail end -----------*/}
           
        </div>


      </div>
    </Card>
  </div>
)))}
</div>
  

    )

    }
    </>
  );
}
const mapStateToProps = (state) => ({
  active_buying: state.product.active_buying,
  loadingGetWishList: state.product.loadingActive_buyingList,
  user_id: state.auth.id,
  filtersQuery: state.filters.queryString_,
});
const mapDispatchToProps = (dispatch) => ({
  // get product list
  active_buyingList_ac: (data) =>

    dispatch({
      type: ACTIVE_BUYING_LIST,
      payload: {
        type: ACTIVE_BUYING_LIST,
        method: POST,
        data,
        label:Labels.active_buying_list,
      
        hideOverlayLoader: true,
      },
    }),
  //
});

export default connect(mapStateToProps, mapDispatchToProps)(ActiveBuy);

