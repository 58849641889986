import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { BsFillCheckCircleFill } from "react-icons/bs";
import "./style.css"
import { useHistory } from "react-router-dom";
import { MdPending } from "react-icons/md";
export const CarSteps = (props) => {

    const {step1,step2,step3,evaluation_id_}=props;
    const history = useHistory();

    useEffect(() => {
        if(!step1 && !evaluation_id_){
            history.push("/")
        }
    
        // 
      }, []);

   






  return (
 <>
  <div className="showInterest_headingCont ">
        <div className="showInterest_heading">
        Steps to Reproduce
        </div>
        <div className="showInterest_description"> 
          Complete the steps which are not complete it will help us to vlaue your car more properly
           </div>
      </div>
 
 <div className='container'>
<div className='row my-5'>

<div className='car_card_step'>


<div className='card_content col-8 '>
    <div className=' row d-flex'>
        <h3>Contact Details</h3>
        <p>Your Contact Information</p>
       {
           step1?
           <span>   <BsFillCheckCircleFill size={15} className='cici'/> &nbsp;Complate</span>:
           <span>   <MdPending size={15} className='cici'/> &nbsp;Pending</span>
       }
      
    </div>
    
</div>

<div div className='col-4'>
{
           step1?
           <button className='card_bton'> Done</button>:
           <button className='card_bton_w'> Start</button>
       }
        
    </div>
</div>

</div>
<div className='row my-5'>

<div className='car_card_step'>


<div className='card_content col-8 '>
    <div className=' row d-flex'>
        <h3>Vehicle Details</h3>
        <p>Information about your vehicle</p>
        
        {
           step2?
           <span>   <BsFillCheckCircleFill size={15} className='cici'/> &nbsp;Complate</span>:
           <span>   <MdPending size={15} className='cici'/> &nbsp;Pending</span>
       }
        {/* <span>   <BsFillCheckCircleFill size={15} className='cici'/> &nbsp;Complate</span> */}
    </div>
    
</div>

<div div className='col-4'>

{
           step2?
           <button className='card_bton'> Done</button>:
           <button className='card_bton_w' onClick={()=>{history.push("/carupdate")}} > Start</button>
       }
        {/* <button className='card_bton_w' onClick={()=>{history.push("/carupdate")}}  > Start</button> */}
    </div>
</div>

</div>

<div className='row my-5'>

<div className='car_card_step'>


<div className='card_content col-8 '>
    <div className=' row d-flex'>
        <h3> Book Appoinment</h3>
        <p>Book your Appointment</p>
        {
           step3?
           <span>   <BsFillCheckCircleFill size={15} className='cici'/> &nbsp;Complate</span>:
           <span>   <MdPending size={15} className='cici'/> &nbsp;Pending</span>
       }
        {/* <span>   <BsFillCheckCircleFill size={15} className='cici'/> &nbsp;Complate</span> */}
    </div>
    
</div>

<div div className='col-4'>
{
           step3?
           <button className='card_bton'> Done</button>:
           <button className='card_bton_w' onClick={()=>{history.push("/bookappointment")}} > Start</button>
       }
        {/* <button className='card_bton_w' onClick={()=>{history.push("/bookappointment")}} > Start</button> */}
    </div>
</div>

</div>
<div className='row my-5'>

<div className='car_card_step'>


<div className='card_content col-8 '>
    <div className=' row d-flex'>
        <h3>Photos</h3>
        {

            step1?
            <p>Please find the link in your email address to provide the Photos of your Car. Using your mobile device</p>:
            <p>Please provide the contact details to get the url to upload photos of your car</p>

        }

        

        {/* <span>   <BsFillCheckCircleFill size={15} className='cici'/> &nbsp;Complate</span> */}
    </div>
    
</div>

{/* <div div className='col-4'>
        <button className='card_bton_w'> Edit</button>
    </div> */}
</div>

</div>
 </div>
 
 
 </>
  )
}
const mapStateToProps = (state) => ({
  

    step1: state.sellMyCar.step1complete,
    step2: state.cardeailupdate.step2complete,
    step3: state.sellMyCar.step3complete,
    evaluation_id_: state.sellMyCar.evaluation_create.id,
  });


const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(CarSteps)