import React, { useEffect, useState } from "react";
import Form from "../../components/Form";
//
import { connect } from "react-redux";
import Labels from "../../services/apiLabels";
import {
  OFFICE_TIMING,
  APPOINTMENT_CREATE,
  SELL_MY_CAR_STEPS,
  EVALUATE_VIDEO,
} from "../../constant";
import { FaImage, FaPlus } from "react-icons/fa";
import { GET, POST } from "../../constant/apiMethods";
import Dropdown from "../../components/Form/Dropdown";
import { getTimeSolts, formateTimeSlot } from "../../utils/formating";
import { scrollTop } from "../../utils/window";
import { getNext7Days } from "../../utils/functions";
import { API_ENDPOINT } from "../../services/api";
import IconInput from "../../components/Form/Input";
//
function SellMyCarstep6(props) {
  const {
    create_video,
    setcreate_video,
    postcreatvideo_fail,
    postcreatvideo_success,
    postcreatvideo_request,
    evaluation,
    createVideoLoading,
    create_video_description,
    setcreate_video_description,
  } = props;
  let videoInputRef = React.createRef();
  //
  const postcreatvideo = async (data) => {
    const parameters = {
      method: POST,
      body: data,
    };
    return await fetch(API_ENDPOINT(Labels.sellMyCarVideo), parameters)
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        return json;
      });
  };
  //
  const setfilesArrHandler = (e) => {
    const filesObj = videoInputRef.current.files || {};
    const arr = Object.values(filesObj);
    // setcreate_video([...create_video, ...arr]);
    setcreate_video([...arr]);
    
  };
  //
  const postcreatvideoHandler = async () => {
    // e.preventDefault();
    if (create_video.length > 0 && create_video_description) {
      const bodyFormData = new FormData();
      for (var i = 0; i < create_video.length; i++) {
        bodyFormData.append("video", create_video[i]);
      }
      bodyFormData.append("evaluate_video", evaluation.id);
      bodyFormData.append("description", create_video_description);
      //
      try {
        postcreatvideo_request();
        const response = await postcreatvideo(bodyFormData);
        postcreatvideo_success(response);
        
      } catch (error) {
        
      }
      //
      //;
    } else {
      
    }
  };
  
  return (
    <div className="w-100 h-auto d-flex justify-content-center">
      <Form
        width="fluid"
        title="Add Car Video"
        onSubmit={postcreatvideoHandler}
        buttonText="Submit Videos"
        // className={formanimationClassname}
        loading={createVideoLoading}
        loadingText="Submitting Videos ..."
      >
        <div className="dropdown_wrapper">
          <IconInput
            loading={createVideoLoading}
            type="text"
            label="Description"
            placeholder="Description"
            onChangeText={setcreate_video_description}
            value={create_video_description}
          />
        </div>
        <div className="dropdown_wrapper d-flex flex-wrap">
          <label
            disabled={create_video.length > 0}
            className="imgpiker  cardShadow"
            htmlFor="img"
          >
            {/* <FaImage color="var(--primary)" size={50} /> */}
            <FaPlus color="var(--primary)" size={45} />
          </label>

          <input
            disabled={createVideoLoading}
            type="file"
            id="img"
            name="img"
            accept="video/*"
            className="d-none"
            ref={videoInputRef}
            onChange={setfilesArrHandler}
            // onChange={(e) => // console.log"file", e.target.files)}
            // multiple
          />
          <div className="dropdown_wrapper">
            <div
              className="dropdown_wrapper"
              style={{ fontWeight: "bold", fontSize: "1.4rem" }}
            >
              {create_video.length > 0 && create_video[0].name}
            </div>
          </div>
          {/* {create_video.length > 0 && (
            <div
              className="imgpiker cardShadow"
              style={{
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              <FaPlus color="var(--primary)" size={50} />
            </div>
          )} */}
          {/* {create_video.map((item, index) => {
            return (
              <img
                className="img1"
                src={URL.createObjectURL(item)}
                alt={`car video ${index} `}
              />
            );
          })} */}
        </div>
      </Form>
    </div>
  );
}
////////
const mapStateToProps = (state) => ({
  create_video: state.sellMyCar.video.create_video,
  create_video_description: state.sellMyCar.video.description,
  evaluation: state.sellMyCar.evaluation_create,
  createVideoLoading: state.sellMyCar.video.create_video_loading,
});
const mapDispatchToProps = (dispatch) => ({
  setcreate_video: (data) =>
    dispatch({
      type: SELL_MY_CAR_STEPS,
      payload: {
        type: "create_video",
        data,
      },
    }),
  setcreate_video_description: (data) =>
    dispatch({
      type: SELL_MY_CAR_STEPS,
      payload: {
        type: "create_video_description",
        data,
      },
    }),
  postcreatvideo_request: (data) =>
    dispatch({
      type: `${EVALUATE_VIDEO}_REQUEST`,
      payload: data,
    }),
  postcreatvideo_success: (data) =>
    dispatch({
      type: `${EVALUATE_VIDEO}_SUCCESS`,
      payload: data,
    }),
  postcreatvideo_fail: (data) =>
    dispatch({
      type: `${EVALUATE_VIDEO}_FAIL`,
      payload: data,
    }),
});
export default connect(mapStateToProps, mapDispatchToProps)(SellMyCarstep6);
