import React from "react";
import Footer from "../../components/Footer";
import { scrollTop } from "../../utils/window";

import { useContext } from "react";
import FeedbackContext from "../../context/cmscontext";
function Terms() {
    const { feedback, isLoading } = useContext(FeedbackContext);
var term_condition = feedback.term_condition;
  return (
    <>
      {scrollTop()}
      <div className="showInterest_headingCont ">
        <div className="showInterest_heading">{term_condition.term_text}</div>
        <div className="showInterest_description">
          {term_condition.term_desc}
        </div>
      </div>

      <div className="container my-5">
        <h2> {term_condition.acceptance_text}</h2>
        <p>{term_condition.acceptance_descp1}</p>
        <p>{term_condition.acceptance_descp2}</p>
        <p>{term_condition.acceptance_descp3}</p>{" "}
        <h2>{term_condition.desc_text}</h2>
        <p>{term_condition.acceptance_descp1}</p>
        <p>{term_condition.acceptance_descp2}</p>
        <p>{term_condition.acceptance_descp3}</p>
      </div>
    </>
  );
}

export default Terms;
