import { sagaRoot } from "./root";
import {
  all,
  call,
  fork,
  put,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import {
  APPOINTMENT_CREATE,
  SHOW_INTEREST,
  EVALUATION_CREATE,
  OFFICE_TIMING,
  MY_INTERSTE,
  MY_APPOINMENT,
  CAR_DETAILE,
  REMOVE_INTEREST,
  CREATE_WISH_LIST,
  GET_WISH_LIST_AUCTION,
  GET_WISH_LIST_BUYNOW,
  CLOSE_WISH_LIST,
  ACTIVE_BUYING_LIST,
  SHIPING_PRICE,
  CAR_MODELS

} from "../constant";
// get all cars brands
export function* postvendorinterest(payload) {
  const response = yield call(sagaRoot, payload);
}
// get my inrest
export function* getvendorinterest(payload) {
  const response = yield call(sagaRoot, payload);
}
// my appoinment
export function* myappointment(payload) {
  const response = yield call(sagaRoot, payload);
}
// get car detail
export function* getcardetail(payload) {
  const response = yield call(sagaRoot, payload);
}
//remove car detail
export function* removecardata(payload) {
  const response = yield call(sagaRoot, payload);
}
//create wishn car detail
export function* createwishlist(payload) {
  const response = yield call(sagaRoot, payload);
}
//Get wishn car detail
export function* getauctionwishlist(payload) {
  const response = yield call(sagaRoot, payload);
}
export function* getbuynowwishlist(payload) {
  const response = yield call(sagaRoot, payload);
}
//Get wish car detail
export function* closewishlist(payload) {
  const response = yield call(sagaRoot, payload);
}
//Get active_buyingList_ac
export function* active_buyinglist(payload) {
  const response = yield call(sagaRoot, payload);
}
//Get shiping_price
export function* shiping_price(payload) {
  const response = yield call(sagaRoot, payload);
}






// saga watcher
export default function* authWatcher() {
  yield takeLatest(MY_INTERSTE, getvendorinterest);
  yield takeLatest(SHOW_INTEREST, postvendorinterest);
  yield takeLatest(MY_APPOINMENT, myappointment);
  yield takeLatest(CAR_DETAILE, getcardetail);
  yield takeLatest(REMOVE_INTEREST, removecardata);
  yield takeLatest(CREATE_WISH_LIST, createwishlist);
  yield takeLatest(GET_WISH_LIST_AUCTION, getauctionwishlist);
  yield takeLatest(GET_WISH_LIST_AUCTION, getbuynowwishlist);
  yield takeLatest(CLOSE_WISH_LIST, closewishlist);
  yield takeLatest(ACTIVE_BUYING_LIST, active_buyinglist);
  yield takeLatest(SHIPING_PRICE, shiping_price);
}
