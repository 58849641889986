import React, { Fragment, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import "./style.css";
import { FaCar } from "react-icons/fa";
import {
  SHOW_INTEREST,
  CAR_DETAILE,
  MY_INTERSTE,
  POST,
  GET,
} from "../../constant";
import Lottie from "lottie-react";
import loader from '../../assets/noCar.json'
import { useState } from "react";
import { connect, useSelector } from "react-redux";
import Labels from "../../services/apiLabels";
import Slider from "react-animated-slider";
import "react-animated-slider/build/horizontal.css";
import pic2 from "../../assets/svg/car_no_image.jpg";
import Nocar from "../../assets/svg/nocar.png";
import MySlider from "./Slider";
import { ShimmerThumbnail ,ShimmerTitle,ShimmerContentBlock } from "react-shimmer-effects";
// import FbImageLibrary from '../../fbgrid/index';
import FbGridImages from "../fb-grid/index"
// import '../../fbgrid/css/style.css';
//
import { useContext } from "react";
import FeedbackContext from "../../context/cmscontext";

function CarDetail(props) {
  const {
    getcardetail,
    data,
    datacar,
    navbarDimensions,
    loadingCardetail,
    getvendorinterest,
    userId,
  } = props;
     const { feedback, isLoading } = useContext(FeedbackContext);
var card_detail = feedback.card_detail;
  let history = useHistory();
  const location = useLocation();
  // const variant_id = location.state;

  const [variant_id, setVariant_id] = useState(location.state.carid);
  
  useEffect(() => {
    getcardetail(variant_id);
  }, []);
  useEffect(() => {
    if (data.length === 0) getvendorinterest(userId);
  }, [userId]);
  const [images, setImages] = useState([]);
  const carDtail = (data) => {
    // console.log"slider cared",data)
    setVariant_id(data);
    getcardetail(data);
  };


  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
  };

  return (
    <Fragment>
      <div className="carDetailBannerCont p-5 pb-0">
        <div className="showInterest_headingCont">
          <div className="showInterest_heading">{card_detail.detail_text}</div>
          <div className="showInterest_description">
            {card_detail.description}
          </div>
        </div>
        <MySlider onSlideClick={carDtail} activeId={variant_id} />
      </div>

      {loadingCardetail ? (
        <div className="d-flex justify-content-center align-content-center">
          <div className="row mdiv1shimer mt-5  ">
            <div className="col-lg-4 col-md- col-sm-4 col-xs-12  pt-3 pl-3 ">
              <div className="shimerpic">
                <ShimmerThumbnail rounded />
              </div>
            </div>
            <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12   pt-5 ">
              <ShimmerThumbnail height={30} width={200} rounded />
              <ShimmerThumbnail height={30} width={270} rounded />

              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <span>
                    <ShimmerThumbnail height={30} width={270} rounded />
                    <ShimmerThumbnail height={30} width={270} rounded />
                  </span>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <span>
                    <ShimmerThumbnail height={30} width={200} rounded />
                    <ShimmerThumbnail height={30} width={200} rounded />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className=" product-list-cont pt-5 maindivcar">
          {data.length === 0 ? (
            <div className="d-flex justify-content-center">
              <div className=" car_l">
                <Lottie
                  animationData={loader}
                  style={{ height: "70%", width: "30%" }}
                  className="animi"
                  autoplay={true}
                  loop={true}
                />
                <h1 className="text-center no_car p-5">
                  {card_detail.no_data}
                </h1>
              </div>
            </div>
          ) : (
            data.map(function (item, i) {
              return (
                <div className="row mdiv1 m-3 ">
                  <div className="col-sm-12 col-lg-5 col-md-5 cola ">
                    {item.images.length < 1 ? (
                      <img className="img1" src={pic2} />
                    ) : (
                      <div className="wrap">
                        <FbGridImages images={item.images} />
                      </div>
                    )}
                  </div>

                  <div className=" col-sm-12 col-lg-7 col-md-7 p-5 colb">
                    <span className="product_list_card_title brndname">
                      {item.brand_name}
                    </span>
                    <div className="mt-2">
                      <span className="modelvarint ">
                        {item.Mileage}-{item.variant_name}
                      </span>
                      <span className="product_list_card_features_text txt mt-4">
                        {card_detail.Post_code}: {item.Postcode}
                      </span>
                      <span className="product_list_card_features_text txt">
                        {card_detail.Previous_owner}: {item.Previous_owners}
                      </span>
                      <span className="product_list_card_features_text txt">
                        {card_detail.Service_history}: {item.Service_history}
                      </span>
                      <span className="product_list_card_features_text txt">
                        {card_detail.fuel_type}: {item.fuel_type}
                      </span>

                      <span className="product_list_card_features_text txt">
                        {card_detail.mod_name}: {item.model_name}
                      </span>

                      <span className="product_list_card_features_text txt">
                        {card_detail.reg_no}: {item.reg_number}
                      </span>

                      <span className="product_list_card_features_text txt">
                        {card_detail.year_name1}: {item.year_name}
                      </span>

                      <span className="product_list_card_features_text txt">
                        {card_detail.year_name2}:{item.year_name}
                      </span>
                    </div>
                  </div>
                </div>
              );
            })
          )}
        </div>
      )}
    </Fragment>
  );
}

const mapStateToProps = (state) => ({
  userId: state.auth.id,
  data: state.cardetail.data,
  loadingCardetail: state.cardetail.loading,
  datacar: state.myInterest.data,
});
const mapDispatchToProps = (dispatch) => ({
  getvendorinterest: (id) =>
    dispatch({
      type: MY_INTERSTE,
      payload: {
        type: MY_INTERSTE,
        label: `${Labels.vendorinterest}?user=${id}`,
        method: GET,
        hideOverlayLoader: true,
      },
    }),
  getcardetail: (variant_id) =>
    dispatch({
      type: CAR_DETAILE,
      payload: {
        type: CAR_DETAILE,
        label: `${Labels.getcarsdetiles}?user=${variant_id}`,
        method: POST,
        hideOverlayLoader: true,
      },
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(CarDetail);
