import React, { useEffect, useRef } from "react";
// packegs
import { Col, Row } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import $ from "jquery";
import { useHistory, useLocation } from "react-router-dom";
// importing redux related things
import { connect } from "react-redux";
import Labels from "../../services/apiLabels";
import Footer from "../../components/Footer"
import { scrollTop } from "../../utils/window";
import { POST } from "../../constant/apiMethods";
import {
  LOGIN,
  TOASTER_SHOW,
  toaster_s,
  toaster_e,
  toaster_w,
} from "../../constant";
import { DEFAULT_ROUTE, USER } from "../../router";
// components
import "./loginstyle.css";
import carlogo from "../../assets/images/login.png";
import OverLayLoader from "../../components/OverLayLoader";
import { useContext } from "react";
import FeedbackContext from "../../context/cmscontext";
// function
function Login(props) {
  const { login, auth, toasterShow } = props;
       const { feedback, isLoading } = useContext(FeedbackContext);
var login_page = feedback.login_page;

  useEffect(() => {
 
    scrollTop()
 
  }, [])



  let history = useHistory();
  let location = useLocation();
  
  
  
  
  const onLoginSuccessHandler = (response) => {
    history.replace(
      location.state ? location.state.from.pathname : DEFAULT_ROUTE
    );
  };
  const loginHandler = () => {
    let username = $("#email").val();
    let password = $("#password").val();
    const remember_me = $("#remember_me").is(":checked");
    if (username && password) {
      login(
        {
          username: $("#email").val(),
          password: $("#password").val(),
        },
        // onLoginSuccessHandler
        ()=>{}
      );
    } else {
      toasterShow({
        show: true,
        autoHide: false,
        type: toaster_w,
        description: `please enter ${!username ? "User Name" : ""} ${
          !password ? "Password" : ""
        }`,
      });
    }
  };
  //

  //
  // useEffect(() => {
  //   if (auth.token) onLoginSuccessHandler();
  //   return;
  // }, [auth]);
  // useEffect(() => {
  //   if (auth.isLogedin && USER) {
  //     let { from } = location.state || { from: { pathname: DEFAULT_ROUTE } };
  //     history.replace(from);
  //     
  //   }
  // }, [auth.isLogedin, USER]);
  if (auth.token) return <OverLayLoader alwaysShow={true} />;
  else
    return (
      <>
        <div>
          <div className="loginbody">
            <div className="loginMainview">
              {/* ==========rightside================== */}
              <div className="Leftside">
                <div className="loginfield p-2">
                  {/* <div className="mlogincardimage">
                <img src={carlogo} alt="BigCo Inc. logo" />
              </div> */}

                  <div className="loginfieldwrapper">
                    <Col className=" mobileform">
                      <div className="formcontainer">
                        <h5 className="logintext">{login_page.login_text}</h5>
                        <div className="form-group">
                          <label htmlFor="email">{login_page.user_name}</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={login_page.user_name_placeholder}
                            id="email"
                          />
                        </div>

                        <div className="form-group">
                          <label htmlFor="password">
                            {login_page.password_text}
                          </label>
                          <input
                            type="password"
                            className="form-control"
                            placeholder={login_page.password_placeholder}
                            id="password"
                          />
                        </div>

                        <div className="form-group">
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="remember_me"
                              // name="remember_me"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="remember_me"
                            >
                              {login_page.remember_text}
                            </label>
                          </div>
                        </div>

                        <button
                          onClick={loginHandler}
                          // to="/auction"
                          // type="submit"
                          className=" "
                          name="CTAButton"
                        >
                          {login_page.submit_text}
                        </button>
                        <p className="forgot-password text-right mt-2">
                          <Link to="/forgetpassword">
                            {" "}
                            {login_page.forget_text}
                          </Link>
                        </p>
                        <p className="forgot-password text-center">
                          {login_page.dont_text}
                          <Link to="/signup"> {login_page.signup_text}</Link>
                        </p>
                      </div>
                    </Col>
                    <Col className="mobileimagecontainer">
                      <div className="carimage">
                        <div className="logincardimage">
                          <img src={login_page.logourl} alt="BigCo Inc. logo" />
                        </div>
                        <div className="imagetext">{login_page.image_text}</div>
                      </div>
                    </Col>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});
const mapDispatchToProps = (dispatch) => ({
  // login action
  login: (data, callBack) =>
    dispatch({
      type: LOGIN,
      payload: {
        type: LOGIN,
        method: POST,
        label: Labels.login,
        data,
        callBack,
      },
    }),
  //
  toasterShow: (data) =>
    dispatch({
      type: TOASTER_SHOW,
      payload: {
        ...data,
      },
    }),
  //
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);

// ================================================

// import React from "react";
// import "./style.css";
// //
// function Login(props) {
//   const { navbarDimensions } = props;
//   return (
//     <div
//       style={{
//         width: "100%",
//         height: window.innerHeight - navbarDimensions.height,
//         background: "yellow",
//       }}
//       className="position-relative"
//     >

//       Login
//     </div>
//   );
// }

// export default Login;
