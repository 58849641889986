export * from "./apiMethods";
export const TOASTER_SHOW = "TOASTER_SHOW";
export const TOASTER_HIDE = "TOASTER_HIDE";
// toaster states
export const toaster_s = "success";
export const toaster_e = "error";
export const toaster_w = "warning";
// filter
export const SET_FILTER = "SET_FILTER";
export const SET_SELECTED_FILTER = "SET_SELECTED_FILTER";
export const CLEAR_ALL_SELECTED_FILTER = "CLEAR_ALL_SELECTED_FILTER";
export const SET_SEARCH_QUERY_FILTER = "SET_SEARCH_QUERY_FILTER";
//

// ===============

export const CAR_REG_NUMBER = "CAR_REG_NUMBER";



export const LOADER_SHOW = "LOADER_SHOW";
export const LOADER_HIDE = "LOADER_HIDE";
export const LOGIN = "LOGIN";
export const LOG_OUT = "LOG_OUT";
export const SIGN_UP = "SIGN_UP";
export const PRODUCT_LIST = "PRODUCT_LIST";
export const PRODUCT_DETAIL = "PRODUCT_DETAIL";
export const PLACE_BID = "PLACE_BID";
export const GET_BIDDING_AUCTION = "PLACE_BID";
export const GET_PRODUCT_BIDDING_GRAPH = "GET_PRODUCT_BIDDING_GRAPH ";
//
export const ESTIMATED_PRICE = "ESTIMATED_PRICE";
//
export const CREATE_WISH_LIST = "CREATE_WISH_LIST";
export const CLOSE_WISH_LIST = "CLOSE_WISH_LIST";
export const DELETE_WISH_LIST_ITEM = "DELETE_WISH_LIST_ITEM"
export const GET_WISH_LIST_AUCTION = "GET_WISH_LIST_AUCTION";
export const GET_WISH_LIST_BUYNOW = "GET_WISH_LIST_BUYNOW";
export const ACTIVE_BUYING_LIST = "ACTIVE_BUYING_LIST";



export const BRANDS = "BRANDS";
export const SELLER = "SELLER"
export const CAR_MODELS = "CAR_MODELS"
//
export const SELL_MY_CAR_STEPS = "SELL_MY_CAR_STEPS";
export const SHOW_INTEREST_STEPS = "SHOW_INTEREST_STEPS";
export const SHOW_INTEREST_STEPS_CURRENT = "SHOW_INTEREST_STEPS_CURRENT";
export const SHOW_INTEREST = "SHOW_INTEREST";
//
export const EVALUATION_CREATE = "EVALUATION_CREATE";
export const APPOINTMENT_CREATE = "APPOINTMENT_CREATE";
export const APPOINTMENT_UPDATE = "APPOINTMENT_UPDATE";
export const EVALUATE_IMAGE = "EVALUATE_IMAGE";
export const EVALUATE_DAMAGE_IMAGE = "EVALUATE_DAMAGE_IMAGE";
export const EVALUATE_VIDEO = "EVALUATE_VIDEO";
export const EVALUATE_AUDIO = "EVALUATE_AUDIO";

//
export const OFFICE_TIMING = "OFFICE_TIMING";
export const MY_INTERSTE = "MY_INTERSTE";
export const MY_APPOINMENT = "MY_APPOINMENT";
export const CAR_DETAILE = "CAR_DETAILE";
export const REMOVE_INTEREST = "REMOVE_INTEREST";


//

export const PRODUCT_LIST_BUY = "PRODUCT_LIST_BUY";
export const PRODUCT_DETAIL_BUY = "PRODUCT_DETAIL_BUY";

export const CREATE_SUBSCRIPTION = "CREATE_SUBSCRIPTION";
export const CREATE_CONTACT_US = "CREATE_CONTACT_US";
export const SALE_PRODUCT_LIST = "SALE_PRODUCT_LIST";
// ==================simple action typres===========

export const CITY_DETAIL_LIST = "CITY_DETAIL_LIST";
export const CHAKBOX_LIST = "CHAKBOX_LIST";


export const FORGET_PASSWORD = "FORGET_PASSWORD";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const APPROXY_BID_NEW = "APPROXY_BID_NEW";
export const PROFILE = "PROFILE";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const CAR_DETAIL_UPDATE = "CAR_DETAIL_UPDATE";



//shipg 


export const SHIPING_COST = "SHIPING_COST";
export const SHIPING_FORM = "SHIPING_FORM";
export const SHIPING_PRICE = "SHIPING_PRICE";
export const ADDRESS_FORM = "ADDRESS_FORM";
export const ADD_CAR_FEATURES = "ADD_CAR_FEATURES";

export const WINERS_CAR_LIST = "WINERS_CAR_LIST";
export const VEHICLE_LIST = "VEHICLE_LIST";
export const ADD_VEHICLE = "ADD_VEHICLE";
export const ADD_VEHICLE_AUCTION = "ADD_VEHICLE_AUCTION";
export const WINERS_CAR_USER_STATUS = "WINERS_CAR_USER_STATUS";

export const SHIPING_STATUS = "SHIPING_STATUS";


// =============
export const TIMER_DATA_UPDATE = "TIMER_DATA_UPDATE";

// ===============car report=============
export const CAR_REPORT = "CAR_REPORT";
export const CAR_REPORT_DETAIL = "CAR_REPORT_DETAIL";
export const GET_COUNTRY_COUNTY = "GET_COUNTRY_COUNTY"