import React from "react";
import { connect, useSelector } from "react-redux";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./Slider.css";
//
function Slider(props) {
  const { onSlideClick, activeId } = props;
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 3, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
    },
  };
  const myInterest = useSelector((state) => state.myInterest.data);
  
  if (myInterest.length === 0) return <></>;
  return (
    <div className="slider_main_cont">
      <div className="slider_carousel_wrapper">
        <Carousel
          autoPlay={false}
          autoPlaySpeed={6000}
          responsive={responsive}
          ssr={true} // means to render carousel on server-side.
          keyBoardControl={true}
          containerClass="carousel-container"
          removeArrowOnDeviceType={["mobile"]}
          deviceType={props.deviceType}
          itemClass="carousel-item-padding-40-px"
          //   centerMode={true}
        >
          {myInterest.map((item, index) => (
            <button
              onClick={() => onSlideClick(item.car_ref)}
              // className="slider_card"
              className={
                activeId === item.car ? "slider_card_active" : "slider_card"
              }
              key={index}
            >
              <div className="slider_card_title">{item.brand_name}</div>
              <div className="slider_card_description">
                {item.model_name} <b>-</b> {item.variant_name}
              </div>
            </button>
          ))}
        </Carousel>
      </div>
    </div>
  );
}

export default Slider;
