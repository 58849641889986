/* eslint-disable import/no-anonymous-default-export */
import React, { Fragment } from "react";
import {
  ShimmerThumbnail,
  ShimmerButton,
  ShimmerTitle,
 } from "react-shimmer-effects";
import "./ListCard.css";

export default (props) => {
  return (
    <Fragment>
      <div className="container-fluid product_list_card_main_cont imageHover ">
        <div className="col-5 p-0">
          <ShimmerThumbnail
            className="product_list_card_image_wrapper"
            height={160}
          />
        </div>
        <div className="col-7 align-self-start pt-2 product_list_card_col_2 ">
          {/* <ShimmerButton size="lg" /> */}
          <ShimmerTitle className="w-75" line={1} gap={10} variant="primary" />
          <div className="d-flex justify-content-start align-items-center">
            <ShimmerTitle
              className="w-25 "
              line={1}
              gap={10}
              variant="primary"
            />

            <ShimmerTitle
              className="w-25"
              line={1}
              gap={10}
              variant="primary"
            />
          </div>
            <ShimmerButton size="md" />
        </div>
      </div>
    </Fragment>
  );
};