import { sagaRoot } from "./root";
import {
  all,
  call,
  fork,
  put,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import {
  APPOINTMENT_CREATE,
  APPOINTMENT_UPDATE,
  BRANDS,
  EVALUATION_CREATE,
  OFFICE_TIMING,
  CAR_DETAIL_UPDATE,
  CAR_REPORT_DETAIL,
  CAR_REPORT,
  CAR_REG_NUMBER,
  CAR_MODELS
} from "../constant";
// get all cars brands
export function* getBrands(payload) {
  const response = yield call(sagaRoot, payload);
}
// get office timings
export function* getOfficetiming(payload) {
  const response = yield call(sagaRoot, payload);
}
//
export function* evaluationCreate(payload) {
  const response = yield call(sagaRoot, payload);
}
//
export function* appointmentCreate(payload) {
  const response = yield call(sagaRoot, payload);
  yield put({ type: `${payload.payload.type}_UPDATE`, payload: response });
}
export function* appointmentUpdate(payload) {
  const response = yield call(sagaRoot, payload);
}
export function* carDetailUpdateSaga(payload) {
  const response = yield call(sagaRoot, payload);
}
export function* CarReportSaga(payload) {
  const response = yield call(sagaRoot, payload);
}
export function* CarReportDetailSaga(payload) {
  const response = yield call(sagaRoot, payload);
}



export function* CarRegNumber(payload) {
  const response = yield call(sagaRoot, payload);
}

// saga watcher
export default function* authWatcher() {
  yield takeLatest(BRANDS, getBrands);
  yield takeLatest(OFFICE_TIMING, getOfficetiming);
  yield takeLatest(EVALUATION_CREATE, evaluationCreate);
  yield takeLatest(APPOINTMENT_CREATE, appointmentCreate);
  yield takeLatest(APPOINTMENT_UPDATE, appointmentUpdate);
  yield takeLatest(CAR_DETAIL_UPDATE, carDetailUpdateSaga);
  yield takeLatest(CAR_REPORT,  CarReportSaga);
  yield takeLatest(CAR_REPORT_DETAIL, CarReportDetailSaga);
  yield takeLatest(CAR_REG_NUMBER, CarRegNumber);
}
