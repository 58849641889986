import React, { Fragment } from "react";
import Dropdown from "../Dropdown/index";
import { Tab, Menu } from "semantic-ui-react";
import { Row, Col } from "react-bootstrap";
// import { GiAbstract002 } from "react-icons/gi";
// import { GiSteeringWheel } from "react-icons/gi";
// import { GiCarDoor } from "react-icons/gi";
// import { FiSpeaker } from "react-icons/fi";
// import { CgCamera } from "react-icons/cg";
// import { SiAircanada } from "react-icons/si";
// import { GiComputerFan } from "react-icons/gi";
// import { GiCarKey } from "react-icons/gi";
// import { RiRemoteControlFill } from "react-icons/ri";
// import { GiSwapBag } from "react-icons/gi";
// import { BsShieldLock } from "react-icons/bs";
// import { GoMirror } from "react-icons/go";
import { FcCheckmark, FcCancel } from "react-icons/fc";
import ImageGallery from "react-image-gallery";
import { connect } from "react-redux";
import "./tabbarstyle.css";
const TabExamplePointing = (props) => {
  const { product } = props;
  const { product_feature, product_accessory,images_inspection,product_description } = product;
  


  console.log("product_feature==============>>>>>>>>>", product_description);
  const get_images=(images)=>{
    var temp=[]
    React.Children.toArray(images.map((item)=>{
      temp.push(
        {
          original:
            item.image,
          thumbnail:
          item.image,
            originalAlt:item.name,
            thumbnailAlt:item.name,
            originalTitle:item.name,
            // thumbnailTitle:item.name,
            // thumbnailLabel:item.name,
            description:item.name
        },
      );
    }));
    return temp;
  }
  const images = [
    {
      original:
        "https://cimg0.ibsrv.net/ibimg/hgm/1600x900-1/100/439/2015-bmw-i8_100439332.jpg",
      thumbnail:
        "https://cimg0.ibsrv.net/ibimg/hgm/1600x900-1/100/439/2015-bmw-i8_100439332.jpg",
    },
    {
      original:
        "https://cdn.motor1.com/images/mgl/VM7B7/s3/2013-410333-bmw-i8-production-version-live-in-frankfurt-10-09-20131.jpg",
      thumbnail:
        "https://cdn.motor1.com/images/mgl/VM7B7/s3/2013-410333-bmw-i8-production-version-live-in-frankfurt-10-09-20131.jpg",
    },
    {
      original:
        "https://cdn.motor1.com/images/mgl/VM7B7/s3/2013-410333-bmw-i8-production-version-live-in-frankfurt-10-09-20131.jpg",
      thumbnail:
        "https://cdn.motor1.com/images/mgl/VM7B7/s3/2013-410333-bmw-i8-production-version-live-in-frankfurt-10-09-20131.jpg",
    },
  ];
  const panes = [
    {
      menuItem: (
        <Menu.Item key={"1"}>
          <h6 className="tabtext">Description</h6>
        </Menu.Item>
      ),
      // "General Information",
      render: () => (
        <Tab.Pane
          menu={{ pointing: true, className: "wrapped" }}
          attached={false}
        >
          <Col>
            <Col>
              {product_description ? (
                <Row className="viewtext">
                  <Col
                    className="textbox text-capitalize"
                    dangerouslySetInnerHTML={{ __html: product_description }}
                  ></Col>
                </Row>
              ) : (
                "No Data Found"
              )}
            </Col>
          </Col>
        </Tab.Pane>
      ),
    },
    {
      menuItem: (
        <Menu.Item key={"2"}>
          <h6 className="tabtext"> General Information</h6>
        </Menu.Item>
      ),
      // "General Information",
      render: () => (
        <Tab.Pane
          menu={{ pointing: true, className: "wrapped" }}
          attached={false}
        >
          <Col>
            <Col>
              {product_feature.length > 0 ? (
                React.Children.toArray(
                  product_feature.map((item, index) => (
                    <Row key={index} className="viewtext">
                      <Col className="textbox text-capitalize">
                        {item.name}:
                      </Col>
                      <Col className="textbox">{item.value}</Col>
                    </Row>
                  ))
                )
              ) : (
                <h4 className="text-center"> No Data Found</h4>
              )}
            </Col>
          </Col>
        </Tab.Pane>
      ),
    },
    {
      menuItem: (
        <Menu.Item key={"3"}>
          <h6 className="tabtext">Features</h6>
        </Menu.Item>
      ),
      render: () => (
        <Tab.Pane attached={false}>
          <div className="insidetab">
            {/* =============== car features =============== */}
            <div className="outertab">
              <Dropdown
                placeholderbackground={true}
                hideDivider={true}
                placeholder="Accessories"
              >
                <div className="listcontainer">
                  {product_accessory.length > 0 ? (
                    React.Children.toArray(
                      product_accessory.map((item, index) => (
                        <Fragment>
                          <Row key={index}>
                            <Col className="logo text-capitalize">
                              {item.value ? (
                                <FcCheckmark className="tabIcons mr-2" />
                              ) : (
                                <FcCancel className="tabIcons mr-2" />
                              )}
                              {item.name}
                            </Col>
                          </Row>
                          {product_accessory.length - 1 === index ? null : (
                            <hr />
                          )}
                        </Fragment>
                      ))
                    )
                  ) : (
                    <h4 className="text-center"> No Data Found</h4>
                  )}
                </div>
              </Dropdown>
            </div>
          </div>
        </Tab.Pane>
      ),
    },

    {
      menuItem: (
        <Menu.Item key={"5"}>
          <h6 className="tabtext"> Images </h6>
        </Menu.Item>
      ),
      render: () => (
        <Tab.Pane attached={false}>
          {/* ============= damge image ================= */}
          <div className="insidetab">
            <div className="outertab">
              <Dropdown
                placeholderbackground={true}
                hideDivider={true}
                placeholder="Exterior Images"
              >
                <div className="slider">
                  {images_inspection["exterior_images_"].length > 0 ? (
                    <ImageGallery
                      showBullets={false}
                      items={get_images(images_inspection["exterior_images_"])}
                    />
                  ) : (
                    <h4 className="text-center"> No Images Found</h4>
                  )}
                </div>
              </Dropdown>
            </div>

            {/* ============= damge image ================= */}
            <div className="outertab">
              <Dropdown
                placeholderbackground={true}
                hideDivider={true}
                placeholder="Interior Images"
              >
                <div className="slider">
                  {images_inspection["interior_images_"].length > 0 ? (
                    <ImageGallery
                      showBullets={false}
                      items={get_images(images_inspection["interior_images_"])}
                    />
                  ) : (
                    <h4 className="text-center"> No Images Found</h4>
                  )}
                </div>
              </Dropdown>
            </div>
            {/* ================= comments =================== */}
            <div className="outertab">
              <Dropdown
                placeholderbackground={true}
                hideDivider={true}
                placeholder="Wheels Images"
              >
                {" "}
                <div className="slider">
                  {images_inspection["wheels_images_"].length > 0 ? (
                    <ImageGallery
                      showBullets={false}
                      items={get_images(images_inspection["wheels_images_"])}
                    />
                  ) : (
                    <h4 className="text-center"> No Images Found</h4>
                  )}
                </div>
              </Dropdown>
            </div>

            <div className="outertab">
              <Dropdown
                placeholderbackground={true}
                hideDivider={true}
                placeholder="Treads Images"
              >
                {" "}
                <div className="slider">
                  {images_inspection["treads_images_"].length > 0 ? (
                    <ImageGallery
                      showBullets={false}
                      items={get_images(images_inspection["treads_images_"])}
                    />
                  ) : (
                    <h4 className="text-center"> No Images Found</h4>
                  )}
                </div>
              </Dropdown>
            </div>
          </div>
        </Tab.Pane>
      ),
    },
  ];

  return (
    <div style={{ width: "100%" }}>
      <Tab menu={{ pointing: true }} panes={panes} />
    </div>
  );
};
const mapStateToProps = (state) => ({
  product: state.product.current,
});
const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(TabExamplePointing);
