import React, { useState, useEffect } from "react";
import "./stlye.css";
import Labels from "../../services/apiLabels";
import { GET } from "../../constant/apiMethods";
import { WINERS_CAR_LIST } from "../../constant";
import { scrollTop } from "../../utils/window";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { useContext } from "react";
import Lottie from "lottie-react";
import loader from "../../assets/noCar.json";
import FeedbackContext from "../../context/cmscontext";
import { ShimmerThumbnail } from "react-shimmer-effects";
function Winers({ winerList, getCarWiners, user_id, winerListloading }) {
  const history = useHistory();  
  const { feedback, isLoading } = useContext(FeedbackContext);
var My_Bids_Page = feedback.My_Bids_Page;
  const arr = [
    {
      name: "gli",
    },
  ];
  const arr2 = [
    {
      name: "gli",
    },
  ];

  console.log("winwerr",winerList)

  useEffect(() => {
    scrollTop();
  }, []);

  const User_Id = JSON.parse(localStorage.getItem("WFAC_localstorage_auth"));
  // console.logUser_Id.id,"chk useect in winer")
  useEffect(() => {
    getCarWiners(User_Id.id);
  }, []);

  return (
    <>
      <div className="showInterest_headingCont ">
        <div className="showInterest_heading">
          {My_Bids_Page.Winer_List_heading}
        </div>
        <div className="showInterest_description">
          {My_Bids_Page.Winer_List_desp}
        </div>
      </div>

      {winerListloading ? (
        <>
          <div className="container-fluied ">
            <div className="card_wrap_contactus">
              <div className="row card_row p-5">
                {React.Children.toArray(
                  [...arr2, ...arr2, ...arr2, ...arr2].map((item) => {
                    return (
                      <div className="col-lg-3 col-md-3 col-sm-12  mt-4  mb-sm-5 mb-4 mb-sm-0 mb-lg-0  ">
                        <div className="card_contact p-3">
                          <div className="text-center pt-4">
                            <ShimmerThumbnail height={200} rounded />
                          </div>
                          <ShimmerThumbnail height={40} rounded />

                          <ShimmerThumbnail height={40} rounded />
                        </div>
                      </div>
                    );
                  })
                )}
              </div>
            </div>
          </div>
        </>
      ) : winerList.length == 0 ? (
        <>
          <div className="no-data">
            <Lottie
              animationData={loader}
              style={{ height: "200px" }}
              className="animi_"
              autoplay={true}
              loop={true}
            />
            <h3 className="text-center"> {My_Bids_Page.No_Data_Found}</h3>
          </div>
        </>
      ) : (
        <div className="container-fluied ">
          <div className="card_wrap_contactus">
            <div className="row card_row p-5">
              {React.Children.toArray(
                winerList.map((item) => {
                  return (
                    <div className="col-lg-3 col-md-3 col-sm-12  mt-4  mb-sm-5 mb-4 mb-sm-0 mb-lg-0 card_main_wrap   ">
                      <div className="card_contact p-3">
                        <div className="text-center pt-4">
                          <img
                            className="mobile_svg"
                            src={item.main_image}
                            alt="Car"
                          />
                        </div>
                        <h3 className="text-center  sell_car">
                          {item.product_name}{" "}
                        </h3>
                        <p className="text-center text_tow">
                          {item.product_description}{" "}
                          {My_Bids_Page.your_book_car}
                        </p>
                      </div>

                      <button
                        className=" btn_contact"
                        onClick={() => {
                          history.push({
                            pathname: "/shipingform",
                            state: { item },
                          });
                        }}
                      >
                        {My_Bids_Page.Book_Shiping_button}
                      </button>
                    </div>
                  );
                })
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  user_id: state.auth.id,
  winerList: state.shiping.winerList,
  winerListloading: state.shiping.winerListloading,
});

const mapDispatchToProps = (dispatch) => ({
  getCarWiners: (user_id) =>
    dispatch({
      type: WINERS_CAR_LIST,
      payload: {
        type: WINERS_CAR_LIST,

        label: `${Labels.car_winer_list}${user_id}/`,
        method: GET,

        hideOverlayLoader: true,
      },
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Winers);
