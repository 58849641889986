/* eslint-disable no-unused-vars */
import React, { Fragment, useState, useEffect } from "react";
// import { AiTwotoneEye, AiOutlineClockCircle } from "react-icons/ai";
// import { BsDot } from "react-icons/bs";
// import { GoLocation } from "react-icons/go";
import GridCardShimmer from "./GridCardShimmer";
import "./produ.css";
import { Container, Row, Col } from "react-bootstrap";
import GridCard from "./GridCard";
  
const Grid = (props) => {
  const { data, Card } = props;
  const dataArray = Array.isArray(data) ? data : [];
  return (
    <div className="grid_container">
      <div className="grid_card_wrapper">
        {React.Children.toArray(dataArray?.map((e,index) => (
          <div className="grid_card">
            <Card Products={e} product_index={index} />
          </div>
        )))}
      </div>
    </div>
  );
};

function Products(props) {
  const { data, loading } = props;
  if (loading) {
    return <Grid data={[{}, {}]} Card={GridCardShimmer} />;
  }
  const productsData = data?.data || [];

  return <Grid data={productsData} Card={GridCard} />;
}

export default Products;