import React, { useEffect, useState } from 'react';

const ProgressBar = ({ startAt, endAt }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const calculateProgress = () => {
      const startTime = new Date(startAt).getTime();
      const endTime = new Date(endAt).getTime();
      const currentTime = new Date().getTime();
        console.log(startTime,endTime,currentTime)
      if (currentTime < startTime) {
        setProgress(0);
      } else if (currentTime > endTime) {
        setProgress(100);
      } else {
        const totalDuration = endTime - startTime;
        const elapsedTime = currentTime - startTime;
        const progressPercentage = (elapsedTime / totalDuration) * 100;
        setProgress(progressPercentage);
      }
    };

    calculateProgress();
    const interval = setInterval(calculateProgress, 60000); // Update every minute

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [startAt, endAt]);

  return (
    <div className="progress mb-1" style={{ height: "10px" }}>
      <div
        className="progress-bar progress-bar-striped bg-success"
        role="progressbar"
        style={{ width: `${progress}%` }}
        aria-valuenow={progress}
        aria-valuemin="0"
        aria-valuemax="100"
      ></div>
    </div>
  );
};

export default ProgressBar;