import React, { useState, useEffect } from "react";
import "./style.css";
import { Breadcrumb } from "antd";
import { Link } from "react-router-dom";
import { HiChevronRight, HiCheck } from "react-icons/hi";
import LandingSection from "../../components/LandingSection";
import Steps from "./Steps";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { formateKey } from "../../utils/formating";
import OverLayLoader from "../../components/OverLayLoader";
import { SELL_MY_CAR_STEPS } from "../../constant";
import Greatings from "./Greatings";
import { sellMyCarStepsName } from "../../constant/constants";
//
function Evaluation(props) {
  const {
    step1,
    step2,
    currentStep,
    setnext_step,
    setprev_step,
    setcurrentStep,
  } = props;
  const dimensions = { height: "calc( 100vh / 1.7 )", width: "100%" };
  //
  const history = useHistory();
  // //checks if step one form is not completed
  useEffect(() => {
    if (!step1["variant"].isSelected || currentStep < 2) {
      history.push("/");
      setcurrentStep(4);
    }
  }, [step1]);
  //
  //
  const NumberIcon = (props) => {
    const { id, size, className } = props;
    return (
      <span style={{ display: "inline-block" }}>
        <span
          style={size ? { width: size, height: size } : {}}
          className={`numberIcon ${className} `}
        >
          {id}
        </span>
      </span>
    );
  };
  //
  const s1c2Items = [
    { text: " No hassle with the paperwork." },
    { text: " Get paid immediately." },
    { text: " Sell your car in an hour." },
  ];
  //
  const s2lcItems = [
    {
      text: "We Will carefully inspect your car and will give you a final offer.",
    },
    {
      text: "Decide wheather to accept the offer, there's no obligation to sell.",
    },
    { text: "If you accept, we take care of all the paperwork." },
    { text: "We'll transfewr your money safely and securely - instantly!" },
    { text: "Kaching!" },
  ];
  //
  const List = (props) => {
    const { title, value } = props;
    
    return (
      <div className="d-flex ">
        <span
          className="text-capitalize text-right elevation_s1_lc_cont_1_t_1"
          style={{ margin: "0.2rem 0.5rem", fontWeight: "bold" }}
        >
          {formateKey(title)} :
        </span>
        <span
          className="text-capitalize elevation_s1_lc_cont_1_t_1"
          style={{ margin: "0.2rem 0.5rem" }}
        >
          {value}
        </span>
      </div>
    );
  };
  //
  const LeftCont2 = ({ title }) => {
    // return s1c2Items.map((item, index) => (
    //   <span
    //     key={index}
    //     className="d-flex align-items-center mt-3 elevation_s1_lc_cont_1_t_1"
    //   >
    //     <HiCheck
    //       color="var(--secondary)"
    //       size={20}
    //       style={{ background: `var(--primary)` }}
    //       className="rounded-circle mr-2 p-1"
    //     />
    //     {item.text}
    //   </span>
    // ));
    return (
      <>
        <div className="overflow-hidden">
          {title && <div className="evaluation_s2_heading">{title}</div>}
          <List title="model" value={step1["model"].current.display} />
          <List title="fuel type" value={step1["fuel_type"].current.display} />
          <List title="year" value={step1["year"].current.display} />
          {currentStep > 2 ? (
            <div
              className={
                currentStep === 3
                  ? "animate__animated animate__fadeInLeftBig"
                  : ""
              }
            >
              <List title="mileage" value={step2["mileage"].current.title} />
              <List
                title="previous_owners"
                value={step2["previous_owners"].current.title}
              />
              <List
                title="services_history"
                value={step2["services_history"].current.title}
              />
            </div>
          ) : null}
        </div>
      </>
    );
  };
  //
  if (!step1["variant"].isSelected || currentStep < 2)
    return <OverLayLoader alwaysShow={true} />;

  return (
    <div style={{ margin: "1rem 0", height: "auto" }}>
      {/* ----------------------------- section 1 ---------------------- */}
      <div
        className="banner_main_cont evaluation_section_1"
        style={{ zIndex: 1 }}
      >
        {/* background gradient */}
        <div
          // style={{ ...dimensions }}
          className="banner_background_wrapper evaluation_background_gradient_wrapper "
        >
          <div className="position-relative">
            <div className="evaluation_background_gradient" />
          </div>
        </div>
        {/*  */}
        {currentStep <= sellMyCarStepsName.length - 2 ? (
          <div className="d-flex flex-lg-row flex-xl-row flex-column">
            <div className="d-flex justify-content-xl-end justify-content-lg-end justify-content-center  pt-5  landingmain_banner_left_cont">
              <div className="elevation_s1_lc">
                <div className="dropdown_wrapper">
                  <div
                    style={{
                      fontSize: "1.5rem",
                      fontWeight: "bold",
                      textTransform: "uppercase",
                      color: "var(--info)",
                    }}
                  >
                   
                    {sellMyCarStepsName[currentStep]}
                  </div>
                </div>
                <div className="background-info w-100 cardShadow p-3 mt-3 d-flex flex-column justify-content-center align-items-center rounded elevation_s1_lc_cont_1">
                  <div className="elevation_s1_lc_cont_1_t_1">
                    We want to buy your car
                  </div>
                  {/* <div className="elevation_s1_lc_cont_1_t_2 mt-2">
                  2014 Toyota Aqua
                </div> */}
                  <div className="elevation_s1_lc_cont_1_price">
                    <span>
                      {`${step1["brand"].current.brand_name} - ${step1["variant"].current.display}`}
                    </span>
                    {/*<span> Rs 21,93,975</span>*/}
                  </div>
                </div>
                <div className="background-info w-100 p-3 rounded elevation_s1_lc_cont_2">
                  <LeftCont2 />
                </div>
              </div>
            </div>
            <div className="d-flex flex-column align-items-center  p-2 pt-0 landingmain_banner_right_cont">
             
              <Steps
                currentStep={currentStep}
                setcurrentStep={setcurrentStep}
              />
              
            </div>
          </div>
        ) : (
          <Greatings />
        )}
      </div>
      {/* ----------------------------- section 2 ---------------------- */}
      {currentStep <= sellMyCarStepsName.length - 6 ? (
        <>
          <div className="banner_main_cont">
            {/*  */}
            <div className="d-flex flex-lg-row flex-xl-row flex-column">
              <div className="d-flex justify-content-xl-end justify-content-lg-end justify-content-center  landingmain_banner_left_cont">
                <div className=" evaluation_s2_lc_heading">
                  <div className="evaluation_s2_heading">
                    What happens at the inspection?
                  </div>
                  <ol className="evaluation_s2_orderlist">
                    {s2lcItems.map((item, index) => {
                      return <li>{item.text}</li>;
                    })}
                  </ol>
                </div>
              </div>
              <div
                className="d-flex flex-column align-items-center  p-2 pt-0 landingmain_banner_right_cont"
                style={{ zIndex: -1 }}
              >
                {/* responsive cont */}
                <div className="background-info w-100 p-3 elevation_s1_lc_cont_2_r mt-0 rounded ">
                  <LeftCont2 title="Car Details" />
                </div>
              </div>
            </div>
          </div>
          {/* ----------------------------- Footer ---------------------- */}
          <div className="evaluation_footer">
            <LandingSection
              title="Any Question"
              description="You find the answers to your questions in our FAQ section."
            >
              Bottom container
            </LandingSection>
            {/* <LandingSection backgroundColor="var(--primary)">
              <div className="d-flex justify-content-between align-items-center">
                <div
                  className="elevation_s1_lc_cont_1_t_1"
                  style={{ color: "var(--white)" }}
                >
                  2022 © All Right reserveed
                </div>
                <div
                  className="elevation_s1_lc_cont_1_t_1"
                  style={{ color: "var(--white)" }}
                >
                  icons will come here
                </div>
              </div>
            </LandingSection> */}
          </div>
        </>
      ) : null}
    </div>
  );
}
const mapStateToProps = (state) => ({
  step1: state.sellMyCar.step1,
  step2: state.sellMyCar.step2,
  currentStep: state.sellMyCar.currentStep,
});
const mapDispatchToProps = (dispatch) => ({
  setcurrentStep: (data) =>
    dispatch({
      type: SELL_MY_CAR_STEPS,
      payload: {
        type: "current_step",
        data,
      },
    }),
  setnext_step: (data) =>
    dispatch({
      type: SELL_MY_CAR_STEPS,
      payload: {
        type: "next_step",
        data,
      },
    }),
  setprev_step: (data) =>
    dispatch({
      type: SELL_MY_CAR_STEPS,
      payload: {
        type: "prev_step",
        data,
      },
    }),
});
export default connect(mapStateToProps, mapDispatchToProps)(Evaluation);
