import React, { useEffect, useState } from "react";
import axios from "axios";
import { SALE_PRODUCT_LIST, SHIPING_FORM, SHIPING_PRICE } from "../../constant";
import { POST } from "../../constant/apiMethods";
import { connect, useSelector } from "react-redux";
import Labels from "../../services/apiLabels";
import { useLocation } from "react-router-dom";
import "./style.css";
import { useHistory } from "react-router-dom";
import { FaSearch } from "react-icons/fa";
import config from "../../config";
import PuffLoader from "react-spinners/ClipLoader";
import { useContext } from "react";
import FeedbackContext from "../../context/cmscontext";
export const Shping = ({
  sumit_form,
  addresform,
  shipmentPrice,
  priceData,
}) => {
  const location = useLocation();
  const history = useHistory();
  const { feedback, isLoading } = useContext(FeedbackContext);
  var shiping_form_page = feedback.shiping_form_page;
  const key_settings = feedback.key_settings;
  let [loading, setLoading] = useState("false");
  const [divshow, setdivshow] = useState("false");
  const [showModal, setShowModal] = React.useState(false);
  const [inpvalue, setInpvalue] = useState("");
  const [postcode, setpostcode] = useState();
  const [desp, setdesp] = useState();
  const [citeslist, setciteslist] = useState([]);
  const [numOfFleets, setNumOfFleets] = useState("1");
  const [fleetType, setFleetType] = useState("");
  const [fleetsList, setFleetsList] = useState([]);
  document.body.style.background = "#F2F4F5";
  const car_category = location?.state?.car_category;
  console.log("Fleets ============ ", numOfFleets);
  console.log("car category-------->>>", car_category);
  // console.log"inp value ============",addresform)

  useEffect(() => {
    // console.log"inp value ===222222222=========",addresform)
    if (addresform) {
      history.push("/winer");
      // console.log"inp value ======4444444444444444======",addresform)
    }

    axios
      .get(`${config.baseUrl}api/v1/get_fleets/`)
      .then(function (response) {
        if (response.data.data.length > 0) {
          setFleetsList(response.data.data);
        }
      })
      .catch(function (error) {});
  }, [addresform]);

  const addres_set = (data) => {
    setInpvalue(data);
    setdivshow("false");
  };

  // console.log"citexs lisstttt",citeslist)

  const citylisthandler = (e) => {
    setInpvalue(e.target.value);
    if (e.target.value.length > 1) {
      // setInpvalue(e.target.value)
      get_all_cites_list(e.target.value);
      setLoading("true");
    }

    if (e.target.value == "") {
      setdivshow("false");
    }
  };

  const apikey = "85oOFVlBH0GWLXWE0FSGgA35569";

  function get_all_cites_list(a) {
    axios
      .get(
        `https://api-finder.eircode.ie/Latest/finderautocomplete?key=${key_settings?.aeroCode}&address=${a}&language=en&geographicAddress=true`
      )
      .then(function (response) {
        if (response.data.options.length > 0) {
          setciteslist(response.data.options);
        }
        setLoading("false");
        setdivshow("true");
      })
      .catch(function (error) {});
  }
  const User_Id = JSON.parse(localStorage.getItem("WFAC_localstorage_auth"));

  const submit_form_data = () => {
    sumit_form({
      postcode: postcode,
      inpvalue: inpvalue,
      desp: desp,
      no_of_cars: numOfFleets,
      shipment_type: fleetType,
      product: location.state.product_id,
      user: User_Id.id,
    });
  };

  return (
    <>
      <div className="containerfluid contact_wrap p-5">
        <h2 className="text-center">
          {shiping_form_page.Shiping_Form_heading}
        </h2>

        <div className="row d-flex justify-content-center ">
          <div className="col-lg-4 col-md-5 col-sm-12 ">
            <h3 className="inp_heading">
              {shiping_form_page.City_Post_Code_label}
            </h3>
            <div className="input_wrapper my-3">
              <input
                className="inp_contact"
                placeholder={shiping_form_page.City_Post_Code_placeholder}
                onChange={(e) => {
                  setpostcode(e.target.value);
                }}
              />
            </div>

            <h3 className="inp_heading">
              {shiping_form_page.Description_label}
            </h3>
            <div className="input_wrapper my-3 mt-2 inp_main abca">
              <input
                className="inp_contact"
                placeholder={shiping_form_page.Description_placeholder}
                onChange={(e) => {
                  setdesp(e.target.value);
                }}
              />
            </div>

            <h3 className="inp_heading">{shiping_form_page.Address_label}</h3>
            <div className="mt-2 inp_main abca" id="inp_main">
              <input
                type="search"
                className="inp_inp "
                placeholder={shiping_form_page.Address_placeholder}
                onChange={citylisthandler}
                value={inpvalue}
              />
              <div className="srech_">
                {loading == "true" ? <PuffLoader /> : <FaSearch size={17} />}
              </div>
            </div>

            {divshow == "true" ? (
              <div className="mt city_list">
                {
                  // citeslist.suggestions.length > 0 ?
                  React.Children.toArray(
                    citeslist.map((item) => {
                      return (
                        <>
                          <ul>
                            <li
                              className="lii"
                              onClick={() => {
                                addres_set(item.displayName);
                              }}
                            >
                              {" "}
                              {item.displayName}
                            </li>
                          </ul>
                        </>
                      );
                    })
                  )

                  // :
                  // <div></div>
                }
              </div>
            ) : (
              ""
            )}

            <h3 className="inp_heading">{shiping_form_page.selectFleet}</h3>
            <div>
              <input
                type="radio"
                id="singleFleet"
                name="fleetType"
                value="1"
                onChange={(e) => {
                  setFleetType(e.target.value);
                }}
              />
              <label htmlFor="singleFleet" className="ms-2 me-5">
                {shiping_form_page.singFleet}
              </label>

              <input
                type="radio"
                id="multipleFleets"
                name="fleetType"
                value="2"
                onChange={(e) => {
                  setFleetType(e.target.value);
                }}
              />
              <label htmlFor="multipleFleets" className="ms-2">
                {shiping_form_page.multipFleet}
              </label>

              {fleetType === "2" ? (
                <>
                  <div className="mt-3 dropdown my-3">
                    <div className="form-group">
                      <select
                        className="custom-select"
                        id="inputGroupSelect01"
                        required
                        defaultValue="0"
                        onChange={(e) => setNumOfFleets(e.target.value)}
                      >
                        <option value="0" disabled>
                          {shiping_form_page.numOfFleets}
                        </option>
                        {fleetsList.map((item, i) => {
                          return (
                            <option key={i} value={item.no_of_cars}>
                              {item.no_of_cars} Fleets
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
            {/* submit_form_data */}
            {showModal ? (
              <>
                <div className="justify-center mt-20 items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                  <form>
                    <div className="relative w-auto my-6 mx-auto max-w-3xl">
                      <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        {fleetType.length > 0 && car_category.length > 0 ? (
                          <>
                            <p className="m-0 py-4 px-8 font-bold">
                              {`The price for shipment is ${priceData.price}
                        If you agree with the price you can click Save button or you can try other option to check price variations
                        `}
                              <br />
                              <h4 className="text-red-800 m-0 py-2">
                                Note: The price may vary on the time of delivery
                              </h4>
                            </p>
                            <div className="flex items-center justify-end p-3 border-t border-solid border-slate-200 rounded-b">
                              <button
                                className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="button"
                                onClick={() => {
                                  setShowModal(false);
                                }}
                              >
                                Close
                              </button>
                              <button
                                className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="submit"
                                onClick={submit_form_data}
                              >
                                Save
                              </button>
                            </div>
                          </>
                        ) : (
                          <div className="py-6 px-4">
                            <p>Please Select the Fleet Type</p>
                            <button
                              className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                              type="button"
                              onClick={() => {
                                setShowModal(false);
                              }}
                            >
                              Close
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
                <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
              </>
            ) : null}
            <button
              className=" btn_contact my-3"
              onClick={() => {
                setShowModal(true);
                if(fleetType.length > 0 && car_category.length > 0 ){
                  shipmentPrice({
                  shipment_type: fleetType,
                  no_of_cars: numOfFleets,
                  car_category: car_category,
                });
                }
              }}
            >
              {shiping_form_page.Sumit_Form_button}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  cityes_List_Data: state.cardeailupdate,
  addresform: state.shiping.addresform,
  priceData: state.shiping.data,
});

const mapDispatchToProps = (dispatch) => ({
  // get product list
  sumit_form: (data) =>
    dispatch({
      type: SHIPING_FORM,
      payload: {
        type: SHIPING_FORM,
        method: POST,
        label: Labels.shiping_form,
        hideOverlayLoader: true,
        data,
      },
    }),

  shipmentPrice: (data) =>
    dispatch({
      type: SHIPING_PRICE,
      payload: {
        type: SHIPING_PRICE,
        method: POST,
        label: Labels.shipment_price,
        hideOverlayLoader: true,
        data,
      },
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Shping);
