import {
  MY_APPOINMENT,
  APPOINTMENT_UPDATE,
  APPOINTMENT_CREATE,
} from "../constant/index";

const INITIAL_STATE = {
  loading: false,
  data: [],
  UpdateData: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case `${MY_APPOINMENT}_REQUEST`:
      return { ...state, loading: true };
    case `${MY_APPOINMENT}_SUCCESS`:
      
      return { ...state, data:[ ...state.data, action.payload], loading: false };

    case `${APPOINTMENT_CREATE}_FAIL`:
      return { ...state, loading: false };
    case `${APPOINTMENT_CREATE}_ERROR`:
      return { ...state, loading: false };
    //
    case `${APPOINTMENT_CREATE}_REQUEST`:
      return { ...state };
    // case `${APPOINTMENT_CREATE}_SUCCESS`:
    //   // console.log"APPOINxxxxTMENT_CREATE",action.payload)
    //   return { ...state,
    //     UpdateData:action.payload,
    //   };
    case `${APPOINTMENT_CREATE}_UPDATE`:
      
      let updateIndex = action.payload.bodyData.data.updateIndex;
      let obj = state.data[updateIndex];
      let updateObj = {
        ...obj,
        appointment: [{ ...obj.appointment, ...action.payload.data }],
      };
      let stateData = [...state.data];
      stateData[updateIndex] = updateObj;
      return {
        ...state,
        data: stateData,
      };

    // case `${MY_APPOINMENT}_FAIL`:
    //   return { ...state, loading: false };
    // case `${MY_APPOINMENT}_ERROR`:
    //   return { ...state, loading: false };
    // //
    //   case `${APPOINTMENT_UPDATE}_REQUEST`:
    //   return { ...state, };
    // case `${APPOINTMENT_UPDATE}_SUCCESS`:
    //   // console.log"APPOINTMENT_UPDATE",action.payload)
    //   return { ...state,
    //     UpdateData:action.payload,
    //   };
  }

  return state;
};
