import React, { useEffect, useState } from "react";
// import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
//
import Magnifier from "../Magnifer/index";
function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "red" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "green" }}
      onClick={onClick}
    />
  );
}

export default (props) => {
  const images = [
    "http://ec2-3-19-159-215.us-east-2.compute.amazonaws.com:5000/media/products/2021/12/20/2.jpg",
    "http://ec2-3-19-159-215.us-east-2.compute.amazonaws.com:5000/media/products/2021/12/20/3.jpg",
    "http://ec2-3-19-159-215.us-east-2.compute.amazonaws.com:5000/media/products/2021/12/20/4.jpg",
    "http://ec2-3-19-159-215.us-east-2.compute.amazonaws.com:5000/media/products/2021/12/20/5.jpg",
    "http://ec2-3-19-159-215.us-east-2.compute.amazonaws.com:5000/media/products/2021/12/20/7.jpg",
    "http://ec2-3-19-159-215.us-east-2.compute.amazonaws.com:5000/media/products/2021/12/20/18_GBmKwX7.jpg",
  ];
  // const { images } = props;
  const [currentImage, setcurrentImage] = useState({
    imageUrl: "",
    id: 0,
  });
  useEffect(() => {
    if (images) {
      if (images.length > 0) {
        setcurrentImage({
          ...currentImage,
          imageUrl: images[0],
        });
      }
    }
  }, []);
  const settings = {
    className: "center slider variable-width",
    dots: true,
    centerMode: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    adaptiveHeight: true,
    // variableWidth: true,
    centerPadding: "60px",
    initialSlide: 0,
    speed: 500,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    afterChange: function (index) {
      
      currentImageHandler(index);
    },
  };

  const currentImageHandler = (id = 0) => {
    let imageUrl = images[id];
    // id=0;
    
    setcurrentImage({ imageUrl, id });
  };
  return (
    <div className="d-flex flex-column h-100 w-90">
      {/* <div style={{ backgroundColor: "red" }}>
      </div> */}
      <Magnifier
        data={{
          imageUrl: "",
          id: 0,
        }}
      />
      {/* <div
          style={{ height: "5%" }}
          className="d-flex justify-content-between align-items-center"
        >
          <button onClick={() => setState({ autoplay: true })}>
            {autoplay ? "pause" : "auto"} play
          </button>
          <span>full screen </span>
        </div> */}

      {/* <div style={{ flex: 1 }}>
        <Slider {...settings}>
          {images.map((item, index) => {
            return (
              <div
                key={index}
                style={{ width: 100, height: 100 }}
                onClick={() => currentImageHandler(index)}
                className="m-1"
              >
                <img width={90} height={90} src={item} />
              </div>
            );
          })}
        </Slider>
      </div> */}
    </div>
  );
};
