// import { Fragment, useState } from 'react'
// import "./style.css";
// import { Dialog, Menu, Transition } from '@headlessui/react'
// import {
//     BrowserRouter,
//     Switch,
//     Route,
//     Link,
//     useParams,
//     useRouteMatch,
//     useLocation 
//   } from "react-router-dom";
// import {
//   Bars3BottomLeftIcon,
//   BellIcon,
//   CalendarIcon,
//   ChartBarIcon,
//   FolderIcon,
//   HomeIcon,
//   InboxIcon,
//   UsersIcon,
//   XMarkIcon,
// } from '@heroicons/react/24/outline'
// import Tab from "../../components/tabs"
// import TabVehicals from "../../components/tabs2"
// import Favourit from "../../components/Favourites"
// import Profile from "../../components/Profile"
// import Shiping from '../../components/Shiping/index';
// import AddWatchlist from '../../components/Add Watchlist';
// import WatchlistActive from '../../components/WatchlistActive';
// import Arbitration from '../../components/Arbitration';
// import { MagnifyingGlassIcon } from '@heroicons/react/20/solid'


// const navigation = [
//   { name: 'My Bids', href: '/dashbord', icon: HomeIcon, current: false },
//   { name: 'My Vehicles', href: '/dashbord/myvehicles', icon: UsersIcon, current: false },
//   { name: 'Profile', href: '/dashbord/profile', icon: ChartBarIcon, current: false },
//   { name: 'Favourites', href: '/dashbord/favourites', icon: FolderIcon, current: false },
//   { name: 'Payments & Shipping', href: '/dashbord/shipping', icon: CalendarIcon, current: false },
//   { name: 'Arbitration', href: '/dashbord/arbitration', icon: InboxIcon, current: false },
//   { name: 'Add Watchlist', href: '/dashbord/watchlist', icon: ChartBarIcon, current: false },
//   { name: 'Watchlist Active', href: '/dashbord/watchlistActive', icon: ChartBarIcon, current: false },
// ]
// const userNavigation = [
//   { name: 'Your Profile', href: '#' },
//   { name: 'Settings', href: '#' },
//   { name: 'Sign out', href: '#' },
// ]

// function classNames(...classes) {
//   return classes.filter(Boolean).join(' ')
// }

// export default function Layout() {
//   const [sidebarOpen, setSidebarOpen] = useState(false)
//   const { path, url } = useRouteMatch();    
//   const location = useLocation()

//   return (
//     <>
//       {/*
//         This example requires updating your template:

//         ```
//         <html class="h-full bg-gray-100">
//         <body class="h-full">
//         ```
//       */}
//       <div className=' mx-10 my-10' style={{height:'85vh'}}>
//         <Transition.Root show={sidebarOpen} as={Fragment}>
//           <Dialog as="div" className="relative z-40 md:hidden" onClose={setSidebarOpen}>
//             <Transition.Child
//               as={Fragment}
//               enter="transition-opacity ease-linear duration-300"
//               enterFrom="opacity-0"
//               enterTo="opacity-100"
//               leave="transition-opacity ease-linear duration-300"
//               leaveFrom="opacity-100"
//               leaveTo="opacity-0"
//             >
//               <div className="fixed inset-0 bg-white bg-opacity-75" />
//             </Transition.Child>

//             <div className="fixed inset-0 z-40 flex">
//               <Transition.Child
//                 as={Fragment}
//                 enter="transition ease-in-out duration-300 transform"
//                 enterFrom="-translate-x-full"
//                 enterTo="translate-x-0"
//                 leave="transition ease-in-out duration-300 transform"
//                 leaveFrom="translate-x-0"
//                 leaveTo="-translate-x-full"
//               >
//                 <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-white pt-5 pb-4">
//                   <Transition.Child
//                     as={Fragment}
//                     enter="ease-in-out duration-300"
//                     enterFrom="opacity-0"
//                     enterTo="opacity-100"
//                     leave="ease-in-out duration-300"
//                     leaveFrom="opacity-100"
//                     leaveTo="opacity-0"
//                   >
//                     <div className="absolute top-0 right-0 -mr-12 pt-2">
//                       <button
//                         type="button"
//                         className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
//                         onClick={() => setSidebarOpen(false)}
//                       >
//                         <span className="sr-only">Close sidebar</span>
//                         <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
//                       </button>
//                     </div>
//                   </Transition.Child>
//                   <div className="flex flex-shrink-0 items-center px-4 ">
//                     <img
//                       className="h-8 w-auto"
//                       src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=500"
//                       alt="Your Company"
//                     />
//                   </div>
//                   <div className="mt-5 h-0 flex-1 overflow-y-auto">
//                     <nav className="space-y-1 px-2">
                   
//                       {navigation.map((item) => (
                        
//                         <a
//                           key={item.name}
//                           href={item.href}
                        
//                           className={classNames(
//                             location.pathname == item.href
//                               ? 'bg-white text-white'
//                               : 'text-gray-300 hover:bg-gray-700 hover:text-white',
//                             'group flex items-center px-2 py-2 text-base font-medium rounded-md'
//                           )}
//                         >
                        
//                           <item.icon
//                             className={classNames(
//                               location.pathname == item.href ? 'text-gray-300' : 'text-gray-400 group-hover:text-gray-300',
//                               'mr-4 flex-shrink-0 h-6 w-6'
//                             )}
//                             aria-hidden="true"
//                           />
//                           {item.name}
//                         </a>
//                       ))}
//                     </nav>
//                   </div>
//                 </Dialog.Panel>
//               </Transition.Child>
//               <div className="w-14 flex-shrink-0" aria-hidden="true">
//                 {/* Dummy element to force sidebar to shrink to fit close icon */}
//               </div>
//             </div>
//           </Dialog>
//         </Transition.Root>

//         {/* Static sidebar for desktop */}
//         <div className=" md:fixed md:inset-y-0 md:flex md:w-64 md:flex-col mt-10">
//           {/* Sidebar component, swap this element with another sidebar if you like */}
//           <div className="flex min-h-0 flex-col bg-white mt-32 mb-12" style={{height:'100%'}}>
//             <div className="flex h-16 flex-shrink-0 items-center bg-white
//              px-4">
//                 <div className=' m-auto '>
//               <img className='h-24 w-24 mt-2'   src="https://trade-bid.ie/TradeBid-HTML/assets/images/no_user.png" alt=""></img>

//                 </div>
//             </div>
//             <div className="flex flex-1 flex-col mt-11 overflow-y-auto">
//               <nav className="flex-1 space-y-1 px-2 py-4">
//                 {navigation.map((item) => (
//                   <Link
//                     key={item.name}
//                     to={item.href}
//                     className={classNames(
//                       location.pathname == item.href ? "selected-tab" : "unselected-tab",
//                       'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
//                     )}
//                   >
//                     <item.icon
//                       className={classNames(
//                         location.pathname == item.href ? 'selected-tab-icon' : '',
//                         'mr-3 flex-shrink-0 h-6 w-6'
//                       )}
//                       aria-hidden="true"
//                     />
//                     {item.name}
//                   </Link>
//                 ))}
//               </nav>
//             </div>
//           </div>
//         </div>
//         <div className="flex flex-col md:pl-64" style={{height:'100%'}}>
      

// <Switch>      
//         <BrowserRouter exact path='/dashbord'>
// <>
// <main className="flex-1 px-5 mt-5 mb-5">
//             <div className="py-6 px-5 bg-white bg-opacity-75" style={{height:'100%'}}>
//               <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
//               <h1 className="text-2xl font-semibold text-gray-900">My Bids</h1>
//                        </div>
//               <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
//                 <div className="py-4">
//                   <div className="h-96 ">
//                   <Tab/>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </main>
// </>
//         </BrowserRouter>
//         <BrowserRouter exact path='/dashbord/myvehicles'>
//         <>
//             <main className="flex-1 px-5 mt-5 mb-5">
//             <div className="py-6 bg-white bg-opacity-75" style={{height:'100%'}}>
//               <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
//               <h1 className="text-2xl font-semibold text-gray-900">My Vehicles</h1>
//                        </div>
//               <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
//                 <div className="py-4">
//                   <div className="">
//                   <TabVehicals/>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </main>
// </>
//         </BrowserRouter>
//         <BrowserRouter exact path='/dashbord/favourites'>
//         <>
//         <main className="flex-1 px-5 mt-5 mb-5">
//         <Favourit/>
//         </main>
//           </>
//         </BrowserRouter>
//         <BrowserRouter exact path='/dashbord/profile'>
//         <>
//         <main className="flex-1 px-5 mt-5 mb-5">
//         <Profile/>
//         </main>
//           </>
//         </BrowserRouter>
//        <BrowserRouter exact path='/dashbord/shipping'>
//         <>
//         <main className="flex-1 px-5 mt-5 mb-5">
//         <Shiping />
//         </main>
//           </>
//         </BrowserRouter>
//         <BrowserRouter exact path='/dashbord/watchlist'>
//         <>
//         <main className="flex-1 px-5 mt-5 mb-5">
//         <AddWatchlist/>
//         </main>
//           </>
//         </BrowserRouter>
//         <BrowserRouter exact path='/dashbord/watchlistActive'>
//         <>
//         <main className="flex-1 px-5 mt-5 mb-5">
//         <WatchlistActive/>
//         </main>
//           </>
//         </BrowserRouter>
//         <BrowserRouter exact path='/dashbord/arbitration'>
//         <>
//         <main className="flex-1 px-5 mt-5 mb-5">
//         <Arbitration/>
//         </main>
//           </>
//         </BrowserRouter> 
     
//     </Switch>

          
//         </div>
//       </div>
//     </>
//   )
// }


import { Fragment, useState } from "react";
import "./style.css";
import { Dialog, Menu, Transition } from "@headlessui/react";
import {
  BrowserRouter,
  Switch,
  Route,
  Link,
  useParams,
  useRouteMatch,
  useLocation,
} from "react-router-dom";
import {
  Bars3BottomLeftIcon,
  BellIcon,
  CalendarIcon,
  ChartBarIcon,
  FolderIcon,
  HomeIcon,
  InboxIcon,
  UsersIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import Tab from "../../components/tabs";
import TabVehicals from "../../components/tabs2";
import Favourit from "../../components/Favourites";
import Profile from "../../components/Profile";
import Shiping from "../../components/Shiping/index";
import AddWatchlist from "../../components/Add Watchlist";
import WatchlistActive from "../../components/WatchlistActive";
import Arbitration from "../../components/Arbitration";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";

const navigation = [
  { name: "My Bids", href: "/dashbord", icon: HomeIcon, current: false },
  {
    name: "My Vehicles",
    href: "/dashbord/myvehicles",
    icon: UsersIcon,
    current: false,
  },
  {
    name: "Profile",
    href: "/dashbord/profile",
    icon: ChartBarIcon,
    current: false,
  },
  {
    name: "Favourites",
    href: "/dashbord/favourites",
    icon: FolderIcon,
    current: false,
  },
  {
    name: "Payments & Shipping",
    href: "/dashbord/shipping",
    icon: CalendarIcon,
    current: false,
  },
  {
    name: "Arbitration",
    href: "/dashbord/arbitration",
    icon: InboxIcon,
    current: false,
  },
  {
    name: "Add Watchlist",
    href: "/dashbord/watchlist",
    icon: ChartBarIcon,
    current: false,
  },
  {
    name: "Watchlist Active",
    href: "/dashbord/watchlistActive",
    icon: ChartBarIcon,
    current: false,
  },
];
const userNavigation = [
  { name: "Your Profile", href: "#" },
  { name: "Settings", href: "#" },
  { name: "Sign out", href: "#" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Layout() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { path, url } = useRouteMatch();
  const location = useLocation();

  return (
    <>
      <style jsx="true">{`
        .background-dashboard {
          overflow-y: hidden;
        }
        .sidebar-class {
          width: 15%;
          height: 75vh;
          background-color: white;
        }

        .bids-section {
          width: 95%;
          height: 75vh;
        }

        @media only screen and (max-width: 860px) {
          .sidebar-menu-text {
            display: none;
          }
        }
      `}</style>
      <div className="container-fluid background-dashboard">
        <div className="d-flex flex-row row-12 ms-10 my-10 pt-10">
          <Transition.Root show={sidebarOpen} as={Fragment}>
            <Dialog
              as="div"
              className="relative z-40 md:hidden"
              onClose={setSidebarOpen}
            >
              <Transition.Child
                as={Fragment}
                enter="transition-opacity ease-linear duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity ease-linear duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-white bg-opacity-75" />
              </Transition.Child>

              <div className="fixed inset-0 z-40 flex">
                <Transition.Child
                  as={Fragment}
                  enter="transition ease-in-out duration-300 transform"
                  enterFrom="-translate-x-full"
                  enterTo="translate-x-0"
                  leave="transition ease-in-out duration-300 transform"
                  leaveFrom="translate-x-0"
                  leaveTo="-translate-x-full"
                >
                  <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-white pt-5 pb-4">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-in-out duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in-out duration-300"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="absolute top-0 right-0 -mr-12 pt-2">
                        <button
                          type="button"
                          className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                          onClick={() => setSidebarOpen(false)}
                        >
                          <span className="sr-only">Close sidebar</span>
                          <XMarkIcon
                            className="h-6 w-6 text-white"
                            aria-hidden="true"
                          />
                        </button>
                      </div>
                    </Transition.Child>
                    <div className="flex flex-shrink-0 items-center px-4 ">
                      <img
                        className="h-8 w-auto"
                        src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=500"
                        alt="Your Company"
                      />
                    </div>
                    <div className="mt-5 h-0 flex-1 overflow-y-auto">
                      <nav className="space-y-1 px-2">
                        {navigation.map((item) => (
                          <a
                            key={item.name}
                            href={item.href}
                            className={classNames(
                              location.pathname === item.href
                                ? "bg-white text-white"
                                : "text-gray-300 hover:bg-gray-700 hover:text-white",
                              "group flex items-center px-2 py-2 text-base font-medium rounded-md"
                            )}
                          >
                            <item.icon
                              className={classNames(
                                location.pathname === item.href
                                  ? "text-gray-300"
                                  : "text-gray-400 group-hover:text-gray-300",
                                "mr-4 flex-shrink-0 h-6 w-6"
                              )}
                              aria-hidden="true"
                            />
                            {item.name}
                          </a>
                        ))}
                      </nav>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
                <div className="w-14 flex-shrink-0" aria-hidden="true">
                  {/* Dummy element to force sidebar to shrink to fit close icon */}
                </div>
              </div>
            </Dialog>
          </Transition.Root>

          {/* Static sidebar for desktop */}
          <div className="d-flex flex-column col-md-12 col-lg-2 col-xl-2 sidebar-class bg-opacity-75">
            {/* Sidebar component, swap this element with another sidebar if you like */}
            <div className="flex flex-col bg-white bg-opacity-75">
              <div className="d-flex justify-content-center align-items-center my-4">
                <div className="d-flex justify-content-center align-items-center">
                  <img
                    className="w-50"
                    src="https://trade-bid.ie/TradeBid-HTML/assets/images/no_user.png"
                    alt=""
                  ></img>
                </div>
              </div>
              <div className="d-flex flex-column">
                <nav className="flex-1 space-y-1 px-0">
                  {navigation.map((item) => (
                    <Link
                      title={item.name}
                      key={item.name}
                      to={item.href}
                      className={classNames(
                        location.pathname === item.href
                          ? "selected-tab"
                          : "unselected-tab",
                        "group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                      )}
                    >
                      <item.icon
                        className={classNames(
                          location.pathname === item.href
                            ? "selected-tab-icon"
                            : "",
                          "mr-3 flex-shrink-0 h-6 w-6"
                        )}
                        aria-hidden="true"
                      />
                      <span className="sidebar-menu-text">{item.name}</span>
                    </Link>
                  ))}
                </nav>
              </div>
            </div>
          </div>

          <div className="d-flex flex-column col-md-12 col-lg-10 col-xl-10 bids-section overflow-y-hidden overflow-x-auto">
            <Switch>
              <BrowserRouter exact path="/dashbord">
                <>
                  <div className="py-4 px-5 h-100 bg-white bg-opacity-75">
                    <div className="pt-4">
                      <Tab />
                    </div>
                  </div>
                </>
              </BrowserRouter>
              <BrowserRouter exact path="/dashbord/myvehicles">
                <>
                  <div className="py-4 px-5 h-100 bg-white bg-opacity-75">
                    <div className="pt-4">
                      <TabVehicals />
                    </div>
                  </div>
                </>
              </BrowserRouter>
              <BrowserRouter exact path="/dashbord/profile">
                <>
                  <div className="py-4 px-5 h-100 bg-white bg-opacity-75">
                    <div className="pt-2">
                      <Profile />
                    </div>
                  </div>
                </>
              </BrowserRouter>
              <BrowserRouter exact path="/dashbord/favourites">
                <>
                  <div className="py-4 px-5 h-100 bg-white bg-opacity-75">
                    <div className="pt-4">
                      <Favourit />
                    </div>
                  </div>
                </>
              </BrowserRouter>
              <BrowserRouter exact path="/dashbord/shipping">
                <>
                  <div className="py-4 px-5 h-100 bg-white bg-opacity-75">
                    <div className="pt-4">
                      <Shiping />
                    </div>
                  </div>
                </>
              </BrowserRouter>
              <BrowserRouter exact path="/dashbord/arbitration">
                <>
                  <div className="py-4 px-5 h-100 bg-white bg-opacity-75">
                    <div className="pt-4">
                      <Arbitration />
                    </div>
                  </div>
                </>
              </BrowserRouter>
              <BrowserRouter exact path="/dashbord/watchlist">
                <>
                  <div className="py-4 px-5 h-100 bg-white bg-opacity-75">
                    <div className="pt-4">
                      <AddWatchlist />
                    </div>
                  </div>
                </>
              </BrowserRouter>
              <BrowserRouter exact path="/dashbord/watchlistActive">
                <>
                  <div className="py-4 px-5 h-100 bg-white bg-opacity-75">
                    <div className="pt-4">
                      <WatchlistActive />
                    </div>
                  </div>
                </>
              </BrowserRouter>
            </Switch>
          </div>
        </div>
      </div>
    </>
  );
}