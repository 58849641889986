import React from "react";
import "./style.css";
import pic2 from "../../assets/svg/car_no_image.jpg";
function Carcard({ item ,onClick,mycar}) {
  return (
    <>
      <div className="row mdiv1 m-3 " onClick={onClick}>
        <div className="col-sm-12 col-lg-5 col-md-5 cola p-4">
          {mycar[0].interior_images_ < 1 ? (
            <img className="img1" src={pic2} alt="description of image" />
          ) : (
            <img
              alt="description of image"
              className="imga1"
              src={mycar[0].interior_images_[0].image}
              data-toggle="modal"
              data-target="#exampleModalLong"
            />
          )}
        </div>

        <div className=" col-sm-12 col-lg-7 col-md-7 p-5 colb">
          <span className="product_list_card_title brndname">
            {item.brand_name}
          </span>
          <div className="mt-2">
            <span className="modelvarint ">
              {item.Mileage}-{item.variant_name}
            </span>
            <span className="product_list_card_features_text txt mt-4">
              Postcode: {item.Postcode}
            </span>
            <span className="product_list_card_features_text txt">
              Previous_owners: {item.Previous_owners}
            </span>
            <span className="product_list_card_features_text txt">
              Service_history: {item.Service_history}
            </span>
            <span className="product_list_card_features_text txt">
              fuel_type: {item.fuel_type}
            </span>

            <span className="product_list_card_features_text txt">
              model_name: {item.model_name}
            </span>

            <span className="product_list_card_features_text txt">
              reg_number: {item.reg_number}
            </span>

            <span className="product_list_card_features_text txt">
              year_name: {item.year_name}
            </span>

            <span className="product_list_card_features_text txt">
              year_name:{item.year_name}
            </span>
          </div>
        </div>
      </div>
    </>
  );
}

export default Carcard;
