import { React, useEffect, useState } from "react";
import axios from "axios";
// import { SALE_PRODUCT_LIST, SHIPING_FORM } from "../../../consant";
import { POST } from "../../../constant/apiMethods";
import { connect } from "react-redux";
import Labels from "../../../services/apiLabels";
import "./style.css";
import CarDetailForm from "../../../components/CarDetailForm/index.js";
import { HiCheck } from "react-icons/hi";
import { Link, useHistory } from "react-router-dom";
import { CAR_REG_NUMBER, SELL_MY_CAR_STEPS } from "../../../constant";
import { useSelector } from "react-redux";
import { scrollTop } from "../../../utils/window";
import { AiFillCaretRight } from "react-icons/ai";
import { Spinner } from 'react-bootstrap';
import { useContext } from "react";
import FeedbackContext from "../../../context/cmscontext";

import { Loader } from 'semantic-ui-react'
function Banner(props) {
  const {
    style,
    car_reg_num,
    sellMyCar,
    setbrand,
    setmodel,
    setfuelType,
    car_reg_loading,
    reducer,
    setyear,
    sellMyCar_new,
    setvariant,
  } = props;
  //

  const brand = props.sellMyCar.step1.brand.current;
  const model = props.sellMyCar.step1.model;
  const fuel_type = props.sellMyCar.step1.fuel_type;
  const year = props.sellMyCar.step1.year;
  const variant = props.sellMyCar.step1.variant;

       const { feedback, isLoading } = useContext(FeedbackContext);
var home_page = feedback.home_page;

  const history = useHistory();
  const step1 = useSelector((state) => state.sellMyCar.step1);
  //

  const defaultState = {
    fetching: false,
    data: [],
    isSelected: false,
    current: {},
  };
  const [show, setshow] = useState(false)

  const [card_show, setcard_show] = useState(false)


  useEffect(() => {
    setshow(false)




    if (sellMyCar_new.brands) {
      

      setbrand({
        ...brand,
        isSelected: true,
        current: sellMyCar_new.brands ,
      });

      setmodel({
        ...model,
        isSelected: true,
        current:sellMyCar_new.model_name ,
      });

      setfuelType({
        ...fuel_type,
        isSelected: true,
        current:sellMyCar_new.fuel_type ,
      });

      setyear({ ...year, isSelected: true, current: sellMyCar_new.year });

      setvariant({
        ...variant,
        isSelected: true,
        current: sellMyCar_new.variant,
      });

      setshow(false)
      // setTimeout(() => {
        // history.push{("evaluation"),  state: { detail: response.data }};
        history.push({
          pathname:"evaluation",
      
          state: { Car_Reg_N: regunm }
      });
        // console.log'This will run after 1 second!')
      // }, 5000);
    


    }


if(sellMyCar_new.status==="error"
){
  // console.log"status===status===",sellMyCar_new.statu)
  setcard_show(true)





  setbrand({
    ...brand,
    isSelected: true,
    current: {},
  });

  setmodel({
    ...model,
    isSelected: true,
    current:{} ,
  });

  setfuelType({
    ...fuel_type,
    isSelected: true,
    current:{} ,
  });

  setyear({ ...year, isSelected: true, current: {} });

  setvariant({
    ...variant,
    isSelected: true,
    current: {},
  });










}


  }, [sellMyCar_new]);

  const Car_handler = () => {};

  const [regunm, setregunm] = useState();







  const car_value = (event) => {

    event.preventDefault();

    // if(regunm){
      setshow(true)
      car_reg_num({
        Reg_Num: regunm,
      });
    // }
// else{
//   alert("please Enter Car Number")
// }
    
  };

  const dimensions = {
    width: style.width || "100%",
    backgroundImage: `url("${home_page.banner_background_image}")`,
    // height: style.height || "800px",
  };
  //
  
  return (
    <div
      style={{ width: dimensions.width, minHeight: dimensions.height }}
      className="banner_main_cont"
    >
      {/*  */}
      <div
        style={dimensions}
        className={
          card_show
            ? "banner_background_wrapper "
            : "banner_background_wrapper2 "
        }
      >
        <div className="position-relative">
          <div
          
            style={dimensions}
            className={
              card_show ? "banner_background_image" : "banner_background_image2"
            }
          />
          <div
            style={dimensions}
            className={
              card_show
                ? "banner_background_gradient"
                : "banner_background_gradient2"
            }
          />
        </div>
      </div>
      {/*  */}
      <div
        className={
          card_show
            ? "d-flex bothcard_wra  flex-lg-row flex-xl-row flex-column"
            : "d-flex bothcard_wrap  flex-lg-row flex-xl-row flex-column"
        }
      >
        <div
          className={
            card_show
              ? "bothcard_wrap2  d-flex flex-column align-items-xl-end align-items-lg-end align-items-center  pt-4  landingmain_banner_left_cont"
              : "bothcard_wrap  d-flex flex-column align-items-xl-end align-items-lg-end align-items-center  pt-5  landingmain_banner_left_cont"
          }
        ></div>
      </div>
      <div className="d-flex flex-lg-row flex-xl-row flex-column">
        <div className="d-flex flex-column align-items-xl-end align-items-lg-end align-items-center  pt-5  landingmain_banner_left_cont">
          <div className="viewtitle ">
            <span className="banner_heading">{home_page.baner_heading}</span>
          </div>
        </div>
        <div className="d-flex justify-content-center  p-2 pt-0 landingmain_banner_right_cont">
          <div className="banner_description mt-4">
            {/* 1 */}
            <span className="d-flex align-items-center mt-3">
              <HiCheck
                color="var(--primary"
                size={20}
                style={{ background: `var(--yellow)` }}
                className="rounded-circle mr-2 p-1"
              />
              {home_page.baner_sub_heading1}
            </span>
            {/* 2 */}
            <span className="d-flex align-items-center mt-3">
              <HiCheck
                color="var(--primary"
                size={20}
                style={{ background: `var(--yellow)` }}
                className="rounded-circle mr-2 p-1"
              />
              {home_page.baner_sub_heading2}
            </span>
            {/* 3 */}
            <span className="d-flex align-items-center mt-3">
              <HiCheck
                color="var(--primary"
                size={20}
                style={{ background: `var(--yellow)` }}
                className="rounded-circle mr-2 p-1"
              />
              {home_page.baner_sub_heading3}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  sellMyCar_new: state.sellMyCar.car_reg_,
  car_reg_loading: state.sellMyCar.car_reg_loading,

  fetchingBrands: state.sellMyCar.fetchingBrands,
  brands: state.sellMyCar.brands,
  //
  showInterest: state.showInterest,
  sellMyCar: state.sellMyCar,
});
const mapDispatchToProps = (dispatch) => ({
  // get product list
  car_reg_num: (data) =>
    dispatch({
      type: CAR_REG_NUMBER,
      payload: {
        type: CAR_REG_NUMBER,
        method: POST,
        label: Labels.car_reg_chk,
        hideOverlayLoader: true,
        data,
      },
    }),

  setbrand: (data) =>
    dispatch({
      type: SELL_MY_CAR_STEPS,
      payload: {
        type: "brand",
        data,
      },
    }),
  setmodel: (data) =>
    dispatch({
      type: SELL_MY_CAR_STEPS,
      payload: {
        type: "model",
        data,
      },
    }),
  setfuelType: (data) =>
    dispatch({
      type: SELL_MY_CAR_STEPS,
      payload: {
        type: "fuel_type",
        data,
      },
    }),
  setyear: (data) =>
    dispatch({
      type: SELL_MY_CAR_STEPS,
      payload: {
        type: "year",
        data,
      },
    }),
  setvariant: (data) =>
    dispatch({
      type: SELL_MY_CAR_STEPS,
      payload: {
        type: "variant",
        data,
      },
    }),

  //
});

export default connect(mapStateToProps, mapDispatchToProps)(Banner);
