/* eslint-disable no-unused-vars */
/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable default-case */
// importing types from constants definded in constant/ index file
import {
  PRODUCT_LIST,
  PRODUCT_DETAIL,
  PLACE_BID,
  GET_PRODUCT_BIDDING_GRAPH,
  PRODUCT_LIST_BUY,
  PRODUCT_DETAIL_BUY,
  APPROXY_BID_NEW,
  TIMER_DATA_UPDATE,
  CREATE_WISH_LIST,
  GET_WISH_LIST_AUCTION,
  GET_WISH_LIST_BUYNOW,
  CLOSE_WISH_LIST,
  ACTIVE_BUYING_LIST,
  DELETE_WISH_LIST_ITEM
} from "../constant/index";

const INITIAL_STATE = {
  fetchingList: true,
  fetchingCurrent: true,
  loadingPlaceBid: false,
  loadingGetWishList: false,
  loadingActive_buyingList: true,
  // fetchBiddingGraph: false,
  data: [],
  databuy: [],
  current: {},
  currentbuy: {},
  newtimer:{},
  wishList:[],
  active_buying:[],
  wishupdate: 0

  // currentProductGraph: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // product list
    case `${PRODUCT_LIST}_REQUEST`:
      return {
        ...state,
        fetchingList: true,
      };
    case `${PRODUCT_LIST}_SUCCESS`:
      return {
        ...state,
        fetchingList: false,
        data: action.payload,
      };
    case `${PRODUCT_LIST}_FAIL`:
      return {
        ...state,
        fetchingList: false,
      };
    case `${PRODUCT_LIST}_ERROR`:
      return {
        ...state,
        newtimer: false,
      };



 // product list  buy
 case `${PRODUCT_LIST_BUY}_REQUEST`:
  return {
    ...state,
    fetchingList: true,
  };
case `${PRODUCT_LIST_BUY}_SUCCESS`:
  return {
    ...state,
    fetchingList: false,
    databuy: action.payload,
  };
case `${PRODUCT_LIST_BUY}_FAIL`:
  return {
    ...state,
    fetchingList: false,
  };
case `${PRODUCT_LIST_BUY}_ERROR`:
  return {
    ...state,
    fetchingList: false,
  };

// =========================== Delete wish list item ==========================
  case DELETE_WISH_LIST_ITEM:
    // console.log("i AM IN delete wish list ==>> ",action.payload.data,state.databuy)
    // let newRecord={...state.databuy}
    // if(action.payload.data.page === "/buynow"){
    //   for(let i=0;i<newRecord?.data?.length;i++){
    //     console.log("single instance ",newRecord.data[i])
    //     if(newRecord.data[i].id == action.payload.data.id){
    //       console.log("single instance matched",newRecord.data[i].id)
    //       newRecord.data[i].watchlist= false
    //       console.log("single instance matched",newRecord.data[i].watchlist)
    //       break
    //     }
    //     console.log(newRecord)
    //   }
    // }

    // let newRecordA={...state.data}
    // if(action.payload.data.page === "/auction"){
    //   console.log("working fine")
    //   for(let i=0;i<newRecordA?.data?.length;i++){
    //     if(newRecordA.data[i].id == action.payload.data.id){
    //       newRecordA.data[i].watchlist= false
    //       break
    //     }
    //   }
      
    const wishupdate = state.wishupdate + 1
    let updatedDatawish = state.wishList.filter(
      (element) => element.product_id != action.payload.data.id
    );
  return action.payload.data.page === "/buynow"?{
    ...state,
    fetchingList: false,
    wishList:updatedDatawish,
    wishupdate: wishupdate
  }:{
    ...state,
    fetchingList: false,
    wishList:updatedDatawish,
    wishupdate: wishupdate
  };


// ===========================  timer data updaate==========================

case TIMER_DATA_UPDATE:
  // console.log"new time action chk", action.payload.data)
  return {
    ...state,
    newtimer:  action.payload.data,
  };

    // current product buy  detail===========================
    
    case `${PRODUCT_DETAIL_BUY}_REQUEST`:
      return {
        ...state,
        fetchingCurrent: true,
        currentbuy: {},
      };
    case `${PRODUCT_DETAIL_BUY}_SUCCESS`:
      return {
        ...state,
        fetchingCurrent: false,
        currentbuy: action.payload,
      };
    case `${PRODUCT_DETAIL_BUY}_FAIL`:
      return {
        ...state,
        fetchingCurrent: false,
        currentbuy: {},
      };
    case `${PRODUCT_DETAIL_BUY}_ERROR`:
      return {
        ...state,
        fetchingCurrent: false,
        currentbuy: {},
      };

    // current product detail
    case `${PRODUCT_DETAIL}_REQUEST`:
      return {
        ...state,
        fetchingCurrent: true,
        current: {},
      };
    case `${PRODUCT_DETAIL}_SUCCESS`:
      return {
        ...state,
        fetchingCurrent: false,
        current: action.payload,
      };
    case `${PRODUCT_DETAIL}_FAIL`:
      return {
        ...state,
        fetchingCurrent: false,
        current: {},
      };
    case `${PRODUCT_DETAIL}_ERROR`:
      return {
        ...state,
        fetchingCurrent: false,
        current: {},
      };




    //  place bid
    case `${PLACE_BID}_REQUEST`:
    
      return {
        ...state,
        loadingPlaceBid: true,
      };
    case `${PLACE_BID}_SUCCESS`:
      console.log(action)
      return {
        ...state,
        message:action.message,
        loadingPlaceBid: false,
      };
    case `${PLACE_BID}_FAIL`:
      return {
        ...state,
        loadingPlaceBid: false,
      };
    case `${PLACE_BID}_ERROR`:
      return {
        ...state,
        loadingPlaceBid: false,
      };


// ===============aprocxy bid===================


case `${APPROXY_BID_NEW}_REQUEST`:
  return {
    ...state,
    loadingPlaceBid: true,
  };
case `${APPROXY_BID_NEW}_SUCCESS`:
  return {
    ...state,
    loadingPlaceBid: false,
  };
case `${APPROXY_BID_NEW}_FAIL`:
  return {
    ...state,
    loadingPlaceBid: false,
  };
case `${APPROXY_BID_NEW}_ERROR`:
  return {
    ...state,
    loadingPlaceBid: false,
  };



// ===============create wish list ===================


case `${CREATE_WISH_LIST}_REQUEST`:
  return {
    ...state,
    loadingPlaceBid: true,
  };
case `${CREATE_WISH_LIST}_SUCCESS`:
  return {
    ...state,
    wishList:[...state.wishList,...action.payload],
    loadingPlaceBid: false,
  };
case `${CREATE_WISH_LIST}_FAIL`:
  return {
    ...state,
    loadingPlaceBid: false,
  };
case `${CREATE_WISH_LIST}_ERROR`:
  return {
    ...state,
    loadingPlaceBid: false,
  };
// ===============get wish list ===================


case `${GET_WISH_LIST_AUCTION}_REQUEST`:
  return {
    ...state,
    loadingGetWishList: true,
  };
case `${GET_WISH_LIST_AUCTION}_SUCCESS`:
  return {
    ...state,
    wishList:action.payload,
    loadingGetWishList: false,
  };
case `${GET_WISH_LIST_AUCTION}_FAIL`:
  return {
    ...state,
    loadingGetWishList: false,
  };
case `${GET_WISH_LIST_AUCTION}_ERROR`:
  return {
    ...state,
    loadingGetWishList: false,
  };


  case `${GET_WISH_LIST_BUYNOW}_REQUEST`:
  return {
    ...state,
    loadingGetWishList: true,
  };
case `${GET_WISH_LIST_BUYNOW}_SUCCESS`:
  return {
    ...state,
    wishListbuy:action.payload,
    loadingGetWishList: false,
  };
case `${GET_WISH_LIST_BUYNOW}_FAIL`:
  return {
    ...state,
    loadingGetWishList: false,
  };
case `${GET_WISH_LIST_BUYNOW}_ERROR`:
  return {
    ...state,
    loadingGetWishList: false,
  };


// ===============update  wish list ===================


case `${CLOSE_WISH_LIST}_REQUEST`:
  return {
    ...state,
    loadingGetWishList: false,
  };
case `${CLOSE_WISH_LIST}_SUCCESS`:
  let updatedData = state.wishList.filter(
    (element) => element.wishlist_id != action.payload.id
  );
  return {
    ...state,
    wishList:updatedData,
    loadingGetWishList: false,
  };
case `${CLOSE_WISH_LIST}_FAIL`:
  return {
    ...state,
    loadingGetWishList: false,
  };
case `${CLOSE_WISH_LIST}_ERROR`:
  return {
    ...state,
    loadingGetWishList: false,
  };

// ===============ACTIVE_BUYING_LIST wish list ===================


case `${ACTIVE_BUYING_LIST}_REQUEST`:
  return {
    ...state,
    loadingActive_buyingList: true,
  };
case `${ACTIVE_BUYING_LIST}_SUCCESS`:
  // console.log"CLOSE_WISH_LIST==========>>>>",action.payload)
  return {
    ...state,
    active_buying:action.payload,
    loadingActive_buyingList: false,
  };
case `${ACTIVE_BUYING_LIST}_FAIL`:
  return {
    ...state,
    loadingActive_buyingList: false,
  };
case `${ACTIVE_BUYING_LIST}_ERROR`:
  return {
    ...state,
    loadingActive_buyingList: false,
  };
  }
  return state;
};
