import { WINERS_CAR_LIST,SHIPING_COST,WINERS_CAR_USER_STATUS,SHIPING_FORM, SHIPING_PRICE} from "../constant/index";

const INITIAL_STATE = {
  loading: false,
  data:[],
 winerList:{},
 winerListloading:true,
 winerCarStatus:{},
 winerCarStatusloading:true,
 addresform:false,

};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {

    case `${SHIPING_COST}_REQUEST`:
      return { ...state, loading: true };
    case `${SHIPING_COST}_SUCCESS`:
      console.log(
        "SHIPING_COST ......SHIPING_COST.....SHIPING_COST",
        action.payload
      );

      return { ...state, data: action.payload, loading: false };
    case `${SHIPING_COST}_FAIL`:
      return { ...state, loading: false };
    case `${SHIPING_COST}_ERROR`:
      return { ...state, loading: false };


    //================car winer list===============

    case `${WINERS_CAR_LIST}_REQUEST`:
      return { ...state, winerListloading: true };
    case `${WINERS_CAR_LIST}_SUCCESS`:
      // console.log"WINERS_CAR_LIST...........WINERS_CAR_LIST",action.payload)

      return { ...state, 
        winerList:action.payload,
        winerListloading:false,
      };
    case `${WINERS_CAR_LIST}_FAIL`:
      return { ...state, winerListloading: false };
    case `${WINERS_CAR_LIST}_ERROR`:
      return { ...state, winerListloading: false };



    //================car winer status==============

    case `${WINERS_CAR_USER_STATUS}_REQUEST`:
      return { ...state, winerCarStatusloading: true };
    case `${WINERS_CAR_USER_STATUS}_SUCCESS`:
      // console.log"WINERS_CAR_USER_STATUS ...........WINERS_CAR_USER_STATUS",action)

      return { ...state, 
        winerCarStatus:action.payload,
        winerCarStatusloading:false,
      };
    case `${WINERS_CAR_USER_STATUS}_FAIL`:
      return { ...state, winerCarStatusloading: false };
    case `${WINERS_CAR_USER_STATUS}_ERROR`:
      return { ...state, winerCarStatusloading: false };

    //================shiping form status==============

    case `${SHIPING_FORM}_REQUEST`:
      return { ...state,  };
    case `${SHIPING_FORM}_SUCCESS`:
      // console.log"WINERS_CAR_USER_STATUS ...........WINERS_CAR_USER_STATUS",action)

      return { ...state, 
        addresform:true,
      };
    case `${SHIPING_FORM}_FAIL`:
      return { ...state, winerCarStatusloading: false };
    case `${SHIPING_FORM}_ERROR`:
      return { ...state, winerCarStatusloading: false };

 //================shiping form status==============

    case `${SHIPING_FORM}_REQUEST`:
      return { ...state,  };
    case `${SHIPING_FORM}_SUCCESS`:
      // console.log"WINERS_CAR_USER_STATUS ...........WINERS_CAR_USER_STATUS",action)

      return { ...state, 
        addresform:true,
      };
    case `${SHIPING_FORM}_FAIL`:
      return { ...state, winerCarStatusloading: false };
    case `${SHIPING_FORM}_ERROR`:
      return { ...state, winerCarStatusloading: false };

       //================shiping price==============

    case `${SHIPING_PRICE}_REQUEST`:
      return { ...state };
    case `${SHIPING_PRICE}_SUCCESS`:
      return { ...state, data: action.payload, loading: false  };
    case `${SHIPING_PRICE}_FAIL`:
      return { ...state, winerCarStatusloading: false };
    case `${SHIPING_PRICE}_ERROR`:
      return { ...state, winerCarStatusloading: false };



}







  return state;
};
