export const formateKey = (text = "") => {
  
  let textArr = text.split("_");
  return textArr.join(" ");
};
export const formateNumber = (e_) => {
  let e = parseInt(e_);
  return e < 10 ? `0${e}` : e;
};
const checkIsTimePassed = (e = "") => {
  var splittedString = e.split(":");
  var hour = parseInt(splittedString[0]);
  var currentHour = new Date().getHours();

  return {
    condition: currentHour > hour,
    start: currentHour > hour ? `${formateNumber(currentHour + 2)}:00:00 ` : e,
  };
};
export const getTimeSolts = (
  start_ = "00:00:00",
  end = "00:00:00",
  isToday = false
) => {
  let start = isToday ? checkIsTimePassed(start_).start : start_;
  
  // // console.log
  //   "getTimeSolts start time check \nstart_==>>",
  //   start_,
  //   "\nstart==>>",
  //   start,
  //   "\nend==>>",
  //   end
  // );
  function addMinutes(time, minutes) {
    var date = new Date(
      new Date("01/01/2015 " + time).getTime() + minutes * 60000
    );
    var tempTime =
      (date.getHours().toString().length == 1
        ? "0" + date.getHours()
        : date.getHours()) +
      ":" +
      (date.getMinutes().toString().length == 1
        ? "0" + date.getMinutes()
        : date.getMinutes()) +
      ":" +
      (date.getSeconds().toString().length == 1
        ? "0" + date.getSeconds()
        : date.getSeconds());
    return tempTime;
  }
  if(start>=end){
    return [];

  }
  else{
    var starttime = start;
    var interval = "60";
    var endtime = end;
    var timeslots = [starttime];
  
    while (starttime != endtime) {
  
  
      
      starttime = addMinutes(starttime, interval);
      if(starttime>endtime){
        starttime=endtime;
        timeslots.push(starttime);
        break
      }
      timeslots.push(starttime);
    }
    // 
    return timeslots;
  }

};

export const formateTimeSlot = (e) => {
  var splittedString = e.split(":");
  return splittedString.slice(0, -1).join(":");
};

export function capitalizeText(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
