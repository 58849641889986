/* eslint-disable import/no-anonymous-default-export */
export default {
  login: "api/login/",
  signUp: "api/register/",
  getupdateprofile: "api/getUpdateUser/",
  changePassword: "api/changepassword/",
  product_list: "api/v1/product_list/",
  product_detail: "api/v1/product_detail/",
  place_bid: "api/v1/bid/",
  bid_graph: "api/v1/bid_graph/",
  filter: "api/v1/filter/",
  // // ================== // //  sell my car fetch data api labels // // ================== // //
  brands: "api/v1/brands/",
  car_models: "%5Echaining/filter/sell/CarModels/brands/sell/year/model_name/",
  car_fuel_types:"%5Echaining/filter/sell/FuelType/model_name/sell/Variant/fuel_type/",
  car_years: "%5Echaining/filter/sell/year/fuel_type/sell/Variant/year/",
  car_variants: "%5Echaining/filter/sell/Variant/year/sell/Mileage/variant/",
  Seller:"api/getUser/",
  // 
  evaluation_create: "api/v1/evaluation_create/",
  appointment_create: "api/v1/appointment_create/",
  appointment_update: "api/v1/appointment_update/",
  //
  officetiming: "api/v1/officetiming",
  creatimage: "api/v1/creatimage/",
  damageimages: "api/v1/damageimages/",
  vendorinterest: "api/v1/vendorinterest/",
  myappointment: "api/v1/appointment_tracking",
  getcarsdetiles: "api/v1/releated_variants/",
  sellMyCarVideo: "api/v1/viedo/",
  sellMyCarAudio: "api/v1/audio/",
  createSubscription: "create_subscription/",
  createContactUs: "create_contact_us/",
  buynow_list: "api/v1/buynow/",
  sale_product: "api/v1/soldproducts/",
  password_Reset: "request_reset_email/",
  password_Reset_New: "password_reset_complete/",

  get_bidding_auction: "api/v1/getbidding_auction/",
  approxy_Bid: "api/v1/createproxybid/",
  carDetail_Update:"api/v1/showfeature/ ",

  shiping_cost:"api/v1/shipcost/",
 
  address_form:"api/v1/Createaddress/",
  add_feaderes:"api/v1/Createfeature/",

  // ==-----shiping=============
  car_winer_list:"mybidd/",
  vechiellist:"api/v1/get_products/",
  addvechiel:"create_product/",
  addvechielAuction:"api/v1/auctionlist/",
  updatevechielAuction:"api/v1/auctiondetail/",
  delvechiel:"del_product/",
  car_winer_status:"api/v1/tracking/",
  shiping_form:"api/v1/insertorder/",
  shipment_price:"api/v1/shipmentPrice/",

// ================car deail =============
  car_report:"api/track/",
  car_report_detail:"api/v1/getselected_inspection/",



  // =================carr detail ==============


car_reg_chk:"api/v1/check_reg/",

get_wish_list:"api/v1/get_wishlist/",
create_wish_list:"api/v1/create_wishlist/",
close_wish_list:"api/v1/update_wishlist/",
active_buying_list:"api/v1/active_buying/"




};
