/* eslint-disable no-useless-concat */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, {
  useEffect,
  useState,
  forwardRef,
  useRef,
  useImperativeHandle,
} from "react";
import { fetchData, API_ENDPOINT } from "../../services/api";
import { POST } from "../../constant/apiMethods";
import Labels from "../../services/apiLabels";
import { Line } from "react-chartjs-2";

export default forwardRef((props, ref) => {
  // destracturing props data
  const {user_id, auction_id, graphDataCb } = props;

  const [apiGraphData, setApiGraphData] = useState({ items: [], cur_bid: "" });
  const [GraphData, setGraphData] = useState({
    datasets: [
      {
        label: "Amount",
        data: [],
        borderColor: "rgba(255, 99, 132, 0.8)",
        fill: false,
        // count:10
      },
    ],
  });
  // fetching real time graph data after every 30 seconds
  async function fetchGraphDataHandler() {
    try {
      const parameters = {
        method: POST,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ p_id: auction_id, user_id: String(user_id) }),
      };
      const result = await fetch(
        API_ENDPOINT(Labels.bid_graph),
        parameters
      ).then((response) => {
        setTimeout(() => {
          fetchGraphDataHandler();
        }, 5000);
        return response.json();
      });
      if (result.data) {
        graphDataCb(result.data);
        setApiGraphData(result.data);
    
        // if (GraphData.datasets[0].data <= 0) {
        //   // console.log
        //     "fetchGraphDataHandler condition = GraphData.datasets[0].data.items.length ===>",
        //     GraphData.datasets[0].data
        //   );
        //   setGraphData({
        //     datasets: [
        //       {
        //         label: "Amount",
        //         data: result.data.items,
        //         borderColor: "rgba(255, 99, 132, 0.8)",
        //         fill: false,
        //       },
        //     ],
        //   });
        // }
        if (result.data.items.length > apiGraphData.items.length) {
          setGraphData({
            datasets: [
              {
                label: "Amount",
                data: result.data.items,
                borderColor: "rgba(255, 99, 132, 0.8)",
                fill: false,
              },
            ],
          });
        }
      }
    } catch (e) {
      
    }
  }
  useEffect(() => {
    if (GraphData.datasets[0].data <= 0) {
      fetchGraphDataHandler();
    }
  }, [auction_id]);
  // --end
  function getCount(tooltipItem, datasets) {
    return datasets.data.find((datum) => {
      return datum.y === tooltipItem.y;
    }).name;
  }

  const options = {
    plugins: {
      tooltip: {
        callbacks: {
          afterLabel: function (data) {
            return "Name" + ": " + getCount(data.raw, data.dataset);
          },
        },
      },
    },
  };
  //
  useImperativeHandle(ref, () => ({
    fetchGraphData() {
      fetchGraphDataHandler();
    },
  }));
  //
  return (
    <>
      <Line type="line" data={GraphData} options={options} />
    </>
  );
});
