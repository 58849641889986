import { combineReducers } from "redux";
import auth from "./auth";
import toaster from "./toaster";
import loader from "./loader";
import product from "./product";
import filters from "./filters";
import sellMyCar from "./sellMyCar";
import showInterest from "./showInterest";
import myInterest from "./myInterest";
import myAppointment from "./myAppointment";
import cardetail from "./cardetail";
import cardeailupdate from "./cardeailupdate";
import shiping from "./shiping";
import carreport from "./carreport"
import myVehicles from "./myVehicles"
import profile from "./profile"
//
export default combineReducers({
  myInterest,
  showInterest,
  myAppointment,
  sellMyCar,
  filters,
  product,
  loader,
  toaster,
  auth,
  cardetail,
  shiping,
  cardeailupdate,
  carreport,
  myVehicles,
  profile
});
