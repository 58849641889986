import { CAR_REPORT,CAR_REPORT_DETAIL} from "../constant/index";

const INITIAL_STATE = {
  loading: true,
  winerListloading: true,
  car_report_data:[],
  car_report_detail_data:[],


};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
// =================================car report==============
    case `${CAR_REPORT}_REQUEST`:
      return { ...state, loading: true };
    case `${CAR_REPORT}_SUCCESS`:
      // console.log"CAR_REPORT ...........CAR_REPORT",action.payload)

      return { ...state, 
        car_report_data:action.payload,
        // loading:false,
      };
    case `${CAR_REPORT}_FAIL`:
      return { ...state, 
        // loading: false 
      };
    case `${CAR_REPORT}_ERROR`:
      return { ...state,
        //  loading: false 
        
        };


    //================CAR_REPORT_DETAIL===============

    case `${CAR_REPORT_DETAIL}_REQUEST`:
      return { ...state, winerListloading: true };
    case `${CAR_REPORT_DETAIL}_SUCCESS`:
      // console.log"CAR_REPORT_DETAIL...........CAR_REPORT_DETAIL",action.payload)

      return { ...state, 
        car_report_detail_data:action.payload,
        winerListloading:false,
      };
    case `${CAR_REPORT_DETAIL}_FAIL`:
      return { ...state, winerListloading: false };
    case `${CAR_REPORT_DETAIL}_ERROR`:
      return { ...state, winerListloading: false };





}







  return state;
};
