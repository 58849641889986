/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, {
  forwardRef,
  useRef,
  useImperativeHandle,
  Fragment,
  useState,
  useEffect,
} from "react";
import { Row, Col } from "react-bootstrap";

import ImageGallery from "react-image-gallery";
// import Magnifier from '../Magnifer/index'
import {
  AiFillCaretUp,
  AiFillCaretDown,
  AiFillHeart,
  AiOutlineHeart,
} from "react-icons/ai";
import { MdTitle, MdLastPage, MdGroups, MdAccountCircle } from "react-icons/md";
import { ImHammer2 } from "react-icons/im";
import { RiReservedFill } from "react-icons/ri";
import { Divider } from "semantic-ui-react";
import TabExamplePointing from "./tabbar";
import Graph from "../Product View/graph";
import $ from "jquery";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import Labels from "../../services/apiLabels";
import ScaleLoader from "react-spinners/ScaleLoader";
import Magnify from "./magnify";
import "./Productview.css";
// import "./style.css";
// import "../../components/Magnifer/magnifier.css";
import Event from "../Magnifer/Event";
import Magnifier from "../Magnifer/Magnifier";
import number_logo from "../../assets/images/logo2.png"
import {
  PLACE_BID,
  PRODUCT_DETAIL,
  TOASTER_SHOW,
  toaster_w,
  PRODUCT_DETAIL_BUY,
} from "../../constant";
import { GET, POST } from "../../constant/apiMethods";
import carSvg from "../../assets/svg/car.svg";
import ProductMainShimmer from "./ProductMainShimmer";
import Tabs from "../TabsComponent";
import SlideButton from "../SlideButton";
import Carousel from "../Carousel";

function ProductMain(props) {
  const {
    product,
    getProductDetail,
    place_bid,
    toasterShow,
    loadingPlaceBid,
    user_id,
    loadingDetails,
  } = props;
  const graphRef = useRef();

  let location = useLocation();
  const { product_code } = location.state;

// console.log"chk product id =======================>>>>>>>>>",product_code)

  const [bidInputValue, setBidInputValue] = useState(0);
  const [bidInputFormated, setbidInputFormated] = useState({
    focused: false,
    formated: "0",
  });
  const [graphData, setGraphData] = useState({});
  const [bidingExpired, setBidingExpired] = useState(true);
  const [timer, setTimer] = useState([
    { id: "days", value: "00" },
    { id: "hours", value: "00" },
    { id: "minutes", value: "00" },
    { id: "seconds", value: "00" },
  ]);
  const [images, setImages] = useState([]);
  const [current_image, setcurrent_image] = useState("");
  // place bid inputs functions
  let bidAmountID = 1000;
  let [currentBid, setcurrentBid] = useState(0);
  const imageGalleryDataFormatter = (arr) => {
    let formattedArr = [];

    // arr.map((item) => {
    //   return formattedArr.push({
    //     image: item,
    //   });
    // });
    React.Children.toArray(arr.map((item, index) => {
      return formattedArr.push({
        original: item,
        thumbnail: item,
        id: index + 1,
      });
    }));
    

    setImages(formattedArr);
  };
  const [isdone, setisdone] = useState(false);
  // price formater adding commas
  function priceFormater(numb) {
    var str = numb.toString();
    return str.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  // car animate handler
  const carAnimationHandler = () => {
    document.getElementById("animating_car_cont").className =
      "bid_animating_car_cont";
    setTimeout(() => {
      document.getElementById("animating_car_cont").className = "d-none";
    }, 3000);
  };

  useEffect(() => {
    if (product_code) {
      getProductDetail(product_code);
    }
    
      return () => {
         evt = new Event();
         m = new Magnifier(evt, {});
      };
  }, []);





  
  useEffect(() => {
    if (product.images) imageGalleryDataFormatter(product.images);
    if (product.start_price) {
      bidInputOnChangeHandler(product.start_price);
      setcurrentBid(product.start_price);
    }
    // 
  }, [product]);
  // graph data handler
  useEffect(() => {
    let currentBidAmmount = graphData.cur_bid;
    if (currentBidAmmount) {
      if (currentBidAmmount > currentBid) {
        bidInputOnChangeHandler(currentBidAmmount);
        setcurrentBid(currentBidAmmount);
        carAnimationHandler();
      }
    }
  }, [graphData]);
  var evt = new Event();
  var m = new Magnifier(evt, {});
  // useEffect(() => {
  // // console.log
  //   "tag class ",
  //   document.getElementsByClassName(".center")[0].getElementsByTagName("img")[0]
  // );
  //   if (!isdone) {
  //     setisdone(true);
  //     m = new Magnifier(evt, {
  //       largeWrapper: document.getElementById("zoom-preview"),
  //     });

  //     m.attach({
  //       thumb: document
  //         .getElementsByClassName(".center")[0]
  //         .getElementsByTagName("img")[0],
  //       large: current_image,
  //       largeWrapper: "zoom-preview",
  //       zoom: 2,
  //       mode: "inside",
  //       zoomable: true,
  //       onthumbenter: function () {
  //         // document.getElementById("enter").innerHTML = "Mouse enter";
  //         // document.getElementById("leave").innerHTML = "";
  //         // document.getElementById("zoom").innerHTML = "";
  //         document.getElementById("zoom-preview").classList.remove("d-none");
  //       },
  //       onthumbmove: function () {
  //         // document.getElementById("move").innerHTML = "Mouse move";
  //         document.getElementById("zoom-preview").classList.remove("d-none");
  //       },
  //       onthumbleave: function () {
  //         // document.getElementById("enter").innerHTML = "";
  //         // document.getElementById("move").innerHTML = "";
  //         // document.getElementById("zoom").innerHTML = "";
  //         // document.getElementById("leave").innerHTML = "Mouse leave";
  //         document.getElementById("zoom-preview").classList.add("d-none");
  //       },
  //       // onzoom: function (data) {
  //       //   document.getElementById("zoom").innerHTML = "Zoom: " + data.zoom;
  //       // },
  //     });
  //   } else {
  //     m.setThumb(
  //       document
  //         .getElementsByClassName(".center")[0]
  //         .getElementsByTagName("img")[0]
  //     );
  //   }
  // }, [current_image]);
  // --------------timer
  // let countDownDate = new Date().getTime() + 10000;
  // 
  const timeFormatter = (e) => {
    return e < 10 ? `0${e}` : e;
  };
  const screen_change = (e) => {
    m.leaver();
    document.getElementById("zoom-preview").classList.add("d-none");
    // 
    
    if (e) {
      
      m.attach({
        thumb: ".image-gallery-image",
        // document
        //   .getElementsByClassName("center")[0]
        //   .getElementsByTagName("img")[0],
        // large: current_image,
        largeWrapper: "zoom-preview",
        zoom: 2,
        mode: "inside",
        zoomable: true,
        onthumbenter: function () {
          // document.getElementById("enter").innerHTML = "Mouse enter";
          // document.getElementById("leave").innerHTML = "";
          // document.getElementById("zoom").innerHTML = "";
          // document.getElementById("zoom-preview").classList.remove("d-none");
        },
        onthumbmove: function () {
          // document.getElementById("move").innerHTML = "Mouse move";
          // document.getElementById("zoom-preview").classList.remove("d-none");
        },
        onthumbleave: function () {
          // document.getElementById("enter").innerHTML = "";
          // document.getElementById("move").innerHTML = "";
          // document.getElementById("zoom").innerHTML = "";
          // document.getElementById("leave").innerHTML = "Mouse leave";
          // document.getElementById("zoom-preview").classList.add("d-none");
        },
        // onzoom: function (data) {
        //   document.getElementById("zoom").innerHTML = "Zoom: " + data.zoom;
        // },
      });
    } else {
      //  
      m.attach({
        thumb: ".image-gallery-image",
        // document
        //   .getElementsByClassName("center")[0]
        //   .getElementsByTagName("img")[0],
        // large: current_image,
        largeWrapper: "zoom-preview",
        zoom: 2,
        mode: "inside",
        zoomable: true,
        onthumbenter: function () {
          // document.getElementById("enter").innerHTML = "Mouse enter";
          // document.getElementById("leave").innerHTML = "";
          // document.getElementById("zoom").innerHTML = "";
          document.getElementById("zoom-preview").classList.remove("d-none");
        },
        onthumbmove: function () {
          // document.getElementById("move").innerHTML = "Mouse move";
          document.getElementById("zoom-preview").classList.remove("d-none");
        },
        onthumbleave: function () {
          // document.getElementById("enter").innerHTML = "";
          // document.getElementById("move").innerHTML = "";
          // document.getElementById("zoom").innerHTML = "";
          // document.getElementById("leave").innerHTML = "Mouse leave";
          document.getElementById("zoom-preview").classList.add("d-none");
        },
        // onzoom: function (data) {
        //   document.getElementById("zoom").innerHTML = "Zoom: " + data.zoom;
        // },
      });
    }
    m.set_data();
    //  m = new Magnifier(evt, {
    //    largeWrapper: document.getElementById("zoom-preview"),
    //  });
    // m.setThumb(document
    //         .getElementsByClassName("center")[0]
    //         .getElementsByTagName("img")[0]);
  };
  const set_magnifier = (e) => {
    if (!isdone) {
      setisdone(true);
      // m = new Magnifier(evt, {
      //   largeWrapper: document.getElementById("zoom-preview"),
      // });
      m.attach({
        thumb: ".image-gallery-image",
        // document
        //   .getElementsByClassName("center")[0]
        //   .getElementsByTagName("img")[0],
        // large: current_image,
        largeWrapper: "zoom-preview",
        zoom: 2,
        mode: "inside",
        zoomable: true,
        onthumbenter: function () {
          // document.getElementById("enter").innerHTML = "Mouse enter";
          // document.getElementById("leave").innerHTML = "";
          // document.getElementById("zoom").innerHTML = "";
              var ab = document.getElementById("zoom-preview");
              if (ab) {
                ab.classList.remove("d-none");
              }
          // document.getElementById("zoom-preview").classList.remove("d-none");
        },
        onthumbmove: function () {
          // document.getElementById("move").innerHTML = "Mouse move";
              var ab = document.getElementById("zoom-preview");
              if (ab) {
                ab.classList.remove("d-none");
              }
          // document.getElementById("zoom-preview").classList.remove("d-none");
        },
        onthumbleave: function () {
          
          // document.getElementById("enter").innerHTML = "";
          // document.getElementById("move").innerHTML = "";
          // document.getElementById("zoom").innerHTML = "";
          // document.getElementById("leave").innerHTML = "Mouse leave";
          var ab = document.getElementById("zoom-preview")
          if(ab){
            ab.classList.add("d-none");
          }
            
        },
        // onzoom: function (data) {
        //   document.getElementById("zoom").innerHTML = "Zoom: " + data.zoom;
        // },
      });
    }
  };
  useEffect(() => {
    let countDownDate = new Date(product.end_at ? product.end_at : 0).getTime();
    const interval = setInterval(function () {
      // Get today's date and time
      var now = new Date().getTime();

      // Find the distance between now and the count down date
      var distance = countDownDate - now;
      

      // Time calculations for days, hours, minutes and seconds
      var days = Math.floor(distance / (1000 * 60 * 60 * 24));
      var hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);

      setTimer([
        { id: "days", value: timeFormatter(days) },
        { id: "hours", value: timeFormatter(hours) },
        { id: "minutes", value: timeFormatter(minutes) },
        { id: "seconds", value: timeFormatter(seconds) },
      ]);
      setBidingExpired(false);
      // If the count down is over, write some text
      if (distance < 0) {
        clearInterval(interval);
        setBidingExpired(true);
        setTimer([
          { id: "days", value: "00" },
          { id: "hours", value: "00" },
          { id: "minutes", value: "00" },
          { id: "seconds", value: "00" },
        ]);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [product]);
  // --------------timer


// ==========aprocxy bid==========

const approxy_bid= async ()=>{


  let obj = {
    date: new Date(),
    auction_id: product.auction_id,
    user_id,
    bid_amount: bidInputValue,
    bid_type:"2",
  };

  place_bid(obj);
  await graphRef.current.fetchGraphData();
  return "bid placed";

}





  // place bid handler
  const placeBidHandler = async () => {
    if (bidInputValue > currentBid) {
      let obj = {
        date: new Date(),
        auction_id: product.auction_id,
        user_id,
        bid_amount: bidInputValue,
        bid_type:"1",
      };
      // 
      await place_bid(obj);
      await graphRef.current.fetchGraphData();
      return "bid placed";
    } else {
      toasterShow({
        type: toaster_w,
        description: `Bidding amount must be greater then ${currentBid}`,
      });
      return "bid not placed";
    }
  };
  const bidInputOnChangeHandler = (e) => {
    if (e) {
      setBidInputValue(e);
      setbidInputFormated({ ...bidInputFormated, formated: priceFormater(e) });
    }
  };

  // document
  //   .getElementByClassName("image-gallery-fullscreen-button")[0]
  //   .addEventListener("click", changeObjectFitHandler);
  // function changeObjectFitHandler() {
  //   let image = document.getElementsByClassName("img")[0];
  //   let style = window.getComputedStyle(image);
  //   let objectFit = style.getPropertyValue("object-fit");
  //   if (objectFit === "contain") {
  //     image.style.objectFit = "cover";
  //   }
  //   if (objectFit === "cover") {
  //     image.style.objectFit = "contain";
  //   }
  //   
  // }
  useEffect(() => {
    
    // console.log"testingggg bidInputValue", parseInt(bidInputValue));
    
  }, [bidInputValue, bidInputFormated]);
  // loadingDetails
  if (loadingDetails) {
    return <ProductMainShimmer />;
  } else {
    return (
      <Fragment>
        <div className="container-fluid mt-5  ">
          <div className="row">
            {/* left column */}
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12  ">
              {/* ================ image slider ================== */}
              <div className="slider_ ">
                {/* <Carousel images={product.images} /> */}

                <ImageGallery
                  lazyLoad={false}
                  // onScreenChange={screen_change}
                  // onSlide={set_magnifier}
                  onImageLoad={set_magnifier}
                  showBullets={true}
                  items={images}
                  showFullscreenButton={false}
                  // renderItem={(e) =>// console.loge)}
                  originalClass="ImageGallery_originalClass"
                />
                {/* {images.length > 0 ? (
                  <SliderImage
                    data={SliderImageData}
                    width="100%"
                    direction="right"
                  />
                ) : null} */}
              </div>
              <div className="col-sm-6.col-md-6.col-lg-6.col-xl-6 desktopbar mt-5">
          
                <TabExamplePointing />
           
              </div>
            </div>
            {/* right column */}
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12  ">
              <div
                className="magnifier-preview d-none"
                id="zoom-preview"
                // style={{
                // position: "absolute",
                // top: 0,
                // zIndex: 100000000,
                // left: wrapper.width,
                // width: window.innerWidth - wrapper.width,
                // height: wrapper.height,
                // backgroundColor: "",
                // }}
              >
                {/* this is side div */}
              </div>
              {/* =============car detail and bid ================= */}
              <div className="cardetailmain d-flex flex-column mt-5">
                {/* =============== car title and detail ================== */}
                {product && <div className="flex justify-center gap-5 items-center flex-col my-2">
                <div><img src={product?.brands_image} className="w-[6rem] h-[5rem] rounded-lg" alt="" /></div>
                  <div className="flex border-2 border-black rounded">
                  <img className="w-[2rem] h-[3rem]" src={number_logo} />
                  <div className="bg-[#fef200] px-8 flex justify-center items-center font-bold">{product?.Registration}</div>
                  </div>
                  <h1 className="viewtitle m-0 mb-5">{`${product?.product_name} ${product?.year} ${product?.fuel_type}`}</h1>
                </div>}
                {product &&          
                  <div
                    className={`${
                      bidingExpired ? null : "d-none"
                    } w-full text-white bg-[#178971] p-5`}
                  >
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="d-flex justify-between items-center" >
                      <span style={{fontSize:"14px", fontWeight:"bold", color:"#FFD700"}}>Make</span>
                      <h2 className="mt-0" style={{fontSize:"16px", fontWeight:"normal"}}>{product?.product_name}</h2>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="d-flex justify-between items-center" >
                      <span style={{fontSize:"14px", fontWeight:"bold", color:"#FFD700"}}>Model</span>  
                      <h2 className="mt-0" style={{fontSize:"16px", fontWeight:"normal"}}>{product?.model_name}</h2>
                      </div>
                    </div>
                  </div>
                  <div className="row pt-4">
                    <div className="col-sm-6">
                      <div className="d-flex justify-between items-center" >
                      <span style={{fontSize:"14px", fontWeight:"bold", color:"#FFD700"}}>Year</span>
                      <h2 className="mt-0" style={{fontSize:"16px", fontWeight:"normal"}}>{product?.year}</h2>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="d-flex justify-between items-center" >
                      <span style={{fontSize:"14px", fontWeight:"bold", color:"#FFD700"}}>Mileage</span>  
                      <h2 className="mt-0" style={{fontSize:"16px", fontWeight:"normal"}}>{product?.Mileage}</h2>
                      </div>
                    </div>
                  </div>
                  <div className="row pt-4">
                    <div className="col-sm-6">
                      <div className="d-flex justify-between items-center" >
                      <span style={{fontSize:"14px", fontWeight:"bold", color:"#FFD700"}}>Fuel Type</span>
                      <h2 className="mt-0" style={{fontSize:"16px", fontWeight:"normal"}}>{product?.fuel_type}</h2>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="d-flex justify-between items-center" >
                      <span style={{fontSize:"14px", fontWeight:"bold", color:"#FFD700"}}>Transmission</span>  
                      <h2 className="mt-0" style={{fontSize:"16px", fontWeight:"normal"}}>{product?.Transmission == 1 ? "Auto" : product?.Transmission == 2 ? "Manual" : "Both"}</h2>
                      </div>
                    </div>
                  </div>
                  <div className="row pt-4">
                    <div className="col-sm-6">
                      <div className="d-flex justify-between items-center" >
                      <span style={{fontSize:"14px", fontWeight:"bold", color:"#FFD700"}}>Body Type</span>
                      <h2 className="mt-0" style={{fontSize:"16px", fontWeight:"normal"}}>{product?.car_body_type == 1 ? "Hacthback" : product?.car_body_type == 2 ? "MPV" : product?.car_body_type == 3 ? "suv" : "Saloon"}</h2>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="d-flex justify-between items-center" >
                      <span style={{fontSize:"14px", fontWeight:"bold", color:"#FFD700"}}>Registration</span>  
                      <h2 className="mt-0" style={{fontSize:"16px", fontWeight:"normal"}}>{product?.Registration}</h2>
                      </div>
                    </div>
                  </div>
                </div>}
                <div
                  className={`${
                    bidingExpired ? "d-none" : ""
                  } d-flex  flex-column justify-content-center align-items-center`}
                >   
                </div>
              
              </div>
              <div className=" mobileTabBar">
                <TabExamplePointing />
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  user_id: state.auth.id,
  product: state.product.current,
  loadingDetails: state.product.fetchingCurrent,
  loadingPlaceBid: state.product.loadingPlaceBid,
});
const mapDispatchToProps = (dispatch) => ({
  // get product list
  getProductDetail: (code) =>
    dispatch({
      type: PRODUCT_DETAIL,
      payload: {
        type: PRODUCT_DETAIL,
        method: GET,
        label: `${Labels.product_detail}${code}/`,
        hideOverlayLoader: true,
      },
    }),
  //
  // place bid
  place_bid: (data) =>
    dispatch({
      type: PLACE_BID,
      payload: {
        type: PLACE_BID,
        method: POST,
        label: `${Labels.place_bid}`,
        data,
        hideOverlayLoader: true,
      },
    }),
  //
  toasterShow: (data) =>
    dispatch({
      type: TOASTER_SHOW,
      payload: {
        show: true,
        autoHide: true,
        ...data,
      },
    }),
});
export default connect(mapStateToProps, mapDispatchToProps)(ProductMain);
