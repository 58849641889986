import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import "./style.css";
import axios from "axios";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Search, Grid, Header, Segment } from "semantic-ui-react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import CircularProgress, {
  circularProgressClasses,
} from "@mui/material/CircularProgress";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { Outlet, Link } from "react-router-dom";
import SearchExampleStandard from "./search";
import Labels from "../../services/apiLabels";
import { ShimmerThumbnail } from "react-shimmer-effects";
import {
  CAR_DETAIL_UPDATE,
  ADD_CAR_FEATURES,
  MY_INTERSTE,
  POST,
  CHAKBOX_LIST,
  GET,
} from "../../constant";
import SearchCity from "../../components/SearchCity";
import ChekBox from "../../components/ChekBox";
import InputTypes from "../../components/InputTypes";

export const CarUpdate = ({ car_data, getcardetail,evaluation_id_, loading,carfeatureadd,inp_chk_action,userinfo,divindex }) => {
  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  useEffect(() => {
    getcardetail();

    // 
  }, []);

  // 

  const [index, setIndex] = useState(0);
  // const [userinfo, setUserInfo] = useState([]);


  // const [divindex, setdivindex] = useState({});
  const [divindex_multiple, setdivindex_multiple] = useState([]);
  


  const [bar, setbar] = useState(0);

  function Buttons({ index, setIndex, size }) {
    const nextSlide = () => {
      // 

if(car_data[index]){

  carfeatureadd({
        evaluation:evaluation_id_,
        question:car_data[index]['question_id'],
        feature:divindex[index],
      })
}




    


      if (index < size) setIndex((i) => i + 1);
      setbar(bar + 100 / car_data.length);
    };
    const prevSlide = () => {
      if (index > 0) setIndex(--index);
      setbar(bar - 100 / car_data.length);
    };
    return (
      <>
        <div className="container-fluied  botm_wrap">
     

          <div className="row d-flex justify-content-center  ">
         
            <div className="col-6 d-flex text-center justify-content-center">
            <Link to="/carsteps"   className="my-3">Save & exit</Link>
              <button onClick={prevSlide} className="back_btn mx-4">
                {" "}
                Previous{" "}
              </button>

              {

              }
              {
              index < car_data.length ? 
              <button onClick={nextSlide} className="next_btn">
              {" "}
              Next{" "}
            </button>:
            null
            }
             
            </div>
          </div>
        </div>
      </>
    );
  }

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
    },
  }));



const childData=(e)=>{

// console.log"chind data in parent",e)
}














  return (
    <>
      <div className="aaaaaa">
        <div className="p_wrap mt-2">
          <BorderLinearProgress
            variant="determinate"
            className="progresbar"
            value={bar}
          />
        </div>
      </div>

      <main>
        {/* <div className="presentation">{renderSlide()}</div> */}
        {loading ? (
          <>
            <div className="my-4">
              <ShimmerThumbnail height={200} rounded />
            </div>

            <div className="p-5">
              <ShimmerThumbnail height={60} width={400} rounded />
              <ShimmerThumbnail height={60} width={400} rounded />
              <ShimmerThumbnail height={60} width={400} rounded />
            </div>
          </>
        ) : 
        
        index < car_data.length ? 
        
        (
          <InputTypes data={car_data[index]}  index_of={index}     />
        ) : (
          <SearchCity />
        )}
        
        <Buttons index={index} setIndex={setIndex} size={car_data.length} />
      </main>
    </>
  );
};

const mapStateToProps = (state) => ({
  car_data: state.cardeailupdate.data,
  loading: state.cardeailupdate.loading,
  divindex: state.cardeailupdate.divindex,
  userinfo: state.cardeailupdate.userinfo,
  evaluation_id_: state.sellMyCar.evaluation_create.id,
});
const mapDispatchToProps = (dispatch) => ({

  carfeatureadd: (data) =>
    dispatch({
      type: ADD_CAR_FEATURES,
      payload: {
        type: ADD_CAR_FEATURES,
        method: POST,
        label: Labels.add_feaderes,
        hideOverlayLoader: true,
        data,
      },
    }),

    inp_chk_action: (data) =>
    dispatch({
      type: CHAKBOX_LIST,
      payload: {
        data,
      },
    }),

  getcardetail: (variant_id) =>
    dispatch({
      type: CAR_DETAIL_UPDATE,
      payload: {
        type: CAR_DETAIL_UPDATE,
        label: Labels.carDetail_Update,
        method: GET,
        hideOverlayLoader: true,
      },
    }),
});
export default connect(mapStateToProps, mapDispatchToProps)(CarUpdate);

function Presentation({ slides, index }) {
  const renderSlide = () => {
    return slides[index];
  };
  return <div className="presentation">{renderSlide()}</div>;
}
