import React, { useState } from "react";
import "./style.css";
import { ShimmerThumbnail } from "react-shimmer-effects";
import Labels from "../../services/apiLabels";
import { POST, GET } from "../../constant/apiMethods";
import { SHOW_INTEREST, MY_APPOINMENT } from "../../constant";
import Slider from "react-animated-slider";
import "react-animated-slider/build/horizontal.css";
import { connect, useSelector } from "react-redux";

import { Carousel } from "3d-react-carousal";
import { MdEmail } from "react-icons/md";
import { FaMapMarkerAlt } from "react-icons/fa";
import Carcard from "../../components/Car_Card/index";
import { useHistory } from "react-router-dom";
import { scrollTop } from "../../utils/window";

function Myappointment(props) {
  const { myappointment, mycardata, loading } = props;
  const history = useHistory();

  const getappointmt = () => {
    myappointment({
      // code: "acf54545",
      // email: "abc@gmail.com",

      code: postcode,
      email:  email,
    });
    
  };
  
  let slides = [
    <img src="https://picsum.photos/800/300/?random" alt="1" />,
    <img src="https://picsum.photos/800/301/?random" alt="2" />,
    <img src="https://picsum.photos/800/302/?random" alt="3" />,
    <img src="https://picsum.photos/800/303/?random" alt="4" />,
    <img src="https://picsum.photos/800/304/?random" alt="5" />,
  ];

  const [email, setemai] = useState("");

  const [postcode, setpostcod] = useState("");

  return (
    <div>
      {/* <div className="mainDiv1_ p-"> */}
        <div className="showInterest_headingCont">
          <div className="showInterest_heading">My Appointment</div>
          <div className="showInterest_description">
            Your interested car details here
          </div>
        </div>
      {/* </div> */}
      <div className="container d-flex justify-content-center flex-column align-items-md-center mt-5">
        <div className="mainDiv">
          <h3 className="text-center mt-3"> Please Enter Your Details</h3>

          <div className="inpdiva  ">
            <div className="inp1  py-2">
              <span className="ml-2">
                <MdEmail size={21} color="var(--primary)" />
              </span>

              <input
                className="ml-2 inptext"
           
                placeholder="example@gmail.com"
                onChange={(e) => setemai(e.target.value)}
              />
            </div>

            <div className="inp1 mt-2 py-2">
              <span className="ml-2">
                <FaMapMarkerAlt size={21} color="var(--primary)" />
              </span>

              <input
                className="ml-2 inptext"
                placeholder="enter your post code"
                onChange={(e) => setpostcod(e.target.value)}
             
              />
            </div>
          </div>

          <div className="inpdiva my-3">
            <button className="btn12" name="CTAButton" onClick={getappointmt}>
              Get Appointments
            </button>
          </div>
        </div>
      </div>

      {loading ? (
        <div className="d-flex justify-content-center align-content-center my-5">
          <div className="row mdiv1shimer my-5  ">
            <div className="col-lg-4 col-md- col-sm-4 col-xs-12  pt-3 pl-3 ">
              <div className="shimerpic">
                <ShimmerThumbnail rounded />
              </div>
            </div>
            <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12   pt-5 ">
              <ShimmerThumbnail height={30} width={200} rounded />
              <ShimmerThumbnail height={30} width={300} rounded />

              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <span>
                    <ShimmerThumbnail height={30} width={300} rounded />
                    <ShimmerThumbnail height={30} width={300} rounded />
                  </span>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <span>
                    <ShimmerThumbnail height={30} width={200} rounded />
                    <ShimmerThumbnail height={30} width={200} rounded />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : mycardata.length > 0 ? (
        mycardata.map(function (item, index) {
          

          return (
            <div className="Card_Div">
              <Carcard
                item={item}
                mycar={mycardata}
                onClick={() =>{
                  history.push({
                    pathname: "/myappointmentdetail",
                    state: { index },
                  })
                  scrollTop()
                }}
              />
            </div>
          );
        })
      ) : (
        <div></div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  userId: state.auth.id,
  data: state.myInterest.data,
  mycardata: state.myAppointment.data,
  loading: state.myAppointment.loading,
});
const mapDispatchToProps = (dispatch) => ({
  myappointment: (data) =>
    dispatch({
      type: MY_APPOINMENT,
      payload: {
        type: MY_APPOINMENT,

        label: Labels.myappointment,
        method: POST,
        data,
        hideOverlayLoader: true,
      },
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Myappointment);
