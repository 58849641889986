import React, { useState, useEffect } from "react";
import "./stlye.css";
import Labels from "../../services/apiLabels";
import { POST, GET } from "../../constant/apiMethods";
import { WINERS_CAR_USER_STATUS, CREATE_CONTACT_US } from "../../constant";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { ShimmerThumbnail, ShimmerCircularImage } from "react-shimmer-effects";
import { formateDate } from "../../utils/functions";
import { useContext } from "react";
import FeedbackContext from "../../context/cmscontext";
function ShipingForm({
  winer_car_status,
  winerCarStatus,
  winerCarStatusloading,
}) {
  const history = useHistory();
    const { feedback, isLoading } = useContext(FeedbackContext);
var order_booked_page = feedback.order_booked_page;
  const location = useLocation();

  const [show, setshow] = useState(true);

  const User_Id = JSON.parse(localStorage.getItem("WFAC_localstorage_auth"));
  // console.logUser_Id.id,"chk useect in winer")
  useEffect(() => {
    if (location.state) {
      winer_car_status({
        product: location.state.item.product_id,
        user_id: User_Id.id,
      });
    } else {
      history.push("/winer");
    }
  }, []);

  const arr = [
    { name: "gli" },
    { name: "xli" },
    { name: "car" },
    { name: "bus" },
  ];

  const cancel_car = () => {
    alert("your amout was detected");
  };

  const book_car = () => {
    history.push({
      pathname: "/shiping",
      state: { product_id: location.state.item.product_id, car_category:location.state.item.car_body_type },
    });
  };

  return (
    <>
      <div className="showInterest_headingCont ">
        <div className="showInterest_heading">
          {order_booked_page.Shiping_Form}
        </div>
        <div className="showInterest_description">
          {order_booked_page.get_in_touch}
        </div>
      </div>

      {winerCarStatusloading ? (
        <>
          <div className="container p-5">
            <section className="root">
              <figure>
                <div className="w-50  smim__">
                  <ShimmerThumbnail height={200} rounded />
                </div>

                <figcaption>
                  <div className="w-75 smim__">
                    <h4 className="shoping_h">
                      {" "}
                      <ShimmerThumbnail height={40} rounded />
                    </h4>
                  </div>
                  <div className="w-75 smim__">
                    <h4 className="shoping_h">
                      {" "}
                      <ShimmerThumbnail height={40} rounded />
                    </h4>
                  </div>
                </figcaption>
              </figure>
            </section>

            {/* ================================mobile====== */}
          </div>
        </>
      ) : // winerCarStatus.length < 0
      winerCarStatus.length == 0 ? (
        <div className="container p-5">
          <div className="shiping_book p-5">
            <img src={location.state.item.main_image} alt="" />
            <h4> {location.state.item.product_name}</h4>
            <h4>{order_booked_page.Book_Car_Shiping}</h4>
            <p>
              {order_booked_page.please_boook_car_shiping}
              {location.state.item.product_description}
            </p>

            <div className="row btn_row">
              <div className="col-sm-6 col-lg-3 ">
                <button className=" btn_contact mt-4 " onClick={book_car}>
                  {order_booked_page.boook_car_text}
                </button>
              </div>
              {/* <div className="col-sm-6 col-lg-3">
              <button className=" btn_contact mt-4 " onClick={cancel_car}>
                Cncel Car
              </button>
            </div> */}
            </div>
          </div>
        </div>
      ) : (
        <div className="container p-5">
          <section className="root">
            <figure>
              <img src={location.state.item.main_image} alt="" />
              <figcaption>
                <h4 className="shoping_h"> {winerCarStatus[0].product}</h4>
                <h6 className="shoping_h">
                  {order_booked_page.Order_Number_text}
                </h6>
                <h2 className="shoping_h">#{winerCarStatus[0].tracking} </h2>
              </figcaption>
            </figure>

            {winerCarStatus[0].orderstatus.length < 1 ? (
              <div className="order-track">
                <div className="order-track-step">
                  <div className="order-track-status">
                    <span className="order-track-status-dot" />

                    <span className="order-track-status-line" />
                  </div>
                  <div className="order-track-text">
                    <p className="order-track-text-stat">
                      {" "}
                      {order_booked_page.Your_Order_Booked_text}
                    </p>
                    <span className="order-track-text-sub"></span>
                  </div>
                </div>
              </div>
            ) : (
              <div className="order-track">
                {React.Children.toArray(
                  winerCarStatus[0].orderstatus.map((item) => {
                    return (
                      <div className="order-track-step">
                        <div className="order-track-status">
                          <span className="order-track-status-dot" />

                          <span className="order-track-status-line" />
                        </div>
                        <div className="order-track-text">
                          <p className="order-track-text-stat">
                            {" "}
                            {item.address}{" "}
                          </p>
                          <span className="order-track-text-sub">
                            {formateDate(item.date)}
                          </span>
                        </div>
                      </div>
                    );
                  })
                )}
              </div>
            )}
          </section>
        </div>
      )}
    </>
  );
}
const mapStateToProps = (state) => ({
  winerCarStatus: state.shiping.winerCarStatus,
  winerCarStatusloading: state.shiping.winerCarStatusloading,
});
const mapDispatchToProps = (dispatch) => ({
  winer_car_status: (data) =>
    dispatch({
      type: WINERS_CAR_USER_STATUS,
      payload: {
        type: WINERS_CAR_USER_STATUS,

        label: Labels.car_winer_status,
        method: POST,
        data,
        hideOverlayLoader: true,
      },
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShipingForm);
