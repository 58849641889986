// import axios from "axios";
// import config from "../config";

// export const Api = axios.create({
//   baseURL: config.baseUrl,
//   timeout: 100000,
// });

// export const API_ENDPOINT = (label) => {
//   return `${config.baseUrl}${label}`;
// };
// // post
// export function* fetchData(e) {
//   const { label, data, method, header, contentType } = e.payload;
//   let parameters;
//   if(contentType){
//     parameters = {
//       method,
//       body: data,
//     };
//   }else{
//     parameters = {
//       method,
//       headers: {
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify(data),
//     };
//   }
  
//   return yield fetch(API_ENDPOINT(label), parameters)
//     .then((response) => {
//       return response.json();
//     })
//     .then((json) => {
//       return json;
//     });
// }

// // export function* post(e) {
// //   const { label, data, header } = e.payload;

// //   const response = yield Api.post(label, data)
// //     .then((response) => {
// //       return response;
// //     })
// //     // .then((json) => {
// //     //   return json;
// //     // })
// //     .catch((error) => error);
// //   
// //   return response;
// // }


import axios from "axios";
import config from "../config";

export const Api = axios.create({
  baseURL: config.baseUrl,
  timeout: 100000,
});

export const API_ENDPOINT = (label) => {
  return `${config.baseUrl}${label}`;
};
// post
export function* fetchData(e) {
  const { label, data, method, header, contentType } = e.payload;
  const headers = new Headers(header);
  if (header) {
    Object.keys(header).forEach((key) => {
      headers.append(key, header[key]);
    });
  }
  let parameters;
  if(contentType){
    parameters = {
      method,
      body: data,
      headers: headers,
    };
  }else{
    headers.append("Content-Type", "application/json");
    parameters = {
      method,
      headers,
      body: JSON.stringify(data),
    };
  }
  console.log([...headers.entries()], "headers after appending");
  return yield fetch(API_ENDPOINT(label), parameters)
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      return json;
    });
}

// export function* post(e) {
//   const { label, data, header } = e.payload;

//   const response = yield Api.post(label, data)
//     .then((response) => {
//       return response;
//     })
//     // .then((json) => {
//     //   return json;
//     // })
//     .catch((error) => error);
//   
//   return response;
// }