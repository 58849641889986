// import React, { Fragment, useState, useEffect } from "react";
// import { connect } from "react-redux";
// import { FeedbackProvider } from "./context/cmscontext";
// import "animate.css";
// import {
//   // BrowserRouter as Router,
//   Switch,

//   Route,
// } from "react-router-dom";
// import "./App.css";
// import { useLocation } from 'react-router-dom'
// import { AuthRoute, PrivateRoute } from "./router/index";
// import CurveNavbar from "./components/Navbar/CurveNavbar";

// import * as Pages from "./pages/index";
// import Toaster from "./components/Toaster";
// import OverLayLoader from "./components/OverLayLoader";

// import Footer from "./components/Footer";
// import FooterMini from "./components/Footer Social";
// import { get } from "./services/localStorage";
// import { LOGIN, LOG_OUT } from "./constant";
// // import 'semantic-ui-css/semantic.min.css'
// //
// function App(props) {

//   // =================================this is vender side project ============================
//   const { auth, login, logOut } = props;
//   const localStorage_auth = () => {
//     const localStorage_auth_data = get({ key: "auth" });
//     return localStorage_auth_data
//       ? localStorage_auth_data.token
//         ? localStorage_auth_data
//         : {}
//       : {};
//   };
//   // console.warn = () => {};
//   // console.log = console.warn = console.error = () => {};
//   useEffect(() => {
//     const auth_data = localStorage_auth();
//     if (!auth.token && auth_data.token) {
//       login(auth_data);
//     } else if (auth.token && !auth_data.token) {
//       logOut();
//     }
//   }, [auth]);
//   //
//   const [navbarDimensions, setnavbarDimensions] = useState({
//     width: 0,
//     height: 72,
//   });

//   const location = useLocation();
//   // // console.log"Location ",location)
//   useEffect(() => {
//     window.scrollTo({
//       top: 0,
//       left: 0,
//       behavior: "instant",
//     });
//   }, [location]);

//   return (
//     <>
//       <FeedbackProvider>
//         {location.pathname === "/carreport" ||
//         location.pathname === "/contactusmobile" ||
//         location.pathname === "/termsmobile" ||
//         location.pathname === "/privacypolicymobile" ||
//         location.pathname === "/faqsmobile" ||
//         location.pathname === "/requridocumentsmobile" ||
//         location.pathname === "/aboutusmobile" ||
//         location.pathname.includes("/carreportmobile") || location.pathname.includes("/report") ? null : (
//           <div className="fixed-top">
//             <CurveNavbar
//               setnavbarDimensions={setnavbarDimensions}
//               auth={auth}
//             />
//           </div>
//         )}

//         <div
//           style={{
//             maxWidth: navbarDimensions.width,
//             height: navbarDimensions.height,
//           }}
//         />
//         <Switch>
//           <Route exact path="/evaluation">
//             <Pages.Evaluation navbarDimensions={navbarDimensions} />
//           </Route>
//           <Route exact path="/contactus">
//             <Pages.ContactUs navbarDimensions={navbarDimensions} />
//           </Route>
//           <Route exact path="/contactusmobile">
//             <Pages.ContactUs navbarDimensions={navbarDimensions} />
//           </Route>
//           <Route exact path="/privacypolicy">
//             <Pages.PrivacyPolicy navbarDimensions={navbarDimensions} />
//           </Route>
//           <Route exact path="/privacypolicymobile">
//             <Pages.PrivacyPolicy navbarDimensions={navbarDimensions} />
//           </Route>

//           <Route exact path="/faqs">
//             <Pages.FAQs navbarDimensions={navbarDimensions} />
//           </Route>
//           <Route exact path="/faqsmobile">
//             <Pages.FAQs navbarDimensions={navbarDimensions} />
//           </Route>
//           <Route exact path="/carreportmobile/:Id">
//             <Pages.CarReportMobile navbarDimensions={navbarDimensions} />
//           </Route>

//           <Route exact path="/requridocuments">
//             <Pages.Documents navbarDimensions={navbarDimensions} />
//           </Route>
//           <Route exact path="/requridocumentsmobile">
//             <Pages.Documents navbarDimensions={navbarDimensions} />
//           </Route>
//           <Route exact path="/terms">
//             <Pages.Terms navbarDimensions={navbarDimensions} />
//           </Route>
//           <Route exact path="/termsmobile">
//             <Pages.Terms navbarDimensions={navbarDimensions} />
//           </Route>

//           <Route exact path="/aboutus">
//             <Pages.AboutUs navbarDimensions={navbarDimensions} />
//           </Route>
//           <Route exact path="/aboutusmobile">
//             <Pages.AboutUs navbarDimensions={navbarDimensions} />
//           </Route>
//           <Route exact path="/forgetpassword">
//             <Pages.Forget navbarDimensions={navbarDimensions} />
//           </Route>
//           <Route exact path="/shiping">
//             <Pages.Shiping navbarDimensions={navbarDimensions} />
//           </Route>
//           <Route exact path="/carupdate">
//             <Pages.CarUpdate navbarDimensions={navbarDimensions} />
//           </Route>
//           <Route exact path="/carsteps">
//             <Pages.CarSteps navbarDimensions={navbarDimensions} />
//           </Route>
//           <Route exact path="/bookappointment">
//             <Pages.EvaluationNew navbarDimensions={navbarDimensions} />
//           </Route>
//           <Route exact path="/carreport">
//             <Pages.CarReport navbarDimensions={navbarDimensions} />
//           </Route>

//           <Route exact path={`/resetpassword/:id/:token`}>
//             <Pages.ChagePassword navbarDimensions={navbarDimensions} />
//           </Route>

//           <Route exact path="/cardamage">
//             <Pages.CarDamage navbarDimensions={navbarDimensions} />
//           </Route>
//           {/* <Route exact path="/myappointment">
//           <Pages.MyAppointment navbarDimensions={navbarDimensions} />
//         </Route> */}
//           <Route exact path="/myappointmentupdate">
//             <Pages.Appoinmentupdate navbarDimensions={navbarDimensions} />
//           </Route>

//           <Route exact path="/myappointmentdetail">
//             <Pages.MyAppointmentDetail navbarDimensions={navbarDimensions} />
//           </Route>
//           <Route exact path="/cardetail">
//             <Pages.CarDetail navbarDimensions={navbarDimensions} />
//           </Route>
//           <PrivateRoute path="/dashbord" auth={auth}>
//             <Pages.Dashboard navbarDimensions={navbarDimensions} />
//           </PrivateRoute>
//           <PrivateRoute exact path="/pdf/:id/report" auth={auth}>
//             <Pages.PDF navbarDimensions={navbarDimensions} />
//           </PrivateRoute>
//           <PrivateRoute exact path="/showInterest" auth={auth}>
//             <Pages.ShowInterest navbarDimensions={navbarDimensions} />
//           </PrivateRoute>

//           <PrivateRoute exact path="/shipingform" auth={auth}>
//             <Pages.ShipingForm navbarDimensions={navbarDimensions} />
//           </PrivateRoute>
//           <PrivateRoute exact path="/winer" auth={auth}>
//             <Pages.Winers navbarDimensions={navbarDimensions} />
//           </PrivateRoute>

//           <PrivateRoute exact path="/find-car-details" auth={auth}>
//             <Pages.ShowInterestDetails navbarDimensions={navbarDimensions} />
//           </PrivateRoute>
//           <PrivateRoute exact path="/myInterest" auth={auth}>
//             <Pages.MyInterest navbarDimensions={navbarDimensions} />
//           </PrivateRoute>
//           <AuthRoute exact path="/login" auth={auth}>
//             <Pages.Login navbarDimensions={navbarDimensions} />
//           </AuthRoute>
//           <AuthRoute exact path="/signup" auth={auth}>
//             <Pages.Signup navbarDimensions={navbarDimensions} />
//           </AuthRoute>
//           <PrivateRoute exact path="/auction" auth={auth}>
//             {/* <Pages.ComingSoon navbarDimensions={navbarDimensions || {}} /> */}
//             <Pages.AuctionDashboard navbarDimensions={navbarDimensions || {}} />
//           </PrivateRoute>
//           <PrivateRoute exact path="/buynow" auth={auth}>
//             <Pages.Buynow />
//             {/* <Pages.ComingSoon navbarDimensions={navbarDimensions || {}} /> */}
//           </PrivateRoute>
//           {/* <Route exact path="/product" children={Pages.ProductMain} /> */}
//           <Route exact path="/product">
//             <Pages.ProductMain navbarDimensions={navbarDimensions || {}} />
//           </Route>
//           <Route exact path="/productbuy">
//             <Pages.ProductMainBuy navbarDimensions={navbarDimensions || {}} />
//           </Route>

//           <Route exact path="/">
//             <Pages.Landingmain navbarDimensions={navbarDimensions} />
//           </Route>
//           {/* <Redirect from="/" to="/" /> */}
//         </Switch>
//         <Toaster />
//         <OverLayLoader />

//         {location.pathname === "/carreport" ||
//         location.pathname === "/contactusmobile" ||
//         location.pathname === "/termsmobile" ||
//         location.pathname === "/privacypolicymobile" ||
//         location.pathname === "/faqsmobile" ||
//         location.pathname === "/requridocumentsmobile" ||
//         location.pathname === "/aboutusmobile" ||
//         location.pathname.includes("/dashbord")  ||
        
//         location.pathname.includes("/carreportmobile") || location.pathname.includes("/report") ? null : (
//           <footer
//             style={{
//               backgroundColor: "#F2F4F5",
//               // borderTop
//               borderTopWidth: "1px",
//               borderTopStyle: "solid",
//               borderTopColor: "black",
//               position:"relative"
//             }}
//           >
//             <Footer />
//             <FooterMini></FooterMini>
//           </footer>
//         )}
//       </FeedbackProvider>
//     </>
//   );
// }
// const mapStateToProps = (state) => ({
//   auth: state.auth,
// });
// const mapDispatchToProps = (dispatch) => ({
//   login: (data) =>
//     dispatch({
//       type: `${LOGIN}_SUCCESS`,
//       payload: { ...data },
//     }),
//   logOut: (data) =>
//     dispatch({
//       type: LOG_OUT,
//     }),
// });

// export default connect(mapStateToProps, mapDispatchToProps)(App);


import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import { FeedbackProvider } from "./context/cmscontext";
import "animate.css";
import {
  // BrowserRouter as Router,
  Switch,

  Route,
} from "react-router-dom";
import "./App.css";
import { useLocation } from 'react-router-dom'
import { AuthRoute, PrivateRoute } from "./router/index";
import CurveNavbar from "./components/Navbar/CurveNavbar";

import * as Pages from "./pages/index";
import Toaster from "./components/Toaster";
import OverLayLoader from "./components/OverLayLoader";

import Footer from "./components/Footer";
import FooterMini from "./components/Footer Social";
import { get } from "./services/localStorage";
import { LOGIN, LOG_OUT } from "./constant";
// import 'semantic-ui-css/semantic.min.css'
//
function App(props) {

  // =================================this is vender side project ============================
  const { auth, login, logOut } = props;
  const localStorage_auth = () => {
    const localStorage_auth_data = get({ key: "auth" });
    return localStorage_auth_data
      ? localStorage_auth_data.token
        ? localStorage_auth_data
        : {}
      : {};
  };
  // console.warn = () => {};
  // console.log = console.warn = console.error = () => {};
  useEffect(() => {
    const auth_data = localStorage_auth();
    if (!auth.token && auth_data.token) {
      login(auth_data);
    } else if (auth.token && !auth_data.token) {
      logOut();
    }
  }, [auth]);
  //
  const [navbarDimensions, setnavbarDimensions] = useState({
    width: 0,
    height: 72,
  });

  const location = useLocation();
  // // console.log"Location ",location)
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  }, [location]);

  return (
    <>
      <FeedbackProvider>
        {location.pathname === "/carreport" ||
        location.pathname === "/contactusmobile" ||
        location.pathname === "/termsmobile" ||
        location.pathname === "/privacypolicymobile" ||
        location.pathname === "/faqsmobile" ||
        location.pathname === "/requridocumentsmobile" ||
        location.pathname === "/aboutusmobile" ||
        location.pathname.includes("/carreportmobile") || location.pathname.includes("/report") ? null : (
          <div className="fixed-top">
            <CurveNavbar
              setnavbarDimensions={setnavbarDimensions}
              auth={auth}
            />
          </div>
        )}

        <div
          style={{
            maxWidth: navbarDimensions.width,
            height: navbarDimensions.height,
          }}
        />
        <Switch>
          <Route exact path="/evaluation">
            <Pages.Evaluation navbarDimensions={navbarDimensions} />
          </Route>
          <Route exact path="/contactus">
            <Pages.ContactUs navbarDimensions={navbarDimensions} />
          </Route>
          <Route exact path="/contactusmobile">
            <Pages.ContactUs navbarDimensions={navbarDimensions} />
          </Route>
          <Route exact path="/privacypolicy">
            <Pages.PrivacyPolicy navbarDimensions={navbarDimensions} />
          </Route>
          <Route exact path="/privacypolicymobile">
            <Pages.PrivacyPolicy navbarDimensions={navbarDimensions} />
          </Route>

          <Route exact path="/faqs">
            <Pages.FAQs navbarDimensions={navbarDimensions} />
          </Route>
          <Route exact path="/faqsmobile">
            <Pages.FAQs navbarDimensions={navbarDimensions} />
          </Route>
          <Route exact path="/carreportmobile/:Id">
            <Pages.CarReportMobile navbarDimensions={navbarDimensions} />
          </Route>

          <Route exact path="/requridocuments">
            <Pages.Documents navbarDimensions={navbarDimensions} />
          </Route>
          <Route exact path="/requridocumentsmobile">
            <Pages.Documents navbarDimensions={navbarDimensions} />
          </Route>
          <Route exact path="/terms">
            <Pages.Terms navbarDimensions={navbarDimensions} />
          </Route>
          <Route exact path="/termsmobile">
            <Pages.Terms navbarDimensions={navbarDimensions} />
          </Route>

          <Route exact path="/aboutus">
            <Pages.AboutUs navbarDimensions={navbarDimensions} />
          </Route>
          <Route exact path="/aboutusmobile">
            <Pages.AboutUs navbarDimensions={navbarDimensions} />
          </Route>
          <Route exact path="/forgetpassword">
            <Pages.Forget navbarDimensions={navbarDimensions} />
          </Route>
          <Route exact path="/shiping">
            <Pages.Shiping navbarDimensions={navbarDimensions} />
          </Route>
          <Route exact path="/carupdate">
            <Pages.CarUpdate navbarDimensions={navbarDimensions} />
          </Route>
          <Route exact path="/carsteps">
            <Pages.CarSteps navbarDimensions={navbarDimensions} />
          </Route>
          <Route exact path="/bookappointment">
            <Pages.EvaluationNew navbarDimensions={navbarDimensions} />
          </Route>
          <Route exact path="/carreport">
            <Pages.CarReport navbarDimensions={navbarDimensions} />
          </Route>

          <Route exact path={`/resetpassword/:id/:token`}>
            <Pages.ChagePassword navbarDimensions={navbarDimensions} />
          </Route>

          <Route exact path="/cardamage">
            <Pages.CarDamage navbarDimensions={navbarDimensions} />
          </Route>
          {/* <Route exact path="/myappointment">
          <Pages.MyAppointment navbarDimensions={navbarDimensions} />
        </Route> */}
          <Route exact path="/myappointmentupdate">
            <Pages.Appoinmentupdate navbarDimensions={navbarDimensions} />
          </Route>

          <Route exact path="/myappointmentdetail">
            <Pages.MyAppointmentDetail navbarDimensions={navbarDimensions} />
          </Route>
          <Route exact path="/cardetail">
            <Pages.CarDetail navbarDimensions={navbarDimensions} />
          </Route>
          <PrivateRoute path="/dashbord" auth={auth}>
            <Pages.Dashboard navbarDimensions={navbarDimensions} />
          </PrivateRoute>
          <PrivateRoute exact path="/pdf/:id/report" auth={auth}>
            <Pages.PDF navbarDimensions={navbarDimensions} />
          </PrivateRoute>
          <PrivateRoute exact path="/showInterest" auth={auth}>
            <Pages.ShowInterest navbarDimensions={navbarDimensions} />
          </PrivateRoute>

          <PrivateRoute exact path="/shipingform" auth={auth}>
            <Pages.ShipingForm navbarDimensions={navbarDimensions} />
          </PrivateRoute>
          <PrivateRoute exact path="/winer" auth={auth}>
            <Pages.Winers navbarDimensions={navbarDimensions} />
          </PrivateRoute>

          <PrivateRoute exact path="/find-car-details" auth={auth}>
            <Pages.ShowInterestDetails navbarDimensions={navbarDimensions} />
          </PrivateRoute>
          <PrivateRoute exact path="/myInterest" auth={auth}>
            <Pages.MyInterest navbarDimensions={navbarDimensions} />
          </PrivateRoute>
          <AuthRoute exact path="/login" auth={auth}>
            <Pages.Login navbarDimensions={navbarDimensions} />
          </AuthRoute>
          <AuthRoute exact path="/signup" auth={auth}>
            <Pages.Signup navbarDimensions={navbarDimensions} />
          </AuthRoute>
          <PrivateRoute exact path="/auction" auth={auth}>
            {/* <Pages.ComingSoon navbarDimensions={navbarDimensions || {}} /> */}
            <Pages.AuctionDashboard navbarDimensions={navbarDimensions || {}} />
          </PrivateRoute>
          <PrivateRoute exact path="/buynow" auth={auth}>
            <Pages.Buynow navbarDimensions={navbarDimensions}/>
            {/* <Pages.ComingSoon navbarDimensions={navbarDimensions || {}} /> */}
          </PrivateRoute>
          {/* <Route exact path="/product" children={Pages.ProductMain} /> */}
          <Route exact path="/product">
            <Pages.ProductMain navbarDimensions={navbarDimensions || {}} />
          </Route>
          <Route exact path="/productbuy">
            <Pages.ProductMainBuy navbarDimensions={navbarDimensions || {}} />
          </Route>

          <Route exact path="/">
            <Pages.Landingmain navbarDimensions={navbarDimensions} />
          </Route>
          {/* <Redirect from="/" to="/" /> */}
        </Switch>
        <Toaster />
        <OverLayLoader />

        {location.pathname === "/carreport" ||
        location.pathname === "/contactusmobile" ||
        location.pathname === "/termsmobile" ||
        location.pathname === "/privacypolicymobile" ||
        location.pathname === "/faqsmobile" ||
        location.pathname === "/requridocumentsmobile" ||
        location.pathname === "/aboutusmobile" ||
        location.pathname.includes("/dashbord")  ||
        
        location.pathname.includes("/carreportmobile") || location.pathname.includes("/report") ? null : (
          <footer
            style={{
              backgroundColor: "#F2F4F5",
              // borderTop
              borderTopWidth: "1px",
              borderTopStyle: "solid",
              borderTopColor: "black",
              position:"relative"
            }}
          >
            <Footer />
            <FooterMini></FooterMini>
          </footer>
        )}
      </FeedbackProvider>
    </>
  );
}
const mapStateToProps = (state) => ({
  auth: state.auth,
});
const mapDispatchToProps = (dispatch) => ({
  login: (data) =>
    dispatch({
      type: `${LOGIN}_SUCCESS`,
      payload: { ...data },
    }),
  logOut: (data) =>
    dispatch({
      type: LOG_OUT,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);