import{ React,useState} from 'react'
import { connect } from 'react-redux'
import "./style.css"
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";
import { useContext } from "react";
import FeedbackContext from "../../context/cmscontext";
import Labels from "../../services/apiLabels";
import { POST, GET,PUT } from "../../constant/apiMethods";
import { SHOW_INTEREST, RESET_PASSWORD } from "../../constant";
// import { connect } from "react-redux";
export const ChagePassword = ({passworedReset_New}) => {
       const { feedback, isLoading } = useContext(FeedbackContext);
var change_password_page = feedback.change_password_page;
  let { id } = useParams();
  let { token } = useParams();
  // console.log"id ",id,"toiken ",token)

    document.body.style.background = "#F2F4F5"

const [email, setemail] = useState("")

const reSetPassword=()=>{
  passworedReset_New({
    uidb64:id,
    token:token,
    password:email,
  })


}


  return (
    <>
      <>
        <div className="containerfluid contact_wrap p-5">
          <h2 className="text-center mt-5">
            {change_password_page.change_pass_text}
          </h2>
          <div className="row text-center d-flex justify-content-center ">
            <div className="col-lg-4 col-md-5 col-sm-12 ">
              <div className="inp_wrap my-3">
                <input
                  value={email}
                  onChange={(e) => {
                    setemail(e.target.value);
                  }}
                  className="inp_contact"
                  placeholder={change_password_page.new_pass_placeholder}
                ></input>
              </div>

              <button className=" btn_contact my-3" onClick={reSetPassword}>
                {change_password_page.btn_change_pass}
              </button>
            </div>
          </div>
        </div>
      </>
    </>
  );
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
 passworedReset_New: (data) =>
    dispatch({
      type: RESET_PASSWORD,
      payload: {
        type: RESET_PASSWORD,

        label: Labels.password_Reset_New,
        method: PUT,
        data,
        hideOverlayLoader: true,
      },
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChagePassword)