import React, { useEffect } from "react";
import { AiTwotoneEye } from "react-icons/ai";
import { BsDot } from "react-icons/bs";
// import { GoLocation} from "react-icons/go";
import { Card } from "react-bootstrap";
import { GET_WISH_LIST_AUCTION, DELETE_WISH_LIST_ITEM } from "../../constant";
import { GET, POST } from "../../constant/apiMethods";
import { connect } from "react-redux";
import Labels from "../../services/apiLabels";
import "./watch.css";
import { ShimmerTitle } from "react-shimmer-effects";
import { useLocation } from "react-router-dom";
import { API_ENDPOINT } from "../../services/api";
import { MdDeleteForever } from "react-icons/md";
function Watch({
  wishList,
  getWishList_ac,
  deleteItem,
  user_id,
  loadingGetWishList,
}) {
  const location = useLocation();
  const page = location.pathname;
  useEffect(() => {
    if (location.pathname === "/auction") {
      getWishList_ac(user_id, "type=auction");
    }
    if (location.pathname === "/buynow") {
      getWishList_ac(user_id, "type=buynow");
    }
  }, []);
  const deleteProduct = async (label, id, index) => {
    const paylaod = {
      user: user_id,
      product: id,
      product_index: index,
    };
    const parameters = {
      method: POST,
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(paylaod),
    };
    return await fetch(API_ENDPOINT(label), parameters)
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        return json;
      });
  };
  const handelDelete = (id, index) => {
    const data = {
      id: id,
      page: page,
    };
    deleteProduct(Labels.close_wish_list, id, index);
    deleteItem(data);
  };
  return (
    <>
      {loadingGetWishList ? (
        <>
          <ShimmerTitle className="w-100" line={1} gap={10} variant="primary" />
          <ShimmerTitle className="w-100" line={1} gap={10} variant="primary" />
          <ShimmerTitle className="w-100" line={1} gap={10} variant="primary" />
        </>
      ) : wishList.length === 0 ? (
        <>
          <h5>No Data Found</h5>
        </>
      ) : (
        <div>
          {React.Children.toArray(
            wishList.map((Products, index) => (
              <div className="watchbody1">
                <Card>
                  <div className="watcdisplaylayout">
                    {/* -------------image plus icons----------- */}
                    <div className="col-sm-4.col-md-4.col-lg-4.col-xl-4 watcfirst">
                      <img src={Products.product_image} className="" />
                      <div className="watciconcontainer">
                        <div className="watciconi">
                          <AiTwotoneEye />
                        </div>
                      </div>
                    </div>
                    {/* --------------product detail----------- */}
                    <div className="col-sm-4.col-md-4.col-lg-4.col-xl-4 watcsecond">
                      {/* -----detail --------- */}
                      <div className="watcseconddetail elipse">
                        <span className="watctexttitle">
                          {Products.product_name}
                        </span>
                        <span className="watctext">
                          {" "}
                          <BsDot />
                          {Products.product_category}{" "}
                        </span>
                        <span className="watctext ">
                          {" "}
                          <BsDot />
                          {Products.product_description}
                        </span>
                        <span className="watctext">
                          <BsDot />
                          {Products.product_price}
                        </span>
                      </div>
                    </div>
                  </div>
                  <MdDeleteForever
                    onClick={() => handelDelete(Products.product_id, index)}
                    className="mr-2 cursor-pointer"
                    size={20}
                  />
                </Card>
              </div>
            ))
          )}
        </div>
      )}
    </>
  );
}
const mapStateToProps = (state) => ({
  wishList: state.product.wishList,
  loadingGetWishList: state.product.loadingGetWishList,
  user_id: state.auth.id,
  filtersQuery: state.filters.queryString_,
});
const mapDispatchToProps = (dispatch) => ({
  // get product list
  getWishList_ac: (data, type) =>
    dispatch({
      type: GET_WISH_LIST_AUCTION,
      payload: {
        type: GET_WISH_LIST_AUCTION,
        method: GET,

        label: `${Labels.get_wish_list}${data}/?${type}`,

        hideOverlayLoader: true,
      },
    }),
  deleteItem: (data) =>
    dispatch({
      type: DELETE_WISH_LIST_ITEM,
      payload: {
        type: DELETE_WISH_LIST_ITEM,
        data,
      },
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Watch);
