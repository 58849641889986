import _ from 'lodash'
import faker from 'faker'

import React, { Component } from 'react'
import { Search, Grid, Header, Segment } from 'semantic-ui-react'
import "./style.css";
import TextField from '@mui/material/TextField';
import 'semantic-ui-css/semantic.min.css'
import 'semantic-ui-css/semantic.css'
import { AiOutlineSearch } from "react-icons/ai";
// import   '../../node_modules/semantic-ui-css/components/segment.css';
// import   '../../node_modules/semantic-ui-css/components/icons.css';
const source = _.times(5, () => ({
  title: faker.company.companyName(),
  
}))

const initialState = { isLoading: false, results: [], value: '' }

export default class SearchExampleStandard extends Component  {
  state = initialState


  

  handleResultSelect = (e, { result }) => this.setState({ value: result.title })

  handleSearchChange = (e, { value }) => {
    this.setState({ isLoading: true, value })

    setTimeout(() => {
      if (this.state.value.length < 1) return this.setState(initialState)

      const re = new RegExp(_.escapeRegExp(this.state.value), 'i')
      const isMatch = (result) => re.test(result.title)

      this.setState({
        isLoading: false,
        results: _.filter(source, isMatch),
      })
    }, 300)
  }

  render() {
    const { isLoading, value, results } = this.state



    // console.log"props",this.props.cites)

const citylist= this.props.cites












    return (
  <>
  
  <div className='container p-5 inp_main_wrapp'>
    <div className='row  qwqw'>
      {/* <AiOutlineSearch className='' size={22}/> */}
      <div className='col-sm-12 col-lg-4 col-md-6 inp_col_2'>
        <div>
        <AiOutlineSearch className='' size={22}/>
        </div>
        <div>
        <Search
          input={{ icon: '', iconPosition: 'left' }}
          loading={isLoading}
          onResultSelect={this.handleResultSelect}
          onSearchChange={_.debounce(this.handleSearchChange, 500, {
            leading: true,
          })}
          results={results}
          value={value}
        />
        </div>
   
      </div>
    
    </div>




  </div>
  
  </>
  
   
    )
  }
}


