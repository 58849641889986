import * as storage from "../services/localStorage";
import { PROFILE, CHANGE_PASSWORD, GET_BIDDING_AUCTION} from "../constant/index";

const INITIAL_STATE = {
  data: {},
  auction:{}
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case `${PROFILE}_REQUEST`:
      return {
        ...state,
        loging: true,
      };
    case `${PROFILE}_SUCCESS`:
      return {
        ...state,
        data: action.payload,
        loging: false,
        isLogedin: true,
      };

    case `${PROFILE}_FAIL`:
      return {
        ...state,
        loging: false,
      };
    
      case `${CHANGE_PASSWORD}_REQUEST`:
        return {
          ...state,
          loging: true,
        };
      case `${CHANGE_PASSWORD}_SUCCESS`:
        return {
          ...state,
          data: action.payload,
          loging: false,
          isLogedin: true,
        };
  
      case `${CHANGE_PASSWORD}_FAIL`:
        return {
          ...state,
          loging: false,
        };

        case `${GET_BIDDING_AUCTION}_REQUEST`:
          return {
            ...state,
            loging: true,
          };
        case `${GET_BIDDING_AUCTION}_SUCCESS`:
          console.log(action.payload)
          return {
            ...state,
            auction: action.payload,
            loging: false,
            isLogedin: true,
          };
    
        case `${GET_BIDDING_AUCTION}_FAIL`:
          return {
            ...state,
            loging: false,
          };
  }
  return state;
};