// import React from 'react'
// import Tab from "../tabs"
// import Watch from "../Auction Won/Watchlist";

// function AddWatchlist() {
//   return (
//     <>
//                 <div className="py-6 px-5 bg-white bg-opacity-75">
//                   <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
//                   <h1 className="text-2xl font-semibold text-gray-900">Add Watchlist</h1>
//                            </div>
//                   <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
//                     <div className="py-4">
//                       <div className="h-96 ">
//                       No Data Found
//                       </div>
//                     </div>
//                   </div>
//                 </div>

//     </>
//   )
// }

// export default AddWatchlist



import React from "react";
import Tab from "../tabs";
import Watch from "../Auction Won/Watchlist";
import Table from "../Table";

const jsonData = [
  {
    id: 1,
    name: "John",
    brand_name: "Brand",
    model_name: "Model",
    fuel_type: "Fuel",
    price: "$10,000",
    image_method: "",
    allow_delete: false,
    hideEdit: true,
    hideCamera: true,
    inspect_id: "12345",
  },
  {
    id: 2,
    name: "Jane",
    brand_name: "Brand",
    model_name: "Model",
    fuel_type: "Fuel",
    price: "$20,000",
    image_method: "",
    allow_delete: false,
    hideEdit: true,
    hideCamera: true,
    inspect_id: "54321",
  },
  {
    id: 3,
    name: "Doe",
    brand_name: "Brand",
    model_name: "Model",
    fuel_type: "Fuel",
    price: "$15,000",
    image_method: "",
    allow_delete: false,
    hideEdit: true,
    hideCamera: true,
    inspect_id: "67890",
  },
];

const columns = ["Name", "Vehicle", "Price", "Image", "Actions"];

function AddWatchlist() {
  return (
    <>
      <style jsx="true">{`
        .table-div-background {
          display: flex;
          flex-direction: column;
          height: 68vh;
        }
      `}</style>

      <div className="table-div-background">
        <div className="my-10 overflow-y-auto container-fluid">
          <Table myVehiclesList={jsonData} columns={columns} />
        </div>
      </div>
    </>
  );
}

export default AddWatchlist;