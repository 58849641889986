import React, {
  useEffect,
  useState,
  useLayoutEffect,
  useCallback,
  useContext,
} from "react";
import MontserratFont from "../../assets/fonts/Montserrat-Regular.ttf";
import MontserratFontBold from "../../assets/fonts/Montserrat-Bold.ttf";
import MontserratFontSemiBold from "../../assets/fonts/Montserrat-SemiBoldItalic.ttf";
import Html from "react-pdf-html";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Image,
  Font,
} from "@react-pdf/renderer";
import { useParams } from "react-router-dom";
import FeedbackContext from "../../context/cmscontext";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { CAR_REPORT_DETAIL, CAR_REPORT } from "../../constant";
import { POST, GET } from "../../constant/apiMethods";
import Labels from "../../services/apiLabels";
export const Report = ({
  condition,
  car_report_detail_data_new,
  car_report_data,
  car_report_detail_data,
  winerListloading,
  car_report_detail_call,
  car_report_call,
  loading,
}) => {
  const { feedback, isLoading } = useContext(FeedbackContext);
  const [load, setLoad] = useState(true);
  const [available, setavailable] = useState(true);
  const location = useLocation();
  let { id } = useParams();
  useEffect(() => {
    // console.log(car_report_data , car_report_detail_data)
    if (
      car_report_data &&
      car_report_data?.length > 0 &&
      car_report_detail_data &&
      car_report_detail_data?.length > 0
    ) {
      setavailable(true);
      setLoad(false);
    }
    if (
      car_report_data &&
      car_report_data?.length < 1 &&
      car_report_detail_data &&
      car_report_detail_data?.length < 1
    ) {
      setavailable(false);
      setLoad(false);
    }
  }, [car_report_data, car_report_detail_data]);
  useEffect(() => {
    // window.location.reload();
    // refreshPage()
    // console.log"useEffectuseEffectuseEffect",location.state.product_code )
    if (id) {
      car_report_call({
        // id:5
        id: id,
      });
      car_report_detail_call({
        // id:5
        id: id,
      });
    }
  }, [id]);
  console.log(car_report_detail_data_new);
  let html = "";
  let html2 = "";
  let html3 = "";
  if (load == false) {
    var car_report_page = feedback.car_report_page;
    // console.log(car_report_detail_data);
    let points = "";
    car_report_data?.map((item) => {
      points += `<p style="position:absolute; top:${item?.top}; left:${item?.left}; color:gray; font-weight: bold;">${item?.symbol}</p>\n`;
    });

    let table_data = "";
    car_report_data?.map((item) => {
      // console.log(item)
      const data = `<div class="vehicle-photo-inner">
                    <a href=${item?.photo_url} target="_blank">
                    <img src=${item?.photo_url} class="vehicle-photo" />
                    </a>
                    <div>
                    <div class="vehicle-detail-container">
                    <div class="vehicle-detail bold">${item?.symbol}:</div>
                    <div class="vehicle-detail">${item?.title}</div>
                    </div>
                    
                    </div>
                    </div>`;
      table_data += `${data}\n`;
    });
    let bar_data = "";
    car_report_detail_data?.map((item) => {
      const data = `<div>
                    <div class="nam_wrap">
                    <p class="bar-title">${item?.title}</p><span class="bar-title">${item?.inspect_count}%</span>
                    </div>
                    <div class="progress">
                    <div role="progressbar" class="progress-bar" aria-valuenow="${item?.inspect_count}" aria-valuemin="0" aria-valuemax="100" style="width: ${item?.inspect_count}%;">
                    </div>
                    </div>
                    </div>`;
      bar_data += `${data}\n`;
    });
    let detail_tabel_row_data = "";
    let detail_tabel_data = "";
    let detail_tabel_images = "";
    car_report_detail_data?.map((item) => {
      detail_tabel_row_data = "";
      item?.inspection_title.map((item) => {
        item?.selected_inspection_question.map((item) => {
          detail_tabel_images = "";
          item?.images_.map((item) => {
            const imageData = `<div class="tabel-data padding bg-remove">
                              <a href=${item?.image} >
                              <img src=${item?.image} class="detail-tabel-img" />
                              </a>
                              
                              </div>`;
            detail_tabel_images += `${imageData}\n`;
          });
        });
        const rowData = `<div class="tabel-row">
                      <div class="tabel-data text-center">
                      <h6 class="padding">${item?.question}</h6>
                      </div>
                      <div class="answer text-center">
                      <h6 class="padding">${
                        item?.selected_inspection_question.length > 0 &&
                        item?.selected_inspection_question[0]?.choice_select_
                      }</h6>
                      </div>
                      </div>
                      <div class="tabel-row tabel-images-row">
                    ${detail_tabel_images}
                    </div>`;
        detail_tabel_row_data += `${rowData}\n`;
      });
      const data = `<div class="detail-tabel">
                    <div class="datail-tabel-wrapper">
                    <div class="icon-heading">
                    <span class="size-10">${item?.title}</span>
                    </div>
                    <div class="percent">
                    <span class="size-10">${item?.inspect_count}%</span>
                    </div>
                    </div>
                    ${detail_tabel_row_data}
                    </div>`;
      detail_tabel_data += `${data}\n`;
    });
    let vehicle_photos = "";

    car_report_detail_data_new.car_info?.images.map((item) => {
      const data = `<div class="vehicle-photo-inner"><a href=${item} target="_blank"><img src=${item} class=="vehicle-photo" /></a></div>`;
      vehicle_photos += `${data}\n`;
    });

    html = `<html>
  <body>
    <style>
    body{
      font-family: "Montserrat";
    }
    .header-container{
      padding-left:7px;
      padding-right:7px;
      margin:0;
      display:flex;
      flex-direction:row;
      align-items:center;
      justify-content:space-between;
    }
    .header-heading{
      margin:0;
      font-size:10px;
      font-weight: 500;
    }
    .header-right{
      display:flex;
      flex-direction:row;
      align-items:center;
      gap:10px;
    }
     .powered-by{
      padding-right:10px;
      font-size:10px;
      font-weight: 500;
    }
    .header-logo{
      width:90px;
      height:60px
    }
    .car-title{
      font-size:10px;
      font-weight:600;
      padding-left:7px;
      padding-right:7px;
    }
    .car-info-container{
      margin-left:7px;
      margin-right:7px;
      display:flex;
      flex-direction:row;
      align-items:center;
      border:2px solid #EAEDEF;
    }
    .car-info{
      font-size:10px;
      text-align:center;
      width:25%;
      border-right:2px solid #EAEDEF;
      height:30px;
      display:flex;
      align-items:center;
      justify-content:center;
      flex-direction:column;
      gap:5px;
    }
    .car-para{
      margin:0px;
      font-size:6px;
      font-weight:bold;
    }
    .border-none{
      border-right:none;
    }
    .size-10{
      font-size:10px;
    }
    .vehicle-photos-title{
      font-size:10px;
      padding-left:7px;
      padding-right:7px;
    }
    .vehicle-container{
      padding-left:7px;
      padding-right:7px;
      display:flex;
      flex-direction:row;
      flex-wrap:wrap;
      align-items:center;
    }
    .vehicle-photo-inner{
      width:33.33%;
      padding:5px
    }
    .vehicle-photo{
      height:100px;
    }
    </style>
    <div class="header-container">
    <span class="header-heading">Condition Report</span>
    <div class="header-right">
    <img src=${
      car_report_detail_data_new?.car_info?.brands_image
    } class="header-logo" />
    </div>
    </div>
    <h5 class="car-title">${car_report_detail_data_new?.car_info?.year.length > 0 ? car_report_detail_data_new?.car_info?.year : "-"} ${
      car_report_detail_data_new?.car_info?.product_name.length > 0 ? car_report_detail_data_new?.car_info?.product_name : ""
    }</h5>
    <div class="car-info-container">
    <div class="car-info">${
      car_report_detail_data_new?.car_info?.Mileage.length > 0 ? car_report_detail_data_new?.car_info?.Mileage : "-" 
    } <span class="car-para"> (KM) </span> </div>
    <div class="car-info">${
      car_report_detail_data_new?.car_info?.car_body_type == 1
        ? "Hatchback"
        : car_report_detail_data_new?.car_info?.car_body_type == 2
        ? "MPV"
        : car_report_detail_data_new?.car_info?.car_body_type == 3
        ? "SUV"
        : "Saloon"
    }</div>
    <div class="car-info">${
      car_report_detail_data_new?.car_info?.fuel_type.length >  0  ? car_report_detail_data_new?.car_info?.fuel_type : "-"
    }</div>
    <div class="car-info border-none">${
      car_report_detail_data_new?.car_info?.Transmission == 1
        ? "AUTO"
        : car_report_detail_data_new?.car_info?.Transmission == 1
        ? "MANUAL"
        : "BOTH"
    }</div>
    </div>
    <h5 class="vehicle-photos-title">Vehicle Photos</h5>
    <div class="vehicle-container">
    ${vehicle_photos}
    </div>
  </body>
</html>
`;
    html2 = `
<html>
  <style>
  body{
    font-family: "Montserrat";
  }
      .report-title{
        font-size:10px;
        padding-left:7px;
        padding-right:7px;
      }
      .main_img{
        position:relative;  
        width:553px;
        height:456px;
      }
        .main_img_inner{
          width:553px;
          height:456px;
        }
  </style>
  <body>
      <h5 class="report-title">${car_report_page.report_text}</h5>
      <div class="main_img">
      <img src="https://backend.sellanywheel.com/static/staticimages/car_certification_v2-e9ae77bea60843256dbd759106c2911e.jpg" class="main_img_inner" />
      ${points}
      </div>
  </body>
</html>
`;
 html3 = `
    <html>
    <style>
    body{
      font-family: "Montserrat";
    }
    .legend-title{
      font-size:10px;
      padding-left:7px;
      padding-right:7px;
    }
    .vehicle-container{
      padding-left:7px;
      padding-right:7px;
      display:flex;
      flex-direction:row;
      flex-wrap:wrap;
      align-items:center;
    }
    .vehicle-photo-inner{
      width:33.33%;
      padding:5px
    }
    .vehicle-photo{
      height:100px;
    }
    .vehicle-detail-container{
      display:flex;
      flex-direction:row;
      align-items:center;
      gap:5px;
    }
    .bold{
      font-weight:600;
    }
    .vehicle-detail{
      font-size:10px;
      color:gray;
      padding-top:3px;
    }
      .circle_wrapper{
        margin-top:20px;
        margin-bottom:20px;
        display:flex;
        justify-content:center;
        align-items: center;
      }
      .center {
        border: 12px solid #337ab7;
        width: 150px;
        height: 150px;
        border-radius: 100%;
        display: flex;
        justify-content:center;
        align-items: center;
        text-align: center;
        color:#3e98c7;
      }
    .para{
      font-size:10px;
      font-weight:normal;
      width:33.33%
      padding:0px 15px 0px 15px;
    }
    .bar-container{
      margin-bottom:20px;
      display:flex;
      flex-direction:row;
      justify-content: space-between;
      padding-left:20px;
      padding-right:20px;
      gap:20px
    }
    .set-bar{
      width:50%;
      margin-right:auto;
      margin-left:auto;
      margin-top:30px;
    }
    .nam_wrap {
      display: flex;
      justify-content: space-between;
      flex-direction:row;
      align-items:center;
      font-size:12px;
      
      
  }
  .progress{
    margin-bottom: 20px;
    overflow: hidden;
    background-color: #f5f5f5;
    border-radius: 4px;
    font-size: .75rem;
    display: flex;
    flex-direction:row;
    height:7px
  }
  .progress-bar {
    background-color:#337ab7;
  }
  .bar-title{
    font-weight:600;
    font-size:10px
  }
  .detail-tabel-main-container{
    display: flex;
    flex-direction:column;
    gap:10px; 
    align-items: center;
    padding-left:20px;
    padding-right:20px;
  }
  .datail-tabel-wrapper{
  width: 100%;
  height:40px
  margin: 0;
  background-color: #6a6a6a !important;
  color: #fff;
  padding: 6px 10px 6px 10px;
  font-size: 10px;
  font-weight:600;
  text-decoration: none;
  text-transform: capitalize;
  display: flex;
  flex-direction:row;
  gap:20px;
  margin-top:15px;
  }
  .icon-heading {
    width:50%;
    display: flex;
    flex-direction:row; 
    align-items: center;
}
.percent{
  width:50%;
  display: flex;
  flex-direction:row;
  justify-content:flex-end; 
  align-items: center;
}
.tabel-row{
  display: flex;
  flex-direction:row;
  align-items: center;
  border: 1px solid #EAEDEF;
}
.padding{
  padding:7px 0px 7px 0px;
  margin:0;
  font-size:10px;
  font-weight:400;
}
.tabel-data{
  border-right: 1px solid #EAEDEF;
  padding-left:12px;
  padding-right:12px;
  width:50%;
  height:40px;
}
.answer{
  border-top: 1px solid #EAEDEF;
  border-bottom: 1px solid #EAEDEF;
  border-right: 1px solid #EAEDEF;
  padding-left:12px;
  padding-right:12px;
  width:50%;
  font-size:10px;
  height:40px;
}
.text-center{
  display:flex;
  flex-direction:row;
  align-items:center;
}
.detail-tabel-img{
  height:100px;
}
.bg-remove{  
  background-color:white; 
  width:50%;
  border-right:none;
  border-right: 1px solid #EAEDEF;
  height:auto
}
.tabel-images-row{
  margin:0;
  flex-wrap:wrap;
}
    </style>
    <body>
    <h5 class="legend-title">${car_report_page.car_legend_text}</h5>
    <div class="vehicle-container">
    ${table_data}
    </div>
 
  <div class="set-bar">
  <div class="nam_wrap">
  <p class="bar-title">Over All Percentage</p><p class="bar-title">${car_report_detail_data_new.total_inspect}/100</p>
  </div>
  <div class="progress">
  <div role="progressbar" class="progress-bar" aria-valuenow="${car_report_detail_data_new.total_inspect}" aria-valuemin="0" aria-valuemax="100" style="width: ${car_report_detail_data_new.total_inspect}%;">
  </div>
  </div>
  </div>
  <div class="bar-container">
  ${bar_data}
  </div>
  <div class="detail-tabel-main-container">
  ${detail_tabel_data}
  </div>
    </body>
    </html>
 `
  }

  Font.register({
    family: "Montserrat",
    fonts: [
      {
        src: MontserratFont,
      },
      {
        src: MontserratFontBold,
        fontWeight: "bold",
      },
      {
        src: MontserratFontSemiBold,
        fontWeight: "semi-bold",
      },
    ],
  });
  const styles = StyleSheet.create({
    page: {
      padding: 10,
      paddingTop: 25,
      paddingBottom: 25,
    },
    section: {
      // margin: 10,
      // padding: 10,
    },
    viewer: {
      width: window.innerWidth, //the pdf viewer will take up all of the width and height
      height: window.innerHeight,
    },
    footer: {
      position: "absolute",
      height: 50,
      bottom: 0,
      left:0,
      right:0,
      textAlign:"center",
      fontWeight: 600,
      paddingTop: 20,
      color: "gray",
      marginTop:10
    },
    footerText:{
      fontSize: 10,
    }
  });
  return load ? (
    <>
      <center style={{ marginTop: "55vh" }}>
        <h2>Generating PDF...</h2>
      </center>
    </>
  ) : (
    <>
      <PDFViewer style={styles.viewer}>
        <Document>
          <Page style={styles.page} wrap>
            <Html>{html}</Html>
            {car_report_detail_data_new.car_info?.images.length > 18 ? null : (
              <Text break></Text>
            )}
            <Html>{html2}</Html>
            <Text break></Text>
            <Html>{html3}</Html>
            <Text
              style={styles.footer}
              render={({ pageNumber, totalPages }) =>
              <Html style={styles.footerText}>{`<b>All Rights Reserved, Sell Any Wheel</b>`}</Html>
              }
              fixed
            />
          </Page>
        </Document>
      </PDFViewer>
    </>
  );
};
{/* <div class="circle_wrapper">
<div class="center">${car_report_detail_data_new.total_inspect}/100</div>
</div> */}
const mapStateToProps = (state) => ({
  car_report_data: state.carreport.car_report_data,
  car_report_detail_data: state.carreport.car_report_detail_data.data,
  car_report_detail_data_new: state.carreport.car_report_detail_data,
  loading: state.carreport.loading,
  winerListloading: state.carreport.winerListloading,
  addresform: state.shiping.addresform,
});
const mapDispatchToProps = (dispatch) => ({
  // car report
  car_report_call: (data) =>
    dispatch({
      type: CAR_REPORT,
      payload: {
        type: CAR_REPORT,
        method: POST,
        label: Labels.car_report,
        hideOverlayLoader: true,
        data,
      },
    }),
  // car report detail
  car_report_detail_call: (data) =>
    dispatch({
      type: CAR_REPORT_DETAIL,
      payload: {
        type: CAR_REPORT_DETAIL,
        method: POST,
        label: Labels.car_report_detail,
        hideOverlayLoader: true,
        data,
      },
    }),

  //
});

export default connect(mapStateToProps, mapDispatchToProps)(Report);
