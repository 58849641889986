// BidDetailsModal.js
import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const BidDetailsModal = ({ show, handleClose, bidsDetail }) => {
  return (
    <Modal show={show} onHide={handleClose} centered style={{marginTop:"15vh"}}>
      <Modal.Header closeButton>
        <Modal.Title>Bid Details</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ maxHeight: '600px', overflowY: 'auto' }}>
        <div className='d-flex justify-content-center my-3 mt-5 pt-5'>
            <p className='fs-5 fw-bold' style={{color:"#096C59"}}>
            Total Bid Details
            </p>
            </div>
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Amount</th>
              <th>Type</th>
              <th>Status</th>
              <th>Winner</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            {bidsDetail.map((bid, index) => (
              <tr key={index}>
                <td>${bid.bid_amount.toFixed(2)}</td>
                <td>{bid.bid_type}</td>
                <td>
                  <span className={`badge ${bid.status ? 'bg-success' : 'bg-danger'}`}>
                    {bid.status ? 'Active' : 'Inactive'}
                  </span>
                </td>
                <td>
                  <span className={`badge ${bid.winner ? 'bg-primary' : 'bg-secondary'}`}>
                    {bid.winner ? 'Yes' : 'No'}
                  </span>
                </td>
                <td>{new Date(bid.date).toLocaleString()}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default BidDetailsModal;
