import React, { useState, useEffect } from "react";
import "./style.css";
import Form from "../../components/Form/index";
import { Breadcrumb } from "antd";
import { Link, useHistory } from "react-router-dom";
import {
  FaCar,
  FaCheck,
  FaGasPump,
  FaCalendar,
  FaCarSide,
} from "react-icons/fa";
import LandingSection from "../../components/LandingSection";
import {
  SHOW_INTEREST,
  SHOW_INTEREST_STEPS,
  POST,
  BRANDS,
} from "../../constant";
//

import { connect, useSelector } from "react-redux";
import Labels from "../../services/apiLabels";
import Progress from "../../components/Progress";
import FormComponent from "./FormComponent";
import { Icon, Grid, Step } from "semantic-ui-react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useContext } from "react";
import FeedbackContext from "../../context/cmscontext";
import { formateKey } from "../../utils/formating";
import { scrollTop } from "../../utils/window";
//

function ShowInterest(props) {
  const {
    navbarDimensions,
    postvendorinterest,
    userId,
    loading,
    getbrands,
    brandsData,
    currentStep,
    showInterest,
    toster_,
  } = props;
  const dimensions = { height: "calc( 100vh / 1.7 )", width: "100%" };
  //
  const history = useHistory();
  const step1 = useSelector((state) => state.sellMyCar.step1);
  //
    const { feedback, isLoading } = useContext(FeedbackContext);
var show_interest = feedback.show_interest;
  //

  const notify = () => toast(' Wow so easy!', {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    });

useEffect(() => {
  if(toster_){
    notify()
  }
 
}, [])

  useEffect(() => {
 
    scrollTop()
 
  }, [])
  useEffect(() => {

    if (Object.keys(brandsData).length < 1) getbrands();
    
  }, [brandsData]);
  //
  
  const NumberIcon = (props) => {
    const { id, size, className } = props;
    return (
      <span style={{ display: "inline-block" }}>
        <span
          style={size ? { width: size, height: size } : {}}
          className={`numberIcon ${className} `}
        >
          {id}
        </span>
      </span>
    );
  };

  // const postvendorinterestHandler = () => {
  //   if (userId && variantId) {
  //     postvendorinterest({
  //       user: userId,
  //       car: variantId,
  //     });
  //   } else {
  //     
  //   }
  // };
  const formanimationClassname = "animate__animated animate__backInRight";
  //
  const steps = [
    { name: show_interest.brands_text, Icon: FaCar, description: "" },
    { name: show_interest.models_text, Icon: FaCar, description: "" },
    { name: show_interest.fuel_type, Icon: FaGasPump, description: "" },
    { name: show_interest.years_text, Icon: FaCalendar, description: "" },
    { name: show_interest.varients_text, Icon: FaCarSide, description: "" },
  ];
  //
  return (
    <>
      <div className="showInterest_mainCont">
        {/* background top section */}
        <div className="showInterest_topBackgroundSection" />
        {/*  */}
        <div className="showInterest_body">
          <div className="showInterest_headingCont_">
            <div className="showInterest_heading">
              {show_interest.interested_cars}
            </div>
            <div className="showInterest_description">
              {show_interest.interested_cars_description}
            </div>
          </div>
          <div
            className="showInterest_columns_wrapper cardShadow"
            style={{
              width: "100%",
              // height: `calc(100vh - 11rem - ${navbarDimensions.height}px )`,
            }}
          >
            {/* left */}
            <div className="showInterest_body_column showInterest_body_column_left ">
              <Step.Group fluid vertical>
                {/*  */}
                {React.Children.toArray(
                  steps.map((item, index) => {
                    const { name, Icon } = item;
                    const isStepCompleted = showInterest[name].isSelected;
                    const completed = isStepCompleted && currentStep != name;
                    const active = currentStep === name;
                    return (
                      <Step key={index} completed={completed} active={active}>
                        <Icon style={{ margin: "0 1rem 0 0" }} size={25} />
                        <Step.Content style={{ width: "80%" }}>
                          <Step.Title
                            style={{
                              textTransform: "capitalize",
                              color: active && "var(--primary)",
                            }}
                          >
                            {formateKey(name)}
                          </Step.Title>
                          <Step.Description>
                            {show_interest.choose_car} {formateKey(name)}{show_interest.your_interest}
                          </Step.Description>
                        </Step.Content>
                      </Step>
                    );
                  })
                )}
                {/*  */}
              </Step.Group>
            </div>
            {/* right  */}
            <div className="showInterest_body_column showInterest_body_column_right">
              {/* <Progress step={2} totalSteps={4} /> */}
              {currentStep === "brands" && (
                <FormComponent
                  name="brands"
                  nextName="models"
                  prevName={false}
                />
              )}
              {currentStep === "models" && (
                <FormComponent
                  name="models"
                  nextName="fuel_types"
                  prevName="brands"
                />
              )}
              {currentStep === "fuel_types" && (
                <FormComponent
                  name="fuel_types"
                  nextName="years"
                  prevName="models"
                />
              )}
              {currentStep === "years" && (
                <FormComponent
                  name="years"
                  nextName="variants"
                  prevName="fuel_types"
                />
              )}
              {currentStep === "variants" && (
                <FormComponent
                  name="variants"
                  nextName={false}
                  prevName="years"
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {/* Same as */}
      <ToastContainer />

      <br></br>
      <br></br>
      {/* <button onClick={notify}>Notify!</button> */}
    </>
  );
}

const mapStateToProps = (state) => ({
  loading: state.showInterest.loading,
  toster_: state.showInterest.toster,
  userId: state.auth.id,
  brandsData: state.showInterest.brands.data,
  currentStep: state.showInterest.currentStep,
  showInterest: state.showInterest,

  // variantId: state.showInterest.step1.variant.current.value,
});
const mapDispatchToProps = (dispatch) => ({
  // get product list
  getbrands: (data) => {
    return dispatch({
      type: BRANDS,
      payload: {
        type: BRANDS,
        method: POST,
        label: `${Labels.brands}`,
        hideOverlayLoader: true,
      },
    });
  },
  //
  postvendorinterest: (data) =>
    dispatch({
      type: SHOW_INTEREST,
      payload: {
        type: SHOW_INTEREST,
        data,
        label: Labels.vendorinterest,
        method: POST,
        hideOverlayLoader: true,
      },
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShowInterest);
