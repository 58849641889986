/* eslint-disable no-unused-vars */
/* eslint-disable import/no-anonymous-default-export */
import React, { useState, useEffect, Fragment } from "react";
import "./produ.css";
import "./ListCard.css";
import { BrowserRouter as Router, Link, useLocation } from "react-router-dom";
import WishListButton from "./WishListButton-card";
import ProgressBar from "./ProgressBar";
import WishListButtonn from "../Product list/WishListButton";
import product from "../../reducers/product";

export default (props) => {
  const { Products, index, product_index,isBuyNow } = props;
  const location = useLocation();
  const [bidingExpired, setBidingExpired] = useState(false);
  const [timer_, setTimer_] = useState([
    { id: "days", value: "00" },
    { id: "hours", value: "00" },
    { id: "minutes", value: "00" },
    { id: "seconds", value: "00" },
  ]);

  const convertTime = (date) => {
    const dateString = date;
    const userOffset = new Date().getTimezoneOffset() * 60 * 1000;
    const localDate = new Date(dateString);
    const utcDate = new Date(localDate.getTime() + userOffset);
    return utcDate;
  };

  let countDownDate = new Date(
    Products.status == "Live"
      ? convertTime(Products.end_at)
      : convertTime(Products.start_at)
  ).getTime();

  const timeFormatter = (e) => {
    return e < 10 ? `0${e}` : `${e}`;
  };

  const transmission_to_text = {
    2: "Manual",
    1: "Auto",
    3: "Both",
  };

  useEffect(() => {
    const interval = setInterval(function () {
      // Get today's date and time
      var now = new Date().getTime();

      // Find the distance between now and the count down date
      var distance = countDownDate - now;

      // Time calculations for days, hours, minutes and seconds
      var days = Math.floor(distance / (1000 * 60 * 60 * 24));
      var hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);
      setTimer_([
        { id: "days", value: timeFormatter(days) },
        { id: "hours", value: timeFormatter(hours) },
        { id: "minutes", value: timeFormatter(minutes) },
        { id: "seconds", value: timeFormatter(seconds) },
      ]);
      setBidingExpired(false);
      if (distance < 0) {
        clearInterval(interval);
        setBidingExpired(true);
        setTimer_([
          { id: "days", value: "00" },
          { id: "hours", value: "00" },
          { id: "minutes", value: "00" },
          { id: "seconds", value: "00" },
        ]);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [countDownDate]);

  return (
    <div className="auction-card-main-class">
       {isBuyNow &&(
            <WishListButtonn
        product_code_wish={Products}
        product_index={index}
        bidingExpired={bidingExpired}
      />
          )}
       <Link
        to={location.pathname === "/auction" && Products.status=="Live"?{
          pathname: "/product",
          state: { product_code: Products.id },
        }: location.pathname === "/buynow" && {
          pathname: "/productbuy",
          state: { product_code: Products.id },
        }}
        style={{ color: "inherit", textDecoration: "inherit" }}
        className="container-fluid product_list_card_main_cont imageHover "
      >

      <div
        style={{ color: "inherit", textDecoration: "inherit" }}
        className="auction-card-sub-class imageHover"
      >
        <div className="col-4 p-0">
          <div className="product_list_card_image_wrapper">
            <img
              className="product_list_card_image thumbnail_product"
              src={Products.main_image}
              alt="banner"
            />
          </div>
        </div>
        <div className="col-8 product-card-details container-fluid">
          <div className="row align-items-center">
            <div className={isBuyNow? "col-lg-12 p-3 p-lg-2":"col-lg-5 p-3 p-lg-2"}>
          <span className="product-card-title">{Products.product_name}</span>
          {/* <div className="">
            {Products.product_feature
              ? React.Children.toArray(
                  Products.product_feature.slice(0, 5).map((item, index) => (
                    <span className="card-features-tags">
                      {item.name}: {item.value}
                    </span>
                  ))
                )
              : null}
          </div> */}
          <div className="mt-3">
            {Products?.mileage && Products?.mileage.length > 0 && (
              <>
                <span className="card-features-tags">
                  <b style={{ color: "#000000" }}>Mileage:</b>{" "}
                  <span style={{ color: "black" }}>{Products?.mileage}</span>
                </span>
              </>
            )}
            {Products?.transmission && Products?.transmission.length > 0 && (
              <>
                <span className="card-features-tags">
                  <b style={{ color: "#000000" }}>Transmission:</b>{" "}
                  <span style={{ color: "black" }}>
                    {transmission_to_text[Products?.transmission]}
                  </span>
                </span>
              </>
            )}
            {Products?.brands && Products?.brands.length > 0 && (
              <>
                <span className="card-features-tags">
                  <b style={{ color: "#000000" }}>Make:</b>{" "}
                  <span style={{ color: "black" }}>{Products?.brands}</span>
                </span>
              </>
            )}

            {Products?.model_name && Products?.model_name.length > 0 && (
              <>
                <span className="card-features-tags">
                  <b style={{ color: "#000000" }}>Model:</b>{" "}
                  <span style={{ color: "black" }}>{Products?.model_name}</span>
                </span>
              </>
            )}
            {Products?.fuel_type && Products?.fuel_type.length > 0 && (
              <>
                <span className="card-features-tags">
                  <b style={{ color: "#000000" }}>Fuel Type:</b>{" "}
                  <span style={{ color: "black" }}>{Products?.fuel_type}</span>
                </span>
              </>
            )}
            {Products?.year && Products?.year.length > 0 && (
              <>
                <span className="card-features-tags">
                  <b style={{ color: "#000000" }}>Year:</b>{" "}
                  <span style={{ color: "black" }}>{Products?.year}</span>
                </span>
              </>
            )}
          </div>
         
          
          </div>
          {!isBuyNow && (

          <div className="col-lg-6  p-3 p-lg-2" style={{background:'#c8e6c9'}}>
            <div className="flex flex-column">
          {location.pathname === "/auction" && !bidingExpired ? (
            <Fragment>
                {/* <span className="card-timer-title">
                  {Products.status == "Live"
                    ? "Bidding Expires in"
                    : "Auction Starts In"}
                </span> */}

              <div className="d-flex justify-content-start align-items-center">
                {React.Children.toArray(
                  timer_.map((item, index) => {
                    return (
                      <Fragment>
                        <span className="p-1 d-flex flex-column justify-content-center">
                          <div className="card-timer-time">
                            {item.value}
                          </div>
                          <span className="card-timer-label">
                            {item.id}
                          </span>
                        </span>
                        <span
                          className="counter-text pt-1 align-self-start"
                        >
                          {index === timer_.length - 1 ? null : ":"}
                        </span>
                      </Fragment>
                    );
                  })
                )}
              </div>
            </Fragment>
          ) : (
            location.pathname === "/auction" && (
              <span className="card-timer-title">Sold</span>
            )
          )}
  <ProgressBar startAt={Products.start_at} endAt={Products.end_at} />
                        <div className="d-flex align-items-center justify-content-center my-2">
              <p className="fw-bold mb-0">Current Bid EUR:</p>
              <p className="ms-1 mb-0">  {Products.current_bid??'N/A'}</p>
              </div>
        <Link     to={
          location.pathname === "/auction" && Products.status == "Live"
            ? {
                pathname: "/product",
                state: { product_code: Products.id },
              }
            : location.pathname === "/buynow" && {
                pathname: "/productbuy",
                state: { product_code: Products.id },
              }
        }>
      <button className="btn_contacta float-right border-radius-2 mb-2">
        View Listing & Bid
        </button>
        </Link>

                <WishListButton
        product_code_wish={Products}
        product_index={index}
        bidingExpired={bidingExpired}
      />
    
      </div>
          </div>)}
          </div>
        </div>
      </div>
      </Link>
    </div>
  );
};