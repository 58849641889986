import React from "react";
import Carpic from "../../assets/How/1.svg";
import Carpic2 from "../../assets/How/3.svg";
import Carpic3 from "../../assets/How/4.svg";
import Carpic4 from "../../assets/How/5.svg";
import { useContext } from "react";
import FeedbackContext from "../../context/cmscontext";
import "./style.css";
function Howwork() {

   const { feedback, isLoading } = useContext(FeedbackContext);
console.log("feedback=============feedback====>>>>>>>>>>>>>>.", feedback);



var home_page = feedback.home_page;
  return (
    <>
      <div
        className="containerfluid  how_wrap"
        style={{ backgroundColor: "#F2F2F2" }}
      >
        <div className="container p-5">
          <h2 className="text-center">{home_page.how_heading}</h2>

          <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-12 ">
              <div className="p-2 pic____">
                <img className="img_2" src={home_page.signup_imageurl1} />
              </div>
              <span className="work_text my-5">{home_page.signup_txt}</span>
              <p>{home_page.signup_description}</p>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="p-2 pic____">
                <img className="img_2" src={home_page.download_imageurl2} />
              </div>
              <span className="work_text">{home_page.Downloadapp_text}</span>
              <p>{home_page.download_description}</p>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="p-2 pic____">
                <img className="img_2" src={home_page.browse_imageurl3} />
              </div>
              <span className="work_text">{home_page.browse_text}</span>
              <p>{home_page.browse_description}</p>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="p-2 pic____">
                <img className="img_2" src={home_page.done_imageurl4} />
              </div>
              <span className="work_text">{home_page.done_text}</span>
              <p>{home_page.done_description}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Howwork;
