/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useMemo } from "react";
import Dropdown from "../Dropdown";
import "./auction.css";
import {
  Header,
  CheckBoxList,
} from "./components"; // libraries
import { useLocation, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import Labels from "../../services/apiLabels";
import { GET, POST } from "../../constant/apiMethods";
import {
  SET_SELECTED_FILTER,
  PRODUCT_LIST,
  SET_SEARCH_QUERY_FILTER,
  CLEAR_ALL_SELECTED_FILTER,
  BRANDS,
  PRODUCT_LIST_BUY
} from "../../constant/index";
import { useContext } from "react";
import FeedbackContext from "../../context/cmscontext";
import { API_ENDPOINT } from "../../services/api";
function Auctionlist(props) {
  const {
    handleDrawer,
    selectedFilters,
    filtersQuery,
    getProductList,
    getbrands,
    filterData,
    getFilterProduct,
    getBuyNowFilterProduct,
    setPage,
    selectedFilter,
    user_id
  } = props;
  const { feedback } = useContext(FeedbackContext);
  const history = useHistory();
  const [models, setModels] = useState({});
  const [years, setYears] = useState({});
  const [fuelType, setFuelType] = useState({});
  const [seller, setSeller] = useState([]);
  const [clear, setClear] = useState(Object.keys(selectedFilter?.current).length - 2);
  var auction_page = feedback.auction_page;
  const brands = filterData?.brands?.data?.brands;
  const [selectedFiltersLocal, setFilters] = useState(selectedFilter.current);
  const [totalAppliedFilters, setTotalAppliedFilters] = useState(0);
  const location = useLocation();

  useEffect(() => {
    let queryString = Object.keys(selectedFilter.current)
      .map((key) => key + "=" + selectedFilter.current[key])
      .join("&");
    if (location.pathname === "/auction") {
      getFilterProduct(queryString);
    }
    if (location.pathname === "/buynow") {
      getBuyNowFilterProduct(queryString);
    }
    return () => {};
  }, []);
  const setTotalAppliedFiltersHandler = () => {
    let array = Object.values(selectedFilters);

    var filtered = array.filter(function (e) {
      return e !== null && e != undefined && e !== "";
    });
    setTotalAppliedFilters(filtered.length);
  };
  const getdata = async (label) => {
    const parameters = {
      GET,
      headers: {
        "Content-Type": "application/json",
      },
    };
    return await fetch(API_ENDPOINT(label), parameters)
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        return json;
      });
  };
  useEffect(async () => {
    getbrands();
    const sellerData = await getdata(Labels.Seller);
    setSeller(sellerData?.data);
  }, []);

  const handlefilter = async (type, value, isCheck) => {
    if (type == "millage") {
      selectedFilter.current["millage"] = value.replace("KM's", "").trim();
    } else {
      if (isCheck) {
        if (type in selectedFilter.current) {
          let old = selectedFilter.current[type].split(",");
          old.push(value);
          selectedFilter.current[type] = old.toString();
        } else {
          selectedFilter.current[type] = value;
        }
      } else {
        let array = selectedFilter.current[type].split(",");
        const index = array.indexOf(value);
        if (index > -1) {
          // only splice array when item is found
          array.splice(index, 1); // 2nd parameter means remove one item only
        }
        selectedFilter.current[type] = array.toString();
        if (selectedFilter.current[type].length == 0) {
          delete selectedFilter.current[type];
        }
      }
    }
    setFilters(selectedFilter.current);
    setClear(Object.keys(selectedFilter.current).length - 2);
    setPage(1);
    selectedFilter.current["page"] = 1;
    let queryString = Object.keys(selectedFilter.current)
      .map((key) => key + "=" + selectedFilter.current[key])
      .join("&");
    if (location.pathname === "/auction") {
      const filter = await getFilterProduct(queryString);
    }
    if (location.pathname === "/buynow") {
      const filter = await getBuyNowFilterProduct(queryString);
    }
  };
  const handleCheckBox = async (id, label, stateData, setData, state) => {
    try {
      if (state) {
        const data = await getdata(`${label}${id}/`);
        if (data.length > 0) {
          stateData[id.toString()] = data;
          setData({ ...stateData });
        }
      } else {
        delete stateData[id.toString()];
        setData({ ...stateData });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getProductListHandler = (query = filtersQuery) => {
    let params = {
      history,
      query,
    };
    getProductList(params);
  };
  useMemo(() => {
    if (selectedFilters) {
      getProductListHandler();
      setTotalAppliedFiltersHandler();
    }
    return () => {};
  }, [filtersQuery, selectedFilters]);
  return (
    <div
      className=" position-relative small_scrn_height "
      style={{
        margin: "0 auto",
        width: "96%",
      }}
    >
      <Header
        totalAppliedFilters={Object.keys(selectedFilter.current).length - 2}
        clearAll={() => {
          selectedFilter.current = {
            page: 1,
            page_size: 10,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            user_id:user_id
          };
          setFilters({});
          setClear(Object.keys(selectedFilter.current).length - 2);
          // getProductListAll()
          let queryString = Object.keys(selectedFilter.current)
            .map((key) => key + "=" + selectedFilter.current[key])
            .join("&");
          if (location.pathname === "/auction") {
            getFilterProduct(queryString);
          }
          if (location.pathname === "/buynow") {
            getBuyNowFilterProduct(queryString);
          }
        }}
        handleDrawer={handleDrawer}
      />
      <div className="auction_dropdown_wrapper ">
        <Dropdown
          placeholder={auction_page.Drive_Type_title}
          hideDivider
          maxHeight={true}
        >
          <CheckBoxList
            default_value={
              selectedFiltersLocal?.seller_type
                ? selectedFiltersLocal?.seller_type?.split(",")
                : []
            }
            handleCheckBox={(id, check, value) =>
              handlefilter("seller_type", value, check)
            }
            data={[
              `${auction_page.Drive_Type_Four_wheel_drive}`,
              `${auction_page.Drive_Type_Front_wheel_drive}`,
            ]}
          />
        </Dropdown>
      </div>
      <div className="auction_dropdown_wrapper ">
        <Dropdown
          placeholder={auction_page.Doors_Seats_title}
          hideDivider
          maxHeight={true}
        >
          {seller?.map((item) => {
            return item.first_name.length > 0 || item.last_name.length > 0 ? (
              <CheckBoxList
                default_value={
                  selectedFiltersLocal?.created_by_name
                    ? selectedFiltersLocal?.created_by_name?.split(",")
                    : []
                }
                key={item?.id}
                data={[`${item?.first_name} ${item?.last_name}`]}
                to_check={item.username}
                id={item?.id}
                handleCheckBox={(id, check, value) =>
                  handlefilter("created_by_name", item.username, check)
                }
              />
            ) : (
              <CheckBoxList
                default_value={
                  selectedFiltersLocal?.created_by_name
                    ? selectedFiltersLocal?.created_by_name?.split(",")
                    : []
                }
                key={item?.id}
                data={[`${item?.username}`]}
                to_check={item.username}
                id={item?.id}
                handleCheckBox={(id, check, value) =>
                  handlefilter("created_by_name", item.username, check)
                }
              />
            );
          })}
        </Dropdown>
      </div>
      {location.pathname === "/auction" && (
        <div className="auction_dropdown_wrapper ">
          <Dropdown
            placeholder={auction_page.Auction_Status}
            hideDivider
            maxHeight={true}
          >
            <CheckBoxList
              default_value={
                selectedFiltersLocal?.auction_status
                  ? selectedFiltersLocal?.auction_status?.split(",")
                  : []
              }
              handleCheckBox={(id, check, value) =>
                handlefilter("auction_status", value, check)
              }
              data={[
                `${auction_page.Type_Live_Auction}`,
                `${auction_page.Type_Upcoming_Auction}`,
              ]}
            />
          </Dropdown>
        </div>
      )}
      <div className="auction_dropdown_wrapper ">
        <Dropdown
          placeholder={auction_page.Make_model_title}
          hideDivider
          maxHeight={true}
        >
          {brands?.map((item) => {
            return (
              <CheckBoxList
                key={item?.id}
                data={[item?.brand_name]}
                default_value={
                  selectedFiltersLocal?.brands
                    ? selectedFiltersLocal?.brands?.split(",")
                    : []
                }
                handleCheckBox={(id, check, value) => {
                  handleCheckBox(
                    item.id,
                    Labels.car_models,
                    models,
                    setModels,
                    check
                  );
                  handlefilter("brands", value, check);
                }}
                id={item?.id}
              />
            );
          })}
        </Dropdown>
      </div>
      <div className="auction_dropdown_wrapper ">
        <Dropdown placeholder={auction_page.Model} hideDivider maxHeight={true}>
          {Object.keys(models)?.map((key, Keyindex) => {
            return models[key].map((item, index) => {
              return (
                <>
                  {
                    <CheckBoxList
                      key={item?.id}
                      data={[item?.display]}
                      default_value={
                        selectedFiltersLocal?.model_name
                          ? selectedFiltersLocal?.model_name?.split(",")
                          : []
                      }
                      handleCheckBox={(id, check, value) => {
                        handleCheckBox(
                          item.value,
                          Labels.car_fuel_types,
                          fuelType,
                          setFuelType,
                          check
                        );
                        handlefilter("model_name", value, check);
                      }}
                    />
                  }
                </>
              );
            });
          })}
        </Dropdown>
      </div>
      <div className="auction_dropdown_wrapper ">
        <Dropdown
          placeholder={auction_page.Price_title}
          hideDivider
          maxHeight={true}
        >
          {Object.keys(fuelType)?.map((key, Keyindex) => {
            return fuelType[key].map((item, index) => {
              return (
                <CheckBoxList
                  key={item?.id}
                  data={[item?.display]}
                  default_value={
                    selectedFiltersLocal?.fuel_type
                      ? selectedFiltersLocal?.fuel_type?.split(",")
                      : []
                  }
                  handleCheckBox={(id, check, value) => {
                    handleCheckBox(
                      item.value,
                      Labels.car_years,
                      years,
                      setYears,
                      check
                    );
                    handlefilter("fuel_type", value, check);
                  }}
                />
              );
            });
          })}
        </Dropdown>
      </div>
      <div className="auction_dropdown_wrapper ">
        <Dropdown
          placeholder={auction_page.Year_title}
          hideDivider
          maxHeight={true}
        >
          {Object.keys(years)?.map((key, Keyindex) => {
            return years[key].map((item, index) => {
              return (
                <CheckBoxList
                  key={item?.id}
                  default_value={
                    selectedFiltersLocal?.year
                      ? selectedFiltersLocal?.year?.split(",")
                      : []
                  }
                  data={[item?.display]}
                  handleCheckBox={(id, check, value) => {
                    handleCheckBox(
                      item.value,
                      Labels.car_fuel_types,
                      fuelType,
                      setFuelType,
                      check
                    );
                    handlefilter("year", value, check);
                  }}
                />
              );
            });
          })}
        </Dropdown>
      </div>
      <div className="auction_dropdown_wrapper ">
        <Dropdown
          placeholder={auction_page.Mileage_title}
          hideDivider
          maxHeight={true}
        >
          <CheckBoxList
            default_value={
              selectedFiltersLocal?.millage
                ? selectedFiltersLocal?.millage?.split(",")
                : []
            }
            handleCheckBox={(id, check, value) =>
              handlefilter("millage", value, check)
            }
            data={[
              "1000 KM's",
              "20000 KM's",
              "30000 KM's",
              "40000 KM's",
              "50000 KM's",
              "60000 KM's",
              "70000 KM's",
              "80000 KM's",
              "90000 KM's",
              "100000 KM's",
              "125000 KM's",
              "150000 KM's",
              "200000 KM's",
            ]}
          />
        </Dropdown>
      </div>
      <div className="auction_dropdown_wrapper ">
        <Dropdown
          placeholder={auction_page.Gearbox_Engine_title}
          hideDivider
          maxHeight={true}
        >
          <CheckBoxList
            default_value={
              selectedFiltersLocal?.transmission
                ? selectedFiltersLocal?.transmission?.split(",")
                : []
            }
            data={["Manual", "Automatic"]}
            handleCheckBox={(id, check, value) =>
              handlefilter("transmission", value, check)
            }
          />
        </Dropdown>
      </div>
      <div className=" filter_bar_mobile_footer">
        <button
          onClick={handleDrawer}
          className="filter_bar_mobile_footer_button "
        >
          {auction_page.Show_Results}
        </button>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => ({
  products: state.product,
  filter: state.filters,
  filtersData: state.filters.data,
  selectedFilters: state.filters.selected,
  filtersQuery: state.filters.queryString_,
  filterData: state.showInterest,
  user_id: state.auth.id,
});
const mapDispatchToProps = (dispatch) => ({
  setSelectedFilters: (data) =>
    dispatch({
      type: SET_SELECTED_FILTER,
      payload: data,
    }),
  clearAllSelectedFilters: (data) => {
    dispatch({
      type: CLEAR_ALL_SELECTED_FILTER,
      payload: data,
    });
  },
  setSelectedQueryFilters: (data) =>
    dispatch({
      type: SET_SEARCH_QUERY_FILTER,
      payload: data,
    }),
  // // get product list without filters
  getProductList: (params) =>
    dispatch({
      type: PRODUCT_LIST,
      payload: {
        type: PRODUCT_LIST,
        method: GET,
        label: `${Labels.product_list}?type=${
          JSON.parse(localStorage.getItem("WFAC_localstorage_auth")).id
        }&${params.query}`,
        hideOverlayLoader: true,
        params,
      },
    }),
  // get product list
  getProductListAll: (data) =>
    dispatch({
      type: PRODUCT_LIST,
      payload: {
        type: PRODUCT_LIST,
        method: GET,
        label: Labels.product_list,
        hideOverlayLoader: true,
      },
    }),
  //
  getFilterProduct: (params) =>
    dispatch({
      type: PRODUCT_LIST,
      payload: {
        type: PRODUCT_LIST,
        method: GET,
        label: `${Labels.product_list}?${params}`,
        hideOverlayLoader: true,
        params,
      },
    }),
  getBuyNowFilterProduct: (params) =>
    dispatch({
      type: PRODUCT_LIST_BUY,
      payload: {
        type: PRODUCT_LIST_BUY,
        method: GET,
        label: `${Labels.buynow_list}?${params}`,
        hideOverlayLoader: true,
      },
    } ),
  getbrands: () => {
    return dispatch({
      type: BRANDS,
      payload: {
        type: BRANDS,
        method: POST,
        label: `${Labels.brands}`,
        hideOverlayLoader: true,
      },
    });
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(Auctionlist);
