import { React, useEffect, useState } from "react";

import "./style.css";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { FaSearch } from "react-icons/fa";
import TextField from "@mui/material/TextField";
import PuffLoader from "react-spinners/ClipLoader";
import { SALE_PRODUCT_LIST, ADDRESS_FORM } from "../../constant";
import { POST } from "../../constant/apiMethods";
import { connect } from "react-redux";
import Labels from "../../services/apiLabels";

function SearchCity(props) {
  const { product, productList, cityes_List_Data, sumit_form,addressform } = props;
  const history = useHistory();

  useEffect(() => {
  // console.log"address from ",addressform)
if(addressform){
  history.push("/carsteps")
}
  }, [addressform]);












  const [inpvalue, setInpvalue] = useState("");
  const [citeslist, setciteslist] = useState([]);

  
  
  // 

  const citylisthandler = (e) => {
    setInpvalue(e.target.value);
    if (e.target.value.length > 1) {
  
      get_all_cites_list(e.target.value);
      setLoading("true");
    }

    if (e.target.value == "") {
      setciteslist([]);
      setdivshow("false");
      document.getElementById("inp_main").style.borderBottom =
        "2px solid #afada3";
    }
  };

  const apikey = "85oOFVlBH0GWLXWE0FSGgA35569";

  function get_all_cites_list(a) {
    axios({
      method: "get",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },

      url: `https://api.getAddress.io/autocomplete/${a}?api-key=${apikey} `,
    })
      .then(function (response) {
        
        setLoading("flase");
        setdivshow("true");
        document.getElementById("inp_main").style.borderBottom = "none";
        if (response.data.suggestions.length > 0) {
          setciteslist(response.data.suggestions);
        }
      })
      .catch(function (error) {
        
      });
  }

  const citydata = (item) => {
      // console.log"wwwwwww",item)
    setInpvalue(item.address)
    get_one_cites_list(item.id);
  };

  const [cityDetail, setcityDetail] = useState("");

  const [show, setshow] = useState(false);

  const [line1, setline1] = useState("");
  const [line2, setline2] = useState("");

  const [city, setCity] = useState("");
  const [postcode, setpostcode] = useState("");

  const [divshow, setdivshow] = useState("false");

  let [loading, setLoading] = useState("false");

  

  function get_one_cites_list(id) {
    axios({
      method: "get",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },

      url: `https://api.getAddress.io/get/${id}?api-key=${apikey} `,
    })
      .then(function (response) {
        
        setline1(response.data.line_1);
        setline2(response.data.line_2);
        setCity(response.data.town_or_city);
        setpostcode(response.data.postcode);
        setcityDetail(response.data);
        setshow(true);
        // citydata(response.data)
      })
      .catch(function (error) {
        
      });
  }

const submit_address=()=>{

  // console.log"cityDetailcityDetail",cityDetail)
    sumit_form({
      evaluation_id:119,
      city:city,
      line1:line1,
      line2:line2,
      post_code:postcode,
     address:inpvalue,
    
     country:cityDetail.country,


    })

}

  const serach_inppp=()=>{
      // console.log"eidt addddd")

      setshow(false)
  }

  const serchbar_ = () => {};

  const actioncall = () => {
    

    // citydata_action({
    //     abc:"abdullah"
    // })
  };

  return (
    <>
      <div className="showInterest_headingCont ">
        <div className="showInterest_heading">Provide Your Address</div>
        <div className="showInterest_description">
Enter the address whare vehicle is located        </div>
      </div>
      <div className="container  inp_main_wrap p-5 kkkkkkkk">
        {/* <button onClick={actioncall}>call action</button> */}

        {/* ===============================form=============== */}

        {show ? (
          <div className="containerfluid contact_wra p-5 ">
            <div className="row  d-flex justify-content-center ">
              <div className="col-lg-4 col-md-5 col-sm-12 ">
                <h4>Line 1</h4>
                <div className="inp_wrap my-3">
                  <input
                    value={line1}
                    onChange={(e) => {
                      setline1(e.target.value);
                    }}
                    className="inp_contact"
                    placeholder="Line 1 "
                  ></input>
                </div>
                <h4>Line 2</h4>
                <div className="inp_wrap">
                  <input
                    value={line2}
                    onChange={(e) => {
                      setline2(e.target.value);
                    }}
                    className="inp_contact"
                    placeholder="Line 2"
                  ></input>
                </div>
                <h4>City</h4>
                <div className="inp_wrap my-3">
                  <input
                    type="email"
                    onChange={(e) => {
                      setCity(e.target.value);
                    }}
                    value={city}
                    className="inp_contact"
                    placeholder="City"
                  ></input>
                </div>
                <h4>Postcode</h4>
                <div className="inp_wrap my-3">
                  <input
                    type="email"
                    onChange={(e) => {
                      setpostcode(e.target.value);
                    }}
                    value={postcode}
                    className="inp_contact"
                    placeholder="Postcode "
                  ></input>
                </div>
                <span onClick={serach_inppp}>Edit Addres</span>

                <button className=" btn_contact my-3" onClick={submit_address} >Submit</button>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className="main_Wrap_ss col-lg-5 col-md-5 col-sm-12 border">
              <label>Enter postcode or the first line of the address</label>
              <div className="mt-2 inp_main" id="inp_main">
                <input
                  type="text"
                  className="inp_inp"
                  placeholder="srearch"
                  onChange={citylisthandler}
                  value={inpvalue}
                ></input>

 
  


                <div className="srech_">
                  {loading == "true" ? <PuffLoader /> : <FaSearch size={17} />}
                </div>
              </div>
              {divshow == "true" ? (
                <div className="mt city_list">
                  {
                    // citeslist.suggestions.length > 0 ?
                    citeslist.map((item) => {
                      return (
                        <>
                          <ul>
                            <li
                              className="lii"
                              onClick={() => {
                                citydata(item);
                              }}
                            >
                              {" "}
                              {item.address}
                            </li>
                          </ul>
                        </>
                      );
                    })

                    // :
                    // <div></div>
                  }
                </div>
              ) : (
                ""
              )}
            </div>
            <div></div>
          </>
        )}
      </div>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
    </>
  );
}

const mapStateToProps = (state) => ({
  cityes_List_Data: state.cardeailupdate,
  addressform: state.cardeailupdate.addressform,
});
const mapDispatchToProps = (dispatch) => ({
  // get product list
  sumit_form: (data) =>
    dispatch({
      type: ADDRESS_FORM,
      payload: {
        type: ADDRESS_FORM,
        method: POST,
        label: Labels.address_form,
        hideOverlayLoader: true,
        data,
      },
    }),

  //
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchCity);
