import { createContext, useState, useEffect } from "react";

const FeedbackContext = createContext();
const d = {
  buy_now_page: {
    No_Car_Avaible: "No Car Available",
    Grid_text: "Grid",
    List_text: "List",
  },
  login_page: {
    login_text: "Log-in",
    user_name: "Username",
    user_name_placeholder: "Enter User Name",
    password_text: "Password",
    password_placeholder: "Enter Password",
    remember_text: "Remember me",
    submit_text: "SUBMIT",
    forget_text: "Forgot Password",
    dont_text: "Don't Have an Account",
    signup_text: "Sign-Up?",
    logourl: "https://dealers.sellanywheel.com/static/media/login.f1dc3995.png",
    image_text:
      "Hello! Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
  },
  forget_password_page: {
    forget_pass_text: "Forget Password",
    email_placeholder: "Email Address",
    btn_forget_pass: "Forget Password",
  },
  change_password_page: {
    change_pass_text: "New Password",
    new_pass_placeholder: "New Password",
    btn_change_pass: "Change Password",
  },
  signup_page: {
    signup_text: "Sign-up",
    fname_text: "First Name",
    fname_placeholder: "First Name",
    Lname_text: "Last Name",
    Lname_placeholde: "Last Name",
    email_text: "Email Address",
    email_placeholder: "Enter Email",
    userName: "Username",
    Uname_placeholder: "Enter Username",
    company_text: "Company  Name",
    Cname_placeholder: "Company Name",
    position_text: "Position",
    select_postion0: "Select position",
    select_postion1: "Buyer",
    select_postion2: "CEO/Managing Director",
    select_postion3: "Director",
    select_postion4: "Group Buyer",
    select_postion5: "Group Sales Manager",
    select_postion6: "Owner/ Proprietor",
    select_postion7: "Sales Manager",
    select_postion8: "Stock Controller",
    Mobile_no_text: "Mobile Number",
    mobile_no_placeholder: "Mobile Number",
    password_signup: "Password",
    password_signup_placeholder: "Enter Password",
    rememberme_text: "Remember me",
    register_text: "REGISTER",
    already_text: "Already Have an Account",
    loginText: "Login?",
    logourl: "https://dealers.sellanywheel.com/static/media/login.f1dc3995.png",
    image_text:
      "Hye! Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
  },
  user_profile_page: {
    profile_info_text: "Profile Information",
    statistics_text: "Activities/Statistics",
    edit_profile_text: "Edit Profile",
  },
  profile_info_page: {
    profile_info_text: "Profile Information",
    first_name_text: "First Name:",
    last_name_text: "Last Name:",
    username_text: "Username: ",
    email_text: "Email: ",
    company_name_text: "Company: ",
    position_text: "Position: ",
    mobile_no_text: "Mobile: ",
  },
  statistics_page: {
    statistics_text: "Statistics",
    bids_won_text: "Bids Won",
    total_bids_text: "Total Bids",
    bids_active_text: "Bids Active",
  },
  edit_profile_page: {
    edit_profile_text: "Edit Profile",
    profile_picture_text: "Upload Photo",
    fname_text: "First Name",
    fname_placeholder: "e.g. John",
    Lname_text: "Last Name",
    Lname_placeholde: "e.g. Doe",
    email_text: "Email Address",
    email_placeholder: "e.g. example@address.com",
    userName: "User Name",
    Uname_placeholder: "e.g. JohnDoe",
    company_text: "Company",
    Cname_placeholder: "Company Name",
    position_text: "Position",
    select_postion0: "Select position",
    select_postion1: "Buyer",
    select_postion2: "CEO/Managing Director",
    select_postion3: "Director",
    select_postion4: "Group Buyer",
    select_postion5: "Group Sales Manager",
    select_postion6: "Owner/ Proprietor",
    select_postion7: "Sales Manager",
    select_postion8: "Stock Controller",
    Mobile_no_text: "Mobile Number",
    mobile_no_placeholder: "Mobile Number",
    change_password_text: "Change Password",
    current_password_text: "Current Password",
    new_password_text: "New Password",
    confirm_password_text: "Confirm Password",
    password_placeholder: "••••••",
    save_changes_text: "SAVE CHANGES",
    save_password_text: "SAVE PASSWORD",
  },
  curveNavBar: {
    homeTitle: "Home",
    buyTitle: "Buy Now",
    auctionTitle: "Auctions",
    interestTitle: "Show Interest",
    myInterest: "My Interest",
    bidsTitle: "My Bids",
    loginLabel: "login",
    logoutLabel: "logout",
  },
  home_page: {
    homeTitle: "Home",
    buyTitle: "Buy Now",
    auctionTitle: "Auctions",
    interestTitle: "Show Interest",
    myInterest: "My Interest",
    bidsTitle: "My Bids",
    loginLabel: "login",
    logoutLabel: "logout",
    Home: "Home",
    My_Appointment: "My Appointment",
    Dost_Program: "Dost Program",
    Sell_Any_Wheel: "Sell Any Wheel",
    How_Works: "How It Works",
    For_Dealers: "For Details",
    More: "More",
    More_Contact: "More Contact",
    More_Privacy_Policy: "More Privacy Policy",
    More_FAQs: "More FAQs",
    More_Documents: "More Documents",
    More_About_us: "More About Us",
    banner_background_image:
      "https://dealers.sellanywheel.com/static/media/landingPageBanner.791494f2.jpg",
    banner_background_image2:
      "https://dealers.sellanywheel.com/static/media/landingPageBanner.791494f2.jpg",
    car_reg_text: "Please Enter Your Car Reg Number For Car Details.",
    car_reg_placeholder: "Enter Reg",
    car_reg_button_text: "Value your car",
    car_reg_button_loding_text: "Loading",
    baner_heading:
      "Selling, buying and exchanging your car has never been this easy",
    baner_sub_heading1: "No hassle with the paperwork",
    baner_sub_heading2: "Get paid immediately",
    baner_sub_heading3: "Sell your car in an hour",
    sell_your_car1: "Sell Your Car",
    get_car_price_button: "Get Car Price",
    brand_title: "Brand",
    model_title: "Model",
    fuel_type_title: "Fuel Type",
    year_title: "Year",
    Variant_title: "Variant",
    what_doest: "What does WFAC offer?",
    buy_car_heading: "Buy a car",
    buy_car_desp: "Browse through 1000+ cerified cars",
    sell_your_car: "Sell your car",
    sell_your_car_desp: "Sell your car instantly and safely within an hour",
    exchange_car: "Exchange your car",
    exchange_car_desp: "Exchange for a new or used car",
    exchange_car_cooming_soon: "Cooming Soon",
    what_expect_img:
      "https://dealers.sellanywheel.com/media/icon/whychooseus-pk.jpg",
    what_expect_heading: "What to expect",
    what_expect_desp: "How do we compare with other ways to sell?",
    fair_prices_heading: "Fair prices",
    fair_prices_desp:
      "Whether you sell, buy or exchange your car, we can assure market-conform and fair prices.",
    A_clear_heading: "A clear and transparent process",
    A_clear_desp:
      "We offer a clear and transparent process for all of our clients using any of our services. We will help you in every step on the way.",
    Secure_safe_heading: "Secure and safe transactions",
    Secure_safe_desp:
      "Never need to worry anymore about unsafe payments. We got your back with our secure and certified RC transfers.",
    We_arrange_heading: "We arrange your paperwork",
    We_arrange_desp:
      "Never worry about the hassle of doing paperwork. Just bring the required documents and we will do the rest.",
    happy_customers_heading: "1000+ happy customers",
    happy_customers_desp:
      "Don't take our word for it! Read our customer's reviews.",
    how_heading: "How it Works",
    signup_imageurl1:
      "https://dealers.sellanywheel.com/static/media/1.df21724d.svg",
    download_imageurl2:
      "https://dealers.sellanywheel.com/static/media/3.16fa70f9.svg",
    browse_imageurl3:
      "https://dealers.sellanywheel.com/static/media/4.ce512232.svg",
    done_imageurl4:
      "https://dealers.sellanywheel.com/static/media/5.619ef1a2.svg",
    signup_txt: "Sign up",
    signup_description:
      "Register your company! We verify every dealer to ensure our sales stay secure and hassle-free",
    Downloadapp_text: "Download the app",
    download_description:
      "Have constant access to all vehicles via our mobile app.",
    browse_text: "Browse and Bid",
    browse_description:
      "Browse our vehicle lists, find the ones that interest you and place a bid!",
    done_text: "Done",
    done_description: "if yor win,the car is yours!",
    any_question: "Any question ?",
    any_question_desp:
      "You'll find the answers Of your questions in our FAQs section.",
    call_number: "123 456 789",
    call_time: "11:30am - 07:30pm",
    contact_us: "Contact",
    Privacy_Policy: "Privacy Policy",
    FAQs: "FAQs",
    Required_documents: "Required documents",
    Terms_conditions: "Terms and conditions",
    About_us: "About us",
    what_do_you_want: "What do you want to do?",
    range_of_services: "Choose from our range of services.",
    Buy_Now_button_text: "Buy Now",
    Auction_button_text: "Auction",
    Interest_button_text: "Interest",
    Subscribe_text: "Subscribe",
    Subscribe_desp: "Subscribe our newsletter to stay update every moment",
    Subscribe_placeholder: "abc@gmail.com",
    Subscribe_button_text: "Subscribe",
    All_right_text: "All Rights Reserved",
    All_right_year: "2020",
    Quality_cars: "Quality cars for the best prices",
    Want_access_to:
      "Want access to more cars? Sign up and join our daily sales!",
    All_cars_inspected: "All cars inspected and document-checke.",
    selling_platform: "No-fuss selling platform – bid or buy with one click.",
    Full_support_team: "Full support team available to answer your questions.",
    main_image:
      "https://dealers.sellanywheel.com/media/images/banner-thumb_2vD7t8T.png",
  },
  privacy_plicy_page: {
    main_heading: "PRIVACY POLICY",
    prha1:
      "Last updated on 06-08-2020. Please find the previous version of your Privacy Policy here. We care about your privacy and are committed to protecting your personal data. This privacy statement will inform you how we handle your personal data and your privacy rights. Please read this privacy statement carefully before using our Services.",
    What_data1: "1.1. What data do we collect about you?",
    What_data2: "1.1 Data provided through direct interactions",
    Information_provided:
      "Information provided through contact form on the Platform",
    Location_information: "Location information",
    prha2:
      " Depending on your device permissions, if you opt to locate the nearest inspection centre, we automatically collect and process information about your actual location. We use various technologies to determine location, including IP address, GPS, Wi-Fi access points and mobile towers. Your location data allows you to see inspection centres near you and helps in suggesting inspection centres near you. Cookies and Similar Technologies We use cookies to manage our users' sessions, to store your preferences language selection and deliver you relevant advertisements.  are small text files transferred by a web server to the hard drive of.",
    html_code:
      "<div class='content'><ul ><li>Car Brand</li><li>Car Model</li><li>Car Trim</li><li>Car Year</li><li>Car Variant/Trim</li><li>Car mileage</li><li>License plate</li><li>Contact Number</li><li>Contact email</li></ul></div>",
  },
  contact_us: {
    contact_text: "CONTACT US",
    contactus_description: "How to get in touch with Sell Any Wheel",
    sell_image:
      "https://dealers.sellanywheel.com/static/media/mobile-svgrepo-com.d0ac8b19.svg",
    sell_text: "Sell your car",
    sell_description: "Get a price for your car from our advisor if you want?",
    sell_bottomtab: "12345678",
    questions_image:
      "https://dealers.sellanywheel.com/static/media/question-svgrepo-com.0c119e4a.svg",
    questions_text: "Questions",
    questions_description: "Find answers in our online support about car.",
    questions_bottomtab: "Go to FAQs",
    chat_iamge:
      "https://dealers.sellanywheel.com/static/media/chat-sms-svgrepo-com.bc60d15f.svg",
    chat_text: "Chat Online",
    chat_description: "If you Want to speak somebody, please text?",
    chat_bottomtab: "Let's Talk",
    sellanywheel_heading: "Sell Any Wheel",
    sellanywheel_description:
      "Whether you're an individual looking to sell your car, an organisation interested in talking about offloading a fleet of vehicles or would just like to find out more about what we do and how we could work together, get in touch using the details below",
    address_text: "Address",
    address_description: "Gulberg II. Lahore",
    phoneno_text: "Phone",
    phone_no: "123 456 789",
    contactus_form: "Contact Us",
    firstname_placeholder: "First Name",
    lastname_placeholder: "Last Name",
    email_placeholder: "Email",
    leave_placeholder: "Leave Comment",
    contactus_submit: "Contact Us",
  },
  show_interest: {
    interested_cars: "INTERESTED CARS",
    interested_cars_description: "tell us about your intersted cars",
    select_text: "Select Cars Brands",
    search_text: "Search",
    brands_search: "Brands",
    brands_text: "brands",
    choose_car: "Choose car",
    your_interest: "of your interest",
    models_text: "models",
    fuel_type: "fuel_types",
    years_text: "years",
    varients_text: "variants",
  },
  form_component: {
    search_placeholder: "Search",
    show_nothing: "nothing to show",
    next_btn: "Next",
  },
  my_interest: {
    interested_text: "MY INTERESTED CARS",
    interested_desc: "tell us about your interested cars",
    no_interest: "No Interest Found",
    alert_dialog_description:
      "Are you sure you want to delete your interested car.",
    yes: "Yes",
    no: "No",
    submit_date: "Submit Date",
    preview_text: "PREVIEW",
  },
  card_detail: {
    detail_text: "My Cars Details",
    description: "some information about my cars",
    no_data: "No Data Available",
    Post_code: "Postcode",
    Previous_owner: "Previous_owners",
    Service_history: "Service_history",
    fuel_type: " fuel_type",
    mod_name: "model_name",
    reg_no: " reg_number",
    year_name1: "year_name",
    year_name2: "year_name",
  },
  car_report_page: {
    report_text: "Exterior Condition",
    car_certification_image:
      "https://wsa4.pakwheels.com/assets/car_certification_v2-e9ae77bea60843256dbd759106c2911e.jpg",
    car_legend_text: "Legend",
  },
  about_us: {
    who_text: "HOW WE ARE",
    who_desc: "We are transforming the way used cars are traded",
    our_text: "Our Services",
    our_desc:
      "Sell Any Wheel is transforming the way used-cars are traded in Pakistan, with a network of over 35+ purchase and sale centers in Lahore, Karachi, Islamabad, Faisalabad, Multan and Hyderabad. Car owners can now easily sell their cars through our secure, convenient, and hassle-free process. Sell Any Wheel also provides car dealers easy access to nationwide inventory through their online sales platform. Sell Any Wheel is a name and brand owned and operated wholly by Augmentech Business Solutions (Pvt) Ltd.",
    a_new_text: "A new standard in used cars sales",
    a_new_desc:
      "You can now get your car inspected and sold at a fair price through our safe and hassle free process. Our nationwide network of purchase centers use an internally developed algorithm, and a professionally trained team to conduct inspections.",
    pakistan_text: "Pakistan's first automated sales platform for car dealers",
    pakistan_desc:
      "You can now get your car inspected and sold at a fair price through our safe and hassle free process. Our nationwide network of purchase centers use an internally developed algorithm, and a professionally trained team to conduct inspections.",
  },
  term_condition: {
    term_text: "terms of use",
    term_desc:
      "THESE TERMS OF USE WERE UPDATED ON 06-08-2020. PLEASE CAREFULLY READ THESE TERMS OF USE. BY USING THIS PLATFORM OR OUR SERVICES, YOU INDICATE YOUR UNDERSTANDING AND ACCEPTANCE OF THESE TERMS. IF YOU DO NOT AGREE TO THESE TERMS YOU MAY NOT USE THE PLATFORM.",
    acceptance_text: "1. Acceptance",
    acceptance_descp1:
      "1.1 Augmentech Business Solutions (Pvt) Ltd located at 28-A, Block K, 1st Floor, MM Tower, Gulberg, Lahore (“Company”, “we”, “us”, “our”) provides services which facilitate the users of the website at www.Sell Any Wheel.com and the mobile application (collectively to be referred as the “Platform”) to sell or buy Vehicles (“Vehicle”) in a convenient manner (collectively to be referred as the “Services”). Your use of the Platform and the Services are subject to these Terms of Use (these 'Terms'). For the purpose of the Terms and wherever the context so requires, the terms 'you' and 'your' shall mean any person who uses or accesses, whether through manual or automated means, the Platform or the Service in any manner whatsoever including persons browsing the Platform and its content, posting comments or any content or responding to any content on the Platform.",
    acceptance_descp2:
      "1.2 By proceeding to use the Platform, you warrant that you are eligible to contract under applicable laws and you are authorised under law to sell the Vehicle, the details of which you have provided on the Platform. If you are using or accessing the Platform as a representative of any person/entity, you acknowledge that you are legally authorised to represent such person/entity. You affirm that you are eligible and competent as per the applicable laws to enter into the terms, conditions, obligations, affirmations, representations, and warranties set forth in these Terms, and to abide by and comply with these Terms.",
    acceptance_descp3:
      "1.3 These Terms may be updated by us at any time at our sole discretion. You understand and agree that you are solely responsible for reviewing these Terms from time to time. Should you object to any term or condition of these Terms, any guideline, or any subsequent changes thereto or become unhappy with our Platform in any way, your only option is to immediately discontinue using the Platform and the Services.",
    desc_text: "2. Description of Platform and Services",
    descp_1:
      "1.1 Augmentech Business Solutions (Pvt) Ltd located at 28-A, Block K, 1st Floor, MM Tower, Gulberg, Lahore (“Company”, “we”, “us”, “our”) provides services which facilitate the users of the website at www.Sell Any Wheel.com and the mobile application (collectively to be referred as the “Platform”) to sell or buy Vehicles (“Vehicle”) in a convenient manner (collectively to be referred as the “Services”). Your use of the Platform and the Services are subject to these Terms of Use (these 'Terms'). For the purpose of the Terms and wherever the context so requires, the terms 'you' and 'your' shall mean any person who uses or accesses, whether through manual or automated means, the Platform or the Service in any manner whatsoever including persons browsing the Platform and its content, posting comments or any content or responding to any content on the Platform.",
    descp_2:
      "1.2 By proceeding to use the Platform, you warrant that you are eligible to contract under applicable laws and you are authorised under law to sell the Vehicle, the details of which you have provided on the Platform. If you are using or accessing the Platform as a representative of any person/entity, you acknowledge that you are legally authorised to represent such person/entity. You affirm that you are eligible and competent as per the applicable laws to enter into the terms, conditions, obligations, affirmations, representations, and warranties set forth in these Terms, and to abide by and comply with these Terms.",
    descp_3:
      "1.3 These Terms may be updated by us at any time at our sole discretion. You understand and agree that you are solely responsible for reviewing these Terms from time to time. Should you object to any term or condition of these Terms, any guideline, or any subsequent changes thereto or become unhappy with our Platform in any way, your only option is to immediately discontinue using the Platform and the Services.",
  },
  FAQs: {
    frequent_text: "Frequently asked.",
    box1_desc: "Wondering how to sell any wheel?",
    box2_desc: "WHAT DO I NEED TO DO?",
    box2_dropdown_desc: "What do I need to bring along?",
    box3_text: "EVALUTION",
    box3_dropdown_desc:
      "Can the actual value differ from the online valuation?",
    dropdown1_desc: "How does the free online evalution work?",
    dropdown1_p1:
      "On www.Sell Any Wheel.com, get an initial estimate for your car by following these steps: Enter the make,",
    dropdown1_p2:
      "On www.Sell Any Wheel.com, get an initial estimate for your car by following these steps: Enter the make,",
    dropdown2_p3:
      "Got your instant evaluation and interested in selling your car?",
    dropdown2_p4:
      "Book your appointment online or call us on on 0304 1111 CAR (227)",
    dropdown1_p5:
      "On www.Sell Any Wheel.com, get an initial estimate for your car by following these steps: Enter the make,",
    dropdown1_p6:
      "On www.Sell Any Wheel.com, get an initial estimate for your car by following these steps: Enter the make,",
    dropdown1_p7:
      "On www.Sell Any Wheel.com, get an initial estimate for your car by following these steps: Enter the make,",
    dropdown2_desc: "Free Inspection",
  },
  requridocuments_page: {
    Bringing_Documents_heading: "  Bringing Documents for Inspection",
    I_own_my_vehicle_heading: "I own my vehicle",
    I_own_my_vehicle_doc_1: "CNIC",
    I_own_my_vehicle_doc_2: "Registration Book",
    I_own_my_vehicle_doc_3: "Complete File",
    I_own_my_vehicle_doc_4: "Transfer Letter",
    some_money_on_my_vehicle_heading: "I still owe some money on my vehicle",
    some_money_on_my_vehicle_doc_1: "CNIC",
    some_money_on_my_vehicle_doc_2: "Registration Book",
    some_money_on_my_vehicle_doc_3: "Complete File",
    some_money_on_my_vehicle_doc_4: "Transfer Letter",
    vehicle_belonging_to_company_heading:
      "I am selling a vehicle belonging to a company.",
    vehicle_belonging_to_company_doc_1: "CNIC",
    vehicle_belonging_to_company_doc_2: "Registration Book",
    vehicle_belonging_to_company_doc_3: "Complete File",
    vehicle_belonging_to_company_doc_4: "Transfer Letter",
    behalf_of_friend_heading:
      " I am selling a vehicle on behalf of a friend of relative.",
    behalf_of_friend_doc_1: "CNIC",
    behalf_of_friend_doc_2: "Registration Book",
    behalf_of_friend_doc_3: "Complete File",
    behalf_of_friend_doc_4: "Transfer Letter",
  },
  auction_page: {
    Filter_text: "Filter",
    List_text: "List",
    Grid_text: "Grid",
    Auction_Won_text: "Auction Won",
    _65_text: "65",
    Today_text: "Today",
    _115_text: "115",
    Week_text: "Week",
    _153_text: "153",
    Month_text: "Month",
    no_data_active_buying: "No Data Found",
    no_data_watchlist: "No Data Found",
    "Pick-up_location_placeholder": "Pick-up location",
    Registered_city_placeholder: "Registered city",
    "Make_&_modelplaceholder": "Make & model",
    Pick_up_location_title: "Pick-up location",
    Search_placeholder: "Search",
    Registered_city_title: "Registered city",
    Show_all_title: "Show all",
    Show_less_title: "Show less",
    Make_title: "Make",
    Make_model: "Model",
    Make_model_2019: "2019",
    to_title: "to",
    From_title: "From",

    filter_title: "No filter",
    Active_Buying_text: "Active Buying",
    Watchlist_text: "Watchlist",
    Make_model_title: "Make & model",
    Mileage_title: "Mileage",
    Price_title: "Price",
    Year_title: "Year",
    Gearbox_Engine_title: "Gearbox & Engine",
    Gearbox_title: "Gearbox",
    Gearbox_type1: "Manual",
    Gearbox_type2: "Automatic",
    engine_size_title: "engine size",
    colors_title: "colors",
    colors_value: "colors",
    Fuel_efficiency_title: "Fuel & Efficiency",
    Fuel_Type_title: "Fuel Type",
    Fuel_Type_petrol: "petrol",
    Fuel_Type_diesel: "diesel",
    Fuel_Type_electric: "electric",
    Fuel_Type_hybrid: "hybrid",
    Fuel_Type_plug_in_hybrid: "plug-in hybrid",
    Drive_Type_title: "Drive Type",
    Drive_Type_Four_wheel_drive: "Four wheel drive",
    Drive_Type_Front_wheel_drive: "Front wheel drive",
    Drive_Type_Rear_wheel_drive: "Rear wheel drive",
    Doors_Seats_title: "Doors & Seats",
    Doors_title: "Doors",
    Doors_type2_3_Doors: "2-3 Doors",
    Doors_type4_5_Doors: "4-5 Doors",
    Seats_title: "Seats",
    Seats_type_2_Seats: "2 Seats",
    Seats_type_4_Seats: "4 Seats",
    Seats_type_5_Seats: "5 Seats",
    Seats_type_7_Seats: "7 Seats",
    Show_Results: "Show Results",
    Shiping_Charges: "Shiping Charges",
    Enter_Your_Post_Code: "Enter Your Post Code to Calculate shiping charges",
    LEG321_placeholder: "LEG321",
    Calculate_button_placeholder: "Calculate",
    Calculating_button_placeholder: "Calculating",
    Total_text: "Total:",
    Place_Bid_text: "Place Bid",
    approxy_bid_text: "approxy bid",
    approxy_bid_placeholder: "Approxy Bid",
    Placing_Bid_text: " Placing Bid ...",
    Placing_Approxy_text: "Placing Approxy bid ...",
    Insepction_Report_text: "Insepction Report",
    total_bids_text: "total bids",
    My_Bids_text: "My Bids",
    Last_Bid_Amount_text: "Last Bid Amount",
    Price_text: "Price",
    Reserved_text: "Reserved",
    SOLD_text: "SOLD",
    General_Information_text: "General Information",
    Features_text: "Features",
    Images_text: "Images",
  },
  My_Bids_Page: {
    Winer_List_heading: "Winer List",
    Winer_List_desp: "How to get in touch with Sell Any Wheel",
    No_Data_Found: "No Data Found",
    your_book_car: "this is your book car",
    Book_Shiping_button: "Book Shiping",
  },
  shiping_form_page: {
    Shiping_Form_heading: "Shiping Form",
    City_Post_Code_label: "City Post Code",
    City_Post_Code_placeholder: "City Post Code",
    Description_label: "Description",
    Description_placeholder: "Description",
    Address_label: "Address",
    Address_placeholder: "srearch",
    Sumit_Form_button: " Sumit Form",

    selectFleet: "Select Fleet Type",
    singFleet: "Single Fleet",
    multipFleet: "Multiple Fleets",
    numOfFleets: "Select Number of Fleet",
  },
  order_booked_page: {
    Shiping_Form: "Shiping Form ",
    get_in_touch: " How to get in touch with Sell Any Wheel",
    Book_Car_Shiping: "Book Car Shiping",
    please_boook_car_shiping: "please boook car shiping for yor shipment",
    boook_car_text: "Book Car",
    Order_Number_text: "Order Number",
    Your_Order_Booked_text: " Your Order is Booked",
  },
};

export const FeedbackProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [feedback, setFeedback] = useState(d);
  const [feedbackEdit, setFeedbackEdit] = useState({
    item: {},
    edit: false,
  });

  useEffect(() => {
    fetchFeedback();
  }, []);

  // Fetch feedback
  const fetchFeedback = async () => {
    const response = await fetch(`/data/datafile.json`);
    const data = await response.json();
    console.log("feedbackl--->> ", data);

    setFeedback(data);
    setIsLoading(false);
  };
  // Add feedback
  const addFeedback = async (newFeedback) => {
    const response = await fetch("/feedback", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newFeedback),
    });

    const data = await response.json();

    setFeedback([data, ...feedback]);

    setFeedbackEdit({
      item: {},
      edit: false,
    });
  };

  // Delete feedback
  const deleteFeedback = async (id) => {
    if (window.confirm("Are you sure you want to delete?")) {
      await fetch(`/feedback/${id}`, { method: "DELETE" });

      setFeedback(feedback.filter((item) => item.id !== id));
    }
  };

  // Update feedback item
  const updateFeedback = async (id, updItem) => {
    const response = await fetch(`/feedback/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updItem),
    });

    const data = await response.json();

    // NOTE: no need to spread data and item
    setFeedback(feedback.map((item) => (item.id === id ? data : item)));

    // FIX: this fixes being able to add a feedback after editing
    // credit to Jose https://www.udemy.com/course/react-front-to-back-2022/learn/lecture/29768200#questions/16462688
    setFeedbackEdit({
      item: {},
      edit: false,
    });
  };

  // Set item to be updated
  const editFeedback = (item) => {
    setFeedbackEdit({
      item,
      edit: true,
    });
  };

  return (
    <FeedbackContext.Provider
      value={{
        feedback,
        feedbackEdit,
        isLoading,
        deleteFeedback,
        addFeedback,
        editFeedback,
        updateFeedback,
      }}
    >
      {children}
    </FeedbackContext.Provider>
  );
};

export default FeedbackContext;
