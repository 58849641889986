import { useState, React } from "react";

import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Button, Collapse } from "react-bootstrap";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./style.css";
import { styled } from "@mui/material/styles";
import { scrollTop } from "../../utils/window";

import { useContext } from "react";
import FeedbackContext from "../../context/cmscontext";
import IconButton from "@mui/material/IconButton";
import Footer from "../../components/Footer";
const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;


  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <span>{children}</span>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function VerticalTabs() {
  const [value, setValue] = useState(0);

  const [expanded, setExpanded] = useState(false);
  const [expanded_a, setExpanded_a] = useState(false);
  const [expanded_b, setExpanded_b] = useState(false);
  const [expanded_c, setExpanded_c] = useState(false);

  const handleExpandClick_a = () => {
    setExpanded_a(!expanded_a);
  };
  const handleExpandClick_b = () => {
    setExpanded_b(!expanded_b);
  };
  const handleExpandClick_c = () => {
    setExpanded_c(!expanded_c);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
     const { feedback, isLoading } = useContext(FeedbackContext);
     var FAQs = feedback.FAQs;
  return (
    <>
      {scrollTop()}
      <div className="showInterest_headingCont ">
        <div className="showInterest_heading">{FAQs.frequent_text}</div>
      </div>

      <div className="container mt-5">
        <Box
          sx={{
            flexGrow: 1,
            bgcolor: "background.paper",
            display: "flex",
            height: 224,
          }}
        >
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            sx={{ borderRight: 1, borderColor: "divider" }}
          >
            <Tab label={FAQs.box1_desc} {...a11yProps(0)} />
            <Tab label={FAQs.box2_desc} {...a11yProps(1)} />
            <Tab label={FAQs.box3_text} {...a11yProps(2)} />
          </Tabs>
          {/* <TabPanel value={value} index={0}>
            <div className="quesetion-wrap">
              <span
                className="span_text"
  
                onClick={handleExpandClick_a}
    
              >
                {" "}
                {FAQs.dropdown1_desc}
              </span>

              <ExpandMore
                expand={expanded_a}
                onClick={handleExpandClick_a}
                aria-expanded={expanded}
                aria-label="show more"
              >
                <ExpandMoreIcon fontSize="large" style={{ fill: "white" }} />
              </ExpandMore>
            </div>

            <Collapse in={expanded_a}>
              <div id="example-collapse-text">
                <span>{FAQs.dropdown1_p1}</span>
                <span>{FAQs.dropdown1_p2}</span>
              </div>
            </Collapse>

             <div className="quesetion-wrap">
              <span
                className="span_text"
                // expand={expanded_b}
                onClick={handleExpandClick_b}
                // aria-expanded={expanded}
              >
                {" "}
                {FAQs.dropdown2_desc}
              </span>

              <ExpandMore
                expand={expanded_b}
                onClick={handleExpandClick_b}
                aria-expanded={expanded}
                aria-label="show more"
              >
                <ExpandMoreIcon fontSize="large" style={{ fill: "white" }} />
              </ExpandMore>
            </div> 

            <Collapse in={expanded_b}>
              <div>
                <span>{FAQs.dropdown2_p3}</span>
                <span>{FAQs.dropdown2_p4}</span>
              </div>
            </Collapse>

            <hr></hr>
          </TabPanel> */}
          <TabPanel value={value} index={0}>
            <div className="quesetion-wrap">
              <span
                className="span_text"
                //  expand={expanded_a}
                onClick={handleExpandClick_a}
                //  aria-expanded={expanded}
              >
                {FAQs.dropdown1_desc}
              </span>

              <ExpandMore
                expand={expanded_a}
                onClick={handleExpandClick_a}
                aria-expanded={expanded}
                aria-label="show more"
              >
                <ExpandMoreIcon fontSize="large" style={{ fill: "white" }} />
              </ExpandMore>
            </div>

            <Collapse in={expanded_a}>
              <div>
                <p>{FAQs.dropdown1_p5}</p>
                <p>{FAQs.dropdown1_p6}</p>
                
              </div>
            </Collapse>

            <hr></hr>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <div className="quesetion-wrap">
              <span
                className="span_text"
                //  expand={expanded_a}
                onClick={handleExpandClick_a}
                //  aria-expanded={expanded}
              >
                {FAQs.box2_dropdown_desc}
              </span>

              <ExpandMore
                expand={expanded_a}
                onClick={handleExpandClick_a}
                aria-expanded={expanded}
                aria-label="show more"
              >
                <ExpandMoreIcon fontSize="large" style={{ fill: "white" }} />
              </ExpandMore>
            </div>

            <Collapse in={expanded_a}>
              <div>
                <p>{FAQs.dropdown1_p5}</p>
                <p>{FAQs.dropdown1_p6}</p>
                <p>{FAQs.dropdown1_p7}</p>
              </div>
            </Collapse>

            <hr></hr>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <div className="quesetion-wrap">
              <span
                className="span_text"
                //  expand={expanded_a}
                onClick={handleExpandClick_a}
                //  aria-expanded={expanded}
              >
                {FAQs.box3_dropdown_desc}
              </span>

              <ExpandMore
                expand={expanded_a}
                onClick={handleExpandClick_a}
                aria-expanded={expanded}
                aria-label="show more"
              >
                <ExpandMoreIcon fontSize="large" style={{ fill: "white" }} />
              </ExpandMore>
            </div>

            <Collapse in={expanded_a}>
              <div>
                <p>{FAQs.dropdown1_p5}</p>
                <p>{FAQs.dropdown1_p6}</p>
                <p>{FAQs.dropdown1_p7}</p>
              </div>
            </Collapse>

            <hr></hr>
          </TabPanel>
        </Box>

        <hr></hr>
      </div>
    </>
  );
}




