import { CAR_DETAIL_UPDATE,CITY_DETAIL_LIST,CHAKBOX_LIST,ADDRESS_FORM} from "../constant/index";

const INITIAL_STATE = {
  loading: true,
  data:[],
  productlist:[],

  step2complete:false,
  cityAddress:{},

  divindex:{},

  addressform:false

};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {

    case `${CAR_DETAIL_UPDATE}_REQUEST`:
      return { ...state, loading: true };
    case `${CAR_DETAIL_UPDATE}_SUCCESS`:
      // console.log"CAR_DETAIL_UPDATE ...........CAR_DETAIL_UPDATE",action.payload)

      return { ...state, 
        data:action.payload,
        loading:false,
        // step2complete:true,
      };
    case `${CAR_DETAIL_UPDATE}_FAIL`:
      return { ...state, loading: false };
    case `${CAR_DETAIL_UPDATE}_ERROR`:
      return { ...state, loading: false };
    //
  // =======================addrss form====================




  case `${ADDRESS_FORM}_REQUEST`:
    return { ...state, };
  case `${ADDRESS_FORM}_SUCCESS`:
    // console.log"CAR_DETAIL_UPDATE ...........CAR_DETAIL_UPDATE",action.payload)

    return { ...state, 
      addressform:true,
      step2complete:true,
      loading:false
     
      
    };
  case `${ADDRESS_FORM}_FAIL`:
    return { ...state, loading: false };
  case `${ADDRESS_FORM}_ERROR`:
    return { ...state, loading: false };
  //
// =======================product sale list====================








  case CHAKBOX_LIST:
      // console.log"checkbox_list ...........checkbox_list",action.payload," state ====> ",state)
      const f={...state.divindex};
      f[action.payload.data.index]=action.payload.data.div
            // return f
      return { 
        ...state,
     
      
        divindex:f,
      
      };








  }




  switch (action.type) {

    case `${CAR_DETAIL_UPDATE}_REQUEST`:
      return { ...state, loading: true };
    case `${CAR_DETAIL_UPDATE}_SUCCESS`:
      // console.log"CAR_DETAIL_UPDATE ...........CAR_DETAIL_UPDATE",action.payload)

      return { ...state, 
        data:action.payload,
        loading:false,
      };
    case `${CAR_DETAIL_UPDATE}_FAIL`:
      return { ...state, loading: false };
    case `${CAR_DETAIL_UPDATE}_ERROR`:
      return { ...state, loading: false };
    //
  // =======================product sale list====================
  



  case CITY_DETAIL_LIST:
      // console.log"CAR_DETAIL_UPDATE ...........CAR_DETAIL_UPDATE",action.payload)

      return { 
        cityAddress:action.payload.data,
      
      };



}







  return state;
};
