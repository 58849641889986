import React, { useState } from "react";
import "./style.css";
import { MdExpandMore, MdExpandLess } from "react-icons/md";

function Dropdown({
  children,
  placeholder,
  hideDivider,
  placeholderbackground,
  maxHeight,
}) {
  const [show, setShow] = useState(false);
  return (
    <div className="d-flex justify-content-center align-items-center flex-column">
      {!hideDivider ? <div className="divider" /> : null}
      <div
        style={{
          background: placeholderbackground ? "var(--info)" : "transparent",
        }}
        onClick={() => setShow(!show)}
        className="d-flex justify-content-between align-items-center text-capitalize side_bar_header_title dropdown-button"
      >
        {placeholder}
        {show ? <MdExpandLess /> : <MdExpandMore />}
      </div>
      {/* expanded-div */}
      {show ? (
        <div className={`${placeholderbackground ? "border" : null} w-100`} style={maxHeight?{maxHeight:"10rem", overflowY:"auto"}:null}>
          <div className="expanded-div">
            {/* body */}
            {children}
          </div>
        </div>
      ) : null}
      {/* <div className="divider" /> */}
    </div>
  );
}

export default Dropdown;
