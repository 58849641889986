import React from 'react'
import Tab from "../tabs"
import Watch from "../Auction Won/Watchlist";

function Shiping() {
  return (
    <>
                <div className="py-6 px-5 bg-white bg-opacity-75">
                  <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
                           </div>
                          < div className=" mt-3">
        <form>
          <div className="row  box8">
            <div className="col-sm-6 form-group">
              <label htmlFor="name-f">First Name</label>
              <input type="text" className="form-control" name="fname" id="name-f" placeholder="Enter your first name." required />
            </div>
            <div className="col-sm-6 form-group">
              <label htmlFor="name-l">Last name</label>
              <input type="text" className="form-control" name="lname" id="name-l" placeholder="Enter your last name." required />
            </div>
            <div className="col-sm-6 form-group">
              <label htmlFor="email">Email</label>
              <input type="email" className="form-control" name="email" id="email" placeholder="Enter your email." required />
            </div>
            <div className="col-sm-6 form-group">
              <label htmlFor="address-1">Address Line-1</label>
              <input type="address" className="form-control" name="Locality" id="address-1" placeholder="Locality/House/Street no." required />
            </div>
            <div className="col-sm-6 form-group">
              <label htmlFor="address-2">Address Line-2</label>
              <input type="address" className="form-control" name="address" id="address-2" placeholder="Village/City Name." required />
            </div>
            <div className="col-sm-6 form-group">
              <label htmlFor="State">State</label>
              <input type="address" className="form-control" name="State" id="State" placeholder="Enter your state name." required />
            </div>   
            <div className="col-sm-6 form-group">
              <label htmlFor="Country">Country</label>
              <select className="form-control p-1 ">
                <option value="Afghanistan">Northern</option>
                <option value="Åland Islands">Ireland</option>
              </select>
            </div>
            <div className="col-sm-12 form-group mb-0">
              <button className="btn btn-primary float-right">Submit</button>
            </div>
          </div>
        </form>
      </div>
                </div>

    </>
  )
}

export default Shiping
