import { useState, React, useEffect } from "react";
import Slider from "react-animated-slider";
import { useLocation, useHistory } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import "./style.css";
import { ImCross } from "react-icons/im";
import Step8 from "../Evaluation/Step8";
import { FaEdit } from "react-icons/fa";
import { Button, Collapse } from "react-bootstrap";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { scrollTop } from "../../utils/window";

import { styled } from "@mui/material/styles";

import IconButton from "@mui/material/IconButton";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

function Myappointmentdetail({ mycardata, appointment_update }) {
  const location = useLocation();
  let history = useHistory();
  const [index, setindex] = useState(location.state.index);

  const [userId, setuserId] = useState("");

  const app_update = (id) => {
    setShowResults(true);
    
    setuserId(id);
  };
  

  useEffect(() => {
    app_update_function();
  }, [appointment_update.appointment_create]);

  const imgArr = [
    "https://picsum.photos/200/310",
    "https://picsum.photos/200/320",
    "https://picsum.photos/200/340",
    "https://picsum.photos/200/350",
    "https://picsum.photos/200/360",
    "https://picsum.photos/200/340",
  ];
  

  const [expandeda, setExpandeda] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [expanded_a, setExpanded_a] = useState(false);
  const [expanded_b, setExpanded_b] = useState(false);
  const [expanded_c, setExpanded_c] = useState(false);

  const handleExpandClickA = () => {
    setExpandeda(!expandeda);
  };
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const handleExpandClick_a = () => {
    setExpanded_a(!expanded_a);
  };
  const handleExpandClick_b = () => {
    setExpanded_b(!expanded_b);
  };
  const handleExpandClick_c = () => {
    setExpanded_c(!expanded_c);
  };







  useEffect(() => {
    scrollTop()
  }, [])
  
  



  const [open, setOpen] = useState(false);

  
  const [showResults, setShowResults] = useState(false);

  const app_update_function = () => {
    if (Object.keys(appointment_update.appointment_create).length > 1) {
      appointment_update.step4 = {
        office: {
          Area: "",
          Postcode: "",
          address: "",
          id: "",
          timming: {},
        },
        time_selected: {
          id: 0,
          day_index: 0,
          slot: "",
          day: "",
          date: "",
          day_formated: "",
        },
      };
      

      mycardata[index].appointment = appointment_update.appointment_create;
 

      setShowResults(false);
      // setTimeout(() => {
      //  history.push("/myappointment")
      // }, 4000);
    }
  };

  if (showResults)
    return (
      <div className="Update_Sction mt-5">
        <div className="btn_wrap ">
          <button className="crosbtn " onClick={() => setShowResults(false)}>
            <ImCross />
          </button>
        </div>
        <div className="Udate_form mt-3">
          <Step8 name="update" appointmentId={userId} updateIndex={index} />
        </div>
      </div>
    );
  return (
    <>
      <div>
        {mycardata.length > 0 ? (
          <div className=" w-100 span-1 my-3">
            <div className="container pt-3">
              <div className="d-flex justify-content-end ">
                <h2> Treads Images</h2>
                <ExpandMore
                  expand={expandeda}
                  onClick={handleExpandClickA}
                  aria-expanded={expandeda}
                  aria-label="show more"
                >
                  <ExpandMoreIcon fontSize="large" />
                </ExpandMore>
              </div>
              <hr></hr>

              <div className="Car_Parts1_pic my-3 ">
                <Collapse in={expandeda}>
                  <div>
                    {mycardata[index].treads_images_.map((item, i) => {
                      return <img className="car_part_pic " src={item.image} />;
                    })}
                  </div>
                </Collapse>
              </div>

              <div className="d-flex justify-content-end">
                <h2> Exterior Images</h2>
                <ExpandMore
                  expand={expanded}
                  onClick={handleExpandClick}
                  aria-expanded={expanded}
                  aria-label="show more"
                >
                  <ExpandMoreIcon fontSize="large" />
                </ExpandMore>
              </div>
              <hr></hr>

              <div className="Car_Parts1_pic my-3 ">
                <Collapse in={expanded}>
                  <div>
                    {mycardata[index].exterior_images_.map((item, i) => {
                      return <img className="car_part_pic " src={item.image} />;
                    })}
                  </div>
                </Collapse>
              </div>



              <div className="d-flex justify-content-end">
                <h2> Interior Images</h2>
                <ExpandMore
                  expand={expanded_a}
                  onClick={handleExpandClick_a}
                  aria-expanded={expanded}
                  aria-label="show more"
                >
                  <ExpandMoreIcon fontSize="large" />
                </ExpandMore>
              </div>
              <hr></hr>
              <div className="Car_Parts1_pic my-3 ">
                <Collapse in={expanded_a}>
                  <div>
                    {mycardata[index].interior_images_.map((item, i) => {
                      return <img className="car_part_pic " src={item.image} />;
                    })}
                  </div>
                </Collapse>
              </div>
              <div className="d-flex justify-content-end">
                <hr></hr>
                <h2> Wheels Images</h2>
                <ExpandMore
                  expand={expanded_b}
                  onClick={handleExpandClick_b}
                  aria-expanded={expanded}
                  aria-label="show more"
                >
                  <ExpandMoreIcon fontSize="large" />
                </ExpandMore>
              </div>
              <hr></hr>

              <div className="Car_Parts1_pic my-3 ">
                <Collapse in={expanded_b}>
                  <div>
                    {mycardata[index].wheels_images_.map((item, i) => {
                      return <img className="car_part_pic " src={item.image} />;
                    })}
                  </div>
                </Collapse>
              </div>
              <div className="d-flex justify-content-end">
                <hr></hr>
                <h2> Damage Images</h2>
                <ExpandMore
                  expand={expanded_c}
                  onClick={handleExpandClick_c}
                  aria-expanded={expanded}
                  aria-label="show more"
                >
                  <ExpandMoreIcon fontSize="large" />
                </ExpandMore>
              </div>
              <hr></hr>

              <div className="Car_Parts1_pic my-3 ">
                <Collapse in={expanded_c}>
                  <div>
                    {mycardata[index].damage_images_.map((item, i) => {
                      return <img className="car_part_pic " src={item.image} />;
                    })}
                  </div>
                </Collapse>
              </div>
             

            
            </div>

            <div className="car_Data">
              {mycardata[index].appointment.length > 0 ? (
                <div className="mt-5 pt-5">
                  <div className=" mt-5">
                    <h1 className="heading1 ">My Appointment</h1>
                    <div className="edit_btn_wrap">
                      <button
                        className="edit_button"
                        onClick={() =>
                          app_update(mycardata[index].appointment[0].id)
                        }
                      >
                        <FaEdit size={25} />
                      </button>
                    </div>

                    <hr />
                  </div>
                  <div className="m-2 ">
                    <div className="list_data_wrapper ">
                      <div className="list_row">
                        <span
                          className="list_row_text"
                          style={{ fontWeight: "bold", flex: 1 }}
                        >
                          Address:
                        </span>
                        <span className="list_row_text list_text_secound" style={{ flex: 3 }}>
                          {mycardata[index].appointment[0].Address}
                        </span>
                      </div>

                      <div className="list_row">
                        <span
                          className="list_row_text"
                          style={{ fontWeight: "bold", flex: 1 }}
                        >
                          {" "}
                          Area:
                        </span>

                        <span className="list_row_text list_text_secound" style={{ flex: 3 }}>
                          {mycardata[index].appointment[0].Area}
                        </span>
                      </div>
                      <div className="list_row">
                        <span
                          className="list_row_text"
                          style={{ fontWeight: "bold", flex: 1 }}
                        >
                          {" "}
                          Time Selected:
                        </span>

                        <span className="list_row_text list_text_secound" style={{ flex: 3 }}>
                          {mycardata[index].appointment[0].time_selected}
                        </span>
                      </div>
                      <div className="list_row">
                        <span
                          className="list_row_text"
                          style={{ fontWeight: "bold", flex: 1 }}
                        >
                          {" "}
                          Date Selected:
                        </span>

                        <span className="list_row_text list_text_secound" style={{ flex: 3 }}>
                          {" "}
                          {mycardata[index].appointment[0].date_selected}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div></div>
              )}

              <div className="mt-2 pt-3">
                <div className=" mt-">
                  <h1 className="heading1 "> Car Details</h1>
                  <hr />
                </div>
                <div className="m-2 ">
                  <div className="list_data_wrapper ">
                    <div className="list_row">
                      <span
                        className="list_row_text"
                        style={{ fontWeight: "bold", flex: 1 }}
                      >
                        Brand:
                      </span>
                      <span className="list_row_text list_text_secound" style={{ flex: 3 }}>
                        {mycardata[index].brand_name}
                      </span>
                    </div>

                    <div className="list_row">
                      <span
                        className="list_row_text"
                        style={{ fontWeight: "bold", flex: 1 }}
                      >
                        {" "}
                        Model:
                      </span>

                      <span className="list_row_text list_text_secound" style={{ flex: 3 }}>
                        {mycardata[index].model_name}
                      </span>
                    </div>
                    <div className="list_row">
                      <span
                        className="list_row_text"
                        style={{ fontWeight: "bold", flex: 1 }}
                      >
                        {" "}
                        Fuel Type:
                      </span>

                      <span className="list_row_text list_text_secound" style={{ flex: 3 }}>
                        {mycardata[index].fuel_type}
                      </span>
                    </div>
                    <div className="list_row">
                      <span
                        className="list_row_text"
                        style={{ fontWeight: "bold", flex: 1 }}
                      >
                        {" "}
                        Year:
                      </span>

                      <span className="list_row_text list_text_secound" style={{ flex: 3 }}>
                        {" "}
                        {mycardata[index].year_name}
                      </span>
                    </div>

                    <div className="list_row">
                      <span
                        className="list_row_text"
                        style={{ fontWeight: "bold", flex: 1 }}
                      >
                        {" "}
                        Previous Owners:
                      </span>

                      <span className="list_row_text list_text_secound" style={{ flex: 3 }}>
                        {mycardata[index].Previous_owners}
                      </span>
                    </div>
                    {/* <div className="list_row">
                    <span className="list_row_text" style={{ fontWeight: "bold" , flex: 1 }}>
                      {" "}
                      Fuel Type:
                    </span>

                    <span className="list_row_text" style={{ flex: 3 }}> {mycardata[index].fuel_type}</span>
                  </div> */}

                    <div className="list_row">
                      <span
                        className="list_row_text"
                        style={{ fontWeight: "bold", flex: 1 }}
                      >
                        {" "}
                        Car
                      </span>

                      <span className="list_row_text list_text_secound" style={{ flex: 3 }}>
                        {mycardata[index].car}
                      </span>
                    </div>

                    <div className="list_row">
                      <span
                        className="list_row_text"
                        style={{ fontWeight: "bold", flex: 1 }}
                      >
                        {" "}
                        Service History
                      </span>

                      <span className="list_row_text list_text_secound" style={{ flex: 3 }}>
                        {mycardata[index].Service_history}
                      </span>
                    </div>

                    <div className="list_row">
                      <span
                        className="list_row_text"
                        style={{ fontWeight: "bold", flex: 1 }}
                      >
                        {" "}
                        Mileage
                      </span>

                      <span className="list_row_text list_text_secound" style={{ flex: 3 }}>
                        {mycardata[index].Mileage}
                      </span>
                    </div>
                    <div className="list_row">
                      <span
                        className="list_row_text"
                        style={{ fontWeight: "bold", flex: 1 }}
                      >
                        {" "}
                        Variant
                      </span>

                      <span className="list_row_text list_text_secound" style={{ flex: 3 }}>
                        {mycardata[index].variant_name}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-5 pt-2">
                <div className=" ">
                  <h1 className="heading1 "> User Information</h1>
                  <hr />
                </div>
                <div className="m-2 ">
                  <div className="list_data_wrapper ">
                    <div className="list_row">
                      <span
                        className="list_row_text"
                        style={{ fontWeight: "bold", flex: 1 }}
                      >
                        {" "}
                        User Name:
                      </span>
                      <span className="list_row_text list_text_secound" style={{ flex: 3 }}>
                        {" "}
                        {mycardata[index].Website_user.toUpperCase()}
                      </span>
                    </div>

                    <div className="list_row">
                      <span
                        className="list_row_text"
                        style={{ fontWeight: "bold", flex: 1 }}
                      >
                        {" "}
                        Email:
                      </span>

                      <span className="list_row_text list_text_secound" style={{ flex: 3 }}>
                        {mycardata[index].Email}
                      </span>
                    </div>
                    <div className="list_row">
                      <span
                        className="list_row_text "
                        style={{ fontWeight: "bold", flex: 1 }}
                      >
                        {" "}
                        Mobile Number:
                      </span>

                      <span className="list_row_text list_text_secound" style={{ flex: 3 }}>
                        {" "}
                        {mycardata[index].Mobile_number}
                      </span>
                    </div>
                    <div className="list_row">
                      <span
                        className="list_row_text"
                        style={{ fontWeight: "bold", flex: 1 }}
                      >
                        {" "}
                        Postcode:
                      </span>

                      <span className="list_row_text list_text_secound" style={{ flex: 3 }}>
                        {" "}
                        {mycardata[index].Postcode}
                      </span>
                    </div>
                    <div className="list_row">
                      <span
                        className="list_row_text"
                        style={{ fontWeight: "bold", flex: 1 }}
                      >
                        {" "}
                        Tracking Number
                      </span>

                      <span className="list_row_text list_text_secound" style={{ flex: 3 }}>
                        {" "}
                        {mycardata[index].tracking_number}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          history.push("/myappointment")
          // <div>aaaaaaaaaaaa</div>
        )}
      </div>
      {/*  */}
    </>
  );
}

const mapStateToProps = (state) => ({
  mycardata: state.myAppointment.data,
  appointment_update: state.sellMyCar,
});
const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Myappointmentdetail);
