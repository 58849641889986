// packeges
import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import $ from "jquery";
// components
import "../Login Page/loginstyle.css";
import carlogo from "../../assets/images/login.png";
import { Link, NavLink, useHistory } from "react-router-dom";
// redux related things
import { useContext } from "react";
import FeedbackContext from "../../context/cmscontext";
import Footer from "../../components/Footer"
import Howworks from "../../components/HowWorks"
import { connect } from "react-redux";
import Labels from "../../services/apiLabels";
import { SIGN_UP, TOASTER_SHOW, toaster_w } from "../../constant";
import { get } from "../../services/localStorage";
import { POST } from "../../constant/apiMethods";
// functional component
function Signup(props) {
  const { auth, signUp, toasterShow ,SIGN_UP_done} = props;
  let history = useHistory();
  // const [email, setEmail] = useState("");
  // const [username, setUsername] = useState("");
  // //  user name handler
  // useEffect(() => {
  //   if (email) {
  //     setUsername(email.split("@")[0]);
  //   } else {
  //     setUsername("");
  //   }
  // }, [email]);
  const { feedback, isLoading } = useContext(FeedbackContext);
var signup_page = feedback.signup_page;
// console.log"chk auth data",auth,"sigb up=======>>>>>>>",SIGN_UP_done)
useEffect(() => {
  
if(SIGN_UP_done.token){
  history.push("/login");
  SIGN_UP_done.token=""

}


}, [SIGN_UP_done])















  const signUpHandler = () => {
    let first_name = $("#first_name").val();
    let last_name = $("#last_name").val();
    let email = $("#email").val();
    let username = $("#username").val();
    let password = $("#password").val();
    const remember_me = $("#remember_me").is(":checked");
    const data = {
      first_name,
      last_name,
      username,
      password,
      email,
    };
    // 
    if (first_name && last_name && email && username && password) {
      signUp(data);
    } else {
      toasterShow({
        show: true,
        autoHide: false,
        type: toaster_w,
        description: `please enter ${!first_name ? "First Name" : ""}${
          !last_name ? ", Last Name" : ""
        } ${!email ? ", Email Address" : ""} ${
          !username ? ", User Name" : ""
        } ${!password ? ", Password" : ""} & try again`,
      });
    }
  };
  const handleNavigation = () => {
    let a = get({ key: "auth" });
    
    // history.replace("/auction");
  };

  return (
    <>
      <div className="loginbody_signup">
        {/* ==============shape 1 ================= */}

        <div className="loginMainview">
          {/* ==========rightside================== */}
          <div className="Leftsides">
            <div className="loginfield_signup p-2">
              <div className="loginfieldwrapper">
                <Col className=" mobileform">
                  <div className="formcontainer">
                    <h5 className="logintext mt-3">
                      {signup_page.signup_text}
                    </h5>
                    <div className="registration">
                      <div className="form-group mr-3">
                        <label htmlFor="first_name">
                          {signup_page.fname_text}
                        </label>
                        <input
                          id="first_name"
                          type="text"
                          className="form-control"
                          placeholder={signup_page.fname_placeholder}
                        />
                      </div>

                      <div className="form-group ">
                        <label htmlFor="last_name">
                          {signup_page.Lname_text}
                        </label>
                        <input
                          id="last_name"
                          type="text"
                          className="form-control"
                          placeholder={signup_page.Lname_placeholde}
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="email">{signup_page.email_text}</label>
                      <input
                        id="email"
                        type="email"
                        className="form-control"
                        placeholder={signup_page.email_placeholder}
                        // onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="email">{signup_page.userName}</label>
                      <input
                        id="username"
                        type="text"
                        // value={username}
                        className="form-control"
                        placeholder={signup_page.Uname_placeholder}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="password">
                        {signup_page.password_signup}
                      </label>
                      <input
                        id="password"
                        type="password"
                        className="form-control"
                        placeholder={signup_page.password_signup_placeholder}
                      />
                    </div>
                    <div className="form-group">
                      <div className="custom-control custom-checkbox" style={{display:'none'}}>
                        <input
                          id="remember_me"
                          type="checkbox"
                          className="custom-control-input"
                          // id="customCheck1"
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="customCheck1"
                        >
                          {signup_page.rememberme_text}
                        </label>
                      </div>
                    </div>
                    <button
                      onClick={signUpHandler}
                      // to="/auction"
                      // type="submit"
                      name="CTAButton"
                    >
                      {signup_page.register_text}
                    </button>
                    <p className=" mt-2 text-right">
                      {signup_page.already_text}{" "}
                      <Link to="/login">{signup_page.loginText}</Link>
                    </p>
                  </div>
                </Col>
                <Col className="mobileimagecontainer">
                  <div className="carimage">
                    <div className="logincardimage">
                      <img src={signup_page.logourl} alt="BigCo Inc. logo" />
                    </div>
                    <div className="imagetext">
                      <p>{signup_page.image_text}</p>
                    </div>
                  </div>
                </Col>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
const mapStateToProps = (state) => ({
  auth: state.auth,
  SIGN_UP_done: state.auth.SIGN_UP_done,
});
const mapDispatchToProps = (dispatch) => ({
  // login action
  signUp: (data) =>
    dispatch({
      type: SIGN_UP,
      payload: { type: SIGN_UP, method: POST, label: Labels.signUp, data },
    }),
  //
  toasterShow: (data) =>
    dispatch({
      type: TOASTER_SHOW,
      payload: {
        ...data,
      },
    }),
  //
});

export default connect(mapStateToProps, mapDispatchToProps)(Signup);
