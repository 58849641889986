import {
  BRANDS,
  EVALUATION_CREATE,
  SELL_MY_CAR_STEPS,
  OFFICE_TIMING,
  APPOINTMENT_CREATE,
  EVALUATE_IMAGE,
  EVALUATE_VIDEO,
  EVALUATE_DAMAGE_IMAGE,
  EVALUATE_AUDIO,
  APPOINTMENT_UPDATE,
  CAR_REG_NUMBER,
} from "../constant/index";
import { sellMyCarStepsName } from "../constant/constants";
const defaultState = {
  fetching: false,
  data: [],
  isSelected: false,
  current: {},
 
};
const INITIAL_STATE = {
  car_reg_:{},
  car_reg_loading:false,
  fetchingBrands: true,
  brands: [],
  //
  loading: false,
  loading_officetiming: false,
  loading_appointment_create: false,
  step1complete:false,

  step3complete:false,
  currentStep: 1,
  // step1
  step1: {
    brand: defaultState,
    model: defaultState,
    fuel_type: defaultState,
    year: defaultState,
    variant: defaultState,
  },
  // step2
  step2: {
    mileage: defaultState,
    previous_owners: defaultState,
    services_history: defaultState,
    reg_number: "",
  },
  //step 3
  user: {
    name: "",
    email: "",
    postcode: "",
    mobile_number: "",
  },
  // step 4
  step4: {
    office: {
      Area: "",
      Postcode: "",
      address: "",
      id: "",
      timming: {},
    },
    time_selected: {
      id: 0,
      day_index: 0,
      slot: "",
      day: "",
      date: "",
      day_formated: "",
    },
  },
  create_image: [],
  create_image_loading: false,
  damage: {
    damage_image: [],
    damage_image_loading: false,
    description: "",
  },
  audio: {
    audio: [],
    audio_loading: false,
    description: "",
  },
  video: {
    create_video: [],
    create_video_loading: false,
    description: "",
  },
  //
  evaluation_create: {},
  officetiming: [],
  appointment_create: {},
};
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // 1 case when requested
    case `${BRANDS}_REQUEST`:
      return {
        ...INITIAL_STATE,
        fetchingBrands: true,
      };
    // 2 case when successful
    case `${BRANDS}_SUCCESS`:
      return {
        ...state,
        fetchingBrands: false,
        brands: action.payload,
      };
    // 3 case when  rejected response get from api
    case `${BRANDS}_FAIL`:
      return {
        ...state,
        fetchingBrands: false,
      };
    // 4 case when some thing went wrong while api call may be syntex error
    case `${BRANDS}_ERROR`:
      return {
        ...state,
        fetchingBrands: false,
      };
    //
    case SELL_MY_CAR_STEPS:
      switch (action.payload.type) {
        case "brand":
          let brandstep1 = {
            brand: action.payload.data,
            // model: defaultState,
            fuel_type: defaultState,
            year: defaultState,
            variant: defaultState,
          };
          return {
            ...INITIAL_STATE,
            fetchingBrands: state.fetchingBrands,
            brands: state.brands,
            step1: {
              ...state.step1,
              ...brandstep1,
            },
          };
        case "model":
          let modelstep1 = {
            model: action.payload.data,
            // fuel_type: defaultState,
            year: defaultState,
            variant: defaultState,
          };
          return {
            ...state,
            step1: { ...state.step1, ...modelstep1 },
          };
        case "fuel_type":
          let fuelTypestep1 = {
            fuel_type: action.payload.data,
            // year: defaultState,
            variant: defaultState,
          };
          return {
            ...state,
            step1: { ...state.step1, ...fuelTypestep1 },
          };
        case "year":
          let yearstep1 = {
            year: action.payload.data,
            // variant: defaultState,
          };
          return {
            ...state,
            step1: { ...state.step1, ...yearstep1 },
          };
        case "variant":
          return {
            ...state,
            step1: { ...state.step1, variant: action.payload.data },
            currentStep: action.payload.data.isSelected ? 2 : 1,
          };
        // step 2
        case "mileage":
          return {
            ...state,
            step2: {
              ...state.step2,
              mileage: action.payload.data,
            },
          };
        case "previous_owners":
          return {
            ...state,
            step2: {
              ...state.step2,
              previous_owners: action.payload.data,
            },
          };
        case "services_history":
          return {
            ...state,
            step2: {
              ...state.step2,
              services_history: action.payload.data,
            },
          };
        case "reg_number":
          return {
            ...state,
            step2: {
              ...state.step2,
              reg_number: action.payload.data,
            },
          };
        // step 3
        case "user_name":
          return {
            ...state,
            user: {
              ...state.user,
              name: action.payload.data,
            },
          };
        case "user_email":
          return {
            ...state,
            user: {
              ...state.user,
              email: action.payload.data,
            },
          };
        case "user_postcode":
          return {
            ...state,
            user: {
              ...state.user,
              postcode: action.payload.data,
            },
          };
        case "user_mobile_number":
          return {
            ...state,
            user: {
              ...state.user,
              mobile_number: action.payload.data,
            },
          };
        case "reset":
          return INITIAL_STATE;
        //
        case "current_step":
          const current_step_ = action.payload.data;

          return {
            ...state,
            currentStep:
              current_step_ <= sellMyCarStepsName.length - 2
                ? current_step_ < 1
                  ? state.currentStep
                  : current_step_
                : state.currentStep,
          };
        //  step 4
        case "create_image":
          return {
            ...state,
            create_image: action.payload.data,
          };
        //  step video
        case "create_video":
          return {
            ...state,
            video: {
              ...state.video,
              create_video: action.payload.data,
            },
          };
        case "create_video_description":
          return {
            ...state,
            video: {
              ...state.video,
              description: action.payload.data,
            },
          };
        //  step audio
        case "audio":
          return {
            ...state,
            audio: {
              ...state.audio,
              audio: action.payload.data,
            },
          };
        case "audio_description":
          return {
            ...state,
            audio: {
              ...state.audio,
              description: action.payload.data,
            },
          };
        //  step damage image
        case "damage_image":
          return {
            ...state,
            damage: {
              ...state.damage,
              damage_image: action.payload.data,
            },
          };
        case "damage_image_description":
          return {
            ...state,
            damage: {
              ...state.damage,
              description: action.payload.data,
            },
          };
        //  step 5
        case "office":
          return {
            ...state,
            step4: {
              ...state.step4,
              office: action.payload.data,
            },
          };
        case "time_selected_slot":
          return {
            ...state,
            step4: {
              ...state.step4,
              time_selected: {
                ...state.step4.time_selected,
                slot: action.payload.data,
              },
            },
          };
        case "time_selected":
          return {
            ...state,
            step4: {
              ...state.step4,
              time_selected: {
                ...state.step4.time_selected,
                ...action.payload.data,
              },
            },
          };
        case "time_selected_reset":
          return {
            ...state,
            step4: {
              ...state.step4,
              time_selected: INITIAL_STATE.step4.time_selected,
            },
          };
      }
    //
    case `${EVALUATION_CREATE}_REQUEST`:
      return {
        ...state,
        loading: true,
      };
    case `${EVALUATION_CREATE}_SUCCESS`:
      
      return {
        ...state,
        loading: false,
        currentStep: 4,
        evaluation_create: action.payload,
        step1complete:true,
      };
    case `${EVALUATION_CREATE}_FAIL`:
      return {
        ...state,
        loading: false,
      };
    case `${EVALUATION_CREATE}_ERROR`:
      return {
        ...state,
        loading: false,
      };
    //
    case `${OFFICE_TIMING}_REQUEST`:
      return { ...state, loading_officetiming: true };
    case `${OFFICE_TIMING}_SUCCESS`:
      return {
        ...state,
        loading_officetiming: false,
        officetiming: action.payload,
      };
    case `${OFFICE_TIMING}_FAIL`:
      return { ...state, loading_officetiming: false };
    case `${OFFICE_TIMING}_ERROR`:
      return { ...state, loading_officetiming: false };
    //
    //
    case `${APPOINTMENT_CREATE}_REQUEST`:
      return { ...state, loading_appointment_create: true };
    case `${APPOINTMENT_CREATE}_SUCCESS`:
      
      return {
        ...state,
        loading_appointment_create: false,
        appointment_create: action.payload,
        currentStep: 9,
        step3complete:true,
      };
    case `${APPOINTMENT_CREATE}_FAIL`:
      return { ...state, loading_appointment_create: false };
    case `${APPOINTMENT_CREATE}_ERROR`:
      return { ...state, loading_appointment_create: false };
    //
    //
    case `${EVALUATE_IMAGE}_REQUEST`:
      return { ...state, create_image_loading: true };
    case `${EVALUATE_IMAGE}_SUCCESS`:
      return { ...state, create_image_loading: false, currentStep: 5 };
    case `${EVALUATE_IMAGE}_FAIL`:
      
      return { ...state, create_image_loading: false };
    //
    case `${EVALUATE_VIDEO}_REQUEST`:
      return {
        ...state,
        video: {
          ...state.video,
          create_video_loading: true,
        },
      };
    case `${EVALUATE_VIDEO}_SUCCESS`:
      return {
        ...state,
        video: {
          ...state.video,
          create_video_loading: false,
        },
        currentStep: 7,
      };
    case `${EVALUATE_VIDEO}_FAIL`:
      
      return {
        ...state,
        video: {
          ...state.video,
          create_video_loading: false,
        },
      };
    //
    //
    case `${EVALUATE_AUDIO}_REQUEST`:
      return {
        ...state,
        audio: {
          ...state.audio,
          audio_loading: true,
        },
      };
    case `${EVALUATE_AUDIO}_SUCCESS`:
      return {
        ...state,
        audio: {
          ...state.audio,
          audio_loading: false,
        },
        currentStep: 8,
      };
    case `${EVALUATE_AUDIO}_FAIL`:
      
      return {
        ...state,
        audio: {
          ...state.audio,
          audio_loading: false,
        },
      };
    //
    //
    case `${EVALUATE_DAMAGE_IMAGE}_REQUEST`:
      return {
        ...state,
        damage: {
          ...state.damage,
          damage_loading: true,
        },
      };
    case `${EVALUATE_DAMAGE_IMAGE}_SUCCESS`:
      return {
        ...state,
        damage: {
          ...state.damage,
          damage_loading: false,
        },
        currentStep: 6,
      };
    case `${EVALUATE_DAMAGE_IMAGE}_FAIL`:
      
      return {
        ...state,
        damage: {
          ...state.damage,
          damage_loading: false,
        },
      };
    //===============update appoitment====================

    // case `${APPOINTMENT_UPDATE}_SUCCESS`:
    //   return { INITIAL_STATE,  };


// =========================car reg number ===================


case `${CAR_REG_NUMBER}_REQUEST`:
      return {
        ...state,
        car_reg_loading:true
      };
    case `${CAR_REG_NUMBER}_SUCCESS`:
      // console.log"car_reg_car_reg_car_reg_",action.payload)
      return {
        ...state,
        car_reg_:action.payload,
        car_reg_loading:true
      };
  

      case `${CAR_REG_NUMBER}_FAIL`:
        
        return {
          ...state,
          car_reg_:action.payload,
          car_reg_loading:false
        };












  }
  return state;
};
