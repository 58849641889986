import {
  SHOW_INTEREST,
  SHOW_INTEREST_STEPS,
  SHOW_INTEREST_STEPS_CURRENT,
  BRANDS,
  CAR_MODELS
} from "../constant/index";
//
const defaultState = {
  data: {},
  selected: [],
  isSelected: false,
  loading: false,
  toster:false,
};
//
const INITIAL_STATE = {
  loading: false,
  loadingBrands: true,
  brands: { ...defaultState, loading: true },
  models: defaultState,
  fuel_types: defaultState,
  years: defaultState,
  variants: defaultState,
  currentStep: "brands",
};
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // 1 case when requested
    case `${BRANDS}_REQUEST`:
      return {
        ...INITIAL_STATE,
        brands: {
          ...defaultState,
          loading: true,
        },
        loadingBrands: true,
      };
    // 2 case when successful
    case `${BRANDS}_SUCCESS`:
      return {
        ...state,
        brands: {
          ...state.brands,
          loading: false,
          data: {
            brands: action.payload,
          },
        },
        loadingBrands: false,
      };
    // 3 case when  rejected response get from api
    case `${BRANDS}_FAIL`:
      return {
        ...state,
        brands: {
          ...defaultState,
          loading: true,
        },
        loadingBrands: false,
      };
    // 4 case when some thing went wrong while api call may be syntex error
    case `${BRANDS}_ERROR`:
      return {
        ...state,
        brands: {
          ...defaultState,
          loading: true,
        },
        loadingBrands: false,
      };
    //
     case `${CAR_MODELS}_REQUEST`:
      console.log(action.payload) 
      return {
        ...INITIAL_STATE,
        models: {
          ...defaultState,
          loading: true,
        },
      };
    // 2 case when successful
    case `${CAR_MODELS}_SUCCESS`:
      console.log(action.payload) 
      return {  
        ...state,
        models: {
          ...state.models,
          loading: false,
          data: {
            models: action.payload,
          },
        },
        loadingBrands: false,
      };
    // 3 case when  rejected response get from api
    case `${CAR_MODELS}_FAIL`:
      console.log(action.payload) 
      return {
        ...state,
        models: {
          ...defaultState,
          loading: true,
        },
        loadingBrands: false,
      };

    case SHOW_INTEREST_STEPS:
      let objName = action.payload.type;
      let objData = action.payload.data;
      return {
        ...state,
        [objName]: {
          ...state[objName],
          ...objData,
        },
      };
    //
    case SHOW_INTEREST_STEPS_CURRENT:
      return {
        ...state,
        currentStep: action.payload,
      };
    //
    case `${SHOW_INTEREST}_REQUEST`:
      return { ...state, loading: true };
    case `${SHOW_INTEREST}_SUCCESS`:
      
      return { ...state, ...INITIAL_STATE ,
        toster:true,
      };
    case `${SHOW_INTEREST}_FAIL`:
      return { ...state, loading: false };
    case `${SHOW_INTEREST}_ERROR`:
      return { ...state, loading: false };
    //

    default:
      return state;
  }
  return state;
};
