import React from "react";
import "./style.css";
import Footer from "../../components/Footer";
import { scrollTop } from "../../utils/window";
import { useContext } from "react";
import FeedbackContext from "../../context/cmscontext";
function AboutUs() {
     const { feedback, isLoading } = useContext(FeedbackContext);
var about_us = feedback.about_us;
  return (
    <>
      {scrollTop()}
      <div className="showInterest_headingCont ">
        <div className="showInterest_heading">{about_us.who_text}</div>
        <div className="showInterest_description">{about_us.who_desc}</div>
      </div>

      <div className="container">
        <h2 className="mt-5">{about_us.our_text}</h2>

        <p className="us_text">{about_us.our_desc}</p>

        <h2>{about_us.a_new_text}</h2>

        <p className="us_text">{about_us.a_new_desc}</p>
        <h2>{about_us.pakistan_text}</h2>

        <p className="us_text">{about_us.pakistan_desc}</p>
      </div>
    </>
  );
}

export default AboutUs;
