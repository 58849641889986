import React from "react";
import Form from "../../components/Form";
//
import { connect } from "react-redux";
import Labels from "../../services/apiLabels";
import { SELL_MY_CAR_STEPS, EVALUATE_DAMAGE_IMAGE } from "../../constant";
import { FaPlus } from "react-icons/fa";
import { MdCancel } from "react-icons/md";
import { GET, POST } from "../../constant/apiMethods";
import { API_ENDPOINT } from "../../services/api";
import IconInput from "../../components/Form/Input";
//
function SellMyCarstep5(props) {
  const {
    damage_image,
    setdamage_image,
    post_damage_image_fail,
    post_damage_image_success,
    post_damage_image_request,
    evaluation,
    createImageLoading,
    setdamage_image_description,
    damage_image_description,
  } = props;
  let imageInputRef = React.createRef();
  //
  const post_damage_image = async (data) => {
    const parameters = {
      method: POST,
      body: data,
    };
    return await fetch(API_ENDPOINT(Labels.damageimages), parameters)
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        return json;
      });
  };
  //
  const setfilesArrHandler = (e) => {
    const filesObj = imageInputRef.current.files || {};
    const arr = Object.values(filesObj);
    setdamage_image([...damage_image, ...arr]);
  };
  //
  const post_damage_imageHandler = async () => {
    // e.preventDefault();
    if (damage_image.length > 0) {
      const bodyFormData = new FormData();
      for (var i = 0; i < damage_image.length; i++) {
        bodyFormData.append("image", damage_image[i]);
      }
      bodyFormData.append("evaluate_image", evaluation.id);
      // bodyFormData.append("evaluate_damageimage", 14);
      bodyFormData.append("description", damage_image_description);

      //
      try {
        post_damage_image_request();
        const response = await post_damage_image(bodyFormData);
        post_damage_image_success(response);
        
      } catch (error) {
        
      }
      //
      //;
    } else {
      
    }
  };
  const removeImage = (index) => {
    const arr = [...damage_image];
    if (index > -1) {
      arr.splice(index, 1);
      setdamage_image(arr);
    }
  };
  return (
    <div className="w-100 h-auto d-flex justify-content-center">
      <Form
        width="fluid"
        title="Add Car Damage Images"
        onSubmit={post_damage_imageHandler}
        buttonText="Submit Images"
        // className={formanimationClassname}
        loading={createImageLoading}
        loadingText="Submitting Images ..."
      >
        <div className="dropdown_wrapper">
          <IconInput
            loading={createImageLoading}
            type="text"
            label="Description"
            placeholder="Description"
            onChangeText={setdamage_image_description}
            value={damage_image_description}
          />
        </div>
        <div className="dropdown_wrapper d-flex align-items-center flex-wrap">
          <label className="imgpiker" htmlFor="img">
            <FaPlus color="var(--primary)" size={45} />
          </label>

          <input
            disabled={createImageLoading}
            type="file"
            id="img"
            name="img"
            accept="image/*"
            className="d-none"
            ref={imageInputRef}
            onChange={setfilesArrHandler}
            // onChange={(e) => // console.log"file", e.target.files)}
            multiple
          />
          {damage_image.map((item, index) => {
            return (
              <div className="imgpiker">
                <img
                  width="100%"
                  height="100%"
                  src={URL.createObjectURL(item)}
                  alt={`car image ${index} `}
                />
                <span
                  onClick={() => removeImage(index)}
                  style={{ position: "absolute", top: 0, right: 0 }}
                >
                  <MdCancel size={20} color="var(--secondary)" />
                </span>
              </div>
            );
          })}
        </div>
      </Form>
    </div>
  );
}
////////
const mapStateToProps = (state) => ({
  damage_image: state.sellMyCar.damage.damage_image,
  damage_image_description: state.sellMyCar.damage.description,
  evaluation: state.sellMyCar.evaluation_create,
  createDamageLoading: state.sellMyCar.damage.damage_image_loading,
});
const mapDispatchToProps = (dispatch) => ({
  setdamage_image: (data) =>
    dispatch({
      type: SELL_MY_CAR_STEPS,
      payload: {
        type: "damage_image",
        data,
      },
    }),
  setdamage_image_description: (data) =>
    dispatch({
      type: SELL_MY_CAR_STEPS,
      payload: {
        type: "damage_image_description",
        data,
      },
    }),
  post_damage_image_request: (data) =>
    dispatch({
      type: `${EVALUATE_DAMAGE_IMAGE}_REQUEST`,
      payload: data,
    }),
  post_damage_image_success: (data) =>
    dispatch({
      type: `${EVALUATE_DAMAGE_IMAGE}_SUCCESS`,
      payload: data,
    }),
  post_damage_image_fail: (data) =>
    dispatch({
      type: `${EVALUATE_DAMAGE_IMAGE}_FAIL`,
      payload: data,
    }),
});
export default connect(mapStateToProps, mapDispatchToProps)(SellMyCarstep5);
