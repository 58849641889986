import {
  all,
  call,
  fork,
  put,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import { fetchData } from "../services/api";
import {
  TOASTER_SHOW,
  TOASTER_HIDE,
  LOADER_SHOW,
  LOADER_HIDE,
} from "../constant";
export function* sagaRoot(payload) {
  
  const { type, hideOverlayLoader } = payload.payload;
  const showOverlayLoader = hideOverlayLoader ? !hideOverlayLoader : true;
  yield put({ type: `${type}_REQUEST` });
  try {
    if (showOverlayLoader) yield put({ type: LOADER_SHOW });
    const response = yield call(fetchData, payload);
    

    if (response.message.type === "success") {
      

      yield put({ type: `${type}_SUCCESS`, payload: response.data, message:response.message });
      yield put({ type: LOADER_HIDE });
      if(type!="BRANDS"){
      yield put({ type: TOASTER_SHOW, payload: response.message });
      }
    } else if (response.message.type === "error") {
      
      yield put({ type: `${type}_FAIL`, payload: response.data });
      yield put({ type: LOADER_HIDE });
      yield put({ type: TOASTER_SHOW, payload: response.message });
    }
    return { ...response, bodyData: payload.payload };
  } catch (error) {
    
    yield put({ type: `${type}_ERROR`, payload: error });
    yield put({ type: LOADER_HIDE });
    yield put({
      type: TOASTER_SHOW,
      payload: {
        show: true,
        autoHide: true,
        type: "error",
        // heading: "",
        description: "Contact With Site Administor.",
      },
    });
  }
}
